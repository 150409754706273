<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Purchase Invoices</li>
						</ol>
					</div>
					<div class="page-title-right2">

						<button type="button" class="btn download-icon" (click)="exportAsXLSX()"
							style="margin-right: 4px !important">
							<i class="ri-download-2-line"></i>
						</button>
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="uploadInvoiceMoadal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Finance Eunctionality Available -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12">
									<div class="filter-all">
										<div class="d-flex align-items-center justify-content-between">
											<div class="page-title-right2">
												<div class="select_box select_box_text">
													<svg xmlns="http://www.w3.org/2000/svg" width="33.123"
														height="35.858" viewBox="0 0 33.123 35.858">
														<g id="Group_86" data-name="Group 86"
															transform="translate(857.079 -756.758)">
															<path id="Path_2227" data-name="Path 2227"
																d="M95.806,83.085H76.251a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H68.6a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM72.427,85.835a1.467,1.467,0,1,1,1.467-1.467A1.468,1.468,0,0,1,72.427,85.835Z"
																transform="translate(-921.196 676.574)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2228" data-name="Path 2228"
																d="M95.806,211.618H92.753a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H85.106a4.033,4.033,0,0,0,7.647,0h3.052a1.284,1.284,0,0,0,0-2.568Zm-6.876,2.751A1.467,1.467,0,1,1,90.4,212.9,1.468,1.468,0,0,1,88.93,214.368Z"
																transform="translate(-921.196 561.785)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2229" data-name="Path 2229"
																d="M95.806,340.151H81.752a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H74.1a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM77.928,342.9a1.467,1.467,0,1,1,1.466-1.467A1.468,1.468,0,0,1,77.928,342.9Z"
																transform="translate(-921.196 446.996)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
														</g>
													</svg>
												</div>
												<div class="select_box ml-3">
													<!-- <div class="input-group date_group">
														<div id="reportrange" class="reportrange"
															style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
															<i class="fa fa-calendar"></i>&nbsp; <span></span> <i
																class="fa fa-caret-down"></i>
														</div>
													</div> -->
													<div class="input-group" daterangepicker [options]="picker1"
														(selected)="selectedDate($event, chosenDate)">
														<span class="form-control uneditable-input" name="daterange">
															<span *ngIf="chosenDate.start else elseblock">
																{{ chosenDate.start | date:'mediumDate' }} - {{
																chosenDate.end| date:'mediumDate' }}
															</span>
															<ng-template #elseblock>
																<span>
																	<div class="calendar-icon"><span>Select
																			date</span><i
																			class="ri-calendar-2-line"></i></div>
																</span>
															</ng-template>
														</span>
														<span class="input-group-btn">
															<a type="button" class="btn btn-default"><i
																	class="glyphicon glyphicon-calendar"></i></a>
														</span>
													</div>
												</div>
												<div class="price_range">
													<label>Amount</label>
													<ngx-slider [(value)]="minValue" [(highValue)]="maxValue"
														[options]="options" (userChange)="onUserChange()">
													</ngx-slider>
												</div>
												<div class="select_box ml-2" *ngIf="userlogindata.role == 'Finance'">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories"
														[(ngModel)]="plantName"
														(change)="selectPlantValue($event.target.value)">
														<option value="" selected>Select Plant </option>
														<option value="{{plant?.unitName}}"
															*ngFor="let plant of unitlist">
															{{plant?.unitName}}</option>
														<!-- <option value="New Delhi">New Delhi</option>
													<option value="Gurgaon Plant">Gurgaon Plant</option>
													<option value="Greater Noida Plant">Greater Noida Plant</option> -->
													</select>
												</div>
												<div class="select_box ml-2" *ngIf="userlogindata.role == 'Finance'">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories"
														[(ngModel)]="partyDetail"
														(change)="selectpartyDetailValue($event.target.value,'Vendor')">
														<option value="" selected>Select Party Detail </option>
														<option [value]="party?._id" *ngFor="let party of vendorlist">
															{{party?.name}}</option>
														<!-- <option value="Vendor 1">Vendor1</option>
													<option value="Vendor 2">Vendor2</option>
													<option value="Vendor 3">Vendor3</option> -->
													</select>
												</div>
												<div class="select_box ml-2">
													<div class="input-group-reset">
														<button type="button" class="btn custom-select-sm"
															(click)="resetAll()"><i
																class="mdi text-primary font-size-18 mdi-refresh"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<div class="dataTables_filter">
											<label>
												<input type="search" class="form-control form-control-sm"
													placeholder="Search..." [(ngModel)]="keyword"
													(input)="searchInvoice($event.target.value)">
											</label>
										</div>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th style="width:90px;">Invoice #</th>
										<th style="width:90px;">e-way Bill #</th>
										<th style="width:90px;">PO #</th>
										<th style="width:90px;">Date</th>
										<th style="width:75px;">Amount (₹)</th>
										<th style="width:60px;">GST (₹)</th>
										<th style="width:60px;">Plant</th>
										<th style="width:60px;">Party Detail</th>
										<th style="width:90px;" *ngIf="userlogindata.role != 'Finance'">Finance Status
										</th>
										<th style="width:90px;">Gatekeeper
											Status</th>

										<!-- <th>Party Details</th> -->
										<th style="width:110px;" *ngIf="userlogindata.role == 'Finance'">Finance Approve
										</th>
										<th style="width:85px;">GSTR2A Recon Status</th>
										<th *ngIf="userlogindata.role != 'Vendor'"> GST Input Credit Eligible</th>
										<th style="width:85px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="invoiceList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let inv of invoiceList | paginate: {id: 'invoiceId', itemsPerPage: limit, currentPage: page,totalItems: count }; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Invoice #">{{inv?.invoiceNumber}}</td>
										<td data-title="e-Way Bill #">{{inv?.eWayBill}}</td>
										<td data-title="PO #">{{inv?.purchaseOrderId?.purchaseOrderNo}}</td>
										<td data-title="Date">{{inv?.invoiceDate | date}}</td>
										<!-- <td data-title="Amount">{{inv?.totalAmount | currency:'INR'}}</td> -->
										<td data-title="Amount">{{inv?.totalAmount}}</td>
										<!-- <p *ngFor="let gst of inv.itemDetails">{{gst?.CGSTAmt}}</p> -->
										<td data-title="GST">{{inv?.GST}}</td>
										<td data-title="Plant">
											{{inv?.purchaseOrderId?.plant}}</td>
										<td data-title="Party Detail">
											<strong><em>{{inv?.purchaseOrderId?.partyCode}}</em></strong>
											<br>{{inv?.purchaseOrderId?.partyName}}
										</td>
										<td data-title="Not Approved" *ngIf="userlogindata.role != 'Finance'">

											<span class="badge badge-success"
												*ngIf="inv?.status == 'Approved'">Approved</span>
											<span class="badge badge-danger" *ngIf="inv?.status == 'Not Approved'">Not
												Approved</span>
										</td>
										<td data-title="Approved">
											<span class="badge badge-success"
												*ngIf="inv?.gatekeeperApproval == 'Approved'">Approved</span>
											<span class="badge badge-warning"
												*ngIf="inv?.gatekeeperApproval == 'Pending'">Pending</span>
											<span class="badge badge-danger"
												*ngIf="inv?.gatekeeperApproval == 'Not Approved'">Rejected</span>
										</td>


										<td data-title="Finance Approve" *ngIf="userlogindata.role == 'Finance'">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"
													(click)="changeStatusInvoice(inv?._id)"
													[checked]="inv.status == 'Approved' ? true  : false"
													*ngIf="inv.gatekeeperApproval == 'Approved' && inv.storekeeperApproval == 'Approved'">
												<span class="slider round"></span>

											</label>
										</td>
										<td data-title="GSTR2A Recon Status"><span
												class="badge badge-warning ng-star-inserted">{{inv?.matchStatus?inv?.matchStatus:'Not
												Found'}}</span></td>
										<td data-title="GST Input Credit Eligible"
											*ngIf="userlogindata.role != 'Vendor'"><label class="switch switch-text">
												<input type="checkbox" id="verify"
													(click)="GstchangeStatus(inv?._id,$event)"
													[checked]="inv.GSTCreditEligible == 'Yes' ? true  : false"
													[disabled]="userlogindata.role != 'Admin' && userlogindata.role != 'Finance'"><span
													class="slider round"></span>
											</label></td>
										<td data-title="Action"> <a
												[routerLink]="['/admin/invoices/invoice-details',inv?._id]"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
											<!-- <button type="button" class="mr-2 text-primary btn_style" (click)="DownloadsingleOrders()"><i
													class="ri-file-list-3-line font-size-16"></i>
											</button> -->
											<a href="javascript:void(0);" class="text-danger"
												(click)="openDeleteModal(inv)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" *ngIf="invoiceList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="invoiceId" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next" (pageChange)="GetInvoiceList($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->
<!-- Start Modal Add Detail -->
<div class="modal fade add_detail" id="add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Add Invoice</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadInvoiceExcel()">
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Upload Excel</label>
								<div class="custom_file">
									<input type="file" id="customFile" formControlName="excelSheet"
										class="custom_file_input" (change)="handleFileInput($event)"
										[ngClass]="{ 'is-invalid': submitted && a.excelSheet.errors }" accept=".xlsx">
								</div>
								<div class="error-msg" *ngIf="submitted && a.excelSheet.errors"> <span
										style="color: red;" *ngIf="a.excelSheet.errors.required">
										Excel sheet is required.</span></div>
								<div class="error-msg error-ul" *ngIf="erroMesage">
									<!-- <span style="color: red;">
																				{{erroMesage}}</span> -->
									<ul>
										<li *ngFor="let err of erroMesage">{{err}}</li>
										<!-- <li>Plant can not be empty</li>
																				<li>Plant can not be empty</li> -->
									</ul>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<!-- Start Modal Edit Detail -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Invoice</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Upload Excel</label>
								<div class="custom_file">
									<input type="file" id="customFile" class="custom_file_input">


								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<div class="modal fade alert_popup" id="delete-invoice" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Invoices (Purchase)</h2>
							<h6>{{invoiceCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"
								(click)="deleteInvoice()"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>

<!-- Download Invoice Template -->
<div id="userorderdetail" class="invoice-box" style="margin: auto;width: 100%;display:none;border: 0px;padding: 20px;">
	<div class="container-fluid invoice-container"
		style="margin: auto; padding:25px;max-width:100%;background-color: #fff;border: 1px solid #ccc;border-radius: 6px;">
		<header>
			<table style="width: 100%;">
				<tbody>
					<tr>
						<td style="width: 50%;  text-align:left;">
							<img src="../../../../assets/images/logo-dark.png" style="height:50px;">
						</td>
						<td style="width: 50%;text-align: right;">
							<h4
								style="font-family: 'Poppins', sans-serif;font-size: 16px;font-weight:500 !important;font-style: normal;letter-spacing: normal;line-height: normal;">
								Invoice</h4>
						</td>
					</tr>
				</tbody>
			</table>
			<hr>
		</header>
		<main>
			<table style="width: 100%;border-collapse: collapse; margin-bottom:20px;">
				<tbody>
					<tr>
						<td class="col-sm--6"
							style="width: 50%;font-family: 'Poppins', sans-serif;font-size: 14px;font-weight: 400 !important;font-style: normal;letter-spacing: normal;line-height: normal;text-align:left;">
							<b
								style="font-family: 'Poppins', sans-serif;font-size: 14px;font-style: normal;letter-spacing: normal;line-height: normal; text-align: right; font-weight:500 !important;">
								Date</b> {{invoidata.invoiceDate}}
						</td>
						<td class="col-sm--6"
							style="width: 50%;font-family: 'Poppins', sans-serif;font-size: 14px;font-weight: 400 !important;font-style: normal;letter-spacing: normal;line-height: normal;text-align: right;">
							<b
								style="font-family: 'Poppins', sans-serif;font-size: 14px;font-weight:500 !important;font-style: normal;letter-spacing: normal;line-height: normal;">Invoice
								No </b>
							<div class="order-details-id">{{invoidata.invoiceDate}}</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="card"
				style="position: relative;display: flex;flex-direction: column;min-width: 0;word-wrap: break-word;background-color: #fff;background-clip: border-box;border: 1px solid rgba(0,0,0,.125);border-radius: .25rem;">
				<div class="card-body px-0" style="padding: 0px;">
					<table class="table mb-0"
						style="margin-bottom: 0px !important;color: #505d69;width: 100%;border-collapse: collapse;">
						<thead class="thead-light">
							<tr>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-bottom: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									Name</th>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-bottom: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									Invoice No</th>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									Date</th>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									Amount</th>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									GST</th>
								<th
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69;font-weight: 500; text-align: left; font-family: 'Poppins', sans-serif;">
									Total Amount</th>


							</tr>
						</thead>
						<tbody>
							<tr>
								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									Bluth Company
								</td>

								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									{{invoidata.invoiceNumber}}
								</td>
								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									{{invoidata.invoiceDate}}
								</td>
								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									{{invoidata.amount}}
								</td>
								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									{{invoidata.gstAmount}}
								</td>
								<td
									style="font-size: 12px;padding: 10px 5px;vertical-align: top;border-top: 1px solid #eff2f7;color: #505d69; text-align: left; font-family: 'Poppins', sans-serif; word-break: break-word;">
									{{invoidata.totalAmount}}
								</td>



							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</main>

	</div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>
