<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Vendors</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="OpenVendorModal('add','')">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." (input)="applyFilter($event.target.value)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:75px;">Logo</th>
										<th style="width:90px;">Name</th>
										<th style="width:90px;">Code</th>
										<th style="width:130px;">Email</th>
										<th style="width:130px;">GST Number</th>
										<th style="width:100px;">Contact Detail</th>
										<th>Address</th>
										<th style="width:100px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="vendorlist?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of vendorlist | paginate: { id:'vendorlist',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Logo">
											<div class="bg_img" *ngIf="list.profileImage"
												style="background-color: rgb(248, 249, 250); background-image:url({{list.profileImage}})">
											</div>
											<div class="bg_img" *ngIf="!list.profileImage"
												style="background-color: rgb(248, 249, 250); background-image:url(assets/images/users/admin.jpg)">
											</div>

										</td>
										<td data-title="Name">{{list.name}}</td>
										<td data-title="Name">{{list.partyCode}}</td>
										<td data-title="Email">{{list.email}}</td>
										<td data-title="GST Number">{{list.gstNumber}}</td>
										<td data-title="Contact Detail">{{list.contactName}} <br>{{list.contactNumber}}
										</td>
										<td data-title="Address">{{list.vendorAddress}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="resetpassword(list)"><i
													class="mdi mdi-lock-reset align-middle mr-1 font-size-18"></i></button>
											<!-- <button type="button" class="mr-2 text-primary btn_style"
												data-toggle="modal" data-target=".edit_detail" (click)="OpenVendorModal('add','')"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button>  -->
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="OpenVendorModal(list,'edit')"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button>
											<!-- <a href="javascript:void(0);" class="text-danger"
												(click)="deletevendor(list,'')"><i
													class="mdi mdi-trash-can font-size-18"></i></a> -->
										</td>
									</tr>

								</tbody>
							</table>
							<div class="row" [hidden]="!vendorlist?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<pagination-controls id="vendorlist" responsive="true" previousLabel="Prev"
										nextLabel="Next" (pageChange)="onTableDataChange($event)">
									</pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  User Popup -->
<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0" *ngIf="!id">Add Vendor </h5>
				<h5 class="modal-title mt-0" *ngIf="id">Edit Vendor </h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="VendorForm">
					<div class="row">
						<input type="hidden" formControlName="id">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Logo</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input"
										formControlName="vendorimage" (change)="handleFileInput($event.target.files)">
									<div class="file_img" *ngIf="!imageUrl && !id"
										style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
									</div>
									<div class="file_img" *ngIf="imageUrl && !id"
										style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
									</div>
									<div *ngIf="id">
										<div class="file_img" *ngIf="!imageUrl"
											style="background-color: rgb(245, 245, 245);background-image: url({{vendorimg}});">
										</div>
										<div class="file_img" *ngIf="imageUrl"
											style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
										</div>
									</div>

								</div>
								<div *ngIf="imageErrMsg" class="invalid-feedback" style="display: block;">
									{{imageErrMsg}}</div>
								<div *ngIf="submitted && f.vendorimage.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.vendorimage.errors.required">Logo is required</div>

								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Party Name</label>
								<input type="text" class="form-control" formControlName="name">
								<div *ngIf="submitted && f.name.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.name.errors.required">Name is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Party Code</label>
								<input type="text" class="form-control" formControlName="partycode">
								<div *ngIf="submitted && f.partycode.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.partycode.errors.required">Partycode is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Email</label>
								<input type="email" class="form-control" formControlName="email"
									(keydown.space)="$event.preventDefault()">
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.email">Email must be a valid email address</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">GST Number</label>
								<input type="text" class="form-control" formControlName="gst">
								<div *ngIf="submitted && f.gst.errors" class="invalid-feedback" style="display: block;">
									<div *ngIf="f.gst.errors.required">GST is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Name</label>
								<input type="text" class="form-control" formControlName="contactname">
								<div *ngIf="submitted && f.contactname.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.contactname.errors.required">Contact Name is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Number</label>
								<input type="text" class="form-control" formControlName="contactnumber">
								<div *ngIf="submitted && f.contactnumber.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.contactnumber.errors.required">Contact Number is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Vendor Address</label>
								<textarea rows="3" class="form-control" autocomplete="off"
									formControlName="address"></textarea>
								<div *ngIf="submitted && f.address.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.address.errors.required">Vendor Address is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12" *ngIf="!id">
							<div class="form-group">
								<label for="validationCustom01">Password
								</label>
								<div class="password_box">
									<input [type]="password ? 'text' : 'password' " class="form-control"
										formControlName="password" (keydown.space)="$event.preventDefault()">
									<i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
										[ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
									<div *ngIf="submitted && f.password.errors" class="invalid-feedback"
										style="display: block;">
										<div *ngIf="f.password.errors.required">Password is required</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12" *ngIf="!id">
							<div class="form-group">
								<label for="validationCustom01">Confirm Password
								</label>
								<div class="password_box">
									<input [type]="confirmpassword ? 'text' : 'password' " class="form-control"
										formControlName="cpassword" (keydown.space)="$event.preventDefault()">
									<i (click)="showconfirmPassword()" class=" auti-custom-input-icon auti-custom-eye"
										[ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
									<div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback"
										style="display: block;">
										<div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
										<div *ngIf="f.cpassword.errors.mustMatch">Passwords must match</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit" (click)="SaveVendor()" *ngIf="!id">Save</button>
					<button class="btn btn-warning" type="submit" (click)="updatevendor()" *ngIf="id">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add  User Popup -->
<!-- Edit  User Popup -->
<!-- <div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Vendor</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Logo</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input">
									<div class="file_img"
										style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
									</div>

								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Name</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Email</label>
								<input type="email" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">GST Number</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Name</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Number</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Vendor Address</label>
								<textarea rows="3" class="form-control" autocomplete="off"></textarea>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div> -->
<!-- End Edit  User Popup -->

<!-- Edit User Reset Password Popup -->
<app-adminresetpassword [adminid]="adminid"></app-adminresetpassword>

<!-- End Edit User Reset Password Popup -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Vendor</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
				(click)="deletevendor(Id,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
				(click)="deletevendor(Id,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodals">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>