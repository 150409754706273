<div class="page-content">
	<div class="container-fluid">

		<div class="row">
			<div class="col-lg-12">

				<!-- Listing of Delivery Instructions -->
				<div class="card table_page">
					<div class="card-body pt-3">

						<div class="row align-items-center mb-2">
							<div class="col-sm-12 col-md-6">

								<label for="deliveryIns" class="stat-label">
									<span>Today's Delivery Instructions</span>
									<select id="deliveryIns" name="deliveryIns" class="form-control stat-select"
										(change)="getDataBYdate($event.target.value)">
										<option value="" selected>-- select --</option>
										<option value="today">Today's Delivery Instructions</option>
										<option value="yesterday">Yesterday's Delivery Instructions</option>
										<option value="all">All Delivery Instructions</option>
									</select>
								</label>

							</div>

							<div class="col-sm-12 col-md-6">
								<div class="dataTables_filter">
									<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light m-0" *ngIf="raiseInvoiceForSalesPo && raiseInvoiceForSalesPo.length > 0" (click)="openRaiseInvoiceModal()">Raise Invoice</button>

									<button type="button" class="btn temp-download custom-title" (click)="exportAsXLSX()"><i
											class="ri-file-download-line"></i><span class="tool">Download
											Template</span></button>

									<button type="button" class="btn upload-icon custom-title" data-toggle="modal"
										data-target=".bulk_add_instructions" style="margin-right:20px;"
										(click)="addBulkDI()"><i class="ri-file-upload-line"></i><span
											class="tool">Upload</span></button>

									<button type="button"
										class="btn btn-rounded btn-warning btn-w waves-effect waves-light btn-new-item ml-0"
										(click)="openDIModal()">Add</button>

								</div>
							</div>
						</div>

						<div class="table-responsive center-table">
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th></th>
										<th>PO Number
											<label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="po_number" placeholder="PO Number"></label>
										</th>
										<th>DI Number
											<label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="di_number" placeholder="DI Number"></label>
										</th>
										<th>Customer Detail</th>
										
										<th>Item Code
											<!-- <label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="po_number"></label> -->
											<div class="form-group multiple-select" style="width: 180px;">
												<ng-select class="test" [(ngModel)]="itemCodeId" [items]="item"
													bindLabel="name" autofocus bindValue="_id" placeholder="Select Item"
													(change)="getDIData()">
												</ng-select>
											</div>
										</th>
										<th>Scheduled Quantity
											<label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="scheduledQuantity" placeholder="Scheduled Qty"></label>
										</th>
										<th>Unloading Location
											<label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="unloadingLocation" placeholder="Unloading Location"></label>
										</th>
										<th>Usage Location
											<label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="usageLocation" placeholder="Usage Location"></label>
										</th>
										<th>Original DI Date
											<div class="input-group" style="width: 110px;" daterangepicker
												[options]="picker1"
												(selected)="selectedDate($event, chosenDate,'orignalDI')">
												<span class="form-control uneditable-input" name="daterange">

													<span *ngIf="chosenDate.start else elseblock">
														{{ chosenDate.start | date:'mediumDate' }} - {{
														chosenDate.end| date:'mediumDate' }}
													</span>
													<ng-template #elseblock>
														<span>
															<div class="calendar-icon"><span>Select
																	date</span><i class="ri-calendar-2-line"></i></div>
														</span>
													</ng-template>
												</span>
												<span class="input-group-btn">
													<a type="button" class="btn btn-default"><i
															class="glyphicon glyphicon-calendar"></i></a>
												</span>
											</div>
										</th>
										<th>Revised DI Date
											<div class="input-group" style="width: 110px;" daterangepicker
												[options]="picker1"
												(selected)="selectedDate($event, chosenDate1,'revisedDI')">
												<span class="form-control uneditable-input" name="daterange">

													<span *ngIf="chosenDate1.start else elseblock">
														{{ chosenDate1.start | date:'mediumDate' }} - {{
														chosenDate1.end| date:'mediumDate' }}
													</span>
													<ng-template #elseblock>
														<span>
															<div class="calendar-icon"><span>Select
																	date</span><i class="ri-calendar-2-line"></i></div>
														</span>
													</ng-template>
												</span>
												<span class="input-group-btn">
													<a type="button" class="btn btn-default"><i
															class="glyphicon glyphicon-calendar"></i></a>
												</span>
											</div>
										</th>
										<th>Supply Date
											<!-- <label class="tb-search"><input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="supplyStartDate"></label> -->
											<div class="input-group" style="width: 110px;" daterangepicker
												[options]="picker1"
												(selected)="selectedDate($event, chosenDate2,'supplyDI')">
												<span class="form-control uneditable-input" name="daterange">

													<span *ngIf="chosenDate2.start else elseblock">
														{{ chosenDate2.start | date:'mediumDate' }} - {{
														chosenDate2.end| date:'mediumDate' }}
													</span>
													<ng-template #elseblock>
														<span>
															<div class="calendar-icon"><span>Select
																	date</span><i class="ri-calendar-2-line"></i></div>
														</span>
													</ng-template>
												</span>
												<span class="input-group-btn">
													<a type="button" class="btn btn-default"><i
															class="glyphicon glyphicon-calendar"></i></a>
												</span>
											</div>
										</th>
										<th>Supply Time
											<label class="tb-search">
												<!-- <input type="search"
													class="form-control form-control-sm" (input)="getDIData()"
													[(ngModel)]="supplyEndTime"> -->
												<input type="time" class="form-control" [(ngModel)]="supplyEndTime"
													(input)="getDIData()">
											</label>
										</th>
										<th>Status</th>

										<th style="width:65px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let dI of deliveryInstructionList | paginate: {id: 'deliveryInstructionList',itemsPerPage: limit,currentPage: page,totalItems: total}; let i = index;">
										<td data-title="Checkbox"><input type="checkbox" [(ngModel)]="dI.isSelected"  class="form-control form-control-sm" (click)="clickForRaiseInvoice($event, dI)"></td>
										<td data-title="PO Number">{{dI?.po_number?.po_number}}</td>
										<td data-title="Invoice #">{{dI?.di_number}}</td>
										<td data-title="Customer Name">{{dI?.customerDetails?.CustomerName}}<br>{{dI?.customerDetails?.CustomerCode}}</td>
										<td data-title="GST">{{dI?.itemCode?.code || ''}}</td>
										<td data-title="Business Unit">{{dI?.scheduledQuantity || ''}}<br>
											<span [hidden]="dI.inventoryList.length == 0" *ngFor="let unit of dI?.inventoryList ;let j = index">{{unit?.plant?.unitName + ':' +'\t' + unit?.finishedGood }} {{dI.inventoryList.length - 1  == j ? '' : ' | '}}
											</span>
										</td>
										<td data-title="Business Unit">{{dI?.unloadingLocation || ''}}</td>
										<td data-title="Business Unit">{{dI?.usageLocation || ''}}</td>
										<td data-title="Date">{{dI?.originalDiDate | date : 'MMM d, y'}}</td>
										<td data-title="Revised DI Date">{{dI?.revisedDiDate | date : 'MMM d, y'}}</td>
										<td data-title="Business Unit">{{dI?.supplyDate | date : 'MMM d, y'}}</td>
										<td data-title="Business Unit">{{dI?.supplyTime}}</td>
										<td data-title="Status">{{dI?.status}}</td>
										<td data-title="Action" style="white-space:nowrap;">
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="openDIModal(dI)"><i class="mdi mdi-pencil font-size-18"></i>
											</button>
											<a href="javascript:void(0);" (click)="openDeleteModal(dI?._id)"
												class="text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !deliveryInstructionList?.length">
										<td colspan="10">
											<h5 class="text-center">No Delivery instructions found!</h5>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="row" *ngIf="apiHitDone && deliveryInstructionList?.length">
							<div class="col-sm-12 col-md-5">
								<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
									{{entries?.total}} entries</div>
							</div>

							<div class="col-sm-12 col-md-7">
								<div class="dataTables_paginate paging_simple_numbers">
									<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
										id="deliveryInstructionList"
										(pageChange)="getDIData($event)"></pagination-controls>
								</div>
							</div>
						</div>


					</div>
				</div>
				<!-- end table_page-->

			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->

<!-- Start Modal Add Delivery Instructions -->
<div class="modal fade bulk_add_instructions" tabindex="-1" id="bulk_add_instructions" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Upload Delivery Instructions</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadExcelSheet()">
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" formControlName="excelSheet"
										(change)="onSelectFile($event)"
										[ngClass]="{ 'is-invalid': submitted && a.excelSheet.errors }" accept=".xlsx">
								</div>
								<div class="error-msg" *ngIf="submitted && a.excelSheet.errors"> <span
										style="color: red;" *ngIf="a.excelSheet.errors.required">
										Excel sheet is required.</span>
								</div>
								<div class="error-msg error-ul" *ngIf="erroMesage">
									<ul>
										<li *ngFor="let err of erroMesage">{{err}}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- Add Instruction Popup -->
<div class="modal fade add_instruction" id="add_instruction" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">{{toBeEdited ? 'Edit' : 'Add'}} Delivery Instruction</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="diForm">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<div class="form-group multiple-select">
								<label for="validationCustom01">PO Number <span style="color:red;">*</span></label>
								<!-- <input type="text" class="form-control" formControlName="po_number"> -->
                                <ng-select class="test" [items]="salesPOList" bindLabel="po_number" autofocus bindValue="_id"
                                formControlName="po_number" placeholder="Select product code">
                              </ng-select>								
								<div *ngIf="diFormSubmited && diForm?.controls['po_number']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['po_number']?.errors?.required">PO Number
										is required</div>
								</div>
							</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">DI Number <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="di_number">
								<div *ngIf="diFormSubmited && diForm?.controls['di_number']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['di_number']?.errors?.required">DI Number
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Original DI Date <span
										style="color:red;">*</span></label>
								<input type="date" class="form-control" formControlName="originalDiDate">
								<div *ngIf="diFormSubmited && diForm?.controls['originalDiDate']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['originalDiDate']?.errors?.required">Original DI Date
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Revised DI Date <span
										style="color:red;">*</span></label>
								<input type="date" class="form-control" formControlName="revisedDiDate">
								<div *ngIf="diFormSubmited && diForm?.controls['revisedDiDate']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['revisedDiDate']?.errors?.required">Revised DI Date is
										required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group multiple-select">
								<label for="validationCustom01">Item Code <span style="color:red;">*</span></label>
								<ng-select class="test" [items]="item" bindLabel="name" autofocus bindValue="_id"
									formControlName="itemCode" placeholder="Select Item">
								</ng-select>
								<div *ngIf="diFormSubmited && diForm?.controls['itemCode']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['itemCode']?.errors?.required">Item Code is required
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Scheduled Quantity <span
										style="color:red;">*</span></label>
								<input type="number" class="form-control" formControlName="scheduledQuantity"
									(keypress)="numberOnly($event)">
								<div *ngIf="diFormSubmited && diForm?.controls['scheduledQuantity']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['scheduledQuantity']?.errors?.required">Scheduled
										Quantity is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Unloading Location <span
										style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="unloadingLocation">
								<div *ngIf="diFormSubmited && diForm?.controls['unloadingLocation']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['unloadingLocation']?.errors?.required">Unloading
										Location is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Usage Location <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="usageLocation">
								<div *ngIf="diFormSubmited && diForm?.controls['usageLocation']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['usageLocation']?.errors?.required">Usage Location is
										required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Date <span style="color:red;">*</span></label>
								<input type="date" class="form-control" formControlName="supplyDate">
								<div *ngIf="diFormSubmited && diForm?.controls['supplyDate']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['supplyDate']?.errors?.required">Supply Date is
										required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Time <span style="color:red;">*</span></label>
								<input type="time" class="form-control" formControlName="supplyTime">
								<div *ngIf="diFormSubmited && diForm?.controls['supplyTime']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="diForm?.controls['supplyTime']?.errors?.required">Supply time is
										required</div>
								</div>
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit" (click)="saveDI()"> {{toBeEdited ? 'Update' :
						'Save'}}</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add Instruction Popup -->
<div class="modal sc-ion-alert-ios-h sc-ion-alert-ios-s" id="delete_modal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Item</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" (click)="deleteDI()"><span
					class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
					class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>
<sales-po-raise-invoice [SPOitemList]="raiseInvoice"></sales-po-raise-invoice>