export class Customer {

    CustomerName: string;
    CustomerCode: string;
    ContactName: string;
    ContactNumber: number;
    PrimaryEmail: string;
    GST: string;
    PAN: string;
    bothAddressesSame: boolean;
    ShippingAddress: string;
    BillingAddress: string;
    PlaceOfSupply: string;
    // password: string;
    // confirmPassword: string;

    constructor(payload: {
        CustomerName: string;
        CustomerCode: string;
        ContactName: string;
        ContactNumber: number;
        PrimaryEmail: string;
        GST: string;
        PAN: string;
        bothAddressesSame: boolean;
        ShippingAddress: string;
        BillingAddress: string;
        PlaceOfSupply: string;
        // password: string;
        // confirmPassword: string;
    }) {
        if (payload) {
            this.CustomerName = payload.CustomerName;
            this.CustomerCode = payload.CustomerCode;
            this.ContactName = payload.ContactName;
            this.ContactNumber = payload.ContactNumber;
            this.PrimaryEmail = payload.PrimaryEmail;
            this.GST = payload.GST;
            this.PAN = payload.PAN;
            this.bothAddressesSame = payload.bothAddressesSame;
            this.ShippingAddress = payload.ShippingAddress;
            this.BillingAddress = payload.BillingAddress;
            this.PlaceOfSupply = payload.PlaceOfSupply;
            // this.password = '123456';
            // this.confirmPassword = '123456';

        }
    }
}
export class updateCustomer {
    _id: string;
    CustomerName: string;
    CustomerCode: string;
    ContactName: string;
    ContactNumber: number;
    PrimaryEmail: string;
    GST: string;
    PAN: string;
    bothAddressesSame: boolean;
    ShippingAddress: string;
    BillingAddress: string;
    PlaceOfSupply: string;


    constructor(payload: {
        _id: string
        CustomerName: string;
        CustomerCode: string;
        ContactName: string;
        ContactNumber: number;
        PrimaryEmail: string;
        GST: string;
        PAN: string;
        bothAddressesSame: boolean;
        ShippingAddress: string;
        BillingAddress: string;
        PlaceOfSupply: string;

    }) {
        if (payload) {
            this._id = payload._id;
            this.CustomerName = payload.CustomerName;
            this.CustomerCode = payload.CustomerCode;
            this.ContactName = payload.ContactName;
            this.ContactNumber = payload.ContactNumber;
            this.PrimaryEmail = payload.PrimaryEmail;
            this.GST = payload.GST;
            this.PAN = payload.PAN;
            this.bothAddressesSame = payload.bothAddressesSame;
            this.ShippingAddress = payload.ShippingAddress;
            this.BillingAddress = payload.BillingAddress;
            this.PlaceOfSupply = payload.PlaceOfSupply;


        }
    }
}
