<div class="modal fade" id="addItem_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content table_page">
            <div class="modal-header">
                <h5 class="modal-title mt-0">{{this.toBeEdited ? (this.toBeEdited.itemDate ? 'Edit' : 'Add') : ''}} Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body card-body">
                <form [formGroup]="AddProductItemForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group multiple-select">
                                <label>Product Code <span style="color: red;">*</span></label>
                                <!-- <select class="form-control"  formControlName="productId" (change)="getPdoductDetail($event.target.value)">
                                    <option value="" disabled selected>Select Product Code</option>
                                    <option value="{{code?._id}}" *ngFor="let code of productCodeList">{{code?.code}}</option>
                                    
                                </select> -->
                                <ng-select (change)="getPdoductDetail($event,'searchbycode')" class="test" [items]="productCodeList" bindLabel="code" autofocus bindValue="_id"
                                formControlName="productId" placeholder="Select product code">
                              </ng-select>
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.productId.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.productId.errors.required">
                                        Product is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group multiple-select">
                                <label>Product Name <span style="color: red;">*</span></label>
                                <!-- <input type="text" class="form-control" readonly formControlName="productName"> -->
                                <ng-select (change)="getPdoductDetail($event,'searchbyname')" class="test" [items]="productCodeList" bindLabel="name" autofocus bindValue="_id"
                                formControlName="productName" placeholder="Select product name">
                              </ng-select>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Product Description <span style="color: red;">*</span></label>
                                <textarea rows="2" class="form-control" readonly formControlName="productDescription"></textarea>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Valid From <span style="color: red;">*</span></label>
                                <input type="date" class="form-control" formControlName="validFromDate">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.validFromDate.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.validFromDate.errors.required">
                                        Valid From Date is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Unit <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="unit" readonly>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Rate <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="rate" (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.rate.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.rate.errors.required">
                                        Rate is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <label>QTY <span style="color: red;">*</span></label>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Delivered"
                                    formControlName="quantityDelieved" (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.quantityDelieved.errors">
                                    <span style="color: red;" *ngIf="itemFormControl.quantityDelieved.errors.required">
                                        Quantity Delieved is required.</span>
                                </div>
                                <div class="error-msg" *ngIf="errormsg"> <span style="color: red;">
                                    {{errormsg}}</span>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Ordered *"
                                    formControlName="quantityOrdered" (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.quantityOrdered.errors">
                                    <span style="color: red;" *ngIf="itemFormControl.quantityOrdered.errors.required">
                                        Quantity Ordered is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Amount (₹) <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="amount">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.amount.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.amount.errors.required">
                                        Amount is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <label>CGST <span style="color: red;">*</span></label>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Rate %"
                                    formControlName="CGSTRate" (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.CGSTRate.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.CGSTRate.errors.required">
                                        CGSTRate is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Amount"
                                    formControlName="CGSTAmount">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.CGSTAmount.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.CGSTAmount.errors.required">
                                        CGSTAmount is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <label>SGST <span style="color: red;">*</span></label>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Rate %"
                                    formControlName="SGSTRate" (ngModelChange)="calculate()">
                                    <div class="error-msg" *ngIf="isSubmited && itemFormControl.SGSTRate.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.SGSTRate.errors.required">
                                        SGSTRate is required.</span>
                                </div>                                    
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Amount"
                                    formControlName="SGSTAmount">
                                    <div class="error-msg" *ngIf="isSubmited && itemFormControl.SGSTAmount.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.SGSTAmount.errors.required">
                                        SGSTAmount is required.</span>
                                </div>                                    
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <label>IGST <span style="color: red;">*</span></label>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Rate %"
                                    formControlName="IGSTRate" (ngModelChange)="calculate()">
                                    <div class="error-msg" *ngIf="isSubmited && itemFormControl.IGSTRate.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.IGSTRate.errors.required">
                                        IGSTRate is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="Amount"
                                    formControlName="IGSTAmount">
                                    <div class="error-msg" *ngIf="isSubmited && itemFormControl.IGSTAmount.errors"> <span
                                        style="color: red;" *ngIf="itemFormControl.IGSTAmount.errors.required">
                                        IGSTAmount is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Total Amount (₹) <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="totalAmount">
                                <div class="error-msg" *ngIf="isSubmited && itemFormControl.totalAmount.errors"> <span
                                    style="color: red;" *ngIf="itemFormControl.totalAmount.errors.required">
                                    TotalAmount is required.</span>
                            </div>                                
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-warning" (click)="saveitem()">Save</button>
                </form>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>