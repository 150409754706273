<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item"><a [routerLink]="['/admin/invoices']">Purchase Invoices</a></li>
							<li class="breadcrumb-item active">Details</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="invoice_box table-responsive">
							<!-- Header -->

							<div class="filter-all">
								<div class="page-title-right2">
									<h4 class="card-title mb-2">Invoice</h4>
								</div>
								<div class="dataTables_filter">
									<div class="edit_mode">
										<div class="switch_profile" *ngIf="userlogindata.role == 'Finance'">
											<label class="label-text">Approved</label>
											<label class="switch">
												<input type="checkbox" id="verify" value="1"
													(click)="changeStatusPOinvoice()"
													[checked]="invoidata?.status == 'Approved' ? true  : false"
													*ngIf="invoidata.gatekeeperApproval == 'Approved' && invoidata.storekeeperApproval == 'Approved'">
												<span class="slider round"></span>
											</label>
										</div>
										<button *ngIf="invoidata?.gatekeeperApproval == 'Not Approved'" type="button"
											(click)="openEdiTInvoiceModal()"
											class="btn btn-rounded btn-warning btn-w waves-effect waves-light ml-2">
											Edit </button>
									</div>
								</div>
							</div>
							<table class="table table-bordered">
								<thead class="thead-light">
									<tr>
										<th style="width: 130px;"><strong>IRN</strong></th>
										<th><strong>Invoice # </strong></th>
										<th><strong>e-way Bill #</strong></th>
										<th><strong>PO #</strong></th>
										<th><strong>Vehicle # </strong></th>
										<th><strong>Date</strong></th>
										<th><strong>Plant</strong></th>
										<th><strong>Party Details </strong></th>
										<th><strong>Amount (₹)</strong></th>
										<th><strong>GST (₹)</strong></th>
										<th><strong>Freight</strong></th>
										<th><strong>TCS On Sales</strong></th>
										<th><strong>Total Amount (₹)</strong></th>
									</tr>
								</thead>
								<!-- <tbody>
                  <tr>
                    <td data-title="Name">Bluth Company</td>
                    <td data-title="Invoice No">{{invoidata.invoiceNumber}}</td>
                    <td data-title="Date">{{invoidata.invoiceDate}}</td>
                    <td data-title="Amount">₹ {{invoidata.amount}}</td>
                    <td data-title="GST">₹ {{invoidata.gstAmount}}</td>
                    <td data-title="Total Amount">₹ {{invoidata.totalAmount}}</td>
                  </tr>
                </tbody> -->
								<tbody>
									<tr>
										<td data-title="IRN" class="irn">{{invoidata?.IRN}}</td>
										<td data-title="Invoice #">{{invoidata?.invoiceNumber}}</td>
										<td data-title="e-Way Bill #">{{invoidata?.eWayBill}}</td>
										<td data-title="PO #"><a href="javascript:void(0)"
												[routerLink]="['/admin/purchase-order/detail']"
												[queryParams]="{ id: invoidata?.purchaseOrderId?._id}">{{invoidata?.purchaseOrderId?.purchaseOrderNo}}</a>
										</td>
										<td data-title="Vehicle #">{{invoidata?.vehicle}}</td>
										<td data-title="Date">{{invoidata?.invoiceDate | date}}</td>
										<td data-title="Plant">{{invoidata?.purchaseOrderId?.plant}}</td>
										<td data-title="Party Details">
											<strong><em>{{invoidata?.purchaseOrderId?.partyCode}}</em></strong>
											<br>{{invoidata?.purchaseOrderId?.partyName}}
										</td>
										<td data-title="Amount">{{invoidata?.purchaseOrderId?.amount}}
										</td>
										<td data-title="GST">{{invoidata?.GST}}</td>
										<td data-title="Freight">{{invoidata?.purchaseOrderId?.freight}}</td>
										<td data-title="TCS On Sales">{{invoidata?.TCSOnSales}}</td>
										<td data-title="Total Amount">{{invoidata?.totalAmount}}</td>
									</tr>
								</tbody>
							</table>
							<div class="filter-all mt-3">
								<div class="page-title-right2">
									<h4 class="card-title mb-2">GSTR 2A</h4>
								</div>

							</div>
							<table class="table table-bordered gstr-2a">
								<thead class="thead-light">
									<tr>
										<th style="width: 160px;"><strong>Matching Status</strong></th>
										<th style="width: 180px;"><strong>Matching Error</strong></th>
										<th><strong>GST Input Credit Eligible</strong></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td data-title="Matching Status"><span
												class="badge badge-warning ng-star-inserted">{{invoidata?.matchStatus?invoidata?.matchStatus:'Not
												Found'}}</span> </td>
										<td data-title="Matching Error">
											<ul class="error-msg error-ul">
												<li>{{invoidata?.matchError?invoidata?.matchError:'Not
													Found'}}</li>
											</ul>
										</td>
										<td data-title="GST Input Credit Eligible">
											<label class="switch switch-text">
												<input type="checkbox" id="verify"
													(click)="GstchangeStatus(invoidata?._id,$event)"
													[checked]="invoidata.GSTCreditEligible == 'Yes' ? true  : false"
													[disabled]="userlogindata?.role !='Admin'"><span
													class="slider round"></span>
											</label>

										</td>

									</tr>
								</tbody>
							</table>
						</div>
						<div class="row">
							<div class="col-sm-12">
								<div class="filter-all">
									<div class="d-flex align-items-center justify-content-between">
										<div class="page-title-right2">

										</div>
									</div>
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search..." [(ngModel)]="ssearchItem"
												(input)="searchOrder($event.target.value)">
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive table_responsive_scroll">

							<table class="table mb-0 responsive_scroll">
								<thead class="thead-light">

									<tr>
										<th style="width:30px;">No.</th>
										<th>Item Code</th>
										<th>Item Name </th>
										<th>Description </th>
										<th style="width: 100px;">Gatekeeper Status</th>
										<th style="width:90px;">Quality Check</th>
										<th style="width:60px;">PO Rate (₹)</th>
										<th style="width:60px;">PO QTY</th>
										<th style="width:70px;">QTY</th>
										<th style="width:80px;">Rate (₹)</th>


										<th style="width:90px;">HSN/SAC</th>
										<th style="width:90px;" class="span-box">CGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:90px;" class="span-box">SGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:140px;" class="span-box">IGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:116px;text-align: right;">Total Amount (₹)</th>
										<th style="width:50px;" *ngIf="userlogindata?.role=='Vendor'">Action</th>

									</tr>
								</thead>
								<tbody>
									<!-- <div class="pending-error" *ngIf="data?.qualityCheck == 'Checked'">
										<span class="icon-alert" *ngIf="storeKeeperCommentAndImage?.images[0] || storeKeeperCommentAndImage?.comment!=''" (click)="openModal()"><i class="ri-alert-fill"></i></span><span class="badge badge-success"> Passed</span>
										<span class="badge badge-success" *ngIf="!storeKeeperCommentAndImage" (click)="openModal()"><i class="ri-alert-fill"></i></span><span *ngIf="!storeKeeperCommentAndImage" class="badge badge-success"> Passed</span>

									</div>
									<div class="pending-error" *ngIf="data?.qualityCheck == 'Unchecked'">
										<span class="icon-alert" *ngIf="storeKeeperCommentAndImage?.images[0] || storeKeeperCommentAndImage?.comment!=''" (click)="openModal()"><i class="ri-alert-fill"></i></span><span class="badge badge-danger"> Reject</span>
										<span class="badge badge-success" *ngIf="!storeKeeperCommentAndImage" (click)="openModal()"><i class="ri-alert-fill"></i></span><span *ngIf="!storeKeeperCommentAndImage" class="badge badge-danger"> Reject</span>

									</div>
									<div class="pending-error" *ngIf="data?.qualityCheck == 'Pending'">
										<span class="icon-alert" *ngIf="storeKeeperCommentAndImage?.images[0] || storeKeeperCommentAndImage?.comment!='' || storeKeeperCommentAndImage==''" (click)="openModal()"><i class="ri-alert-fill"></i></span><span class="badge badge-warning"> Pending</span>
										<span class="badge badge-success" *ngIf="storeKeeperCommentAndImage==''" (click)="openModal()"><i class="ri-alert-fill"></i></span><span *ngIf="!storeKeeperCommentAndImage" class="badge badge-warning">Pending</span>

									</div> -->
									<tr [hidden]="itemDetails?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let data of itemDetails | paginate: {id: 'items', itemsPerPage: limit, currentPage: page,totalItems: count }; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Item Code">{{data?.itemCode}}</td>
										<td data-title="Item Name	">{{data?.itemName}}</td>
										<td data-title="Description">{{data?.itemDesc}} </td>
										<td data-title="Gatekeeper Status">
											<span class="badge badge-warning"
												*ngIf="data?.gateKeeperStatus == 'Pending'">Pending</span>
											<span class="badge badge-success"
												*ngIf="data?.gateKeeperStatus == 'Approved'">Approved</span>
											<span class="badge badge-danger"
												*ngIf="data?.gateKeeperStatus == 'Not Approved'">Rejected</span>
										</td>
										<td data-title="Quality Check">
											<div class="icon-alerts">
												<span style="cursor: pointer;" class="badge badge-warning"
													*ngIf="disabledAlret && data?.qualityCheck == 'QS'"
													(click)="openModal()" title="ITEM QUANTITY SHORT">QS
												</span>
												<span class="badge badge-warning"
													*ngIf="!disabledAlret && data?.qualityCheck == 'QS'"
													title="ITEM QUANTITY SHORT">QS</span>
												<span class="badge badge-warning"
													*ngIf="disabledAlret && data?.qualityCheck == 'OK'"
													(click)="openModal()"
													title="ITEMS ARE AS PER INVOICE & FOUND OK">OK</span><span
													class="badge badge-warning"
													*ngIf="!disabledAlret && data?.qualityCheck == 'OK'"
													title="ITEMS ARE AS PER INVOICE & FOUND OK">OK</span>
												<span style="cursor: pointer;" class="badge badge-warning"
													*ngIf="disabledAlret && data?.qualityCheck == 'IR'"
													(click)="openModal()"
													title="ITEM REJECTED DUE TO QUALITY ISSUE">IR</span><span
													class="badge badge-warning"
													*ngIf="!disabledAlret && data?.qualityCheck == 'IR'"
													title="ITEM REJECTED DUE TO QUALITY ISSUE">IR</span>

											</div>
											<!-- gg -->

										</td>
										<td data-title="PO Rate"> {{data?.poRate}}</td>

										<td data-title="QTY">{{data?.ordQnty}}</td>

										<td data-title="QTY">
											<div class="label-edit">
												<span style="word-break: keep-all;">{{data?.balQnty}}</span>
												<input type="tel" id="{{i}}"
													class="form-control form-control-sm b-radius-0 inpt"
													*ngIf="index_id === i "
													(keypress)="commonService.numberOnly($event)" maxlength="9"
													(input)="getRate($event.target.value)" onlyNumber>
												<div *ngIf="(userlogindata?.role == 'Vendor') else elseblock">
													<button type="button" class="ml-2 text-primary btn_style"
														*ngIf="!preferencesEditable && (data?.gateKeeperStatus == 'Not Approved' || data?.gateKeeperStatus == 'Pending')"
														(click)="openField(true,data,i)">
														<i class="mdi mdi-pencil font-size-14"></i>
													</button>
												</div>
												<ng-template #elseblock>
													<button type="button" class="ml-2 text-primary btn_style"
														*ngIf="!preferencesEditable" (click)="openField(true,data,i)">
														<i class="mdi mdi-pencil font-size-14"></i>
													</button>
												</ng-template>
												<button type="button" class="ml-2 btn_success btn_style"
													*ngIf="index_id === i " (click)="openField(false)">
													<i class="ri-close-line font-size-14"></i>
												</button>
												<button type="button" class="ml-2 btn_success btn_style"
													*ngIf="index_id === i " (click)="editPreferenceTitle(false,data,i)">
													<i class="mdi mdi-check-bold font-size-14" *ngIf="balQnty!=''"></i>
												</button>
											</div>
										</td>

										<td data-title="Rate">
											<div class="label-edit">
												<span style="word-break: keep-all;"
													[ngClass]="data?.poRate != data?.rate? 'error-text' : ''">{{data?.rate}}</span>
												<input type="tel" id="{{i}}"
													class="form-control form-control-sm b-radius-0 inpt"
													*ngIf="index_id2 === i "
													(keypress)="commonService.numberOnly($event)" maxlength="5"
													(input)="getRate($event.target.value)" onlyNumber>
												<div *ngIf="(userlogindata?.role == 'Vendor') else elseblock2">
													<button type="button" class="ml-2 text-primary btn_style"
														*ngIf="!qtyEditable  && (data?.gateKeeperStatus == 'Not Approved' || data?.gateKeeperStatus == 'Pending')"
														(click)="openeditqty(true,data,i)">
														<i class="mdi mdi-pencil font-size-14"></i>
														<!-- <i *ngIf="data?.gateKeeperStatus === 'Not Approved'" class="mdi mdi-pencil font-size-18" ></i> -->

														<!-- <i class="mdi mdi-pencil font-size-18"  ></i> -->

													</button>
												</div>
												<ng-template #elseblock2>
													<button type="button" class="ml-2 text-primary btn_style"
														*ngIf="!qtyEditable" (click)="openeditqty(true,data,i)">
														<i class="mdi mdi-pencil font-size-14"></i>


													</button>
												</ng-template>
												<button type="button" class="ml-2 btn_success btn_style"
													*ngIf="index_id2 === i " (click)="openeditqty(false)">
													<i class="ri-close-line font-size-14"></i>
												</button>
												<button type="button" class="ml-2 btn_success btn_style"
													*ngIf="index_id2 === i " (click)="editqty(false,data,i)">
													<i class="mdi mdi-check-bold font-size-14" *ngIf="ordQnty !=''"></i>
												</button>
											</div>

										</td>

										<td data-title="HSN/SAC">{{data.HSNSANum?.hsnNumber}}</td>
										<td data-title="CGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{data?.CGSTRate}}</span>
												<span class="amount ">{{data?.CGSTAmt}}</span>
											</div>
										</td>
										<td data-title="SGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{data?.SGSTRate}}</span>
												<span class="amount ">{{data?.SGSTAmt}}</span>
											</div>
										</td>
										<td data-title="IGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{data?.IGSTRate}}</span>
												<span class="amount ">{{data?.IGSTAmt}}</span>
											</div>
										</td>
										<td data-title="Total Amount" style="text-align: right;">{{data?.totalAmount}}
										</td>

										<td data-title="Action" *ngIf="userlogindata?.role=='Vendor'">
											<a href="javascript:void(0);" class="text-secondary"
												*ngIf="data?.gateKeeperStatus === 'Approved'"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
											<a href="javascript:void(0);"
												*ngIf="data?.gateKeeperStatus === 'Not Approved' || data?.gateKeeperStatus === 'Pending'"
												class="text-danger" (click)="opemDeleteModal(data)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>

										</td>

										<!-- <td data-title="Action" *ngIf="userlogindata?.role=='Vendor'">
										</td> -->
									</tr>

								</tbody>

							</table>
							<div class="row" *ngIf="itemDetails?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entries?.from}} to
										{{entries?.to}} of
										{{entries?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="items" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next" (pageChange)="SingleInvoice($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card table_page" *ngIf="gatekeeperCommentDetails">
					<div class="card-body pt-3"
						*ngIf="gatekeeperCommentDetails.images.length > 0 || gatekeeperCommentDetails.comment != ''">
						<h4 class="card-title mb-2">Gatekeeper Comment</h4>
						<div class="row picture-box">
							<div class="col-md-6 mb-2">
								<h4 class="card-title mb-2">Picture</h4>
								<div class="picture-row">
									<a data-fancybox="" href="{{imgs}}"
										*ngFor="let imgs of gatekeeperCommentDetails?.images" class="data-fancybox-img">
										<div class="bg_img"
											style="background-color: rgb(248, 249, 250); background-image: url({{imgs}});">

										</div>

										<!-- <div class="bg_img"
										style="background-color: rgb(248, 249, 250); background-image: url({{url}});">

									  </div> -->
										<!-- <img class="bg_img" src="{{imgs}}" style="background-color: rgb(248, 249, 250)"> -->
									</a>

								</div>
							</div>
							<div class="col-md-6 mb-2">
								<h4 class="card-title mb-2">Comment</h4>
								<p>{{gatekeeperCommentDetails?.comment}}</p>
							</div>
						</div>
					</div>
				</div>
				<!-- end table_page-->
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->
<!-- <div data-sidebar="dark" class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios"  *ngIf="disableds">
    <div class="alert-head sc-ion-alert-ios">
        <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">No Quality Issues</h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios"></div>
    <div class="alert-button-group">
        <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close" style=" width: 100%;
    border-right: 0px;
" (click)="okButton()"><span class="alert-button-inner sc-ion-alert-ios">Ok</span>
        </button>

    </div>

</div> -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="disableds">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios"></h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">No Quality Issues</div>
		<div class="alert-button-group">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close" style=" width: 100%;
    border-right: 0px;
" (click)="okButton()"><span class="alert-button-inner sc-ion-alert-ios">Ok</span>
			</button>

		</div>

	</div>
</div>
<!-- Reported issue	 Popup -->
<div class="modal fade reported-issue" id="reported-issue" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Quality Issues</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="assign-list">
					<div class="form-group mb-1">
						<label>Picture of Issue</label>
						<div class="quality-img quality-bg" *ngIf="storekeeperData">
							<a *ngFor="let image of storekeeperData[0]?.images" data-fancybox="" href="{{image}}">
								<div class="bg_img"
									style="background-color: rgb(248, 249, 250); background-image: url({{image}});">
								</div>
							</a>
						</div>
					</div>
					<!-- <div class="form-group" *ngIf="storekeeperData">
						<label>Reported Issue</label>
						<p>{{this.storekeeperData[0]?.comment}}.</p>
					</div> -->
				</div>
				<ng-template #elseblock>
					No Quality Check
				</ng-template>
			</div>
		</div>
	</div>
</div>

<!-- End Reported issue	 Popup -->


<!-- Start Modal purchase order edit -->
<div class="modal fade purchase_order_edit" tabindex="-1" id="purchase_order_edit" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Invoice Detail</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form [formGroup]="invoceEditForm" (ngSubmit)="edirPoInvoce()">
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>IRN </label>
								<input type="text" class="form-control" formControlName="inr">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Invoice # </label>
								<input type="text" class="form-control" formControlName="invoice">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>e-way Bill # </label>
								<input type="text" class="form-control" formControlName="eWayBill">
							</div>
						</div>
						<!-- <div class="col-lg-12">
							<div class="form-group">
								<label>PO # </label>
								<input type="text" class="form-control" formControlName="pO">
							</div>
						</div> -->
						<div class="col-lg-12">
							<div class="form-group">
								<label>Vehicle # </label>
								<input type="text" class="form-control" formControlName="vehicle">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Date </label>
								<input type="date" class="form-control" formControlName="createdDate">
							</div>
						</div>
						<!-- <div class="col-lg-12">
							<div class="form-group">
								<label>Plant</label>
								<select class="form-control" formControlName="plant">
									<option value="">Select</option>
									<option [value]="plnt?._id" *ngFor="let plnt of palntList">{{plnt?.unitName}}
									</option>

								</select>
							</div>
						</div> -->
						<!-- <div class="col-lg-12">
							<div class="form-group">
								<label>Party Code </label>
								<input type="text" class="form-control" formControlName="partyCode">
							</div>
						</div> -->
						<!-- <div class="col-lg-12">
							<div class="form-group">
								<label>Party Name </label>
								<input type="text" class="form-control" formControlName="partyName">
							</div>
						</div> -->
						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>Amount</label>
								<input type="text" class="form-control" formControlName="amount">
							</div>
						</div> -->
						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>GST</label>
								<input type="text" class="form-control" formControlName="gst">
							</div>
						</div> -->
						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>Freight</label>
								<select class="form-control" formControlName="freight">
									<option value="Extra">Extra</option>
									<option value="Include">Include</option>
								</select>
							</div>
						</div> -->
						<div class="col-sm-6">
							<div class="form-group">
								<label>TCS On Sales</label>
								<input type="text" class="form-control" formControlName="tcsOnSales">
							</div>
						</div>

						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>Total Amount</label>
								<input type="text" class="form-control" formControlName="totalAmount">
							</div>
						</div> -->
					</div>
					<button type="submit" class="btn btn-warning">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete item</h2>
							<h6>Item Code-{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios" *ngIf="!erroMesage">Are you sure
							you want to delete?
						</div>
						<!-- <div id="alert-4-msg" class="alert-message sc-ion-alert-ios" style="color: red;"
							*ngIf="erroMesage">{{erroMesage}}
						</div> -->
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"><span
									class="alert-button-inner sc-ion-alert-ios" (click)="itemsDelete()">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- /.modal -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>
