import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  page = 1;
  count = 0;
  tableSize = 7;
  userlist: any = [];
  searchText: any;
  limit: any;
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  EditUserForm: FormGroup;
  submitted = false;
  displaymsg: any;
  commonmodal: boolean;
  Deletemodal: boolean;
  userlogindata: any = {};
  companyId: any
  constructor(
    private commonService: CommonService,
    private router: Router,
    private companyservice: CompanyService,
    private auth: AuthenticationService,
    private formBuilder: FormBuilder,
    private events: EventsService,
    private storage: StorageService,
  ) {
    this.events.subscribe(eventKeys.comapnyadded, () => {
      this.GetUserList();
    })
    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"];
    this.companyId = this.userlogindata.companyId._id;
  }

  ngOnInit(): void {
    this.GetUserList();
    this.EditUserForm = this.formBuilder.group({
      id: ['', ''],
      userimage: ['', ''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    })
  }
  get f() { return this.EditUserForm.controls; }
  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Formats allowed : jpg, jpeg, png";
      this.EditUserForm.controls['userimage'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }
  onTableDataChange(event) {

    this.page = event;
    this.GetUserList();
  }
  pageData: any;
  GetUserList() {
    this.commonService.presentSpinner();
    this.companyservice.getUserList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.userlist = [];
            this.userlist = res.adminList;
            this.count = res.totalResult
            this.limit = res.limit;
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }




          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }

  adminid: any;
  resetpassword(item) {
    this.adminid = item._id;
    $('.forgot_password').modal('show');
  }

  userimg: any;
  comapnyname: any;
  OpenEditModal(item) {
    // this.id = item._id;
    this.EditUserForm.reset();
    this.submitted = false;

    this.userimg = item.profileImage
    this.EditUserForm.patchValue({
      id: item._id,
      name: item.name,
      email: item.email,
      role: item.role,

    });
    $('.edit_detail').modal('show');
  }

  EditUser() {
    this.imageErrMsg = ''
    this.submitted = true;
    // stop here if form is invalid
    if (this.EditUserForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    var frmData = new FormData();
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("name", this.EditUserForm.value.name);
    frmData.append("email", this.EditUserForm.value.email.toLowerCase());
    frmData.append("role", this.EditUserForm.value.role);
    frmData.append("companyId", this.companyId);

    this.companyservice.Editadmin(frmData, this.EditUserForm.value.id).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.GetUserList();
            this.EditUserForm.reset();
            $('.edit_detail').modal('hide');
            this.imageUrl = ''
            this.submitted = false;
            this.displaymsg = res.message;
            this.commonmodal = true;
          } else {
            this.displaymsg = res.message;
            this.commonmodal = true;

          }
        }
      }
    );
  }
  id: any;
  deleteuser(item, e) {

    if (e == '') {
      this.id = item._id;
      this.Deletemodal = true
    }

    if (e == 'yes') {
      this.commonService.presentSpinner();

      this.auth.CommonDelete(item).then(
        (res) => {
          this.commonService.dismissSpinner();
          if (res) {
            this.commonService.dismissSpinner();
            if (res.code === 200) {
              this.Deletemodal = false;
              this.GetUserList();
              this.displaymsg = res.message;
              this.commonmodal = true;
            } else {
              this.displaymsg = res.message;
              this.commonmodal = true;
            }
          } else {
            this.commonService.dismissSpinner();
          }
        }
      );
    } else if (e == 'no') {
      this.Deletemodal = false;
    }
  }

  SearchUserByRole(item) {

    if (item) {
      let postdata = {
        "role": item
      }
      this.commonService.presentSpinner();
      this.companyservice.SearchByRole(this.page, postdata).then(
        (res) => {
          this.commonService.dismissSpinner();
          if (res) {
            if (res.code == 200) {
              this.userlist = [];
              this.userlist = res.userList;
              this.count = res.totalResult
              this.limit = res.limit;
              if (this.page == 1) {
                // var abc = this.page + "-" + this.page * 10;
                // this.pageData = this.page + "-" + this.page * 10;
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }

              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
                //   (this.page - 1) * 10 + 1 - this.page * 10
              }




            } else if (res.code == 401) {
              this.router.navigate(['/']);
            }
          } else {

          }

        }
      )
    } else {
      this.GetUserList()
    }

  }

  errmsg: string = ''
  applyFilter(filterValue) {

    if (filterValue.length >= 3) {

      this.companyservice.UserSearchFilter(filterValue).then(
        (res) => {
          if (res) {
            if (res.code == 200 && res.adminList.length > 0) {
              this.userlist = [];
              this.userlist = res.adminList;
              this.count = res.totalResult
              this.limit = res.limit;
              if (this.page == 1) {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
                //   (this.page - 1) * 10 + 1 - this.page * 10
              }
            } else {
              this.userlist = [];
              this.errmsg = 'No record found...'

            }
          } else {

          }

        }
      )
    } else {
      this.GetUserList();
    }

  }

  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }

}
