<div class="modal fade forgot_password show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Reset Password</h5>
                <button type="button" class="close" (click)="closeResetpasswordModal()"> <span
                        aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" [formGroup]="resetpasswordForm">
                    <div class="form-group auth-form-group-custom mb-4"> <i
                            class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input [type]="forgotpassword ? 'text' : 'password' " class="form-control" id="userpassword"
                            placeholder="Enter new password" formControlName="password"
                            (keydown.space)="$event.preventDefault()"> <i (click)="showforgotPassword()"
                            class=" auti-custom-input-icon auti-custom-eye"
                            [ngClass]="forgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.password.errors.required">Password is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group auth-form-group-custom mb-4"> <i
                            class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input [type]="confirmforgotpassword ? 'text' : 'password' " class="form-control"
                            id="userpassword" placeholder="Enter Confirm password" formControlName="confirmpassword"
                            (keydown.space)="$event.preventDefault()"> <i (click)="showConfirmforgotPassword()"
                            class=" auti-custom-input-icon auti-custom-eye"
                            [ngClass]="confirmforgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
                        <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback"
                            style="display: block;">
                            <div *ngIf="f.confirmpassword.errors.required">ConfirmPassword is required
                            </div>
                            <div *ngIf="f.confirmpassword.errors.mustMatch">Passwords must match</div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
                            (click)="ResetPassword(adminid)">Save</button>
                    </div>
                </form>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>