<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Plants</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="OpenUnitModal('', 'add')">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<!-- <label class="select_box"><select class="custom-select custom-select-sm">
                          <option>Type of User</option>
                          <option value="1">Vendor</option>
                          <option value="2">Finance</option>
                          <option value="4">Gatekeeper</option>
                          <option value="5">Owner</option>
                        </select></label> -->
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." (input)="applyFilter($event.target.value)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:190px;">Plant Name</th>
										<th style="width:190px;">GST/UIN</th>
										<th>Plant Address</th>
										<th style="width:60px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="unitlist?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of unitlist | paginate: { id:'unitlist',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Plant Name">{{list.unitName}}</td>
										<td data-title="GST/UIN">{{list.gstUin}}</td>
										<td data-title="Address">{{list.unitAddress}}
										</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="OpenUnitModal(list, 'edit')"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button> <a href="javascript:void(0);" class="text-danger"
												(click)="deleteunit(list,'')"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>

								</tbody>
							</table>
							<div class="row" [hidden]="!unitlist?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<pagination-controls id="unitlist" responsive="true" previousLabel="Prev"
										nextLabel="Next" (pageChange)="onTableDataChange($event)">
									</pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  User Popup -->
<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0" *ngIf="!id">Add Plant</h5>
				<h5 class="modal-title mt-0" *ngIf="id">Edit Plant</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="UnitForm">
					<div class="row">
						<div class="col-lg-12">
							<input type="hidden" formControlName="id">
							<div class="form-group">
								<label for="validationCustom01">Plant Name</label>
								<input type="text" class="form-control" formControlName="unitname">
								<div *ngIf="submitted && f.unitname.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.unitname.errors.required">Unit name is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">GST/UIN</label>
								<input type="tel" class="form-control" formControlName="gst">
								<div *ngIf="submitted && f.gst.errors" class="invalid-feedback" style="display: block;">
									<div *ngIf="f.gst.errors.required">GST/UIN is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Plant Address</label>
								<textarea rows="3" class="form-control" autocomplete="off"
									formControlName="address"></textarea>
								<div *ngIf="submitted && f.address.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.address.errors.required">Plant address is required</div>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit" (click)="Saveunit()" *ngIf="!id">Save</button>
					<button class="btn btn-warning" type="submit" (click)="updatevendor()" *ngIf="id">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add  User Popup -->
<!-- Edit  User Popup -->
<!-- <div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Unit</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Unit Name</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">GST/UIN</label>
								<input type="tel" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Unit Address</label>
								<textarea rows="3" class="form-control"></textarea>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div> -->
<!-- End Edit  User Popup -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Plant</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
				(click)="deleteunit(Id,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
				(click)="deleteunit(Id,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodals">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>
