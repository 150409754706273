<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Inventory</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<div class="upload-date mr-3">Last Upload Date: {{lastUploadedDate | date :'MMM d, y'}} </div>
						<button type="button" class="btn temp-download custom-title" (click)="exportAsXLSX()"><i
								class="ri-file-download-line"></i><span class="tool">Download Template</span></button>

						<button type="button" class="btn upload-icon custom-title" (click)="openExcelModal()"><i
								class="ri-file-upload-line"></i><span class="tool">Upload</span></button>

						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="openInventoryModal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">

							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width: 150px;">Part Number<br>
											<div class="multiple-select">
												<ng-select class="test" [items]="productList" bindLabel="code" autofocus
													bindValue="_id" [(ngModel)]="searchByPartNumber"
													placeholder="Part Number" (change)="getInventry(1)">
												</ng-select>
										</div>
										</th>
										<th  style="width: 150px;">Part Name<br>
											<!-- <label class="tb-search"><input type="search"
													class="form-control form-control-sm" [(ngModel)]="searchByPartName"
													(input)="getInventry(1)"></label> -->
													<div class="multiple-select">
														<ng-select class="test" [items]="productList" bindLabel="name" autofocus
															bindValue="_id" [(ngModel)]="searchByPartName"
															placeholder="Part Name" (change)="getInventry(1)">
														</ng-select>
												</div>													
										</th>
										<th style="width:160px;">Plant</th>
										<th style="width:160px;">Work in progress (WIP)</th>
										<th style="width:140px;">Finished Goods (FG)</th>
										<th style="width:80px;">Total</th>
										<th style="width:65px;">Action</th>
									</tr>
								</thead>

								<tbody>

									<tr
										*ngFor="let inventry of inventryList | paginate: {id: 'inventryList',itemsPerPage: limit,currentPage: page,totalItems: totalResult}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Part Number">{{inventry?.productId?.code}}</td>
										<td data-title="Part Name">{{inventry?.productId?.name}}</td>
										<td data-title="Part Name">{{inventry?.plant ? inventry?.plant?.unitName + '\t'
											+ inventry?.plant?.unitAddress : '-'}}</td>
										<td data-title="Work in Progress">{{inventry?.workOfProgress}}</td>
										<td data-title="Finished Goods">{{inventry?.finishedGood}}</td>
										<td data-title="Total">{{inventry?.total}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="openInventoryModal(inventry)"><i
													class="mdi mdi-pencil font-size-18"></i></button>

											<a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"
													(click)="openDeleteModal(inventry)"></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !inventryList?.length">
										<td colspan="10">
											<h5 class="text-center">No Inventry found!</h5>
										</td>
									</tr>
								</tbody>

							</table>
							<div class="row" *ngIf="apiHitDone && inventryList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
											id="inventryList" (pageChange)="getInventry($event)"></pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->



<!-- Start Modal Add Inventory -->
<div class="modal fade bulk_add_instructions" id="bulk_add_instructions" tabindex="-1" id="bulk_add_instructions"
	role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Upload Inventory</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadExcelSheet()">
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" accept=".xlsx"
										formControlName="excelSheet" (change)="onSelectFile($event)"
										[ngClass]="{ 'is-invalid': excelsubmitted && excelSheet.excelSheet.errors }">
								</div>
								<div class="error-msg" *ngIf="excelsubmitted && excelSheet.excelSheet.errors"> <span
										style="color: red;" *ngIf="excelSheet.excelSheet.errors.required">
										Excel sheet is required.</span>
								</div>
								<div class="error-msg error-ul" *ngIf="erroMesage">
									<!-- <span style="color: red;">
									{{erroMesage}}</span> -->
									<ul>
										<li *ngFor="let err of erroMesage">{{err}}</li>
										<!-- <li>Plant can not be empty</li>
									<li>Plant can not be empty</li> -->
									</ul>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>

<!-- Add Inventory Popup -->
<div class="modal fade" id="add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">{{inventryToBeEdited ? 'Edit' : 'Add'}} Inventory</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="inventoryForm">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<div class="multiple-select">
									<label for="validationCustom01">Part Number <span
											style="color:red;">*</span></label>
									<!-- <input type="text" class="form-control" formControlName="partNumber"> -->
									<ng-select class="test" [items]="productList" bindLabel="code" autofocus
										bindValue="_id" formControlName="partNumber" placeholder="Select Item"
										(change)="getpartName($event)">
									</ng-select>
									<div *ngIf="inventoryFormsubmitted && inventoryFormControls.partNumber.errors"
										class="invalid-feedback" style="display: block;">
										<div *ngIf="inventoryFormControls.partNumber.errors.required">Part Number is
											required</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<div class="multiple-select">
									<label for="validationCustom01">Part Name <span style="color:red;">*</span></label>
									<!-- <input type="text" class="form-control" formControlName="partName"> -->
									<ng-select class="test" [items]="productList" bindLabel="name" autofocus
										bindValue="_id" formControlName="partName" placeholder="Select Item"
										(change)="getpartName($event)">
									</ng-select>
									<div *ngIf="inventoryFormsubmitted && inventoryFormControls.partName.errors"
										class="invalid-feedback" style="display: block;">
										<div *ngIf="inventoryFormControls.partName.errors.required">Part Name is
											required
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Plant Name <span style="color:red;">*</span></label>
								<!-- <input type="text" class="form-control" formControlName="plant"> -->
								<select class="form-control" formControlName="plant">
									<option value="" selected disabled>-- Select Plant-- </option>
									<option [value]="plant._id" *ngFor="let plant of unitlist">{{plant?.unitName}}
									</option>
								</select>
								<div *ngIf="inventoryFormsubmitted && inventoryFormControls.plant.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="inventoryFormControls.plant.errors.required">plant Name is required
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01"> Work in progress <span
										style="color:red;">*</span></label>
								<input type="number" class="form-control nos" formControlName="workOfProgress"
									(keypress)="numberOnly($event)">
								<div *ngIf="inventoryFormsubmitted && inventoryFormControls.workOfProgress.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="inventoryFormControls.workOfProgress.errors.required">Work in Progress
										is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01"> Finished Goods <span
										style="color:red;">*</span></label>
								<input type="number" class="form-control" formControlName="finishedGood"
									(keypress)="numberOnly($event)">
								<div *ngIf="inventoryFormsubmitted && inventoryFormControls.finishedGood.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="inventoryFormControls.finishedGood.errors.required">Finished Goods is
										required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Total<span style="color:red;">*</span></label>
								<input type="number" class="form-control" formControlName="total"
									(keypress)="numberOnly($event)">
								<div *ngIf="inventoryFormsubmitted && inventoryFormControls.total.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="inventoryFormControls.total.errors.required">Total is required</div>
								</div>
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit" (click)="addUpdateInventry()">{{inventryToBeEdited ?
						'Update' : 'Save'}}</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Inventory Popup -->

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Inventory</h2>
							<h6>{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"
								(click)="deleteInventry()"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>