import { Injectable } from '@angular/core';
import { endpoint } from '../constants/endpoints.constants';
import { CommonService } from './common/common.service';
import { EventsService } from './core/events/events.service';
import { HttpWrapperService } from './core/http/http-wrapper/http-wrapper.service';
import { StorageService } from './core/storage/storage.service';
import { storageKeys } from '../constants/storage-keys.constants';
import { eventKeys } from '../constants/event-keys.constants';

import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  Uid: string;

  constructor(private httpWrapperService: HttpWrapperService,
    private storage: StorageService,
    private events: EventsService) { }

  async login(body: { email: string, password: string }) {

    return this.httpWrapperService.post(endpoint.login, body, { observe: 'response' }).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  private handleLogin(loginResponse: any) {
    this.storage.set(storageKeys.userDetails, loginResponse.body.AdminData);
    this.storage.set(storageKeys.auth_token, loginResponse.body.AdminData.authToken);
    this.events.publish(eventKeys.loginSuccess, '');
  }

  async ChangePassword(payload: any, adminid) {

    return this.httpWrapperService.put(endpoint.changepassword(adminid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 422) {
            return error.error
          } else {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
  async CommonDelete(id) {

    return this.httpWrapperService.delete(endpoint.commondelete(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 422) {
            return error.error
          } else {
            return error.error
          }
        }
      )
  }
  async Logout() {

    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
    return this.httpWrapperService.put(endpoint.logout, '').map(
      (res) => {
        if (res) {
          if (res.code === 200) {
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          // this.commonService.handleError(error);
        }
      );
  }
}
