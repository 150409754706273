<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item"><a [routerLink]="['/admin/gstr-2a']"> Goods and Services Tax  - GSTR 2A </a>
							</li>
							<li class="breadcrumb-item active">Details</li>
						</ol>
					</div>
					<div class="page-title-right2">
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-lg-12">
				<!-- Invoice -->
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="invoice_box table-responsive">
							<!-- Header -->
							<div class="filter-all purchase-order-edit">
								<div class="d-flex align-items-center justify-content-between">
									<h4 class="card-title mb-2">Business to Business</h4>
								</div>
								 
							</div>
							<table class="table table-bordered ">
								<thead class="thead-light">
									<tr>
										<th><strong>Taxpayer's GSTIN</strong></th>
										<th><strong>Legal Name</strong></th>
										<th><strong>Financial Year</strong></th>
										<th><strong>Date of Generation</strong></th>
										<th><strong>Matching Status</strong></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td data-title="Taxpayer's GSTIN">06AAHFP1865F1Z9 </td>
                                        <td data-title="Legal Name"> PRECISION TECH ENTERPRISES</td>
                                        <td data-title="Financial Year"> 2020-21</td>
                                        <td data-title="Date of Generation">Mar 03, 2021</td>
                                        <td data-title="Matching Status"><span class="badge badge-warning ng-star-inserted">Partial Matched</span></td>
									</tr>
								</tbody>
							</table>

						</div>
						<div class="row">
							<div class="col-sm-12">
								<div class="filter-all">
									<div class="d-flex align-items-center justify-content-between">
										<h4 class="card-title mb-2">Item Details</h4>
									</div>
									<div class="dataTables_filter">
										<label>
											<button type="button" 
												class="btn btn-rounded btn-warning btn-w waves-effect waves-light m-0">
												Reconcile
											</button>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive table_responsive_scroll">
							<table class="table mb-0 responsive_scroll">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th>GSTIN of Supplier</th>
										<th>Name of The Supplier</th>
                                        <th>Invoice Number</th>
										<th>Invoice Date</th>
										<th>Invoice Value (₹)</th>
                                        <th>Place of Supply</th>
                                        <th>Rate (%)</th>
                                        <th>Taxable Value (₹)</th>
                                        <th>IGST(₹)</th>
                                        <th>CGST (₹)</th>
                                        <th>SGST (₹)</th>
                                        <th>CESS</th>
                                        <th>GSTR-1/IFF/GSTR-5 Filing Status</th>
                                        <th>GSTR-1/IFF/GSTR-5 Filing Date</th>
                                        <th>GSTR-1/IFF/GSTR-5 Filing Period</th>
                                        <th>GSTR-3B Filing Status</th>
                                        <th>Matching Status</th>
                                        <th style="width:140px;">Matching Error</th>
 									</tr>
								</thead>
								<tbody>
									<tr>
										<td data-title="No.">1 </td>
										<td data-title="GSTIN of Supplier">06AAACD4569J1ZI </td>
                                        <td data-title="Name of The Supplier">DIRCO  POLYMERS PVT LTD </td>
                                        <td data-title="Invoice Number"><span class="link-number" [routerLink]="['/admin/invoices/invoice-details']">2013460</span></td>
                                        <td data-title="Invoice Date"> 07-12-2020</td>
                                        <td data-title="Invoice Value (₹)">49688.64 </td>
                                        <td data-title="Place of Supply">Haryana</td>
                                        <td data-title="Rate (%)">18</td>
                                        <td data-title="Taxable Value (₹)">42109</td>
                                        <td data-title="IGST(₹)">0 </td>
                                        <td data-title="SGST (₹)">3789.81 </td>
                                        <td data-title="CGST (₹)">3789.81 </td>
                                        <td data-title="CESS">0 </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Status">Y </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Date">08-Jan-21</td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Period">Dec-20</td>
                                        <td data-title="GSTR-3B Filing Status "> Y</td>
                                        <td data-title="Matching Status"> <span class="badge badge-success ng-star-inserted">Matched</span></td>
                                        <td data-title="Matching Error"> </td>
									</tr>
                                    <tr>
										<td data-title="No.">2</td>
										<td data-title="GSTIN of Supplier">06AAACD4569J1ZI </td>
                                        <td data-title="Name of The Supplier">DIRCO  POLYMERS PVT LTD </td>
                                        <td data-title="Invoice Number"><span class="link-number" [routerLink]="['/admin/invoices/invoice-details']">2013460</span></td>
                                        <td data-title="Invoice Date"> 07-12-2020</td>
                                        <td data-title="Invoice Value (₹)">49688.64 </td>
                                        <td data-title="Place of Supply">Haryana</td>
                                        <td data-title="Rate (%)">18</td>
                                        <td data-title="Taxable Value (₹)">42109</td>
                                        <td data-title="IGST(₹)">0 </td>
                                        <td data-title="SGST (₹)">3789.81 </td>
                                        <td data-title="CGST (₹)">3789.81 </td>
                                        <td data-title="CESS">0 </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Status">Y </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Date">08-Jan-21</td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Period">Dec-20</td>
                                        <td data-title="GSTR-3B Filing Status "> Y</td>
                                        <td data-title="Matching Status"> <span class="badge badge-danger ng-star-inserted">Not Found

                                        </span></td>
                                        <td data-title="Matching Error"> </td>
									</tr>
                                    <tr>
										<td data-title="No.">3</td>
										<td data-title="GSTIN of Supplier">06AAACD4569J1ZI </td>
                                        <td data-title="Name of The Supplier">DIRCO  POLYMERS PVT LTD </td>
                                        <td data-title="Invoice Number"><span class="link-number" [routerLink]="['/admin/invoices/invoice-details']">2013460</span></td>
                                        <td data-title="Invoice Date"> 07-12-2020</td>
                                        <td data-title="Invoice Value (₹)">49688.64 </td>
                                        <td data-title="Place of Supply">Haryana</td>
                                        <td data-title="Rate (%)">18</td>
                                        <td data-title="Taxable Value (₹)">42109</td>
                                        <td data-title="IGST(₹)">0 </td>
                                        <td data-title="SGST (₹)">3789.81 </td>
                                        <td data-title="CGST (₹)">3789.81 </td>
                                        <td data-title="CESS">0 </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Status">Y </td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Date">08-Jan-21</td>
                                        <td data-title="GSTR-1/IFF/GSTR-5 Filing Period">Dec-20</td>
                                        <td data-title="GSTR-3B Filing Status "> Y</td>
                                        <td data-title="Matching Status"> <span class="badge badge-warning ng-star-inserted">Differences</span></td>
                                        <td data-title="Matching Error"><ul class="error-msg error-ul">
                                            <li>Invoice Value Mismatch</li>
                                         </ul> </td>
									</tr>

								</tbody>
							</table>
						</div>
					</div>
				</div>
			 
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->
 

 

 
 


