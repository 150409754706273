<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Groups</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="openItemGroupModal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.."  [(ngModel)]="search"
												(input)="getitemGroup(1)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width: 120px;">Group Code</th>
										<th>Group Description</th>
										<th style="width:65px;">Action</th>
									</tr>
								</thead>

								<tbody>
									<tr *ngFor="let item of itemGroupList | paginate: {id: 'itemGroupList',itemsPerPage: limit,currentPage: page,totalItems: totalResult}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Group Code">{{item?.itemGroupCode}}</td>
										<td data-title="Group Description">{{item?.itemGroupDesc}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												 (click)="openItemGroupModal(item)"><i
													class="mdi mdi-pencil font-size-18"></i></button>

											<a href="javascript:void(0);" class="text-danger" (click)="openDeleteModal(item._id)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !itemGroupList?.length">
										<td colspan="10">
											<h5 class="text-center">No item group found!</h5>
										</td>
									</tr>
								</tbody>

							</table>
							<div class="row" *ngIf="apiHitDone && itemGroupList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
											id="itemGroupList" (pageChange)="getitemGroup($event)"></pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->



<!-- Add Item Groups Popup -->
<div class="modal fade add_detail" id="item_group" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">{{itemGroupToBeEdited ? 'Edit' : 'Add'}} Group</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="addItemGroupForm" (ngSubmit)="addUpdateItem()">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Group Code <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="itemGroupCode" required>
								<div *ngIf="itemFormSubmitted && addItemGroupForm?.controls['itemGroupCode']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="addItemGroupForm?.controls['itemGroupCode']?.errors?.required">Group
										code
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Description <span style="color:red;">*</span></label>
								<textarea rows="3" class="form-control" autocomplete="off"
									formControlName="itemGroupDesc" required></textarea>
								<div *ngIf="itemFormSubmitted && addItemGroupForm?.controls['itemGroupDesc']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="addItemGroupForm?.controls['itemGroupDesc']?.errors?.required">
										Description
										is required</div>
								</div>
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Item Groups Popup -->

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Group</h2>
							<h6>{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close" (click)="deleteItemGroup()"><span
									class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
