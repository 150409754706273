import { Injectable } from '@angular/core';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { eventKeys } from '../../constants/event-keys.constants';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  order_id: string;
  pOinvoice_id: String;
  purchaseInvoice_id: string;
  purchaseItemInvoice_id: string;

  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService,
    private router: Router,
    
    ) { }


  getpurchaneOrderList(page, keyword, partyDetail, plantName, startDate, endDate, days, minValue, maxValue, companyid) {
    return this.httpWrapperService.get(endpoint.getPurchaseOrderList(page, keyword, partyDetail, plantName, startDate, endDate, days, minValue, maxValue, companyid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }

          this.commonService.dismissSpinner();



        }
      );
  }

  filterPrice(page,minValue, maxValue, companyid) {

    return this.httpWrapperService.get(endpoint.filterPrice(page,minValue, maxValue, companyid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
         return error;

          // this.commonService.dismissSpinner();



        }
      );
  }


  getSinglepurchaneOrder(id) {
    return this.httpWrapperService.get(endpoint.getSinglePurchaseOrder(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }
  addPurchaseOrder(payload) {
    let endPoint =  endpoint.addPurchaseOrder;
    if(this.router.url == '/admin/sales-po'){
      endPoint =  endpoint.salesProduct.uploadSalesPO;
    }
    return this.httpWrapperService.post(endPoint, payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

  deletePurchaseOrder() {
    return this.httpWrapperService.delete(endpoint.purchaseOrderDetere(this.order_id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

        }
      );
  };

  async EditPO(payload: any, id) {

    return this.httpWrapperService.put(endpoint.editPurchaseOrder(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }
        }
      );
  }

  async AddItem(postdata) {
    return this.httpWrapperService.post(endpoint.additem, postdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }
        }
      )
  }

  GetItems(id, page, keyword) {

    return this.httpWrapperService.get(endpoint.getItemList(id, page, keyword)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }

  async EditItem(payload: any, id) {

    return this.httpWrapperService.put(endpoint.editItem(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          } else {
            return error;
          }
        }
      );
  }
  GetRaiseInvoice(id) {

    return this.httpWrapperService.get(endpoint.getraiseinvoice(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }

  async RaiseInvoice(postdata) {
    return this.httpWrapperService.post(endpoint.raiseInvoice, postdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }
        }
      )
  }

  partynamebyid(id) {

    return this.httpWrapperService.get(endpoint.partynamebyid(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }

  deleteitem(id) {
    return this.httpWrapperService.delete(endpoint.deleteitem(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }

        }
      );
  };

  GetPOInvoice(id, page) {

    return this.httpWrapperService.get(endpoint.adminPOinvoice(id, page)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  };

  chnageStatus() {

    return this.httpWrapperService.put(endpoint.pOinvoiceStatus(this.pOinvoice_id), '').map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);
        }
      );
  }

  editPurchaseInvoce(payload: any, purchaseInvoice_id) {
    return this.httpWrapperService.put(endpoint.editInvoicepurchase(purchaseInvoice_id), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          // this.commonService.presentErrorToast('', error.error.message);
          return error;

        }
      )
  }

  getAdminPlantList(id) {
    return this.httpWrapperService.get(endpoint.adminplantList(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', 'Plants not available.');
        }
      );
  }

  itemPurchaseQuantity(payload: any) {
    return this.httpWrapperService.put(endpoint.editInvoiceItempurchase(this.purchaseItemInvoice_id), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);

        }
      )
  }

  editItemPurchasePORateQuantity(payload: any) {
    return this.httpWrapperService.put(endpoint.editItemPurchasePORateQuantity(this.purchaseItemInvoice_id), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);

        }
      )
  }

  GetItemList(companyid) {

    return this.httpWrapperService.get(endpoint.getitemlist(companyid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }

  SingleItemDetail(itemid) {

    return this.httpWrapperService.get(endpoint.singleitemdetail(itemid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }


        }
      );
  }

}


