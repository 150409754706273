<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><a [routerLink]="['/admin/products']">Product</a>
              </li>
              <li class="breadcrumb-item active">Details</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">

        <!-- Sales Item Detail -->
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="invoice_box table-responsive">
              <!-- Header -->
              <div class="filter-all purchase-order-edit">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="card-title mb-2">Product</h4>
                  <div class="dataTables_filter">
                    <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light mb-2"
                      (click)="editProduct()">Edit</button>
                  </div>
                </div>
              </div>
              <table class="table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Code Internal</th>
                    <th>Group Code</th>
                    <th>Description</th>
                    <th>HSN/SAC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-title="Name">{{productData?.name}}</td>
                    <td data-title="Item Code (Internal)">{{productData?.code}}</td>
                    <td data-title="Customer Item Code">{{productData?.group?.itemGroupCode}}</td>
                    <td data-title="Description">{{productData?.group?.itemGroupDesc}}</td>
                    <td data-title="HSN/SAC">{{productData?.hsn?.hsnNumber}}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>


        <!-- Sales BOM -->
        <div class="card table_page">
          <div class="card-body pt-3">

            <div class="filter-all">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title mb-2">Customer Code</h4>
              </div>
            </div>

            <div class="bom_box">
              <form [formGroup]="customerForm">
                <table class="table mb-0 table-bordered position-relative">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 300px;">Customer Name</th>
                      <th>Customer Item Code</th>
                      <th>Customer Item Name</th>
                      <th style="width: 77px;">Action</th>
                    </tr>
                  </thead>
                  <tbody id="customer-table">
                    <ng-container formArrayName="Rows">
                      <tr *ngFor="let itemrow of customerForm.controls.Rows['controls']; let i=index"
                        [formGroupName]="i">
                        <td data-title="Customer Name">
                          <div class="multiple-select">
                            <ng-select class="customer-name" formControlName="customerId" [items]="customer"
                              bindLabel="CustomerName" autofocus bindValue="_id"
                              placeholder="Select Customer"></ng-select>
                            <p *ngIf="itemrow.controls.customerId.invalid && isSubmitted" class="invalid-feedback"
                              style="display: block;">
                              <span [hidden]="!itemrow.controls.customerId.errors.required">
                                customer Name is required
                              </span>
                            </p>
                          </div>
                        </td>
                        <td data-title="Customer Item Code"><input formControlName="customerItemCode" type="text"
                            class="form-control" style="text-transform:uppercase;">
                          <p *ngIf="itemrow.controls.customerItemCode.invalid && isSubmitted" class="invalid-feedback"
                            style="display: block;">
                            <span [hidden]="!itemrow.controls.customerItemCode.errors.required">
                              Customer Item Code is required
                            </span>
                          </p>
                        </td>
                        <td data-title="Customer Item Name"><input type="text" formControlName="customerItemName"
                            class="form-control">
                          <p *ngIf="itemrow.controls.customerItemName.invalid && isSubmitted" class="invalid-feedback"
                            style="display: block;">
                            <span [hidden]="!itemrow.controls.customerItemName.errors.required">
                              Customer Item Name is required
                            </span>
                          </p>
                        </td>
                        <td data-title="Action">
                          <button type="button"
                            *ngIf="itemrow.controls.isSelected.value &&  (customerForm.controls.Rows['controls'].length != i+1) && itemrow.controls.customerCodeId.value != ''"
                            class="mr-2 text-primary btn_style" (click)="editCustomer('edit',itemrow ,i)"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <button type="button"
                            *ngIf="!itemrow.controls.isSelected.value &&  (customerForm.controls.Rows['controls'].length != i+1) &&  itemrow.controls.customerCodeId.value != ''"
                            class="mr-2 text-primary btn_style" (click)="editCustomer('set',itemrow, i)"><i
                              class="dripicons-checkmark font-size-20"></i>
                          </button>
                          <button type="button" class="mr-2 text-primary btn_style"
                            (click)="addNewRowForCustomerproduct()"
                            [hidden]="customerForm.controls.Rows['controls'].length != i + 1"><i
                              class="fas fa-plus font-size-15"></i>
                          </button>
                          <button type="button" class="mr-2 text-primary btn_style" (click)="addProduCtcustomer()"
                            [hidden]="customerForm.controls.Rows['controls'].length != i + 1">Save
                          </button>
                          <a _ngcontent-yjo-c108="" href="javascript:void(0);"
                            (click)="deleteRow(i,itemrow.controls.customerCodeId.value)"
                            *ngIf=" customerForm.controls.Rows['controls'].length != i+1 "
                            class="text-danger del-cust"><i _ngcontent-yjo-c108=""
                              class="mdi mdi-trash-can font-size-18"></i></a>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>



        <!-- Sales BOM -->
        <div class="card table_page bom-fill">
          <div class="card-body pt-3">
            <div class="filter-all">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title mb-2">Bill of Material [ {{(productData?.quantity ? productData?.quantity : '')  + '\t' + (productData?.unit ? productData?.unit : '') + '\t' + (productData?.processStage ? productData?.processStage?.stageName : '')}} ]</h4>
                <div class="dataTables_filter">
                  <label>
                    <input type="search" class="form-control form-control-sm" placeholder="Search.." [(ngModel)]="searchOnBillOfMaterial" (input)="getProductDetailById()">
                  </label>
                </div>
              </div>
            </div>
            <form [formGroup]="billOfMatrialForm">
              <table class="table table-bordered position-relative">
                <thead class="thead-light">
                  <tr>
                    <th style="width:200px;">Process Stage</th>
                    <th style="width:250px;">Item</th>
                    <th style="width:110px;">Unit</th>
                    <th>Quantity (No.s)</th>
                    <th>Gross Weight (Kgs)</th>
                    <th>Net Weight (Kgs)</th>
                    <th>Scrap Weight (Kgs)</th>
                    <th style="width:120px;">Action</th>
                  </tr>
                </thead>
                <ng-container formArrayName="BomRows">
                  <tbody id="bom-table">
                    <tr *ngIf="!productData?.billOfMaterialList?.length">
                      <td colspan="7">
                        <h6 class=" mb-0" style="color: #545a60;">No records found</h6>
                      </td>
                    </tr>
                    <tr *ngFor="let itemrow of billOfMatrialForm.controls.BomRows['controls']; let i=index"
                      [formGroupName]="i">
                      <td data-title="Process Stage">
                        <select class="process-select form-control" formControlName="processStage">
                          <option value="" selected disabled>Select Process Stage</option>
                          <option value="{{process?._id}}" *ngFor="let process of processStage">{{process?.stageName}}
                            ({{process?.stageCode}})</option>
                        </select>
                        <p *ngIf="itemrow.controls.processStage.invalid && isBOMSubmitted" class="invalid-feedback"
                          style="display: block;">
                          <span [hidden]="!itemrow.controls.processStage.errors.required">
                            Process Stage is required
                          </span>
                        </p>
                      </td>
                      <td data-title="Item">
                        <div class="multiple-select">
                          <ng-select class="test" [items]="item" bindLabel="name" autofocus bindValue="_id"
                            formControlName="productItem" placeholder="Select Item">
                          </ng-select>
                          <p *ngIf="itemrow.controls.productItem.invalid && isBOMSubmitted" class="invalid-feedback"
                            style="display: block;">
                            <span [hidden]="!itemrow.controls.productItem.errors.required">
                              Product Item is required
                            </span>
                          </p>
                        </div>
                      </td>
                      <td data-title="Unit">
                        <select class="unit-select form-control" formControlName="unit"
                          (change)="getUnit($event.target.value,i)">
                          <option value="" selected>Select Unit</option>
                          <option value="NOS">No.s</option>
                          <option value="KGS">Kgs</option>
                        </select>
                        <p *ngIf="itemrow.controls.unit.invalid && isBOMSubmitted" class="invalid-feedback"
                          style="display: block;">
                          <span [hidden]="!itemrow.controls.unit.errors.required">
                            Unit is required
                          </span>
                        </p>
                      </td>
                      <td data-title="Quantity"><input type="number" class="form-control nos"
                          formControlName="quantity">
                          <p *ngIf="itemrow.controls.quantity.invalid && isBOMSubmitted" class="invalid-feedback"
                          style="display: block;">
                          <span [hidden]="!itemrow.controls.quantity.errors.required">
                            Quantity is required
                          </span>
                        </p>
                        </td>
                      <td data-title="Gross Weight"><input type="number" class="form-control kgs"
                          formControlName="grossWeight"></td>
                      <td data-title="Net Weight"><input type="number" class="form-control kgs"
                          formControlName="netWeight"></td>
                      <td data-title="Scrap Weight"><input type="number" class="form-control kgs"
                          formControlName="scrapWeight"></td>
                      <td data-title="Action">
                        <button type="button"
                          *ngIf="itemrow.controls.isSelected.value &&  (billOfMatrialForm.controls.BomRows['controls'].length != i+1) && itemrow.controls.billOfMaterialId.value != ''"
                          class="mr-2 text-primary btn_style" (click)="editItem('edit',itemrow ,i)"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                        <button type="button"
                          *ngIf="!itemrow.controls.isSelected.value &&  (billOfMatrialForm.controls.BomRows['controls'].length != i+1) &&  itemrow.controls.billOfMaterialId.value != ''"
                          class="mr-2 text-primary btn_style" (click)="editItem('set',itemrow, i)"><i
                            class="dripicons-checkmark font-size-20"></i>
                        </button>
                        <button type="button" class="mr-2 text-primary btn_style" (click)="addNewRowForBOM()"
                          [hidden]="billOfMatrialForm.controls.BomRows['controls'].length != i + 1"><i
                            class="fas fa-plus font-size-15"></i>
                        </button>
                        <button type="button" class="mr-2 text-primary btn_style" (click)="saveBOM()"
                          [hidden]="billOfMatrialForm.controls.BomRows['controls'].length != i + 1">Save
                        </button>
                        <a _ngcontent-yjo-c108="" href="javascript:void(0);"
                          (click)="deleteBOMRow(i,itemrow.controls.billOfMaterialId.value)"
                          *ngIf=" billOfMatrialForm.controls.BomRows['controls'].length != i+1 "
                          class="text-danger del-cust"><i _ngcontent-yjo-c108=""
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    
                  </tbody>
                </ng-container>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- page-content -->


<div class="modal sc-ion-alert-ios-h sc-ion-alert-ios-s" id="delete_modal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
    <div class="alert-head sc-ion-alert-ios">
      <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Item</h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
    <div class="alert-button-group sc-ion-alert-ios">
      <button type="button" class="alert-button" (click)="deleteCustomerProduct()"><span
          class="alert-button-inner sc-ion-alert-ios">Yes</span>
      </button>
      <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
          class="alert-button-inner sc-ion-alert-ios">No</span></button>
    </div>
  </div>
</div>
<div class="modal sc-ion-alert-ios-h sc-ion-alert-ios-s" id="delete_BOM_modal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
    <div class="alert-head sc-ion-alert-ios">
      <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Bill of Material</h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
    <div class="alert-button-group sc-ion-alert-ios">
      <button type="button" class="alert-button" (click)="deleteBOM()"><span
          class="alert-button-inner sc-ion-alert-ios">Yes</span>
      </button>
      <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
          class="alert-button-inner sc-ion-alert-ios">No</span></button>
    </div>
  </div>
</div>
<app-add-products [productDetail]="toBeEdited" (sendAction)="getResponseFromChild($event)"></app-add-products>