import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { addUpdateInventory } from 'src/app/Services/DataManagement/products/product.model';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
declare var $: any;

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  itemCode: any;
  inventoryForm: FormGroup;
  inventoryFormsubmitted : boolean;
  excelsubmitted : boolean;
  apiHitDone:boolean
  page:number = 1;
  limit:number = 10;
  searchKeyword:string;
  inventryList:Array<[]>;
  unitlist:Array<[]>;
  productList:Array<[]>;
  totalResult:number;
  entries: Entries;
  inventryToBeEdited:any;
  excelSheetForm: FormGroup;
  inventryToBeDeleted:any;
  execlSheetData: File = null;
  erroMesage: any;
  searchByPartNumber:string
  searchByPartName:string;
  lastUploadedDate:string;
  constructor(
    private commonservices : CommonService,
    private productservices : ProductsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private excelService: FileSaverService,
    private formGroupService: FormsGroupService,
    private companyservice: CompanyService,

  ) { 
    this.inventoryForm = new FormGroup({
      partNumber: new FormControl('', [Validators.required]),
      partName: new FormControl('', [Validators.required]),
      plant: new FormControl('', [Validators.required]),
      workOfProgress: new FormControl('', Validators.required),
      finishedGood: new FormControl('', Validators.required),
      total: new FormControl('', Validators.required),
    });
    this.excelSheetForm = this.formGroupService.getExcelSheetForm();
  }

  get inventoryFormControls() { return this.inventoryForm.controls; }

  ngOnInit(): void {
    this.getInventry(1)
    this.GetUnitList();
    this.getProductList();
  }

  get excelSheet() {
		return this.excelSheetForm.controls;
	}

  getProductList() {
    this.productservices.getItemList().then(
      (res: any) => {
        if (res.code == 200 && res.list.length) {
          this.productList = res.list;
        }
      }
    )
  }

  GetUnitList() {
    this.commonservices.presentSpinner();
    this.companyservice.getUnitList(this.page).then(
      (res) => {
        this.commonservices.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.unitlist = [];
            this.unitlist = res.unitList;
           } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }

  getpartName(event){
    this.inventoryForm['controls'].partName.setValue(event._id)
    this.inventoryForm['controls'].partNumber.setValue(event._id)
  }

  openExcelModal(){
    this.excelSheetForm.reset();
    this.excelsubmitted = false;
    $('#bulk_add_instructions').modal('show');
  }

  exportAsXLSX(): void {
		this.commonservices.presentSpinner();
		this.productservices.downloadInventryExcelTemplate().then(
			(res) => {
				this.commonservices.dismissSpinner();
				if (res) {
					if (res.status == 200 && res.body) {
						this.commonservices.dismissSpinner();
						this.excelService.save(res.body, 'Inventry.xlsx');
					} else {
						this.commonservices.dismissSpinner();
					}
				} else {
					this.commonservices.dismissSpinner();
				}
			}
		)
	}

  onSelectFile(event) {

		this.erroMesage = '';
		var file: File = event.target.files[0];
		let currentFile = file;
		let imagetype = currentFile.type.split('/');
		let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if ($.inArray(imagetype[1], ValidImageExt) < 0) {
			this.commonservices.presentErrorToast('', "Only .xlsx format is allowed");
			this.excelSheetForm.controls['excelSheet'].setValue('');
			return false;
		}
		if (event.target.files && event.target.files[0]) {
			this.execlSheetData = event.target.files[0]
		}
	};

  uploadExcelSheet(){
    this.excelsubmitted = false;
		if (this.excelSheetForm.invalid) {
			this.excelsubmitted = true;
			return;
		};

    this.commonservices.presentSpinner()
    let payload = new FormData();
    payload.append("excelSheet", this.execlSheetData);
    this.productservices.uploadInventryExcel(payload).then(
      (res:any)=>{
        if(res.code == 200){
          this.execlSheetData = null
          this.commonservices.dismissSpinner();
          $('#bulk_add_instructions').modal('hide');
          this.excelSheetForm.reset();
          this.getInventry(1);
        }
      }
    ).catch((error)=>{
      this.erroMesage = error.error.message
    })
  }

  openInventoryModal(inventryDetail?:any){
    // inventryToBeEdited
    this.inventoryForm.reset();
    this.inventoryFormsubmitted = false;
    this.inventryToBeEdited = null;
    if(inventryDetail){
      this.inventryToBeEdited = { ...inventryDetail };
      this.inventoryForm.patchValue(this.inventryToBeEdited);
      this.inventoryForm['controls'].plant.setValue(this.inventryToBeEdited.plant ? this.inventryToBeEdited.plant._id : '') 
    }
    $('#add_detail').modal('show');
  }


  getInventry(page?: number) {
    this.page = page || 1;
    this.apiHitDone = false;
    this.commonservices.presentSpinner();
    this.productservices.getInventryList({ page: this.page.toString(), limit: this.limit.toString(), search: this.searchKeyword,searchByPartNumber:this.searchByPartNumber,searchByPartName:this.searchByPartName }).then(
      (res: any) => {
        if (res['body']['inventoryList']['data'].length > 0) {
          this.inventryList = res['body']['inventoryList']['data'];
          this.totalResult = res['body']['inventoryList'].total;
          this.lastUploadedDate = res['body']['inventoryList'].lastUploadedDate.createdDate;
          this.entries = this.commonservices.calculateEntries(this.inventryList, page, this.limit, this.totalResult);
        } else {
          this.inventryList = [];
          this.totalResult = 0;
          this.entries = this.commonservices.calculateEntries(this.inventryList, page, this.limit, this.totalResult);
        }
      }
    ).finally(
      () => {
        this.commonservices.dismissSpinner();
        this.apiHitDone = true;
        this.entries = this.commonservices.calculateEntries(this.inventryList, page, this.limit, this.totalResult);
        this.page = page;
        if (this.page > 1 && this.inventryList.length == 0) {
          this.page = this.page - 1;
          this.getInventry(this.page);
        } else {
          let params: any = {
            "page": this.page
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }

          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }

  addUpdateInventry(){
    let promise: Promise<any> = null;
    let payload = null
    this.inventoryFormsubmitted = false;
    payload = new addUpdateInventory(this.inventoryForm.value);
    payload.productId = this.inventoryForm.value.partNumber
    if(this.inventoryForm.invalid){
      this.inventoryFormsubmitted = true;
      return
    }
    if(this.inventryToBeEdited){
    promise = this.productservices.updateInventory(payload,this.inventryToBeEdited._id)      
    }else{
      promise = this.productservices.addinventory(payload)      
    }
    this.commonservices.presentSpinner();
    promise.then(
      (res:any)=>{
        if(res.code  == 200){
          this.commonservices.presentSuccessToast(res.message,'');
        }
      }
    ).catch((error)=>{
      this.commonservices.presentErrorToast(error.error.message,'');
    }).finally(()=>{
      this.getInventry(this.page);
      this.commonservices.dismissSpinner();
      $('#add_detail').modal('hide');
      this.inventoryForm.reset();
      this.inventoryFormsubmitted = false;
    })
  }

  openDeleteModal(inventryId:any){
    this.inventryToBeDeleted = inventryId._id;
    this.itemCode = inventryId.partNumber;
    if(this.inventryToBeDeleted){
      $('#delete-item').modal('show');
    }
  }

  deleteInventry(){
    this.commonservices.presentSpinner();
    this.productservices.deleteInventry(this.inventryToBeDeleted).then(
      (res:any)=>{
        if(res.code == 200){
          this.commonservices.presentSuccessToast(res.message,'');
        }
      }
    ).catch((error)=>{
      this.commonservices.presentErrorToast(error.error.message,'');
    }).finally(()=>{
      this.commonservices.dismissSpinner();
      $('#delete-item').modal('hide');
      this.inventryToBeDeleted = '';
      this.getInventry(this.page)
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
