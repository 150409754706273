import { Injectable } from '@angular/core';
import { endpoint } from '../../constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { storageKeys } from '../../constants/storage-keys.constants';
import { eventKeys } from '../../constants/event-keys.constants';

@Injectable({
  providedIn: 'root'
})
export class GatekeeperService {

  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService) { }

  async getGatekeeperInvoiceList() {

    return this.httpWrapperService.get(endpoint.gatekeepervendorInvoices).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getVendorInvoiceList(vendorid) {

    return this.httpWrapperService.get(endpoint.gatekeepervendorinvoice(vendorid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.vendorNameList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async GetInvoiceData(invoiceid) {

    return this.httpWrapperService.get(endpoint.gatekeeperinvoicedetail(invoiceid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceData) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async GatekeeperinvoiceApprove(invoiceid, postdata) {

    return this.httpWrapperService.put(endpoint.gatekeeperapproveinvoice(invoiceid), postdata).map(
      (res) => {
        if (res) {
          // if (res.code === 200) {
          //   if (res.invoiceData) {
          //   }
          // } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async gatekeeperuploadImage(invoiceid, formdata) {

    return this.httpWrapperService.post(endpoint.gatekeeperuploadimage(invoiceid), formdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async InvoiceReject(invoiceid) {

    return this.httpWrapperService.get(endpoint.gatekeeperrejectinvoice(invoiceid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
}
