<div class="modal fade" id="add_product" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" data-backdrop="false" style="background-color: rgba(0, 0, 0, 0.5);">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0"> {{detail ? 'Edit' : 'Add'}} Product</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
                <form [formGroup]="productForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Product Name <span style="color:red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name">
                                <div *ngIf="submitted && productFormSubmitted.name.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="productFormSubmitted.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Code Internal <span style="color:red;">*</span></label>
                                <input type="text" class="form-control" formControlName="code">
                                <div *ngIf="submitted && productFormSubmitted.code.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="productFormSubmitted.code.errors.required">code is required</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Description <span style="color:red;">*</span></label>
                                <textarea rows="3" class="form-control" autocomplete="off" formControlName="description"></textarea>
                                <div *ngIf="submitted && productFormSubmitted.description.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="productFormSubmitted.description.errors.required">Description is required</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="validationCustom01">Group Code <span style="color:red;">*</span></label>
                                <div class="multiple-select">
                                    <ng-select [searchable]="true" placeholder="Select Group Code" formControlName="group">
                                        <ng-option *ngFor="let item of itemGroup" value="{{item._id}}">{{item?.itemGroupCode}}</ng-option>
                                        
                                    </ng-select>
                                    <div *ngIf="submitted && productFormSubmitted.group.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="productFormSubmitted.group.errors.required">Group code is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="validationCustom01">Process Stage <span style="color:red;">*</span></label>
                                <div class="multiple-select">
                                    <ng-select [searchable]="true" placeholder="Select Process Stage" formControlName="processStage">
                                        <ng-option *ngFor="let list of processStage" value="{{list._id}}">{{list?.stageName}}</ng-option>
                                        
                                    </ng-select>
                                    <div *ngIf="submitted && productFormSubmitted.processStage.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="productFormSubmitted.processStage.errors.required">process Stage code is required</div>
                                    </div>                    
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="validationCustom01">Quantity <span style="color:red;">*</span></label>
                                <input type="number" class="form-control" formControlName="quantity">
                                <div *ngIf="submitted && productFormSubmitted.quantity.errors" class="invalid-feedback"
                                style="display: block;">
                                <div *ngIf="productFormSubmitted.quantity.errors.required">Quantity code is required</div>
                            </div>                                    
                            </div>
                        </div>
                
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="validationCustom01">Units <span style="color:red;">*</span></label>
                                <input type="text" class="form-control" formControlName="unit">
                                <div *ngIf="submitted && productFormSubmitted.unit.errors" class="invalid-feedback"
                                style="display: block;">
                                <div *ngIf="productFormSubmitted.unit.errors.required">Unit code is required</div>
                            </div>   
                            </div>
                        </div>
                
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">HSN/SAC <span style="color:red;">*</span></label>
                                <div class="multiple-select">
                                    <ng-select [items]="HSNCode" bindValue="_id" [searchable]="true" bindLabel="hsnNumber"
                                        placeholder="Select HSN Number" formControlName="hsn">
                                    </ng-select>
                                    <div *ngIf="submitted && productFormSubmitted.hsn.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="productFormSubmitted.hsn.errors.required">HSN code is required</div>
                                </div>   
                                </div>
                            </div>
                        </div>
                
                    </div>
                    <button class="btn btn-warning" type="submit" (click)="addProductForm()">Save</button>
                </form>
				
			</div>
		</div>
	</div>
</div>