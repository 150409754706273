<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active"> Goods and Services Tax - GSTR 2A</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openAddMoadal()">
              Add
            </button>
            <!-- <button type="button" class="btn btn-warning btn-w btn-rounded">Report Download </button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Vendor Eunctionality Available -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12">
                  <div class="filter-all">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="page-title-right2">
                        <div class="select_box select_box_text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="33.123" height="35.858"
                            viewBox="0 0 33.123 35.858">
                            <g id="Group_86" data-name="Group 86" transform="translate(857.079 -756.758)">
                              <path id="Path_2227" data-name="Path 2227"
                                d="M95.806,83.085H76.251a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H68.6a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM72.427,85.835a1.467,1.467,0,1,1,1.467-1.467A1.468,1.468,0,0,1,72.427,85.835Z"
                                transform="translate(-921.196 676.574)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                              <path id="Path_2228" data-name="Path 2228"
                                d="M95.806,211.618H92.753a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H85.106a4.033,4.033,0,0,0,7.647,0h3.052a1.284,1.284,0,0,0,0-2.568Zm-6.876,2.751A1.467,1.467,0,1,1,90.4,212.9,1.468,1.468,0,0,1,88.93,214.368Z"
                                transform="translate(-921.196 561.785)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                              <path id="Path_2229" data-name="Path 2229"
                                d="M95.806,340.151H81.752a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H74.1a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM77.928,342.9a1.467,1.467,0,1,1,1.466-1.467A1.468,1.468,0,0,1,77.928,342.9Z"
                                transform="translate(-921.196 446.996)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                            </g>
                          </svg>
                        </div>
                        <div class="select_box ml-3">
                          <div class="input-group date_group">
                            <div class="input-group" daterangepicker [options]="picker1"
                              (selected)="selectedDate($event, chosenDate)">
                              <span class="form-control uneditable-input" name="daterange">

                                <span *ngIf="chosenDate.start else elseblock">
                                  {{ chosenDate.start | date:'mediumDate' }} - {{
                                  chosenDate.end| date:'mediumDate' }}
                                </span>
                                <ng-template #elseblock>
                                  <span>
                                    <div class="calendar-icon"><span>Select
                                        date</span><i class="ri-calendar-2-line"></i></div>
                                  </span>
                                </ng-template>
                              </span>
                              <span class="input-group-btn">
                                <a type="button" class="btn btn-default"><i
                                    class="glyphicon glyphicon-calendar"></i></a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="search_box ml-2">
                          <select class="custom-select custom-select-sm">
                            <option value="">Select Match Count</option>
                            <option value="all-matched">All Matched</option>
                            <option value="partial-matched"> Partial Matched</option>
                            <option value="not-matched">Not Matched</option>
                          </select>
                        </div> -->
                        <div class="select_box ml-2">
                          <div class="input-group-reset">
                            <button type="button" class="btn custom-select-sm" (click)="Resetfilter()"><i
                                class="mdi text-primary font-size-18 mdi-refresh"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="dataTables_filter">
                      <label>
                        <!-- <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light m-0">
                          Reconcile </button> -->
                        <input type="search" class="form-control form-control-sm" placeholder="Search..."
                          [(ngModel)]="keyword" (input)="searcGST($event.target.value)">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:30px;">No.</th>
                    <th style="width:120px;">Taxpayer's GSTIN</th>
                    <th style="width:210px;">Legal Name</th>
                    <th style="width:120px;">Financial Year</th>
                    <th style="width:120px;">Date of Generation</th>
                    <th>Match Count</th>
                    <th style="width:64px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let data of gstList | paginate: {id: 'gst', itemsPerPage: limit, currentPage: page,totalItems: count }; let i = index">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Taxpayer's GSTIN">{{data?.gstNumber}}</td>
                    <td data-title="Legal Name">{{data?.legalName}}</td>
                    <td data-title="Financial Year">{{data?.financialYear}}</td>
                    <td data-title="Date of Generation">{{data?.genarationDate | date}}</td>
                    <td data-title="Match Count">
                      <!-- <span class="badge badge-success ng-star-inserted">All Matched</span> -->
                      {{data?.matchCount}}
                    </td>
                    <td data-title="Action">

                      <a href="javascript:void(0);" class="text-danger" (click)="deleteGstModal(data)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                      <a class="mr-2 text-solitud" (click)="downloadReport(data)"><i
                          class="ri-download-2-fill float-right download-btn"
                          style="position: static;font-size: 16px;"></i></a>
                    </td>
                  </tr>


                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}}
                    entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls id="gst" [autoHide]="true" responsive="true" previousLabel="Prev"
                      nextLabel="Next" (pageChange)="GetGstList($event)">
                    </pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- page-content -->
<!-- Start Modal Add Detail -->
<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Add GSTR 2A</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="GSTexcelSheetForm" (ngSubmit)="uploadExcelSheet()">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Upload Excel</label>
                <div class="custom_file">
                  <input type="file" id="customFile" class="custom_file_input" formControlName="excelSheet"
                    accept=".xlsx" (change)="onSelectFile($event)">
                </div>
                <div class="error-msg" *ngIf="submitted && a.excelSheet.errors"> <span style="color: red;"
                    *ngIf="a.excelSheet.errors.required">
                    Excel sheet is required.</span>

                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-warning" type="submit">Save</button>
        </form>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<!-- Start Modal Edit Detail -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Edit Invoice</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Upload Excel</label>
                <div class="custom_file">
                  <input type="file" id="customFile" class="custom_file_input">


                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-warning" type="submit">Save</button>
        </form>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
    <div class="alert-head sc-ion-alert-ios">
      <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Gst</h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
    <div class="alert-button-group sc-ion-alert-ios">
      <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
        (click)="deleteGst(gstid,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
      </button>
      <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
        (click)="deleteGst(gstid,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
    </div>
  </div>
</div>