import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { ExcelService } from 'src/app/Services/excel.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { GSTService } from 'src/app/Services/GSTServices/gst.service';
import { FileSaverService } from 'ngx-filesaver';

declare var $: any;

@Component({
  selector: 'app-goods-and-services-tax',
  templateUrl: './goods-and-services-tax.component.html',
  styleUrls: ['./goods-and-services-tax.component.scss']
})
export class GoodsAndServicesTaxComponent implements OnInit {
  erroMesage: any;
  submitted = false;
  GSTexcelSheetForm: FormGroup;
  execlSheet: File = null;
  Deletemodal: boolean;
  constructor(
    private daterangepickerOptions: DaterangepickerConfig,
    private formGroupService: FormsGroupService,
    private gstService: GSTService,
    private commonService: CommonService,
    private excelService: FileSaverService,
  ) {
    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      "opens": "right",
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };
    this.GSTexcelSheetForm = this.formGroupService.getExcelSheetForm();
  }
  public picker1 = {
    opens: 'left',
    startDate: moment().subtract(5, 'day'),
    endDate: moment(),
    isInvalidDate: function (date: any) {
      if (date.isSame('2017-09-26', 'day'))
        return 'mystyle';
      return false;
    }
  }
  public chosenDate: any = {
    // start: moment().subtract(12, 'month'),
    // end: moment().subtract(6, 'month'),
  };
  ShowdStardate = '';
  ShowdEndate = '';
  public selectedDate(value: any, dateInput: any): void {

    dateInput.start = value.start;
    dateInput.end = value.end;
    this.ShowdStardate = value.start.format('YYYY-MM-DD');
    this.ShowdEndate = value.end.format('YYYY-MM-DD');
    this.GetGstList(this.page)
  }
  get a() {
    return this.GSTexcelSheetForm.controls;

  }
  ngOnInit(): void {
    this.GetGstList(this.page)
  }
  onSelectFile(event) {

    this.erroMesage = '';
    if (event.target.files && event.target.files[0]) {

      this.execlSheet = event.target.files[0]
      // var reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]); // read file as data url
      // reader.onload = (event) => { // called once readAsDataURL is completed
      // 	this.url = event.target.result;
      // }
    }
  };

  page = 1;
  keyword: string = '';
  gstList: any = [];
  count = 0;
  limit = 10;
  entries: Entries = {
    from: 0,
    to: 0,
    total: 0
  };
  matchcount: any = '';
  GetGstList(page) {

    this.commonService.presentSpinner();
    this.page = page;
    this.gstService.GetGst(this.page, this.matchcount, this.keyword.length >= 3 ? this.keyword : '', this.ShowdStardate, this.ShowdEndate).then(
      (res) => {
        if (res.code == 200) {
          this.commonService.dismissSpinner();

          this.gstList = res.taxpayerList;

          this.count = res.totalResult;
          let amount = []
          this.entries = this.commonService.calculateEntries(this.gstList, page, this.limit, this.count);
        } else {
          this.commonService.dismissSpinner();
          this.gstList = [];
        }

      }
    ).catch((error) => {
      this.gstList = [];
      this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      this.commonService.dismissSpinner();
    }).finally(() => {
      this.commonService.dismissSpinner();
    })
  }

  searcGST(value) {
    this.keyword = value;
    this.ShowdStardate = ''
    this.GetGstList(this.page)
  }

  Resetfilter() {
    this.keyword = '';
    this.ShowdEndate = '';
    this.matchcount = '';
    this.chosenDate.start = ''
    this.chosenDate.end = ''
    this.GetGstList(this.page)
  }
  openAddMoadal() {
    this.submitted = false;

    this.GSTexcelSheetForm.reset();
    $('.add_detail').modal('show');
  }
  uploadExcelSheet() {
    this.submitted = true;
    if (this.GSTexcelSheetForm.invalid) {
      return;
    };
    this.commonService.presentSpinner();
    let payload = new FormData();
    payload.append("excelSheet", this.execlSheet);
    this.gstService.addGST(payload).then(
      (res) => {
        if (res.code == 200) {
          this.execlSheet = null
          this.commonService.dismissSpinner();
          this.commonService.presentSuccessToast(res.message, '')
          this.submitted = false;
          $('.add_detail').modal('hide');
        } else {
          this.commonService.dismissSpinner();
          this.submitted = false;
          this.execlSheet = null;
        }

      }
    ).catch((error) => {
      this.commonService.presentErrorToast(error.error.message, '');
      this.commonService.dismissSpinner();
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.GSTexcelSheetForm.reset();
      this.GetGstList(this.page)
    })
  }
  gstid: any;
  deleteGstModal(data) {
    this.Deletemodal = true;
    this.gstid = data._id;
  }
  id: any;
  deleteGst(item, e) {

    if (e == '') {
      this.id = item._id;
      this.Deletemodal = true
    }

    if (e == 'yes') {
      this.commonService.presentSpinner();

      this.gstService.deletegst(item).then(
        (res) => {
          this.commonService.dismissSpinner();
          if (res) {
            this.commonService.dismissSpinner();
            if (res.code === 200) {
              this.Deletemodal = false;
              this.commonService.presentSuccessToast(res.message, '')
              this.GetGstList(this.page)

            } else {

            }
          } else {
            this.commonService.dismissSpinner();
          }
        }
      );
    } else if (e == 'no') {
      this.Deletemodal = false;
    }
  }
  downloadReport(items) {

    this.commonService.presentSpinner();

    this.gstService.Downloadreport(items._id).then(
      (res: any) => {
        if (res.status == 200 && res.body) {
          this.excelService.save(res.body, 'GSTReport.xlsx');
          this.commonService.dismissSpinner();
        } else {
          this.commonService.presentErrorToast('No Data exist!', '');
        }

      }
    ).catch((error) => {
      this.commonService.dismissSpinner();
    }).finally(() => {
      this.commonService.dismissSpinner();
    })
  }


}