import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { storageKeys } from 'src/app/constants/storage-keys.constants';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
declare var $: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  ProfileSettingsForm: FormGroup;
  userlogindata: any = {};
  submitted = false;
  commonmodal: boolean;
  displaymsg: any;
  constructor(
    private formGroupService: FormsGroupService,
    private companyservice: CompanyService,
    private commonService: CommonService,
    private storage: StorageService,
  ) {
    this.ProfileSettingsForm = this.formGroupService.profileSetting();

    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"]
  }
  get f() {
    return this.ProfileSettingsForm.controls;

  }
  ngOnInit(): void {
  }

  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.imageErrMsg = ''
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Formats allowed : jpg, jpeg, png";
      this.ProfileSettingsForm.controls['profileimg'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  SaveProfileSettings() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.ProfileSettingsForm.invalid) {
      return;
    }

    this.commonService.presentSpinner();
    var frmData = new FormData();
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("name", this.ProfileSettingsForm.value.name);
    frmData.append("email", this.ProfileSettingsForm.value.emailid);
    frmData.append("role", this.userlogindata.role);

    this.companyservice.Editadmin(frmData, this.userlogindata._id).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            $('.edit_detail').modal('hide');
            this.userlogindata.name = res.result.name;
            this.userlogindata.profileImage = res.result.profileImage;
            this.storage.set(storageKeys.userDetails, this.userlogindata);

            this.submitted = false;
            this.displaymsg = res.message;
            this.commonmodal = true;
            // this.commonService.presentSuccessToast('', res.message);

          }
        }
      }
    );
  }
  OpenEditprofile() {
    this.imageErrMsg = ''
    $('.edit_detail').modal('show');
    this.imageUrl = this.userlogindata.profileImage ? this.userlogindata.profileImage : '',
      this.ProfileSettingsForm.patchValue({

        name: this.userlogindata.name,
        emailid: this.userlogindata.email
      })
  }
  CloseProfile() {
    this.imageUrl = '';
    this.fileToUpload = null
  }
  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }
}
