import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { ItemService } from 'src/app/Services/item/item.service';
declare var $: any;
@Component({
	selector: 'app-items',
	templateUrl: './items.component.html',
	styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
	addAdminItemForm: FormGroup;
	editAdminItemForm: FormGroup;
	submitted = false;
	itemsList: any;
	item_id: any;
	page = 1;
	count = 0;
	limit = 10;
	itemCode: any;
	entries: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	userlogindata: any;
	companyId: any;
	Deletemodal: boolean;
	displaymsg: any;
	commonmodal: boolean;
	orderstatus: any;
	HSNCode: any = [];

	constructor(
		private formsGroupService: FormsGroupService,
		private itemService: ItemService,
		private commonService: CommonService,
		private storage: StorageService) { }

	ngOnInit(): void {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyId = this.userlogindata.companyId._id;


		this.addAdminItemForm = this.formsGroupService.addItemsAdminSection();
		this.editAdminItemForm = this.formsGroupService.editItemsAdminSection();
		this.getItems(this.page);
		this.getHSNCode();

	}

	get a() {
		return this.addAdminItemForm.controls;
	}

	opemModals(val, data) {

		if (val == "addModal") {
			this.addAdminItemForm.reset();
			this.submitted = false;
			$('#add_detail').modal('show');
		} else if (val == "delete") {
			$('#delete-item').modal('show');
			this.item_id = data._id;
			this.itemCode = data.itemCode
		} else if (val == "edit") {
			this.submitted = false;
			this.item_id = data._id;
			$('#edit_detail').modal('show');
			this.editAdminItemForm.patchValue({
				"itemCode": data.itemCode,
				"itemName": data.itemName,
				"description": data.itemDesc,
				"unit": data.unit,
				"HSNnumber": data.HSNSANum._id,
			})

		}
	}
	getItems(page) {
		this.page = page;
		this.itemService.getAdminItemsList(this.page).then(
			(res) => {
				if (res.code == 200) {
					this.itemsList = res.itemList;
					this.count = res.totalResult;
					this.entries = this.commonService.calculateEntries(this.itemsList, page, this.limit, this.count);
				}
			}
		)
	}
	getHSNCode() {
		this.itemService.getHSNCodeList().then(
			(res) => {
				if (res.code == 200) {
					this.HSNCode = res.hsnList;

				}
			}
		)
	}
	addItem() {

		this.commonService.presentSpinner();
		this.submitted = true;
		if (this.addAdminItemForm.invalid) {
			this.commonService.dismissSpinner();
			return;
		}
		let payload = {
			"itemCode": this.addAdminItemForm.value.itemCode,
			"itemName": this.addAdminItemForm.value.itemName,
			"itemDesc": this.addAdminItemForm.value.description,
			"unit": this.addAdminItemForm.value.unit,
			"HSNSANum": this.addAdminItemForm.value.HSNnumber,
			"companyId": this.companyId
		};

		this.itemService.addAdminItems(payload).then(
			(res) => {
				if (res.code == 200) {
					$('#add_detail').modal('hide');
					this.getItems(this.page);
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.commonmodal = true;
				} else {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.commonmodal = true;
				}
			}
		);
	}

	editItem() {

		this.commonService.presentSpinner();
		let payload = {
			"itemCode": this.editAdminItemForm.value.itemCode,
			"itemName": this.editAdminItemForm.value.itemName,
			"itemDesc": this.editAdminItemForm.value.description,
			"unit": this.editAdminItemForm.value.unit,
			"HSNSANum": this.editAdminItemForm.value.HSNnumber,
			"companyId": this.companyId

		};

		this.itemService.item_id = this.item_id;

		this.itemService.editAdminItems(payload).then(
			(res) => {

				if (res.code == 200) {
					$('#edit_detail').modal('hide');
					this.getItems(this.page);
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.commonmodal = true;

				} else {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.commonmodal = true;
				}
			}
		);
	}

	itemsDelete() {
		this.commonService.presentSpinner();
		this.itemService.item_id = this.item_id;
		this.itemService.adminItemeDelete().then(
			(res) => {

				if (res.code == 200) {
					$('#delete-item').modal('hide');
					this.getItems(this.page);
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.commonmodal = true;


				}
			}
		)
	}

	GetChildData(data) {
		if (data) {
			this.commonmodal = false;
		}
	}

}
