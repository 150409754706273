export const endpoint = {
    login: "/admin/common/login",
    changepassword: (adminid: string) => { return `/admin/common/change-password/${adminid}` },
    companylist: (page: string) => { return `/admin/company?page=` + page + `&limit=10` },
    deletecompany: (companyid: string) => { return `/admin/company/${companyid}` },
    commondelete: (id: string) => { return `/admin/common/${id}` },
    editcompany: (companyid: string) => { return `/admin/company/${companyid}` },
    addcompany: "/admin/company",
    administrators: "/admin/administrators",
    administratorslist: (page: string) => { return `/admin/administrators?page=` + page + `&limit=10` },
    userlist: (page: string) => { return `/admin/user?page=` + page + `&limit=10` },
    searchuserbyrole: (page: string) => { return `/admin/user-by-role?page=` + page + `&limit=10` },
    editadministrators: (adminid: string) => { return `/admin/administrators/${adminid}` },
    getcompanylist: "/admin/company-name",
    searchcompany: "/admin/company",
    searchadmin: "/admin/administrators",
    searchuser: "/admin/user",
    addvendor: "/admin/vendor",
    vendorlist: (page: string) => { return `/admin/vendor?page=` + page + `&limit=10` },
    editvendor: (vendorid: string) => { return `/admin/vendor/${vendorid}` },
    // searchvendor: "/admin/vendor",
    searchvendor: (page: string, searchUser) => { return `/admin/vendor?page=` + page + `&limit=10` + `&search=${searchUser}` },
    addunit: "/admin/unit",
    editunit: (unitid: string) => { return `/admin/unit/${unitid}` },
    getunitlist: (page: string) => { return `/admin/unit?page=` + page + `&limit=1000` },
    deleteunit: (unitid: string) => { return `/admin/unit/${unitid}` },
    searchunit: (page: string, searchUser) => { return `/admin/unit?page=` + page + `&limit=10` + `&search=${searchUser}` },
    addInvoicePurchase: "/admin/add-invoice",
    addInvoiceSale: "/admin/invoice-sale",
    filterPrice: (page: string, minValue: number, maxValue: number, company_Id: string) => { return `/admin/purchase-order?page=` + page + `&limit=10` + `&minAmount=${minValue}` + (maxValue ? `&maxAmount=${maxValue}` : '') + (company_Id ? `&companyId=${company_Id}` : '') },
    filterPriceInvoice: (page: string, minValue: number, maxValue: number, company_Id: string) => { return `/admin/invoice-purchase?page=` + page + `&limit=10` + `&minAmount=${minValue}` + (maxValue ? `&maxAmount=${maxValue}` : '') + (company_Id ? `&companyId=${company_Id}` : '') },

    GetFilterinvoice: (page: string, keyword: string, partyName: string, plantName: string, startDate: string, endDate: string, days: string, company_Id: string) => { return `/admin/invoice-purchase?page=` + page + `&limit=10` + (keyword ? `&search=${keyword}` : '') + (partyName ? `&partyName=${partyName}` : '') + (plantName ? `&plant=${plantName}` : '') + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (days ? `&days=${days}` : '') + (company_Id ? `&companyId=${company_Id}` : '') },
    downloadinvoicedata: (page: string, keyword: string, startDate: string, endDate: string, company_Id: string) => { return `/admin/invoice-purchase/report/download?page=` + page + `&limit=10` + (keyword ? `&search=${keyword}` : '') + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (company_Id ? `&companyId=${company_Id}` : '') },
    singleinvoicedetail: (invoiceid: string, page: string, searchItem: string) => { return `/admin/single-invoice/${invoiceid}?page=` + page + `&limit=10` + (searchItem ? `&search=${searchItem}` : '') },
    deleteinvoicepurchase: (invoiceid: string) => { return `/admin/invoice-purchase/${invoiceid}` },
    getinvoicesale: (page: string) => { return `/admin/invoice-sale?page=` + page + `&limit=10` },
    logout: "/admin/common/logout",

    // --------------------Purchase Order-------------------//
    getPurchaseOrderList: (page: string, keyword: string, partyName: string, plantName: string, startDate: string, endDate: string, days: string, minValue: number, maxValue: number, companyid: string) => { return `/admin/purchase-order?page=` + page + `&limit=10` + (keyword ? `&search=${keyword}` : '') + (partyName ? `&partyName=${partyName}` : '') + (plantName ? `&plant=${plantName}` : '') + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (days ? `&days=${days}` : '') + `&minAmount=${minValue}` + (maxValue ? `&maxAmount=${maxValue}` : '') + (companyid ? `&companyId=${companyid}` : '') },
    getSinglePurchaseOrder: (poId) => { return `/admin/purchase-order/${poId}` },
    editPurchaseOrder: (poId) => { return `/admin/purchase-order/${poId}` },
    editItem: (itemid) => { return `/admin/item/${itemid}` },
    getraiseinvoice: (poId) => { return `/admin/raise-invoice/${poId}` },
    addPurchaseOrder: "/admin/purchase-order",
    additem: "/admin/item",
    raiseInvoice: "/admin/submit-raise-invoice",
    getItemList: (poId: string, page: string, keyword: string) => { return `/admin/item/${poId}?page=` + page + `&limit=10` + (keyword ? `&search=${keyword}` : '') },
    purchaseOrderDetere: (order_id: string) => { return `/admin/purchase-order/${order_id}` },
    plantDropDownList: (admin_id: string) => { return `/admin/plants/${admin_id}` },
    partyDropdownList: (admin_id: string) => { return `/admin/party-name/${admin_id}` },//

    //-------------------------Admin Items ----------------------------------//
    adminItemsList: (page: string) => { return `/admin/get-item?page=${page}` + `&limit=10` },
    addadminitems: "/admin/add-item",
    editadminites: (item_id: string) => { return `/admin/edit-item/${item_id}` },
    deleteAdminItems: (items_id: string) => { return `/admin/delete-item/${items_id}` },
    gethsncode: "/admin/hsn-number?page=1&limit=1000",
    deleteitem: (itemid: string) => { return `/admin/item/${itemid}` },
    adminPOinvoice: (poid: string, page: string) => { return `/admin/PO-invoice/${poid}?page=${page}` + `&limit=10` },
    partynamebyid: (vendorId: string) => { return `/admin/single-party-name/${vendorId}` },
    deleteInvoiceItem: (invoice_id: string, items_id: string) => { return `/admin/invoice-purchase-item/${invoice_id}?_id=${items_id}` },

    //-------------------------------PO-Invoce ------------------------------//
    pOinvoiceStatus: (pOinvoice_id: String) => { return `/admin/PO-invoice-status-change/${pOinvoice_id}` },
    //--------------------------------Puschase Order >Detail>Invoices>Details>Edit--------------------//
    editInvoicepurchase: (purchaseInvoice_id: string) => { return `/admin/invoice-purchase/${purchaseInvoice_id}` },
    adminplantList: (admin_id: string) => { return `/admin/plants/${admin_id}` },
    editInvoiceItempurchase: (purchaseItemInvoice_id: string) => { return `/admin/edit-invoice-item-quantity/${purchaseItemInvoice_id}` },
    editItemPurchasePORateQuantity: (purchaseItemInvoice_id: string) => { return `/admin/edit-invoice-item-po-rate/${purchaseItemInvoice_id}` },
    getitemlist: (companyId: string) => { return `/admin/item-list/${companyId}` },
    singleitemdetail: (itemid: string) => { return `/admin/single-item/${itemid}` },
    gatekeepervendorInvoices: `/admin/getekeeper/get-pending-invoices`,
    gatekeepervendorinvoice: (vendorid: string) => { return `/admin/gatekeeper/vendor-invoice-list/${vendorid}` },
    gatekeeperinvoicedetail: (invoiceid: string) => { return `/admin/gatekeeper/single-invoice-data/${invoiceid}` },
    gatekeeperapproveinvoice: (invoiceid: string) => { return `/admin/gatekeeper/invoice-item-approved-notapproved/${invoiceid}` },
    gatekeeperuploadimage: (invoiceid: string) => { return `/admin/gatekeeper/upload-images/${invoiceid}` },
    gatekeeperrejectinvoice: (invoiceid: string) => { return `/admin/getekeeper/reject-invoice/${invoiceid}` },
    searchinvoiceitem: (invoiceid: string) => { return `/admin/search-invoice-item/${invoiceid}` },

    // //------------------------------Invoices (Purchase)----------------------------//
    invoiceChangeSatatue: (invoice_id: String) => { return `/admin/PO-invoice-status-change/${invoice_id}` },

    //----------------------------------Store keeper ----------------------------------//

    getstoreKeeperInvoiceData: (vendor_id: string) => { return `/admin/storekeeper/single-invoice-data/${vendor_id}` },
    gatStoreKeepervendor: (companyid: string) => { return `/admin/gatekeeper/vendor-list/${companyid}` },
    gatStoreKeepervendorinvoice: (vendorid: string) => { return `/admin/gatekeeper/vendor-invoice-list/${vendorid}` },
    gatstorekeeperapproveinvoice: (invoiceid: string) => { return `/admin/storekeeper/item-checked-unchecked/${invoiceid}` },
    gatStorekeeperuploadImage: (invoiceid: string) => { return `/admin/storekeeper/upload-images/${invoiceid}` },
    rejectInvoice: (invoide_id: string) => { return `/admin/storekeeper/reject-invoice/${invoide_id}` },
    editQuantuty: (invoice_id) => { return `/admin/storekeeper/edit-item-quantity/${invoice_id}` },
    searchInvoice: (invoice_id: string, searchInvoce: string) => { return `/admin/search-invoice-item/${invoice_id}?search=${searchInvoce}` },
    // storekeeperinvoiceList: (company_id: string) => { return `/admin/storekeeper/get-pending-invoices=${company_id}` },
    storekeeperinvoiceList: "/admin/storekeeper/get-pending-invoices",
    gethsnlist: (page: string, keyword: string,) => { return `/admin/hsn-number?page=${page}` + `&limit=10` + `&searchKey=${keyword}` },
    addhsn: "/admin/hsn-number",
    editDeletehsn: (hsnId) => { return `/admin/hsn-number/${hsnId}` },

    // Gst
    uploadgstr: "/admin/gstr/upload-file",
    // gstrlist: "/admin/gstr",
    gstrlist: (page: string, matchStatus: string, search: string, startDate: string, endDate: string) => { return `/admin/gstr?page=` + page + `&limit=10` + (matchStatus ? `&matchStatus=${matchStatus}` : '') + (search ? `&search=${search}` : '') + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') },
    deletegst: (gstid) => { return `/admin/gstr/${gstid}` },
    crediteligibilty: (invoiceid) => { return `/admin/invoice-credit-eligibilty/${invoiceid}` },

    // Download Report:
    downloadreport: (gstrId) => { return `/admin/gstr/${gstrId}/download` },


    //Customer end points
    addcustomer: "/admin/add-customer",
    getcustomer: "/admin/get-customer",
    updatecustomer: "/admin/edit-customer",
    customersexcel: "/admin/add-customers-excel",
    deletecustomer: "/admin/delete-customer",
    itemGroup: {
        itemgroup: '/admin/itemgroup'
    },
    procesStage : {
        processStage: "/admin/process-stage"
    },
    dataManagement : {
        products : '/admin/add-products',
        itemgroupList : '/admin/itemgroupList',
        processList : '/admin/processList',
        itemGroup : '/admin/itemgroupList',
        productlist : '/admin/get-product-lists',
        editproduct : '/admin/edit-product',
        deleteproduct : '/admin/product',
        productdetails : '/admin/get-product-details',
        customerList : '/admin/getCustomerlist',
        addproductcustomer: '/admin/add-products-customer-code',
        deleteproductcustomer: '/admin/products-customer-code',
        bomprocessList: '/admin/processList',
        getitemlist: '/admin/getProductList',
        addBOM: '/admin/add-products-billOfMatrial',
        deleteBOM: '/admin/bill-of-matrial',
        downloadproductsheet: '/admin/download-product-excelsheet',
        uploadproductsheet: '/admin/uploadProductExcelsheetData',
        downloadcustomerexcel: '/admin/download-customer-excelsheet',
        inventory:{
            inventory : '/admin/add-inventory',
            getinventory : '/admin/inventory',
            getexceltemplate : '/admin/download-inventory',
            uploadexcel : '/admin/upload-inventory',
        },
    },
    salesProduct : {
        downloadSalesPOExcelsheetData : "/admin/downloadSalesPOExcelsheetData",
        uploadSalesPO : "/admin/uploadSalesPOExcelsheetData",
        getsalesPOList : "/admin/getsalesPOList",
        getsalesPOListwithoutpagination : "/admin/getAllSalesPOListwithoutPagination",
        deleteSalesPO : "/admin/deleteSalesPO",
        editsalespodetail : "/admin/salesPO",
        getProductList : "/admin/getProductList",
        addsalesProduct : "/admin/addSalesPOProduct",
        getsalesPOProductList : "/admin/getsalesPOProductList",
        updatesalesProduct : "/admin/updateSalesPOProduct",
        deleteSalesPOProduct : "/admin/deleteSalesPOProduct",
        downloadDIExcel : "/admin/downloadInstructionExcelsheet",
        uploadDIExcel : "/admin/uploadInstructionExcelsheetData",
        addDI : "/admin/addDeliveryIntruction",
        updateDI : "/admin/updateDeliveryIntruction",
        getDi : "/admin/getDeliveryInstruction",
        deleteDi : "/admin/deliveryInstruction",

    }

}
