import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { GatekeeperService } from 'src/app/Services/GatekeeperService/gatekeeper.service';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-gatekeeper',
	templateUrl: './gatekeeper.component.html',
	styleUrls: ['./gatekeeper.component.scss']
})
export class GatekeeperComponent implements OnInit {
	radio = true;
	value: any;
	InvoiceDetail: boolean;
	GatekeeperApprove: boolean;
	SearchInvoice: boolean = true;
	HideContinuebutton: boolean = true;
	HideInvoicelist: boolean = true;
	invoicereport: boolean;
	userlogindata: any = {};
	companyid: any;
	GatekeeperInvoicelist: any = [];
	invoiceList: any = [];
	vendorname: string = '';
	invoicenumber: string = '';
	invoicedata: any = [];
	urls: any = [];
	comment: string = ''
	constructor(
		private route: ActivatedRoute,

		private gatekeeperservice: GatekeeperService,
		private commonService: CommonService,
		private storage: StorageService,
		// private events: EventsService,
	) {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyid = this.userlogindata.companyId._id
	}

	ngOnInit(): void {
		this.GatekeeperinvoiceList();
	}
	changestatus(val) {
		this.value = val;
		if (val == "reject") {
			this.value = val;
		}

	}
	invoiceid: any;
	GetInvoiceId(e) {
		this.invoiceid = ''
		// this.invoiceid = e.target.value
	}

	clickcount: number = 0;
	ShowInvoiceDetail(status, invoiceid) {

		this.invoiceid = invoiceid
		if (status == 'status') {
			this.GetInvoiceData(this.invoiceid);
			return
		}
		this.clickcount++;

		if (this.clickcount == 1) {
			this.GetInvoiceData(this.invoiceid)
			this.HideContinuebutton = true;
		}
	}
	ContinueInvoiceReport() {

		var filtered = [];
		for (var i = 0; i < this.invoicedata.itemDetails.length; i++) {
			if (this.invoicedata.itemDetails[i].gateKeeperStatus == "Pending") {
				filtered.push(this.invoicedata.itemDetails[i]);

			}
		}
		if (filtered.length > 0) {
			this.displaymsg = "Action on " + filtered.length + " items are still pending";
			this.commonemodal = true;
			return
		}
		this.clickcount++;




		if (this.clickcount == 1) {
			this.HideInvoicelist = false;
			this.InvoiceDetail = true;
			this.invoicereport = false;
			this.HideContinuebutton = true;
			this.GatekeeperApprove = false;
		}
		else if (this.clickcount == 2) {
			this.HideInvoicelist = false;
			this.InvoiceDetail = false;
			this.invoicereport = true;
			this.HideContinuebutton = false;
			this.GatekeeperApprove = true;
		}

	}
	approveitemlist: any = []
	GetInvoiceData(invoiceid) {
		this.gatekeeperservice.GetInvoiceData(invoiceid).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.HideInvoicelist = false
						this.InvoiceDetail = true
						this.approveitemlist = res
						this.invoicedata = res.invoiceData;
						var invoicedate = new Date(this.invoicedata.invoiceDate);
						var d = String(invoicedate.getDate()).padStart(2, '0');
						var m = String(invoicedate.getMonth() + 1).padStart(2, '0'); //January is 0!
						var y = invoicedate.getFullYear();
						let dateformate = d + '/' + m + '/' + y;
						this.invoicedata['invoiceDate'] = dateformate;
					}
				}

			}
		)
	}
	backbutton(item) {
		this.clickcount--;

		if (item == 'invoice') {
			this.HideInvoicelist = true;
			this.InvoiceDetail = false;
			this.invoicereport = false;
			this.HideContinuebutton = false;
			this.GatekeeperApprove = false;
		}
		if (item == 'invoiceDetail') {
			this.HideInvoicelist = false;
			this.InvoiceDetail = true;
			this.invoicereport = false;
			this.HideContinuebutton = true;
			this.GatekeeperApprove = false;
		}

	}

	GatekeeperinvoiceList() {

		this.gatekeeperservice.getGatekeeperInvoiceList().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.GatekeeperInvoicelist = res.invoiceList;
					}
				}

			}
		)
	}

	GetInvoice(e) {
		let vandorid = e.target.value
		this.gatekeeperservice.getVendorInvoiceList(vandorid).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.invoiceList = [];
						this.invoiceList = res.invoiceList;
					} else {
						this.invoiceList = [];
					}
				}

			}
		)
	}

	ApproveInvoice(item, e) {
		var postdata;
		this.commonService.presentSpinner();
		if (e == 'approve') {
			postdata = {
				"itemId": item._id,
				"gateKeeperStatus": "Approved"
			}
		} else {
			postdata = {
				"itemId": item._id,
				"gateKeeperStatus": "Not Approved"
			}
		}
		this.gatekeeperservice.GatekeeperinvoiceApprove(this.invoiceid, postdata).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.ShowInvoiceDetail('status', this.invoiceid);
						this.commonService.dismissSpinner();
					} else {
						this.commonService.dismissSpinner();
					}
				}

			}
		)
	}
	fileToUpload: File = null;
	postimg: any = []
	onSelectFile(event) {
		let file = event.target.files
		if (file && file[0]) {
			var filesAmount = file.length;
			for (let i = 0; i < filesAmount; i++) {
				this.fileToUpload = file[i];
				var reader = new FileReader();
				reader.readAsDataURL(file[i]);
				reader.onload = (evnt) => {
					this.urls.push(evnt.target.result);
					this.postimg.push(file[i]);
				}

			}
		}


	}

	removeImage(index: number) {
		this.urls.splice(index, 1);
		this.postimg.splice(index, 1);
	}
	img: any = []
	isSubmit: boolean;
	Deletemodal: boolean;
	commonemodal: boolean;
	displaymsg: any;
	UploadImage() {
		this.commonService.presentSpinner();
		var frmData = new FormData();

		// for (let file of this.postimg) {

		// }
		for (let index = 0; index < this.postimg.length; index++) {
			const element = this.postimg[index];

			frmData.append("itemImages", element);
		}

		frmData.append("comment", this.comment);
		if (frmData) {

		}
		this.gatekeeperservice.gatekeeperuploadImage(this.invoiceid, frmData).then(
			(res) => {

				if (res) {
					if (res.code === 200) {
						this.displaymsg = "Invoice approved successfully..";
						this.Deletemodal = true;
						this.GetInvoiceData(this.invoiceid);
						this.commonService.dismissSpinner();
						this.postimg = [];
						this.urls = []
						this.comment = ''
						// this.commonService.presentSuccessToast('', res.message);

					} else {
						// this.commonService.presentErrorToast('', res.message);
					}
				}
			}
		);
	}
	RejectInvoice() {
		this.commonService.presentSpinner();
		this.gatekeeperservice.InvoiceReject(this.invoiceid).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.GetInvoiceData(this.invoiceid);
						this.displaymsg = res.message;
						this.Deletemodal = true;
						this.HideInvoicelist = true;
						this.InvoiceDetail = false;
						this.invoicereport = false;
						this.HideContinuebutton = false;
						this.GatekeeperApprove = false;
						this.commonService.dismissSpinner();
					} else {
						this.commonService.dismissSpinner();
					}
				}

			}
		)
	}
	OKButton() {
		this.clickcount = 0
		this.Deletemodal = false;
		this.HideInvoicelist = true;
		this.InvoiceDetail = false;
		this.invoicereport = false;
		this.HideContinuebutton = false;
		this.GatekeeperApprove = false;
		this.invoiceid = '';
		this.invoiceList = [];
		this.SearchInvoice = true;
		this.GatekeeperinvoiceList();
	}
	GetChildData(data) {
		if (data) {
			this.commonemodal = false;
		}
	}
}
