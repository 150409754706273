import { LabelType, Options } from '@angular-slider/ngx-slider';
import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { PurchaseOrderService } from 'src/app/Services/PurchaseOrder/purchase-order.service';
declare var $: any;
const resolvedPromise = Promise.resolve(null);

@Component({
	selector: 'app-purchase-order-detail',
	templateUrl: './purchase-order-detail.component.html',
	styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {
	minValue: number = 10;
	maxValue: number = 90;
	userlogindata: any = {};
	poId: any;
	a: any = '2'
	Deletemodal: boolean;
	commonmodal: boolean;
	b: any = '₹ 2000.00'
	submitted = false;
	RaiseInvoiceForm: FormGroup;
	PoDetailForm: FormGroup;
	SingleorderDetail: any = [];
	itemlist: any = [];
	page = 1;
	ListItems: any = [];
	counts = 0;
	Itemcount = 0;
	limit = 10;
	entriesItems: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	entries: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	options: Options = {

		floor: 0,
		ceil: 100,
		step: 1,
		minRange: 10,
		maxRange: 30,
		pushRange: true,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return "<b>Min:</b> ₹" + value;
				case LabelType.High:
					return "<b>Max:</b> ₹" + value;
				default:
					return "₹" + value;
			}
		}
	};
	plantlist = [];
	companyId: any;
	vendorlist: any;
	Freight = [];
	partyname: any;
	purchaseOrPurchaseCode_id: any;
	openAndCloseModal = false;
	successMessage: any;
	erroMesage: string;
	constructor(
		private route: ActivatedRoute,
		private storage: StorageService,
		private purchaseOrderService: PurchaseOrderService,
		private formGroupService: FormsGroupService,
		private commonService: CommonService,
		private router: Router,
		private events: EventsService,
		private companyservice: CompanyService,
		private datePipe: DatePipe
	) {

		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyId = this.userlogindata.companyId._id;
		this.PoDetailForm = this.formGroupService.getPoDetailForm();

		this.route.queryParams.subscribe(params => {
			this.poId = params['id'];
			this.RaiseInvoiceForm = this.formGroupService.RaiseInvoice();
		});
		this.events.subscribe(eventKeys.comapnyadded, () => {
			this.getItemList(this.page);
			this.GetSinglepurchaneOrder();
			this.GetRaiseInvoice();
		});


	}

	ngOnInit(): void {
		this.GetSinglepurchaneOrder();
		this.POInvoice(this.page);
		this.getItemList(this.page);
		this.Freight = [{ value: 'Extra', name: 'Extra' }, { value: 'Include', name: 'Include' }]
		this.GetRaiseInvoice();
	}
	private formatDate(date) {
		const d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		const year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	RaiseInvoiceModal() {
		let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
		formArray.clear();
		this.orderQtyCount = 0;
		this.ItemQuantity = 0;
		this.submitted = false;
		this.patchRaiseInvoiceFormValue();
		this.RaiseInvoiceForm.get('invoicedate').patchValue(this.formatDate(new Date()));
		$('.add_detail').modal('show');
		this.GetRaiseInvoice();
	}

	get f() {
		return this.RaiseInvoiceForm.controls;
	}

	patchRaiseInvoiceFormValue() {
		// get array control
		let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;

		this.itemlist.forEach(e => {
			formArray.push(new FormGroup({
				itemId: new FormControl(e._id),
				itemCode: new FormControl(e.itemCode),
				itemName: new FormControl(e.itemName),
				itemDesc: new FormControl(e.itemDesc),
				poRate: new FormControl(e.poRate),
				rate: new FormControl(e.rate),
				rateTotal: new FormControl(e.rateTotal),
				unit: new FormControl(e.unit),
				ordQnty: new FormControl(e.ordQnty),
				balQnty: new FormControl(e.balQnty),
				receivedQnty: new FormControl(e.receivedQnty),
				SGSTRate: new FormControl(e.SGSTRate),
				SGSTAmt: new FormControl(e.SGSTAmt),
				CGSTRate: new FormControl(e.CGSTRate),
				CGSTAmt: new FormControl(e.CGSTAmt),
				IGSTRate: new FormControl(e.IGSTRate),
				IGSTAmt: new FormControl(e.IGSTAmt),
				totalAmount: new FormControl(e.totalAmount),
				status: new FormControl(e.status),
				gateKeeperStatus: new FormControl(e.gateKeeperStatus),
				adminId: new FormControl(e.adminId),
				addedBy: new FormControl(e.addedBy),
				purchaseOrderId: new FormControl(e.purchaseOrderId),
				Rate: new FormControl(e.rate),
				itemQTY: new FormControl(e.balQnty),
				itmecheck: new FormControl()
			}))

		})
	}


	ItemRate: any;;
	Itemqty: any;
	GetRaiseInvoice() {
		this.commonService.presentSpinner();
		this.purchaseOrderService.GetRaiseInvoice(this.poId).then(
			(res) => {

				if (res) {
					if (res.code == 200) {
						this.itemlist = []
						this.itemlist = res.itemList;
						this.commonService.dismissSpinner();
						this.itemlist.forEach(e => {
							e['checked'] = false;
							e.totalAmount = parseFloat(e.totalAmount).toFixed(2);

						});

					}
					else if (res.code == 401) {
						this.commonService.dismissSpinner();
						this.router.navigate(['/']);
					}
				}

			}
		)
	}


	onSelectAll(e: any): void {

		for (let i = 0; i < this.itemlist.length; i++) {
			const item = this.itemlist[i];
			item.checked = e.target.checked;
			let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
			formArray.controls[i].patchValue({
				"itmecheck": item.checked
			})
			// this.itemlist.filter(item => item.checked);
			this.toggleVisibility(e)

		}
	}
	orderQtyCount: number = 0;
	ItemQuantity: number = 0;
	total_amount: any;
	result2: any;
	toggleVisibility(e) {
		this.itemlist.filter(item => item.checked);
		this.orderQtyCount = 0
		this.ItemQuantity = 0
		let quantity = 0;
		this.result2 = this.result;



		this.result.forEach(x => {
			this.orderQtyCount = this.orderQtyCount + parseInt((x.totalAmount))
			quantity = quantity + parseInt(x.balQnty)
			this.ItemQuantity = quantity ? quantity : 0;

		});
		if (e.target.check == true) {
			let rows = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
		}

	}


	RaiseInvoicecalc(e, itemcode, itemvalue, index) {

		this.orderQtyCount = 0;
		// let quantity = parseInt(e);
		let quantity = 0;
		let amount = 0;


		var i = 0;
		if (e.trim() == '') {
			e = 0
		}

		this.result2.forEach(x => {
			if (x.itemCode == itemcode) {
				// x.totalAmount = quantity ? quantity : 0;
				// this.orderQtyCount = this.orderQtyCount + parseInt(x.totalAmount)
				switch (itemvalue) {
					case 'Itemrate':
						x.rate = e
						amount = x.rate * x.balQnty;
						x.CGSTAmt = (amount * parseFloat(x.CGSTRate)) / 100;
						x.SGSTAmt = (amount * parseFloat(x.SGSTRate)) / 100;
						x.IGSTAmt = (amount * parseFloat(x.IGSTRate)) / 100;
						x.totalAmount = (amount + x.CGSTAmt + x.SGSTAmt + x.IGSTAmt)
						break;
					case 'Itemqnty':

						x.balQnty = e
						amount = x.rate * x.balQnty;
						x.CGSTAmt = (amount * parseFloat(x.CGSTRate)) / 100;
						x.SGSTAmt = (amount * parseFloat(x.SGSTRate)) / 100;
						x.IGSTAmt = (amount * parseFloat(x.IGSTRate)) / 100;
						x.totalAmount = (amount + x.CGSTAmt + x.SGSTAmt + x.IGSTAmt)
						break;
					case 'ItemCGSTrate':
						x.CGSTRate = e
						amount = x.rate * x.balQnty;
						x.CGSTAmt = (amount * parseFloat(x.CGSTRate)) / 100;
						x.SGSTAmt = (amount * parseFloat(x.SGSTRate)) / 100;
						x.IGSTAmt = (amount * parseFloat(x.IGSTRate)) / 100;
						x.totalAmount = (amount + x.CGSTAmt + x.SGSTAmt + x.IGSTAmt)
						break;
					case 'ItemSGSTrate':
						x.SGSTRate = e
						amount = x.rate * x.balQnty;
						x.CGSTAmt = (amount * parseFloat(x.CGSTRate)) / 100;
						x.SGSTAmt = (amount * parseFloat(x.SGSTRate)) / 100;
						x.IGSTAmt = (amount * parseFloat(x.IGSTRate)) / 100;
						x.totalAmount = (amount + x.CGSTAmt + x.SGSTAmt + x.IGSTAmt)
						break;

					case 'Itemigstrate':
						x.IGSTRate = e
						amount = x.rate * x.balQnty;
						x.CGSTAmt = (amount * parseFloat(x.CGSTRate)) / 100;
						x.SGSTAmt = (amount * parseFloat(x.SGSTRate)) / 100;
						x.IGSTAmt = (amount * parseFloat(x.IGSTRate)) / 100;
						x.totalAmount = (amount + x.CGSTAmt + x.SGSTAmt + x.IGSTAmt)
						break;
				}

				let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
				formArray.controls[index].patchValue({

					"rate": x.rate,
					"balQnty": x.balQnty,
					"CGSTRate": x.CGSTRate,
					"CGSTAmt": x.CGSTAmt,
					"SGSTRate": x.SGSTRate,
					"SGSTAmt": x.SGSTAmt,
					"IGSTRate": x.IGSTRate,
					"IGSTAmt": x.IGSTAmt,
					"totalAmount": x.totalAmount.toFixed(2),
				})
			} else {
				// this.orderQtyCount = this.orderQtyCount + parseInt(x.totalAmount);
			}

			this.orderQtyCount = this.orderQtyCount + parseFloat(x.totalAmount)
			quantity = quantity + parseInt(x.balQnty)
			this.ItemQuantity = quantity ? quantity : 0;
		});

	}


	displaymsg: any;
	errorhover: boolean
	SaveRaiseInvoice() {
		this.submitted = true;
		if (this.RaiseInvoiceForm.invalid) {
			return;
		};
		var selectedItem1 = [];
		// this.commonService.presentSpinner();
		const value = this.RaiseInvoiceForm.value;
		var selectedItem = this.RaiseInvoiceForm.value.itemDetails.filter(f => f.itmecheck);
		selectedItem1 = selectedItem

		for (var i = 0; i < selectedItem1.length; i++) {
			// delete selectedItem1[i]['itmecheck'];
			delete selectedItem1[i]['Rate'];
			delete selectedItem1[i]['itemQTY'];
		}
		selectedItem1.forEach(x => {

			this.result2.forEach(y => {
				if (x.itemCode == y.itemCode) {
					x.totalAmount = y.totalAmount
					x.ordQnty = y.ordQnty
				}
			});
		});
		// let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
		// formArray.controls[j].value.balQnty
		for (let i = 0; i < this.itemlist.length; i++) {
			for (let j = 0; j < selectedItem1.length; j++) {
				// (document.querySelector('.abc') as HTMLElement).style.border = '';
				if (this.itemlist[i]._id === selectedItem1[j].itemId) {
					if (parseInt(this.itemlist[i].balQnty) < parseInt(selectedItem1[j].balQnty)) {
						this.displaymsg = selectedItem1[j].itemName + " Quantity is not greater than blance quantity";
						this.commonmodal = true;
						this.errorhover = true
						selectedItem1 = []
						return
					} else {
					}

				}

			}

		}
		let data = {
			"purchaseOrderId": this.poId,
			"partyId": this.partyid,
			"plantName": this.SingleorderDetail.plant,
			"IRN": this.RaiseInvoiceForm.value.IRN,
			"invoiceNumber": this.RaiseInvoiceForm.value.invoicenumber,
			"eWayBill": this.RaiseInvoiceForm.value.ewaybill,
			"vehicle": this.RaiseInvoiceForm.value.vehiclenumber,
			"TCSOnSales": this.RaiseInvoiceForm.value.tcsonsale,
			"invoiceDate": this.RaiseInvoiceForm.value.invoicedate,
			"itemQty": this.RaiseInvoiceForm.value.amount,
			"totalAmount": this.RaiseInvoiceForm.value.totalammount,
			"itemDetails": selectedItem1
		}
		// form value binde
		this.purchaseOrderService.RaiseInvoice(data).then(
			(res) => {

				if (res) {
					if (res.code === 200) {

						selectedItem = [];
						this.itemlist = [];
						this.result2 = []
						this.commonService.dismissSpinner();
						this.RaiseInvoiceForm.reset();
						this.POInvoice(this.page);
						this.GetRaiseInvoice();
						$('.add_detail').modal('hide');
						this.submitted = false;
						this.displaymsg = res.message;
						this.commonmodal = true;
						// this.commonService.presentSuccessToast('', res.message);

					} else {
						this.commonService.dismissSpinner();
						this.displaymsg = res.message;
						this.commonmodal = true;
					}
				}
			}
		);
	}


	partyid: any;
	GetSinglepurchaneOrder() {
		this.commonService.presentSpinner();
		this.purchaseOrderService.getSinglepurchaneOrder(this.poId).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {

						this.SingleorderDetail = res.purchaseOrderList;
						this.SingleorderDetail.totalAmount = parseFloat(this.SingleorderDetail.totalAmount).toFixed(2);

						this.partyid = res.purchaseOrderList.partyId
						this.getVendorList();
						this.getUnitList();
					}

					else if (res.code == 401) {
						this.router.navigate(['/']);
					}
				}

			}
		)
	}
	keyword: string = '';
	DisabledRaiseInvoice: boolean = true;
	getItemList(page) {

		this.DisabledRaiseInvoice = true;
		this.page = page;
		this.purchaseOrderService.GetItems(this.poId, this.page, this.keyword.length >= 3 ? this.keyword : '').then(
			(res) => {
				if (res) {
					this.ListItems = res.itemList;
					this.Itemcount = res.totalResult;
					let sum = 0;
					this.ListItems.forEach(e => {
						e.itemAmount = parseFloat(e.itemAmount).toFixed(2);
						e.CGSTAmt = parseFloat(e.CGSTAmt).toFixed(2);
						e.SGSTAmt = parseFloat(e.SGSTAmt).toFixed(2);
						e.totalAmount = parseFloat(e.totalAmount).toFixed(2);
						sum = sum + parseFloat(e.balQnty)
						if (sum == 0) {
							this.DisabledRaiseInvoice = false;
						}
					});
					// this.GetRaiseInvoice();
					this.entriesItems = this.commonService.calculateEntries(this.ListItems, this.page, this.limit, this.Itemcount);
				}

			}
		)
	}

	PoInvoice: any = []
	POInvoice(page) {
		this.page = page;
		this.purchaseOrderService.GetPOInvoice(this.poId, this.page).then(
			(res) => {
				if (res) {

					this.PoInvoice = res.invoiceList;

					this.counts = res.totalResult;
					this.entries = this.commonService.calculateEntries(this.PoInvoice, this.page, this.limit, this.counts);

					let monthNames = ["January", "February", "March", "April", "May", "June",
						"July", "August", "September", "October", "November", "December"
					];
					this.PoInvoice.map((e) => {
						let invoiceDate = e.invoiceDate
						let d = new Date(invoiceDate);
						let dateformate = monthNames[d.getMonth()] + ' ' + d.getDate() + ' ' + d.getFullYear();
						let time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
						e.invoiceDate = dateformate;
					})
				}

			}
		)
	}
	// SaveRaiseInvoice() {
	//   this.submitted = true;
	//   if (this.RaiseInvoiceForm.invalid) {
	//     return;
	//   };
	// }
	itemdata: any = [];
	EditItemModal(item) {

		this.itemdata = item
		$('.addItem_detail').modal('show')

	}

	AddItemModal() {
		this.itemdata = ''
		$('.addItem_detail').modal('show')
	}

	searchitem(value) {
		this.keyword = value;
		this.getItemList(this.page);
	}



	get result() {
		return this.RaiseInvoiceForm.controls['itemDetails'].value.filter(item => item.itmecheck)
		// return this.itemlist.filter(item => item.checked);
	}





	id: any;
	deleteitem(item, e) {
		if (e == '') {
			this.id = item._id;
			this.Deletemodal = true
		}

		if (e == 'yes') {
			this.commonService.presentSpinner();

			this.purchaseOrderService.deleteitem(item).then(
				(res) => {

					if (res) {

						if (res.code === 200) {
							this.Deletemodal = false;
							this.getItemList(this.page);
							this.GetRaiseInvoice();
							this.commonService.dismissSpinner();
							this.displaymsg = res.message;
							this.commonmodal = true;
							// this.commonService.presentSuccessToast('', res.message);

						}
					} else {
						this.commonService.dismissSpinner();
						this.displaymsg = res.message;
						this.commonmodal = true;
					}
				}
			);
		} else if (e == 'no') {
			this.Deletemodal = false;
		}
	}

	changeStatusPOinvoice(id) {
		this.purchaseOrderService.pOinvoice_id = id;
		this.purchaseOrderService.chnageStatus().then(
			(res) => {
				if (res.code == 200) {
					this.displaymsg = res.message;
					this.commonmodal = true;
					// this.commonService.presentSuccessToast('', res.message);
				}
			}
		)
	};

	getUnitList() {
		this.commonService.presentSpinner();
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPlantDropDownList().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.plantlist = res.palntList;
					}
				}

			}
		)
	}

	getVendorList() {
		this.commonService.presentSpinner();
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPartyDropDownList().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.vendorlist = res.partyNameList;
					}
				}

			}
		)
	}


	openEditModa() {
		$('#purchase_order_edit').modal('show');
		this.SingleorderDetail
		this.patch_value();

	}
	patch_value() {
		this.PoDetailForm.patchValue({
			"PO": this.SingleorderDetail.purchaseOrderNo,
			"Freight": this.SingleorderDetail.freight,
			"Plant": this.SingleorderDetail.plant,
			"PartyCode": this.SingleorderDetail.partyCode,
			"PartyName": this.SingleorderDetail.partyName,
			"CreatedBy": this.SingleorderDetail.createdBy,
			"AuthorisedBy": this.SingleorderDetail.authorizedBy,
			"FormDate": this.datePipe.transform(this.SingleorderDetail.purchaseOrderDate, 'yyyy-MM-dd'),


		})
	}

	EditPoDetail() {
		this.commonService.presentSpinner();
		this.submitted = true;
		if (this.PoDetailForm.invalid) {
			return;
		};

		let postdata = {
			"purchaseOrderNo": this.PoDetailForm.value.PO,
			"freight": this.PoDetailForm.value.Freight,
			"purchaseOrderDate": this.PoDetailForm.value.FormDate,
			"plant": this.PoDetailForm.value.Plant,
			"partyCode": this.purchaseOrPurchaseCode_id ? this.purchaseOrPurchaseCode_id : this.SingleorderDetail.partyId,
			"partyName": this.purchaseOrPurchaseCode_id ? this.purchaseOrPurchaseCode_id : this.SingleorderDetail.partyId,
			"createdBy": this.PoDetailForm.value.CreatedBy,
			"authorizedBy": this.PoDetailForm.value.AuthorisedBy,
		}
		this.purchaseOrderService.EditPO(postdata, this.SingleorderDetail._id).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code === 200) {
						this.openAndCloseModal = true;
						this.successMessage = res.message;
						this.PoDetailForm.reset();
						$('.purchase_order_edit').modal('hide');
						this.submitted = false;
						this.displaymsg = res.message;
						this.commonmodal = true;
						// this.commonService.presentSuccessToast('', res.message);


					} else {
						this.openAndCloseModal = true;
						this.erroMesage = res.error.message;
					}
				}
			}
		);

	}

	selectValueId(e, value) {
		this.purchaseOrPurchaseCode_id = e

		this.purchaseOrderService.partynamebyid(this.purchaseOrPurchaseCode_id).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {

						if (value == "partCode") {
							this.partyname = res.partyNameList;
							this.SingleorderDetail.partyName = this.partyname.name;
							this.SingleorderDetail.partyCode = this.partyname.partyCode;
							this.patch_value();
						}
						if (value == "partyName") {
							this.partyname = res.partyNameList;
							this.SingleorderDetail.partyName = this.partyname.name;
							this.SingleorderDetail.partyCode = this.partyname.partyCode;
							this.patch_value();

						}

					}
				}

			}
		)
	}

	dismissModal() {
		this.openAndCloseModal = false;
		this.erroMesage = '';


	}
	GetChildData(data) {
		if (data) {
			this.commonmodal = false;

		}
	}

	cancelRaiseInvoice() {
		let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
		formArray.clear();
		this.RaiseInvoiceForm.reset();
		this.orderQtyCount = 0;
		this.ItemQuantity = 0;
		$('.add_detail').modal('hide');
		this.submitted = false;
	}
}
