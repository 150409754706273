import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
declare var $: any;

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.scss']
})
export class GSTComponent implements OnInit {

  minValue: number = 100;
  maxValue: number = 400;
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min:</b> ₹" + value;
        case LabelType.High:
          return "<b>Max:</b> ₹" + value;
        default:
          return "₹" + value;
      }
    }
  };
  public picker1 = {
    opens: 'left',
    startDate: moment().subtract(5, 'day'),
    endDate: moment(),
    isInvalidDate: function (date: any) {
      if (date.isSame('2017-09-26', 'day'))
        return 'mystyle';
      return false;
    }
  }
  public chosenDate: any = {
    // start: moment().subtract(12, 'month'),
    // end: moment().subtract(6, 'month'),
  };
  constructor(
    private daterangepickerOptions: DaterangepickerConfig,
  ) {
    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      "opens": "right",
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };
  }
  ShowdStartate: any = '';
  ShowdEndate: any = '';
  public selectedDate(value: any, dateInput: any): void {

    dateInput.start = value.start;
    dateInput.end = value.end;
    this.ShowdStartate = value.start.format('YYYY-MM-DD');
    this.ShowdEndate = value.end.format('YYYY-MM-DD');
  }
  ngOnInit(): void {
    $(function () {

      var start = Moment().subtract(29, 'days');
      var end = Moment();

      function cb(start, end) {
        $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      }

      $('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
          'Today': [Moment(), Moment()],
          'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
          'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
          'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
          'This Month': [Moment().startOf('month'), Moment().endOf('month')],
          'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')]
        }
      }, cb);

      cb(start, end);

    });
  }


}
