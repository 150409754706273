import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';

declare var $:any;
@Component({
  selector: 'sales-po-raise-invoice',
  templateUrl: './sales-po-raise-invoice.component.html',
  styleUrls: ['./sales-po-raise-invoice.component.scss']
})
export class SalesPoRaiseInvoiceComponent implements OnInit {
raiseInvoiceItems:Array<[]>;
RaiseInvoiceForm:FormGroup;
itemQuantity:number=0;
totalAmount:number=0;
submitted;
@Input() set SPOitemList(data: any) {
  this.raiseInvoiceItems = [];
  this.RaiseInvoiceForm.reset();
  if(data){
    // let formArray = this.RaiseInvoiceForm.get('itemDetails') as FormArray;
		// formArray.clear();
    this.raiseInvoiceItems = data;
    this.itemQuantity = 0;
    this.totalAmount = 0;
    
    this.raiseInvoiceItems.map((x:any)=>{
      if(x.orderDetails){
        x.orderDetails['blanceQty'] = x.orderDetails.quantityOrdered - x.orderDetails.quantityDelieved;
        this.totalAmount = this.totalAmount +  x.orderDetails.totalAmount;
      }
      this.itemQuantity = this.itemQuantity + x.scheduledQuantity;
    })
  }
}
  constructor() {
    this.RaiseInvoiceForm = new FormGroup({
      IRN : new FormControl('', [Validators.required]),
      invoicenumber : new FormControl('', [Validators.required]),
      ewaybill : new FormControl('', [Validators.required]),
      vehiclenumber : new FormControl('', [Validators.required]),
      tcsonsale : new FormControl('', [Validators.required]),
      invoicedate : new FormControl('', [Validators.required])   
     })
   }

   get raiseInvoiceFormControl() {
		return this.RaiseInvoiceForm.controls;
	}

  ngOnInit(): void {
  }

  RaiseInvoicecalc(event,itemDetail, orderDetails, itemvalue, index){
    if (event.trim() == '') {event = 0};

    let amount = 0;
    this.totalAmount = 0;
    let cloneOfraiseInvoiceItems : any;
    cloneOfraiseInvoiceItems = [ ...this.raiseInvoiceItems] 
    console.log("cloneOfraiseInvoiceItems", cloneOfraiseInvoiceItems)
    cloneOfraiseInvoiceItems.forEach((element:any) => {
      if(element.orderDetails && orderDetails){
        // switch (itemvalue) {
        //   case 'ItemRate':
            amount = element.orderDetails.rate * itemDetail.orderDetails.quantityOrdered;
            element.orderDetails.CGSTAmount = (amount * parseFloat(element.orderDetails.CGSTRate)) / 100;
            element.orderDetails.SGSTAmount = (amount * parseFloat(element.orderDetails.SGSTRate)) / 100;
            element.orderDetails.IGSTAmount = (amount * parseFloat(element.orderDetails.IGSTRate)) / 100;
            element.orderDetails.totalAmount = (amount + element.orderDetails.CGSTAmount + element.orderDetails.SGSTAmount + element.orderDetails.IGSTAmount);
            
        //     break;
        
        //   default:
        //     break;
        // }
      }
      this.totalAmount = this.totalAmount +  parseFloat(element.orderDetails.totalAmount)
    });
  

  }

  SaveRaiseInvoice(){
    this.submitted = false;
    if(this.RaiseInvoiceForm.invalid){
      this.submitted = true;
    }
  }


  cancelRaiseInvoice() {
		$('#add_detail').modal('hide');
	}
}
