import { Injectable } from '@angular/core';
import { endpoint } from '../../constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { storageKeys } from '../../constants/storage-keys.constants';
import { eventKeys } from '../../constants/event-keys.constants';
@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  invoiceid: any;
  purchaseInvoice_id: string;
  invoice_id: String;
  constructor(
    private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService
  ) { }

  async AddInvoice(invoice) {
    return this.httpWrapperService.post(endpoint.addInvoicePurchase, invoice).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      )
  }

  async GetInvoiceList(page, keyword, partyDetail, plantName, startDate, endDate, days, company_Id) {

    return this.httpWrapperService.get(endpoint.GetFilterinvoice(page, keyword, partyDetail, plantName, startDate, endDate, days, company_Id)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async DwnloadInvoiceList(page, keyword, startDate, endDate, company_Id) {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.httpWrapperService.get(endpoint.downloadinvoicedata(page, keyword, startDate, endDate, company_Id), httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  filterPriceInvoice(page, minValue, maxValue, companyid) {

    return this.httpWrapperService.get(endpoint.filterPriceInvoice(page, minValue, maxValue, companyid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

          // this.commonService.dismissSpinner();



        }
      );
  }

  async GetSingleInvoice(page, searchItem) {

    return this.httpWrapperService.get(endpoint.singleinvoicedetail(this.invoiceid, page, searchItem)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async DeleteInvoice(id) {

    return this.httpWrapperService.delete(endpoint.deleteinvoicepurchase(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);
        }
      )
  }

  async AddInvoiceSale(invoice) {
    return this.httpWrapperService.post(endpoint.addInvoiceSale, invoice).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async GetInvoiceSaleList(page) {

    return this.httpWrapperService.get(endpoint.getinvoicesale(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }


  invoiceChnageStatus() {

    return this.httpWrapperService.put(endpoint.invoiceChangeSatatue(this.invoice_id), '').map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);
        }
      );
  }

  Changecrediteligibilty(payload) {
    return this.httpWrapperService.put(endpoint.crediteligibilty(this.invoice_id), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.presentErrorToast('', error.error.message);
        }
      );
  }

  async InvoiceItemSearch(payload) {

    return this.httpWrapperService.get(endpoint.searchinvoiceitem + '?search=' + payload, { observe: 'response' }).map(
      (res) => {
        if (res) {
          if (res.body.code === 200) {
            if (res.body.AllOrder) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error

          }
          // this.commonService.handleError(error);
        }
      );
  }

  searchInvoice(invoice_id, searchName) {

    return this.httpWrapperService.get(endpoint.searchInvoice(invoice_id, searchName)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error

          }
          // this.commonService.handleError(error);
        }
      );
  }


  deleteInvoiceItem(invoice_id, invoiceItem) {
    return this.httpWrapperService.delete(endpoint.deleteInvoiceItem(invoice_id, invoiceItem)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

        }
      );
  };


}

