import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerConfig } from "ng2-daterangepicker";
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { ExcelService } from 'src/app/Services/excel.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { InvoiceService } from 'src/app/Services/Invoice/invoice.service';
import { Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { FormGroup } from '@angular/forms';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
declare var $: any;

@Component({
	selector: 'app-my-invoices',
	templateUrl: './my-invoices.component.html',
	styleUrls: ['./my-invoices.component.scss']
})
export class MyInvoicesComponent implements OnInit {
	page = 1;
	count = 0;
	tableSize = 7;
	commonmodal: boolean;
	invoiceList: any = [];
	searchText: any;
	limit = 10;
	Deletemodal: boolean;
	displaymsg: any;
	data: any = [{
		Invoice: '', Date: '', Amount: '', GST: '', FinanceStatus: '', GatekeeperStatus: ''
	}]
	minValue: number = 0;
	maxValue: number = 0;
	userlogindata: any = {};
	customFile: any;
	options: Options = {
		floor: 0,
		ceil: 1,
		step: 5,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return "<b>Min:</b> ₹" + value;
				case LabelType.High:
					return "<b>Max:</b> ₹" + value;
				default:
					return "₹" + value;
			}
		}
	};


	company_Id: any;
	configerSliderOptions(floorCeil: { floor: number, ceil: number }) {
		let newoptions: Options = Object.assign({}, this.options);
		newoptions.floor = floorCeil.floor;
		newoptions.ceil = floorCeil.ceil;
		this.options = newoptions;
	}
	configerSliderOption(floorCeil: { floor: number, ceil: number }) {
		let newoptions: Options = Object.assign({}, this.options);
		newoptions.floor = 0;
		newoptions.ceil = 0;
		this.options = newoptions;
	}
	public picker1 = {
		opens: 'left',
		startDate: moment().subtract(5, 'day'),
		endDate: moment(),
		isInvalidDate: function (date: any) {
			if (date.isSame('2017-09-26', 'day'))
				return 'mystyle';
			return false;
		}
	}
	public chosenDate: any = {
		// start: moment().subtract(12, 'month'),
		// end: moment().subtract(6, 'month'),
	};
	invoive_id: any;
	keyword = '';
	partyDetail = '';
	plantName = '';
	startDate = '';
	endDate = '';
	days: any;
	admin_id: any;
	unitlist: any;
	vendorlist: any;
	invoiceCode: any;
	excelSheetForm: FormGroup;
	erroMesage: any;
	submitted = false;
	entries: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	companyId: any;
	constructor(
		private daterangepickerOptions: DaterangepickerConfig,
		private storage: StorageService,
		private excelService: FileSaverService,
		private commonService: CommonService,
		private invoiceservice: InvoiceService,
		private companyservice: CompanyService,
		private formGroupService: FormsGroupService,
		private router: Router,
	) {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyId = this.userlogindata.companyId._id;
		this.daterangepickerOptions.settings = {
			locale: { format: 'YYYY-MM-DD' },
			alwaysShowCalendars: false,
			"opens": "right",
			ranges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		};
		this.excelSheetForm = this.formGroupService.getInvoiceExcel();
	}
	get a() {
		return this.excelSheetForm.controls;

	}
	uploadInvoiceMoadal() {
		this.submitted = false;
		this.erroMesage = '';
		this.excelSheetForm.reset();
		$('#add_detail').modal('show');
	}

	exportAsXLSX(): void {
		// this.excelService.exportAsExcelFile(this.data, 'InvoiceExport');
		if (this.userlogindata.role == "Admin") {
			var companyId = this.userlogindata.companyId._id;
		} else {
			companyId = ''
		}
		this.commonService.presentSpinner();
		this.invoiceservice.DwnloadInvoiceList(this.page, this.keyword, this.ShowdStardate, this.ShowdEndate, companyId).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.status == 200 && res.body) {
						this.commonService.dismissSpinner();
						this.excelService.save(res.body, 'Invoice.xlsx');
					} else {
						this.commonService.dismissSpinner();
					}
				} else {
					this.commonService.dismissSpinner();
				}
			}
		)
	}
	ShowdStardate = '';
	ShowdEndate = '';
	public selectedDate(value: any, dateInput: any): void {

		dateInput.start = value.start;
		dateInput.end = value.end;
		this.ShowdStardate = value.start.format('YYYY-MM-DD');
		this.ShowdEndate = value.end.format('YYYY-MM-DD');
		this.GetInvoiceList(this.page);

	}

	ngOnInit(): void {
		this.GetInvoiceList(this.page);
		this.getVendorList();
		this.getUnitList();
	}
	fileToUpload: File = null;
	execlSheet: File = null;
	imageUrl: any;
	handleFileInput(event) {
		this.erroMesage = '';
		var file: File = event.target.files[0];
		let currentFile = file;
		let imagetype = currentFile.type.split('/');
		let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if ($.inArray(imagetype[1], ValidImageExt) < 0) {
			// this.imageErrMsg = "Only jpg, jpeg ,webp,gif & x-icon image formats are allowed";
			this.commonService.presentErrorToast('', "Only .xlsx format is allowed");
			this.excelSheetForm.controls['excelSheet'].setValue('');
			return false;
		}
		if (event.target.files && event.target.files[0]) {
			this.execlSheet = event.target.files[0]
		}

	}

	// sliderEvent() {
	// 	this.maxValue;
	// 	// this.minValue;
	// 	// this.configerSliderOptions({ floor: 0, ceil: Number(this.maxValue) });
	// 	// this.GetInvoiceList(this.page);
	// }

	filterAmount(page) {
		this.page = page;
		this.commonService.presentSpinner();
		if (this.userlogindata.role == "Admin") {
			var companyId = this.userlogindata.companyId._id;
		} else {
			companyId = ''
		}
		this.invoiceservice.filterPriceInvoice(this.page = page, this.minValue, this.maxValue, companyId).then(
			(res) => {
				this.commonService.dismissSpinner();
				this.invoiceList = res.invoiceList;
			}
		)
	}

	onUserChange(): void {
		// this.logText += `onUserChange(${this.getChangeContextString(changeContext)})\n`;
		this.filterAmount(this.page);
	}


	onTableDataChange(event) {
		this.page = event;
		this.GetInvoiceList(this.page);
	}

	pageData: any;
	GetInvoiceList(page) {
		this.page = page;
		// if (this.userlogindata.role == "Admin") {
		// 	this.admin_id = this.userlogindata._id;
		// 	this.company_Id = this.userlogindata.companyId._id;

		// } else {
		// 	this.invoive_id = '';
		// }
		this.commonService.presentSpinner();
		this.invoiceservice.GetInvoiceList(page, this.keyword, this.partyDetail, this.plantName, this.ShowdStardate, this.ShowdEndate, this.days, this.companyId).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.invoiceList != 0) {
						this.commonService.dismissSpinner();
						this.invoiceList = res.invoiceList;
						this.count = res.totalResult;
						this.limit = res.limit;
						let amount = [];;
						this.invoiceList.forEach(e => {
							e.totalAmount = parseFloat(e.totalAmount).toFixed(2);
							e.GST = parseFloat(e.GST).toFixed(2);
						});
						for (var data of this.invoiceList) {
							amount.push(data.totalAmount);
						}
						this.maxValue = Math.max(...amount);
						this.minValue = Math.min(...amount);
						this.configerSliderOptions({ floor: Number(1000), ceil: Number(this.maxValue) });

						let monthNames = ["January", "February", "March", "April", "May", "June",
							"July", "August", "September", "October", "November", "December"
						];
						this.invoiceList.map((e) => {
							if (!e.matchStatus) {
								e.matchStatus = ''
							}
							let couponExpiryDate = e.invoiceDate
							let d = new Date(couponExpiryDate);
							let dateformate = monthNames[d.getMonth()] + ' ' + d.getDate() + ' ' + d.getFullYear();
							let time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
							// e.createdDate = dateformate + ' | ' + time
							e.invoiceDate = dateformate;
						})

						this.entries = this.commonService.calculateEntries(this.invoiceList, page, this.limit, this.count);

					} else {
						this.commonService.dismissSpinner();
						this.invoiceList = [];
					}
				} else {
					this.commonService.dismissSpinner();


				}

			}
		)
	}
	uploadInvoiceExcel() {
		this.commonService.presentSpinner();
		this.submitted = false;
		if (this.excelSheetForm.invalid) {
			this.submitted = true;
			return;
		};
		var frmData = new FormData();

		frmData.append("excelSheet", this.execlSheet);

		this.invoiceservice.AddInvoice(frmData).then(
			(res) => {
				if (res.code == 200) {
					this.commonService.dismissSpinner();
					this.execlSheet = null;
					this.GetInvoiceList(this.page);
					$('#add_detail').modal('hide');
				} else if (res.error.code == 422) {
					this.commonService.dismissSpinner();
					this.displaymsg = res.error.message;
					this.commonmodal = true;
					this.excelSheetForm.reset();
					this.submitted = false;
					this.execlSheet = null;
				} else {
					this.commonService.dismissSpinner();
					this.excelSheetForm.reset();
					this.submitted = false;
					this.erroMesage = res.error.errorArray;
					this.execlSheet = null;
				}
			}
		)
	}
	invoidata: any = [];
	Invoicedetail(item, e) {
		const component = this;
		if (e != '') {
			this.invoiceservice.invoiceid = item._id;
			this.invoiceservice.GetSingleInvoice(this.page, '').then(
				(res) => {
					this.commonService.dismissSpinner();
					if (res) {
						if (res.code == 200) {
							this.invoidata = [];
							this.invoidata = res.userData;


							let monthNames = ["January", "February", "March", "April", "May", "June",
								"July", "August", "September", "October", "November", "December"
							];

							let invoicedate = this.invoidata.invoiceDate
							let d = new Date(invoicedate);
							let dateformate = monthNames[d.getMonth()] + ' ' + d.getDate() + ' ' + d.getFullYear();
							let time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
							// e.createdDate = dateformate + ' | ' + time
							this.invoidata.invoiceDate = dateformate;
							setTimeout(function () {
								component.DownloadsingleOrders()
							}, 1000);
						} else if (res.code == 401) {
							this.router.navigate(['/']);
						}
					} else {

					}

				}
			)

		} else {
			this.router.navigate(['/admin/invoices/invoice-details'], { queryParams: { invoiceid: item._id } },);
		}


	}

	Id: any;
	openDeleteModal(data) {
		this.invoiceCode = data.invoiceNumber
		this.invoive_id = data._id;
		$('#delete-invoice').modal('show');

	}
	deleteInvoice() {
		this.minValue == 0 ? '' : this.minValue, this.maxValue != 0 ? '' : this.maxValue
		this.commonService.presentSpinner();
		this.invoiceservice.DeleteInvoice(this.invoive_id).then(
			(res) => {
				if (res) {
					if (res.code === 200) {
						this.commonService.dismissSpinner();
						$('#delete-invoice').modal('hide');
						this.displaymsg = res.message;
						this.Deletemodal = true;
						this.GetInvoiceList(this.page);
						// this.commonService.presentSuccessToast('', res.message);
					} else {
						this.commonService.dismissSpinner();
						this.displaymsg = res.message;
						this.Deletemodal = true;
					}
				}
			}
		);

	}


	getUnitList() {
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPlantDropDownList().then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						this.unitlist = res.palntList;
						this.GetInvoiceList(this.page)

					}
				}

			}
		)
	}


	getVendorList() {
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPartyDropDownList().then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						this.vendorlist = res.partyNameList;
						this.GetInvoiceList(this.page);


					}
				}

			}
		)
	}

	resetAll() {
		this.minValue = 0;
		this.maxValue = 0;
		this.partyDetail = '';
		this.ShowdStardate = '';
		this.ShowdEndate = '';
		this.plantName = '';
		this.chosenDate = {};
		// this.configerSliderOptions({ floor: 10, ceil: Number(0) });
		this.GetInvoiceList(this.page);


	}

	selectPlantValue(e) {
		// this.partyDetail = '';
		this.plantName = e;
		this.GetInvoiceList(this.page);
	}

	selectpartyDetailValue(e) {
		// this.plantName = '';
		this.partyDetail = e;
		this.GetInvoiceList(this.page);
	}

	searchInvoice(value) {
		this.keyword = value;
		this.GetInvoiceList(this.page)
	}

	changeStatusInvoice(id) {
		this.commonService.presentSpinner();
		this.invoiceservice.invoice_id = id;
		this.invoiceservice.invoiceChnageStatus().then(
			(res) => {
				if (res.code == 200) {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.Deletemodal = true;
					// this.commonService.presentSuccessToast('', res.message);
				} else {
					this.commonService.presentSpinner();
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		)
	}

	GstchangeStatus(id, e) {
		let postdata = {}
		if (e.target.checked) {
			postdata = {
				'GSTCreditEligible': 'Yes',
			}
		} else {
			postdata = {
				'GSTCreditEligible': 'No',
			}
		}
		this.invoiceservice.invoice_id = id;
		this.invoiceservice.Changecrediteligibilty(postdata).then(
			(res) => {
				if (res.code == 200) {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.GetInvoiceList(this.page);
					this.commonService.presentSuccessToast('', res.message);
				} else {
					this.commonService.presentSpinner();
				}
			}
		)
	}
	DownloadsingleOrders(): void {

		let printContents, popupWin;
		printContents = document.getElementById('userorderdetail').innerHTML;
		popupWin = window.open('', '_blank', 'top=70px,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
<html>
<head>
<title></title>
<style>
@media print {
  @page { margin: 0; }
  body { margin: 1.6cm; }
}
</style>
</head>
<body onload="window.print();window.close()">${printContents}</body>
</html>`
		);
		popupWin.document.close();
	}

	GetSingleINvoice(item) {

	}

	GetChildData(data) {
		if (data) {
			this.commonmodal = false;
			this.Deletemodal = false;
		}
	}
}


