<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Process Stages</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							 (click)="openProcessStageFormModal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." [(ngModel)]="search"
												(input)="getProcessStage(1)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width: 120px;">Stage Code</th>
										<th>Stage Name</th>
										<th style="width:65px;">Action</th>
									</tr>
								</thead>

								<tbody>

									<tr *ngFor="let list of processStageList | paginate: {id: 'processStageList',itemsPerPage: limit,currentPage: page,totalItems: totalResult}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Stage Code">{{list?.stageCode || ''}}</td>
										<td data-title="Stage Name">{{list?.stageName || ''}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
											(click)="openProcessStageFormModal(list)"><i
													class="mdi mdi-pencil font-size-18"></i></button>

											<a href="javascript:void(0);" class="text-danger" (click)="openDeleteModal(list?._id)"><i
													class="mdi mdi-trash-can font-size-18" ></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !processStageList?.length">
										<td colspan="10">
											<h5 class="text-center">No Process stage found!</h5>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" *ngIf="apiHitDone && processStageList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
											id="processStageList" (pageChange)="getProcessStage($event)"></pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->



<!-- Add Process Stage Popup -->
<div class="modal fade" id="add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">{{processStageToEdit ? 'Edit' : 'Add'}} Process Stage</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form  [formGroup]="processStageForm">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Stage Code <span style="color:red;">*</span></label>
								<input type="text" class="form-control"  formControlName="stageCode">
								<div *ngIf="processStageFormSubmitted && processStageForm?.controls['stageCode']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="processStageForm?.controls['stageCode']?.errors?.required">Stage Code is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Stage Name <span style="color:red;">*</span></label>
								<input type="text" class="form-control"  formControlName="stageName">
								<div *ngIf="processStageFormSubmitted && processStageForm?.controls['stageName']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="processStageForm?.controls['stageName']?.errors?.required">Stage Name is required</div>
								</div>
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit" (click)="saveData()">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Process Stage Popup -->

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete this item</h2>
							<h6>{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"  (click)="deleteProcessStage()"><span
									class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>