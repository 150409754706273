<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add Company

                </h5>
                <button type="button" class="close" (click)="closecompanymodal()"> <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddCompanyForm" (ngSubmit)="AddCompany()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Logo</label>
                                <div class="custom_file custom_img_input">
                                    <input type="file" class="custom_file_input" formControlName="companyimage"
                                        id="customFile" (change)="handleFileInput($event.target.files)">
                                    <div class="file_img" *ngIf="!imageUrl"
                                        style="background-color: rgb(245, 245, 245);background-image: url(assets/images/companyimg.png);">
                                    </div>
                                    <div class="file_img" *ngIf="imageUrl"
                                        style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
                                    </div>

                                </div>
                                <div *ngIf="imageErrMsg" class="invalid-feedback" style="display: block;">
                                    {{imageErrMsg}}</div>
                                <!-- <div *ngIf="submitted && f.companyimage.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.companyimage.errors.required">ComapnyImage is required</div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Name</label>
                                <input type="text" class="form-control" formControlName="name">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Registration Number</label>
                                <input type="tel" class="form-control" formControlName="registrationnumber">
                                <div *ngIf="submitted && f.registrationnumber.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.registrationnumber.errors.required">Registration Number is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">PAN Number</label>
                                <input type="tel" class="form-control" formControlName="pannumber">
                                <div *ngIf="submitted && f.pannumber.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.pannumber.errors.required">PAN Number is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Registered Office Address</label>
                                <textarea rows="3" class="form-control" autocomplete="off"
                                    formControlName="registredaddress"></textarea>
                                <div *ngIf="submitted && f.registredaddress.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.registredaddress.errors.required">Registered Office Address is
                                        required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>