import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/constants/regex.constants';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  UserForm: FormGroup;
  submitted = false;
  userlogindata: any = {};
  displaymsg: any;
  commonmodal: boolean;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private companyservice: CompanyService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
  ) {
    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"]
  }


  ngOnInit(): void {
    this.UserForm = this.formBuilder.group({
      adminimage: ['', ''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'cpassword')
      }
    )
  }
  get f() { return this.UserForm.controls; }
  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Formats allowed : jpg, jpeg, png";
      this.UserForm.controls['adminimage'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  AddUser() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.UserForm.invalid) {
      return;
    }

    this.commonService.presentSpinner();
    var frmData = new FormData();

    frmData.append("name", this.UserForm.value.name);
    frmData.append("email", this.UserForm.value.email.toLowerCase());
    frmData.append("role", this.UserForm.value.role);
    frmData.append("password", this.UserForm.value.password);
    frmData.append("confirmPassword", this.UserForm.value.cpassword);
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("companyId", this.userlogindata.companyId._id);

    this.companyservice.Addadministrators(frmData).then(
      (res) => {

        if (res) {
          if (res.code === 200) {
            this.UserForm.reset();
            this.fileToUpload = null;
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.imageUrl = ''
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.commonmodal = true;
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.commonmodal = true;
          }
        }
      }
    );
  }

  closeUserModal() {
    this.fileToUpload = null;
    this.imageErrMsg = '';
    $('.add_detail').modal('hide');
    this.submitted = false;
  }
  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }
}
