<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Purchase Items</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="opemModals('addModal','')">Add</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:120px;">Purchase Items Code</th>
										<th style="width:100px;">Purchase Items Name</th>
										<th style="width:200px;">Description</th>
										<th style="width:100px;">Unit</th>
										<th style="width: 60px;">HSN/SAC </th>
										<th style="width:65px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="itemsList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let data of itemsList | paginate: {id: 'itemList', itemsPerPage: limit, currentPage: page,totalItems: count }; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Purchase Items Code">{{data?.itemCode}}</td>
										<td data-title="Purchase Items Name">{{data?.itemName}}</td>
										<td data-title="Description">{{data?.itemDesc}}</td>
										<td data-title="Unit">{{data?.unit}}</td>
										<td data-title="HSN/SAC">{{data.HSNSANum?.hsnNumber}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"><i
													class="mdi mdi-pencil font-size-18"
													(click)="opemModals('edit',data)"></i>
											</button> <a href="javascript:void(0);" class="text-danger"
												(click)="opemModals('delete',data)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" *ngIf="itemsList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="itemList" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next" (pageChange)="getItems($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  Item Popup -->
<div class="modal fade add_detail" tabindex="-1" id="add_detail" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Add Purchase Item</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="addAdminItemForm" (ngSubmit)="addItem()">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Purchase Item Code <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="itemCode"
									[ngClass]="{ 'is-invalid': submitted && a.itemCode.errors }">
								<div class="error-msg" *ngIf="submitted && a.itemCode.errors"> <span style="color: red;"
										*ngIf="a.itemCode.errors.required">
										Purchase Items Code is required.</span>
								</div>
							</div>

						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Purchase Item Name <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="itemName"
									[ngClass]="{ 'is-invalid': submitted && a.itemName.errors }">
								<div class="error-msg" *ngIf="submitted && a.itemName.errors"> <span style="color: red;"
										*ngIf="a.itemName.errors.required">
										Purchase Items name is required.</span>
								</div>
							</div>

						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Description <span style="color:red;">*</span></label>
								<textarea class="form-control" rows="3" formControlName="description"
									[ngClass]="{ 'is-invalid': submitted && a.description.errors }"></textarea>
								<div class="error-msg" *ngIf="submitted && a.description.errors"> <span
										style="color: red;" *ngIf="a.description.errors.required">
										Description is required.</span>
								</div>
							</div>

						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Unit <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="unit"
									[ngClass]="{ 'is-invalid': submitted && a.unit.errors }">
								<div class="error-msg" *ngIf="submitted && a.unit.errors"> <span style="color: red;"
										*ngIf="a.unit.errors.required">
										Unit name is required.</span>
								</div>
							</div>

						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">HSN/SAC</label>
								<!-- <ng-select formControlName="HSNnumber" placeholder="Select HSN Number">
									<ng-option [value]="'Order received'">Order received</ng-option>
									<ng-option [value]="'In the work'">In the work</ng-option>
									<ng-option [value]="'Order shipped'">Order shipped</ng-option>
									<ng-option [value]="'Delivered'">Delivered</ng-option>
								</ng-select> -->
								<div class="multiple-select">
									<ng-select [items]="HSNCode" bindValue="_id" [searchable]="true"
										bindLabel="hsnNumber" formControlName="HSNnumber"
										placeholder="Select HSN Number">
									</ng-select>
								</div>
								<!-- <div class="error-msg" *ngIf="submitted && a.itemName.errors"> <span style="color: red;"
										*ngIf="a.itemName.errors.required">
										Purchase Items name is required.</span>
								</div> -->
							</div>

						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add  Item Popup -->
<!-- Edit  Item Popup -->
<div class="modal fade edit_detail" tabindex="-1" id="edit_detail" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Purchase Item</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="editAdminItemForm" (ngSubmit)="editItem()">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Purchase Item Code <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="itemCode">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Purchase Item Name <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="itemName">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Description</label>
								<textarea class="form-control" rows="3" formControlName="description"></textarea>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Unit</label>
								<input type="text" class="form-control" formControlName="unit">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">HSN/SAC</label>
								<!-- <input type="text" class="form-control" formControlName="hsnSAC"> -->
								<div class="multiple-select">
									<ng-select [items]="HSNCode" bindValue="_id" [searchable]="true"
										bindLabel="hsnNumber" formControlName="HSNnumber"
										placeholder="Select HSN Number">
									</ng-select>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit  Item Popup -->

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Purchase Item</h2>
							<h6>{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close" (click)="itemsDelete()"><span
									class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>
