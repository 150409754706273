import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  page = 1;
  count = 0;
  tableSize = 7;
  vendorlist: any = [];
  searchText: any;
  limit: any;
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  VendorForm: FormGroup;
  submitted = false;
  Deletemodal: boolean;
  Deletemodals: boolean;
  displaymsg: any;
  userlogindata: any = {};
  constructor(
    private commonService: CommonService,
    private router: Router,
    private companyservice: CompanyService,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private storage: StorageService,

  ) {
    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"]
  }

  ngOnInit(): void {
    this.GetVendorList();
    this.VendorForm = this.formBuilder.group({
      id: ['', ''],
      vendorimage: ['', ''],
      name: ['', Validators.required],
      partycode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      gst: ['', Validators.required],
      contactname: ['', Validators.required],
      contactnumber: ['', Validators.required],
      address: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'cpassword')
      }
    )
  }
  get f() { return this.VendorForm.controls; }
  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Only formats are allowed : jpg, jpeg, png";
      this.VendorForm.controls['vendorimage'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  id: any;
  vendorimg: any;
  OpenVendorModal(item, e) {

    if (e == 'add') {
      this.imageErrMsg = '';
      $('.add_detail').modal('show');
    } else {
      this.id = item._id
      this.VendorForm.reset();
      this.submitted = false;

      this.vendorimg = item.profileImage
      this.VendorForm.patchValue({
        id: item._id,
        name: item.name,
        partycode: item.partyCode,
        email: item.email,
        gst: item.gstNumber,
        contactname: item.contactName,
        contactnumber: item.contactNumber,
        address: item.vendorAddress,
      });
      $('.add_detail').modal('show');
    }

  }

  SaveVendor() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.VendorForm.invalid) {
      return;
    }

    this.commonService.presentSpinner();
    var frmData = new FormData();

    frmData.append("name", this.VendorForm.value.name);
    frmData.append("partyCode", this.VendorForm.value.partycode);
    frmData.append("email", this.VendorForm.value.email.toLowerCase());
    frmData.append("gstNumber", this.VendorForm.value.gst);
    frmData.append("password", this.VendorForm.value.password);
    frmData.append("confirmPassword", this.VendorForm.value.cpassword);
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("contactName", this.VendorForm.value.contactname);
    frmData.append("contactNumber", this.VendorForm.value.contactnumber);
    frmData.append("vendorAddress", this.VendorForm.value.address);
    frmData.append("companyId", this.userlogindata.companyId._id);

    this.companyservice.AddVendor(frmData).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.GetVendorList();
            this.VendorForm.reset();
            this.fileToUpload = null;
            this.imageUrl = ''
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.displaymsg = res.message;
            this.Deletemodals = true;
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            this.displaymsg = res.message;
            this.Deletemodals = true;
            // this.commonService.presentErrorToast('', res.message);
          }
        }
      }
    );
  }
  onTableDataChange(event) {

    this.page = event;
    this.GetVendorList();
  }
  pageData: any;
  GetVendorList() {
    this.commonService.presentSpinner();
    this.companyservice.getVendorList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.vendorlist = [];
            this.vendorlist = res.adminList;
            this.count = res.totalResult
            this.limit = res.limit;
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }




          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }
  adminid: any;
  resetpassword(item) {
    this.adminid = item._id;
    $('.forgot_password').modal('show');
  }

  updatevendor() {

    this.commonService.presentSpinner();
    var frmData = new FormData();
    frmData.append("name", this.VendorForm.value.name);
    frmData.append("partyCode", this.VendorForm.value.partycode);
    frmData.append("email", this.VendorForm.value.email.toLowerCase());
    frmData.append("gstNumber", this.VendorForm.value.gst);

    frmData.append("profileImage", this.fileToUpload);
    frmData.append("contactName", this.VendorForm.value.contactname);
    frmData.append("contactNumber", this.VendorForm.value.contactnumber);
    frmData.append("vendorAddress", this.VendorForm.value.address);

    this.companyservice.EditVendor(frmData, this.VendorForm.value.id).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            this.GetVendorList();
            this.VendorForm.reset();
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.displaymsg = res.message;
            this.Deletemodals = true;
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.Deletemodals = true;
          }
        }
      }
    );
  }

  Id: any;
  deletevendor(item, e) {

    if (e == '') {
      this.Id = item._id;
      this.Deletemodal = true
    }

    if (e == 'yes') {
      this.commonService.presentSpinner();

      this.auth.CommonDelete(item).then(
        (res) => {
          if (res) {
            if (res.code === 200) {
              this.commonService.dismissSpinner();
              this.Deletemodal = false;
              this.displaymsg = res.message;
              this.Deletemodals = true;
              this.GetVendorList();

              // this.commonService.presentSuccessToast('', res.message);

            }
          } else {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.Deletemodals = true;
          }
        }
      );
    } else if (e == 'no') {
      this.Deletemodal = false;
    }
  }

  errmsg: string = ''
  applyFilter(filterValue) {

    if (filterValue.length >= 3) {

      this.companyservice.VendorSearchFilter(this.page, filterValue).then(
        (res) => {
          if (res) {
            if (res.code == 200 && res.adminList.length > 0) {
              this.vendorlist = [];
              this.vendorlist = res.adminList;
              this.count = res.totalResult

              // this.limit = res.limit;
              if (this.page == 1) {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
                //   (this.page - 1) * 10 + 1 - this.page * 10
              }
            } else {
              this.vendorlist = [];
              this.errmsg = 'No record found...'

            }
          } else {

          }

        }
      )
    } else {
      this.GetVendorList();
    }

  }

  GetChildData(data) {
    if (data) {
      this.Deletemodals = false;
    }
  }
}
