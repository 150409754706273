import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { promise } from 'protractor';
import { Product } from 'src/app/Services/DataManagement/products/product.model';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { ItemService } from 'src/app/Services/item/item.service';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss']
})
export class AddProductsComponent implements OnInit {
  // @Input('productDetail') productDetail : any;
  @Output() sendAction: EventEmitter<any> = new EventEmitter();
  HSNCode: any = [];
  productForm:FormGroup;
  submitted = false;
  processStage:Array<any>;
  itemGroup:Array<any>;
  constructor(
    private itemService: ItemService,
    private commonService: CommonService,
    private router: Router,
    private processListService : ProductsService
    ) { 
      this.productForm = new FormGroup({
        stageCode: new FormControl('', Validators.compose([Validators.required])),
        stageName: new FormControl('', Validators.compose([Validators.required])),
      })

      this.productForm = new FormGroup({
        name : new FormControl('', Validators.compose([Validators.required])),
        code : new FormControl('', Validators.compose([Validators.required])),
        description : new FormControl('', Validators.compose([Validators.required])),
        group : new FormControl('', Validators.compose([Validators.required])),
        processStage : new FormControl('', Validators.compose([Validators.required])),
        quantity : new FormControl('', Validators.compose([Validators.required])),
        unit : new FormControl('', Validators.compose([Validators.required])),
        hsn : new FormControl('', Validators.compose([Validators.required])),
      })
    this.getHSNCode();
    this.getprocessList();
    this.getItemGroup();
  }

  ngOnInit(): void {
    
  }
 
  detail:any=null;
  @Input() set productDetail (data: any) {
    this.detail = data;
    this.productForm.reset();
    this.submitted = false;
    if(this.detail){
      this.productForm.patchValue({
        name : this.detail.name,
        code : this.detail.code,
        description : this.detail.description,
        group : this.detail.group._id,
        processStage : this.detail.processStage._id,
        quantity : this.detail.quantity,
        unit : this.detail.unit,
        hsn : this.detail.hsn,
      })
    }else{

    }
  }

  get productFormSubmitted() { 
    return this.productForm.controls; 
  }

  getHSNCode() {
    this.itemService.getHSNCodeList().then(
      (res) => {
        if (res.code == 200) {
          this.HSNCode = res.hsnList;
        }
      }
    )
  }

  getprocessList(){
    this.processListService.getprocessList().then(
      (res:any)=>{
        if(res.code == 200 && res.processStagelist.length){
          this.processStage = res.processStagelist;
        }else{
          this.processStage = []
        }
      }
    )
  }
  getItemGroup(){
    this.processListService.getitemGroup().then(
      (res:any)=>{
        if(res.code == 200 && res.groups.length){
          this.itemGroup = res.groups;
        }
      }
    )
  }

  addProductForm(){
    if(this.productForm.invalid){
      this.submitted = true;
      return
    }
    let promise : Promise<any> = null;
    this.submitted = false;
    this.commonService.presentSpinner();
    let product = new Product(this.productForm.value);
    if(this.detail){
      promise = this.processListService.editproduct(product,this.detail._id);
    }else{
      promise = this.processListService.addItemGroup(product);
    }
    this.commonService.presentSpinner();
    promise.then(
      (res:any)=>{
        if(res.code == 200){
          this.commonService.presentSuccessToast(res.message,'')
        }
      }
    ).catch((error)=>{

    }).finally(()=>{
      this.productForm.reset();
      this.submitted = false;
      this.detail = null;
      this.commonService.dismissSpinner();
      this.sendAction.emit('product Update');
    })
  }

  close(){
    this.productForm.reset();
    this.submitted = false;
    this.detail = null;
  }
}
