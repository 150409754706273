<div class="modal fade addItem_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content table_page">
            <div class="modal-header">
                <h5 class="modal-title mt-0" *ngIf="itemdata == ''">Add Item</h5>
                <h5 class="modal-title mt-0" *ngIf="itemdata != ''">Edit Item</h5>
                <!-- itemdata == {{itemdata | json}} -->
                <!-- <button type="button" class="close" aria-label="Close" data-dismiss="modal"> <span
                        aria-hidden="true">&times;</span>
                </button> -->
                <button type="button" class="close" (click)="CloseIemModal(itemdata)"> <span
                        aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body card-body">
                <form [formGroup]="AddItemForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Item Code</label>
                                <!-- <input type="text" class="form-control"
                                    [ngModel]="itemdata.itemCode?itemdata.itemCode:''" formControlName="ItemCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.ItemCode.errors }"> -->
                                <select class="form-control" [ngModel]="itemdata.itemId?itemdata.itemId:''"
                                    formControlName="ItemCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.ItemCode.errors }"
                                    (change)="selectitemCode($event,'itemcode')">
                                    <option selected value="">Select</option>
                                    <option [selected]="itemdata.itemCode == code.itemCode?true:false"
                                        value="{{code._id}}" *ngFor="let code of itemlist">
                                        {{code.itemCode}}</option>
                                </select>
                                <div class="error-msg" *ngIf="submitted && f.ItemCode.errors"> <span style="color: red;"
                                        *ngIf="f.ItemCode.errors.required">
                                        ItemCode is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Item Name</label>
                                <!-- <input type="text" class="form-control"
                                    [ngModel]="itemdata.itemName?itemdata.itemName:''" formControlName="ItemName"
                                    [ngClass]="{ 'is-invalid': submitted && f.ItemName.errors }"> -->
                                <select class="form-control" [ngModel]="itemdata.itemId?itemdata.itemId:''"
                                    formControlName="ItemName"
                                    [ngClass]="{ 'is-invalid': submitted && f.ItemName.errors }"
                                    (change)="selectitemCode($event,'itemname')">
                                    <option selected value="">Select</option>
                                    <option value="{{code._id}}" *ngFor="let code of itemlist"
                                        [selected]="itemdata.itemName == code.itemName?true:false">
                                        {{code.itemName}}</option>
                                </select>
                                <div class="error-msg" *ngIf="submitted && f.ItemName.errors"> <span style="color: red;"
                                        *ngIf="f.ItemName.errors.required">
                                        ItemName is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Item Description </label>
                                <textarea rows="2" class="form-control"
                                    [ngModel]="itemdata.itemDesc?itemdata.itemDesc:''" formControlName="Description"
                                    [ngClass]="{ 'is-invalid': submitted && f.Description.errors }"></textarea>
                                <div class="error-msg" *ngIf="submitted && f.Description.errors"> <span
                                        style="color: red;" *ngIf="f.Description.errors.required">
                                        Description is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Unit</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.unit?itemdata.unit:''"
                                    formControlName="Unit" [ngClass]="{ 'is-invalid': submitted && f.Unit.errors }"
                                    readonly>
                                <div class="error-msg" *ngIf="submitted && f.Unit.errors"> <span style="color: red;"
                                        *ngIf="f.Unit.errors.required">
                                        Unit is required.</span>
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Rate</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.poRate?itemdata.poRate:''"
                                    formControlName="Rate" [ngClass]="{ 'is-invalid': submitted && f.Rate.errors }"
                                    (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="submitted && f.Rate.errors"> <span style="color: red;"
                                        *ngIf="f.Rate.errors.required">
                                        Rate is required.</span>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-lg-12">
                            <label>Delivered QTY</label>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Delivered QTY</label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.receivedQnty?itemdata.receivedQnty:''" formControlName="QTY"
                                    [ngClass]="{ 'is-invalid': submitted && f.QTY.errors }"
                                    (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="errormsg"> <span style="color: red;">
                                        {{errormsg}}</span>
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Order QTY</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.ordQnty?itemdata.ordQnty:''"
                                    formControlName="Order" [ngClass]="{ 'is-invalid': submitted && f.Order.errors }"
                                    (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="submitted && f.Order.errors"> <span style="color: red;"
                                        *ngIf="f.Order.errors.required">
                                        Order is required.</span>
                                </div>
                            </div>

                        </div>

                        <!-- <div class="col-lg-12">
                            <label>CGST</label>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>CGST Rate</label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.CGSTRate?itemdata.CGSTRate:''" formControlName="CGSTRate"
                                    [ngClass]="{ 'is-invalid': submitted && f.CGSTRate.errors }"
                                    (ngModelChange)="calculate()">
                            </div>
                            <!-- <div class="error-msg" *ngIf="submitted && f.CGSTRate.errors"> <span style="color: red;"
                                    *ngIf="f.CGSTRate.errors.required">
                                    CGST is required.</span>
                            </div> -->
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>CGST Amount</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.CGSTAmt?itemdata.CGSTAmt:''"
                                    formControlName="CGSTammount"
                                    [ngClass]="{ 'is-invalid': submitted && f.CGSTammount.errors }" readonly>
                            </div>
                            <!-- <div class="error-msg" *ngIf="submitted && f.CGSTammount.errors"> <span style="color: red;"
                                    *ngIf="f.CGSTammount.errors.required">
                                    CGSTammount is required.</span>
                            </div> -->
                        </div>
                        <!-- <div class="col-lg-12">
                            <label>SGST</label>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>SGST Rate</label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.SGSTRate?itemdata.SGSTRate:''" formControlName="SGSTrate"
                                    [ngClass]="{ 'is-invalid': submitted && f.SGSTrate.errors }"
                                    (ngModelChange)="calculate()">
                                <div class="error-msg" *ngIf="submitted && f.SGSTrate.errors"> <span style="color: red;"
                                        *ngIf="f.SGSTrate.errors.required">
                                        SGSTrate is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>SGST Amount</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.SGSTAmt?itemdata.SGSTAmt:''"
                                    formControlName="SGSTammount"
                                    [ngClass]="{ 'is-invalid': submitted && f.SGSTammount.errors }" readonly>
                            </div>
                            <!-- <div class="error-msg" *ngIf="submitted && f.SGSTammount.errors"> <span style="color: red;"
                                    *ngIf="f.SGSTammount.errors.required">
                                    SGSTammount is required.</span>
                            </div> -->
                        </div>
                        <!-- <div class="col-lg-12">
                            <label>IGST</label>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>IGST Rate</label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.IGSTRate?itemdata.IGSTRate:''" formControlName="IGSTrate"
                                    [ngClass]="{ 'is-invalid': submitted && f.IGSTrate.errors }"
                                    (ngModelChange)="calculate()">
                            </div>
                            <!-- <div class="error-msg" *ngIf="submitted && f.IGSTrate.errors"> <span style="color: red;"
                                    *ngIf="f.IGSTrate.errors.required">
                                    IGSTrate is required.</span>
                            </div> -->
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>IGST Amount</label>
                                <input type="tel" class="form-control" [ngModel]="itemdata.IGSTAmt?itemdata.IGSTAmt:''"
                                    formControlName="IGSTammount"
                                    [ngClass]="{ 'is-invalid': submitted && f.IGSTammount.errors }" readonly>
                                <div class="error-msg" *ngIf="submitted && f.IGSTammount.errors"> <span
                                        style="color: red;" *ngIf="f.IGSTammount.errors.required">
                                        IGSTammount is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Amount </label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.itemAmount?itemdata.itemAmount:''" formControlName="itemAmount"
                                    [ngClass]="{ 'is-invalid': submitted && f.itemAmount.errors }" readonly>
                                <div class="error-msg" *ngIf="submitted && f.itemAmount.errors"> <span
                                        style="color: red;" *ngIf="f.itemAmount.errors.required">
                                        ItemAmount is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Total Amount </label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.totalAmount?itemdata.totalAmount:''"
                                    formControlName="TotalAmount"
                                    [ngClass]="{ 'is-invalid': submitted && f.TotalAmount.errors }" readonly>
                                <div class="error-msg" *ngIf="submitted && f.TotalAmount.errors"> <span
                                        style="color: red;" *ngIf="f.TotalAmount.errors.required">
                                        TotalAmount is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>HSN Number</label>
                                <input type="tel" class="form-control"
                                    [ngModel]="itemdata.HSNSANum?.hsnNumber?itemdata.HSNSANum?.hsnNumber:''"
                                    formControlName="hsnnumber"
                                    [ngClass]="{ 'is-invalid': submitted && f.hsnnumber.errors }" readonly>
                                <div class="error-msg" *ngIf="submitted && f.hsnnumber.errors"> <span
                                        style="color: red;" *ngIf="f.hsnnumber.errors.required">
                                        HSN is required.</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <button type="submit" class="btn btn-warning" *ngIf="itemdata == ''"
                        (click)="SaveItem(poId)">Save</button>
                    <button type="submit" class="btn btn-warning" *ngIf="itemdata != ''"
                        (click)="UpdateItem(itemdata._id)">Update</button>
                </form>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>