import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { CommonService, Entries, passwordMatchValidator } from 'src/app/Services/common/common.service';
import { Customer, updateCustomer } from 'src/app/Services/customer/customer.model';
import { CustomerService } from 'src/app/Services/customer/customer.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { regex } from 'src/app/constants/regex.constants';
declare var $: any;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  customerForm: FormGroup;
  customerFormSubmitted: boolean;
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  isChecked: boolean;
  page: number = 1;
  entries: Entries;
  limit: number = 10;
  totalResult: any;
  customerList: Array<any> = new Array<any>();
  searchKeyword: string;
  apiHitDone: boolean;
  excelSheetForm: FormGroup;
  submitted = false;
  erroMesage: any;
  toBeDeletedId: any;
  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formGroupService: FormsGroupService,
    private excelService: FileSaverService,
  ) {
    this.activatedRoute.queryParams.subscribe(
      ({ page, search, offerType }) => {
        this.page = page || 1;
        this.searchKeyword = search || "";

        this.getCustomer(this.page);
      }
    ).unsubscribe();
    this.isChecked = false;
    this.customerForm = new FormGroup({
      "bothAddressesSame": new FormControl(''),
      "CustomerName": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.onlycharactors)])),
      "CustomerCode": new FormControl('', Validators.compose([Validators.required])),
      "ContactName": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.onlycharactors)])),
      "ContactNumber": new FormControl('', Validators.compose([Validators.required,Validators.minLength(10)])),
      "PrimaryEmail": new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
      "GST": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.gstvalidator)])),
      "PAN": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.panvalidator)])),
      "ShippingAddress": new FormControl('', Validators.compose([Validators.required])),
      "BillingAddress": new FormControl('', Validators.compose([Validators.required])),
      "PlaceOfSupply": new FormControl('', Validators.compose([Validators.required])),

    });
    this.excelSheetForm = this.formGroupService.getExcelSheetForm();
  }
  get a() {
    return this.excelSheetForm.controls;

  }
  ngOnInit(): void {
  }

  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  exportAsXLSX(): void {
		this.commonService.presentSpinner();
		this.customerService.downloadCustomerExcelTemplate().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.status == 200 && res.body) {
						this.commonService.dismissSpinner();
						this.excelService.save(res.body, 'customer.xlsx');
					} else {
						this.commonService.dismissSpinner();
					}
				} else {
					this.commonService.dismissSpinner();
				}
			}
		)
	}

  getCustomer(page: number) {
    this.apiHitDone = false;
    this.commonService.presentSpinner();
    this.customerService.getCustomer({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword }).then(
      (res: any) => {
        if (res['body']['AllCustomersList'].data.length > 0) {
          this.customerList = res['body']['AllCustomersList'].data;
          this.totalResult = res['body']['AllCustomersList'].total;
          this.entries = this.commonService.calculateEntries(this.customerList, page, this.limit, this.totalResult);
        } else {
          this.customerList = [];
          this.totalResult = 0;
          // this.entries = this.commonService.calculateEntries(this.customerList, page, this.limit, this.totalResult);
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.apiHitDone = true;
        this.entries = this.commonService.calculateEntries(this.customerList, page, this.limit, this.totalResult);
        this.page = page;
        if (this.page > 1 && this.customerList.length == 0) {
          this.page = this.page - 1;
          this.getCustomer(this.page);
        } else {
          let params: any = {
            "page": this.page
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }

          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }
  checkValue(e) {
    if (e.target.checked) {
      const shippingAddress = this.customerForm.controls["ShippingAddress"].value;
      this.customerForm.patchValue({ BillingAddress: shippingAddress })
      // this.customerForm.controls["BillingAddress"].setValue(shippingAddress);
    } else {
      // this.customerForm.controls["BillingAddress"].setValue('');
      this.customerForm.patchValue({ BillingAddress: '' })
    }
  }

  customerToEdit: any;
  async openCustomerFormModal(customerDetail?: any) {
    this.customerForm.reset();
    this.customerFormSubmitted = false;
    this.customerToEdit = null;
    if (customerDetail) {
      this.customerToEdit = { ...customerDetail };
      this.customerForm.patchValue(customerDetail)
    } else {
    }
    $('#customer-form').modal('show');
  }
  addOrUpdateCustomer() {
    let promise: Promise<any> = null;
    let payload = null
    this.customerFormSubmitted = false;
    if (this.customerToEdit) {
      if (this.customerForm.controls['CustomerName'].invalid || this.customerForm.controls['CustomerCode'].invalid || this.customerForm.controls['ContactName'].invalid || this.customerForm.controls['ContactNumber'].invalid || this.customerForm.controls['PrimaryEmail'].invalid || this.customerForm.controls['GST'].invalid || this.customerForm.controls['PAN'].invalid || this.customerForm.controls['ShippingAddress'].invalid || this.customerForm.controls['BillingAddress'].invalid || this.customerForm.controls['PlaceOfSupply'].invalid) {
        this.customerFormSubmitted = true;
        return;
      }
      payload = new updateCustomer(this.customerForm.value);
      payload._id = this.customerToEdit._id;
      promise = this.customerService.updateCustomer(payload);
    } else {
      if (this.customerForm.invalid) {
        this.customerFormSubmitted = true;
        return;
      }
      payload = new Customer(this.customerForm.value);
      promise = this.customerService.addCustomer(payload);
    }
    this.commonService.presentSpinner();
    promise.then(
      (res) => {
        if (res) {
          if (this.customerToEdit) {

          } else {
            this.commonService.presentSuccessToast('', 'Added successfully!');
          }
        }
      }
    ).catch((error: HttpErrorResponse) => {
      if (error && error.error && error.error.message) {
        this.commonService.presentErrorToast('', error.error.message);
      }
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.customerForm.reset();
      this.getCustomer(1)
      $('#customer-form').modal('hide');
      this.customerToEdit = null;
    })
  }
  openAddMoadal() {
    this.submitted = false;
    this.erroMesage = '';
    this.excelSheetForm.reset();
    $('.bulk_add_customer').modal('show');
  }
  execlSheet: File = null;
  onSelectFile(event) {
    var file: File = event.target.files[0];
    let currentFile = file;
    let imagetype = currentFile.type.split('/');
    let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.commonService.presentErrorToast('', "Only .xlsx format is allowed");
      this.excelSheetForm.controls['excelSheet'].setValue('');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      this.execlSheet = event.target.files[0]
    }
  };


  uploadExcelSheet() {
    this.submitted = false;
    if (this.excelSheetForm.invalid) {
      this.submitted = true;
      return;
    };
    this.commonService.presentSpinner();
    let payload = new FormData();
    payload.append("excelSheet", this.execlSheet);
    this.customerService.uploadBulkCustomerData(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.presentSuccessToast('', res.message);
          this.submitted = false;
          this.execlSheet = null;
          $('.bulk_add_customer').modal('hide');
        }
      }
    ).catch((error) => {
      if (error.error.code == 400) {
        this.erroMesage = error.error.errorArray;
      }
      // this.commonService.presentErrorToast('', error.message);
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.getCustomer(1)
    })
  }

  openDeleteModal(customerId) {
    this.toBeDeletedId = customerId;
    if (this.toBeDeletedId) {
      $('#delete-customer').modal('show');
    }
  }
  deletecustomer() {
    this.commonService.presentSpinner();
    this.customerService.deleteCustomer(this.toBeDeletedId).then(
      (res: any) => {

      }
    ).catch((error) => {

    }).finally(() => {
      $('#delete-customer').modal('hide');
      this.commonService.dismissSpinner();
      this.getCustomer(1);
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}

