import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { ItemService } from 'src/app/Services/item/item.service';
declare var $: any;

@Component({
  selector: 'app-sales-items',
  templateUrl: './sales-items.component.html',
  styleUrls: ['./sales-items.component.scss']
})
export class SalesItemsComponent implements OnInit {

  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  itemCode: any;
  item_id: any;
  apiHitDone:boolean;
  page:number = 1;
  limit:number = 10;
  search:string='';
  productList:Array<any>;
  totalResult:number;
  entries: Entries;
  toBeEdit:any = null;
  toBeDeletedId:any;
  excelSheetForm: FormGroup;
  submitted = false;
  execlSheetData: File = null;
  erroMesage: any;
  constructor(
    private commonService: CommonService,
    private productsService: ProductsService,
    private excelService: FileSaverService,
    private formGroupService: FormsGroupService,
  ) { 
    this.getProductList();
    this.excelSheetForm = this.formGroupService.getExcelSheetForm();
  }

  ngOnInit(): void {
   
  }

  get excelSheet() {
		return this.excelSheetForm.controls;
	}

  openExcelSheetModal(){
    this.submitted = false;
    this.excelSheetForm.reset()
    $('#product-excel').modal('show');
    
  }

  onSelectFile(event) {

		this.erroMesage = '';
		var file: File = event.target.files[0];
		let currentFile = file;
		let imagetype = currentFile.type.split('/');
		let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if ($.inArray(imagetype[1], ValidImageExt) < 0) {
			this.commonService.presentErrorToast('', "Only .xlsx format is allowed");
			this.excelSheetForm.controls['excelSheet'].setValue('');
			return false;
		}
		if (event.target.files && event.target.files[0]) {
			this.execlSheetData = event.target.files[0]
		}
	};

  uploadExcelSheet(){
    this.submitted = false;
		if (this.excelSheetForm.invalid) {
			this.submitted = true;
			return;
		};

    this.commonService.presentSpinner()
    let payload = new FormData();
    payload.append("excelSheet", this.execlSheetData);
    this.productsService.uploadProductExcel(payload).then(
      (res:any)=>{
        if(res.code == 200){
          this.execlSheetData = null
          this.commonService.dismissSpinner();
          $('#product-excel').modal('hide');
          this.getProductList();
        }
      }
    ).catch((error)=>{
      this.erroMesage = error.error.message;
    })
  }

  exportAsXLSX(): void {

		this.commonService.presentSpinner();
		this.productsService.downloadProductExcelTemplate().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.status == 200 && res.body) {
						this.commonService.dismissSpinner();
						this.excelService.save(res.body, 'product.xlsx');
					} else {
						this.commonService.dismissSpinner();
					}
				} else {
					this.commonService.dismissSpinner();
				}
			}
		)
	}

  openProductFormModal(productDetail?:any){
    if(productDetail){
      this.toBeEdit = productDetail
    }
    $('#add_product').modal('show');
  }

  getProductList(page?:number){
    this.apiHitDone = false;
    this.page = page || 1
    let payload = {
      page:this.page.toString(),
      limit:this.limit.toString(),
      search:this.search
    }
    this.commonService.presentSpinner();
    this.productsService.getProductList(payload).then(
      (res:any)=>{
        if(res['body']['code'] == 200 && res['body']['productList']['data'].length){
          this.productList = res['body']['productList']['data'];
          this.totalResult =  res['body']['productList'].total;
          this.entries = this.commonService.calculateEntries(this.productList, this.page, this.limit, this.totalResult);
        }else{
          this.productList = [];
        }
      }
    ).catch((error)=>{
      this.commonService.presentErrorToast(error.error.message,'')
    }).finally(()=>{
      this.apiHitDone = true;
      this.commonService.dismissSpinner();
    })
  }

  opemModals(val, data) {
    if (val == "delete") {
      $('#delete-item').modal('show');
      this.item_id = data._id;
      this.itemCode = data.itemCode
    }
  }




  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  getResponseFromChild(event){
    $('#add_product').modal('hide');
    this.getProductList();
  }

  openDeleteModal(Id) {
    this.toBeDeletedId = Id;
	    if (this.toBeDeletedId) {
        $('#delete-item').modal('show');
      }
	}

  deleteProduct(){
    this.commonService.presentSpinner();
    this.productsService.deleteProduct(this.toBeDeletedId).then(
      (res: any) => {
        if(res.code == 200){
          this.commonService.presentSuccessToast(res.message, '');
        }
      }
    ).catch((error) => {

    }).finally(() => {
      $('#delete-item').modal('hide');
      this.commonService.dismissSpinner();
      this.getProductList(1);
    })
  }

}
