import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alertmodal',
  templateUrl: './alertmodal.component.html',
  styleUrls: ['./alertmodal.component.scss']
})
export class AlertmodalComponent implements OnInit {
  @Input() displaymsg;
  @Output() myOutput: EventEmitter<string> = new EventEmitter();
  outputMessage: string = "child component msg."
  constructor() { }

  ngOnInit(): void {
  }

  Deletemodal: boolean = true;
  OKButton() {
    this.Deletemodal = false;
    this.myOutput.emit(this.outputMessage);
  }
}
