import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoint } from 'src/app/constants/endpoints.constants';

import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class GSTService {

  constructor(
    private httpWrapperService: HttpWrapperService
  ) { }

  addGST(payload) {
    return this.httpWrapperService.post(endpoint.uploadgstr, payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

  GetGst(page, matchcount, keyword, startDate, endDate) {

    return this.httpWrapperService.get(endpoint.gstrlist(page, matchcount, keyword, startDate, endDate)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          }
        }
      );
  }

  Downloadreport(gstrid) {
    let httpInput = new HttpInputData();
    // let httpparams = new HttpHeaders();
    // httpparams = httpparams.set('Content-type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    // httpInput.headers = httpparams;
    httpInput.responseType = "blob";
    return this.httpWrapperService.get(endpoint.downloadreport(gstrid), httpInput).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {

          return error;

        }
      );
  }



  deletegst(gstid) {
    return this.httpWrapperService.delete(endpoint.deletegst(gstid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

        }
      );
  };

}
