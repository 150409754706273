import { Injectable } from '@angular/core';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  item_id: string;

  constructor(
    private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private events: EventsService) { }


  getAdminItemsList(page) {
    return this.httpWrapperService.get(endpoint.adminItemsList(page)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error.error.message);
          return error;
        }
      );
  }

  addAdminItems(payload) {
    return this.httpWrapperService.post(endpoint.addadminitems, payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          } else {
            return error;
          }

        }
      );
  };

  adminItemeDelete() {
    return this.httpWrapperService.delete(endpoint.deleteAdminItems(this.item_id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

        }
      );
  };

  editAdminItems(payload) {
    return this.httpWrapperService.put(endpoint.editadminites(this.item_id), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          } else {
            return error;
          }

        }
      );
  };

  getHSNCodeList() {
    return this.httpWrapperService.get(endpoint.gethsncode).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error.error.message);
          return error;
        }
      );
  }

  getHSNList(page, keyword) {
    return this.httpWrapperService.get(endpoint.gethsnlist(page, keyword)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error.error.message);
          return error;
        }
      );
  }

  addHSN(payload) {
    return this.httpWrapperService.post(endpoint.addhsn, payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          } else {
            return error;
          }

        }
      );
  };

  editHSN(hsnid, payload) {
    return this.httpWrapperService.put(endpoint.editDeletehsn(hsnid), payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error == 422) {
            return error;
          } else {
            return error;
          }

        }
      );
  };

  Deletehsn(hsnid) {
    return this.httpWrapperService.delete(endpoint.editDeletehsn(hsnid)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;

        }
      );
  };
}
