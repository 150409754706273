<div class="page-content">
    <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item active">HSNCode</li>
                        </ol>
                    </div>
                    <div class="page-title-right2">
                        <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                            (click)="opemModals('addModal','')">Add</button>
                    </div>

                </div>
                <div class="dataTables_filter">
                    <label>
                        <input type="search" class="form-control form-control-sm" placeholder="Search..."
                            [(ngModel)]="keyword" (input)="searchHSN($event.target.value)">
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card table_page">
                    <div class="card-body pt-3">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="width:40px;">No.</th>
                                        <th style="width:120px;">HSN/SAC</th>
                                        <th >GST %</th>
                                        <th style="width:65px;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr [hidden]="HSNList?.length">
                                        <td colspan="7">
                                            <h6 class=" mb-0" style="color: #545a60;">No records found</h6>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="let data of HSNList | paginate: {id: 'hsnList', itemsPerPage: limit, currentPage: page,totalItems: count }; let i = index">
                                        <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                                        <td data-title="HSN/SAC">{{data?.hsnNumber}}</td>
                                        <td data-title="Item Name">{{data?.gstRate}} %</td>
                                        <td data-title="Action">
                                            <button type="button" class="mr-2 text-primary btn_style"><i
                                                    class="mdi mdi-pencil font-size-18"
                                                    (click)="opemModals('edit',data)"></i>
                                            </button> <a href="javascript:void(0);" class="text-danger"
                                                (click)="opemModals('delete',data)"><i
                                                    class="mdi mdi-trash-can font-size-18"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row" *ngIf="HSNList?.length">
                                <div class="col-sm-12 col-md-5">
                                    <div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
                                        {{entries?.total}}
                                        entries</div>
                                </div>
                                <div class="col-sm-12 col-md-7">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <pagination-controls id="hsnList" [autoHide]="true" responsive="true"
                                            previousLabel="Prev" nextLabel="Next" (pageChange)="GetHSNList($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  Item Popup -->
<div class="modal fade add_detail" tabindex="-1" id="add_detail" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addHSNForm" (ngSubmit)="addHSN()">
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">HSN Number</label>
                                <input type="text" class="form-control" formControlName="hsnnumber"
                                    [ngClass]="{ 'is-invalid': submitted && a.hsnnumber.errors }">
                                <div class="error-msg" *ngIf="submitted && a.hsnnumber.errors"> <span
                                        style="color: red;" *ngIf="a.hsnnumber.errors.required">
                                        HSN Number is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">GST % </label>
                                <input type="text" class="form-control" formControlName="GST"
                                    [ngClass]="{ 'is-invalid': submitted && a.GST.errors }">
                                <div class="error-msg" *ngIf="submitted && a.GST.errors"> <span style="color: red;"
                                        *ngIf="a.GST.errors.required">
                                        GST is required.</span>
                                </div>
                            </div>

                        </div>


                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Add  Item Popup -->
<!-- Edit  Item Popup -->
<div class="modal fade edit_detail" tabindex="-1" id="edit_detail" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Edit Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="editHSNForm" (ngSubmit)="editHSN()">
                    <div class="row">
                        <input type="hidden" formControlName="id">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">HSN Number</label>
                                <input type="text" class="form-control" formControlName="hsnnumber"
                                    [ngClass]="{ 'is-invalid': submitted && f.hsnnumber.errors }">
                                <div class="error-msg" *ngIf="submitted && f.hsnnumber.errors"> <span
                                        style="color: red;" *ngIf="f.hsnnumber.errors.required">
                                        HSN Number is required.</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">GST % </label>
                                <input type="text" class="form-control" formControlName="GST"
                                    [ngClass]="{ 'is-invalid': submitted && f.GST.errors }">
                                <div class="error-msg" *ngIf="submitted && f.GST.errors"> <span style="color: red;"
                                        *ngIf="f.GST.errors.required">
                                        GST is required.</span>
                                </div>
                            </div>

                        </div>


                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Edit  Item Popup -->
<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
                    <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
                        <div class="alert-head sc-ion-alert-ios">
                            <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete HSN Number</h2>
                            <!-- <h6>{{itemCode}}</h6> -->
                        </div>
                        <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
                        </div>
                        <div class="alert-button-group sc-ion-alert-ios">
                            <button type="button" class="alert-button" aria-label="Close"
                                (click)="HSNDelete(hsnid)"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
                            </button>
                            <button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
                                aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>