import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/constants/regex.constants';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
declare var $: any;
@Component({
  selector: 'app-add-administrators',
  templateUrl: './add-administrators.component.html',
  styleUrls: ['./add-administrators.component.scss']
})
export class AddAdministratorsComponent implements OnInit {
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  AdminForm: FormGroup;
  Deletemodal: boolean;
  displaymsg: any;
  submitted = false;
  companys: any = [];
  constructor(
    private commonService: CommonService,
    private router: Router,
    private companyservice: CompanyService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.AdminForm = this.formBuilder.group({
      comapnyname: ['', Validators.required],
      adminimage: [''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // role: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'cpassword')
      }
    )
    this.GetCompanys();
  }

  get f() { return this.AdminForm.controls; }
  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Formats allowed : jpg, jpeg, png";
      this.AdminForm.controls['comapnyname'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }

  showPassword() {
    this.password = !this.password;
  }

  GetCompanys() {
    this.commonService.presentSpinner();
    // this.page = 1
    this.companyservice.getCompanys().then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.companys = [];
            this.companys = res.companyList;
          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }

  AddAdmin() {

    this.submitted = true;


    // stop here if form is invalid
    if (this.AdminForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    var frmData = new FormData();

    frmData.append("name", this.AdminForm.value.name);
    frmData.append("email", this.AdminForm.value.email.toLowerCase());
    frmData.append("role", "Admin");
    frmData.append("password", this.AdminForm.value.password);
    frmData.append("confirmPassword", this.AdminForm.value.cpassword);
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("companyId", this.AdminForm.value.comapnyname);

    this.companyservice.Addadministrators(frmData).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            this.AdminForm.reset();
            $('.add_detail').modal('hide');
            this.imageUrl = '';
            this.submitted = false;
            // this.commonService.presentSuccessToast('', res.message);
            this.displaymsg = res.message;
            this.Deletemodal = true;

          } else {
            // this.commonService.presentErrorToast('', res.message);//
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.Deletemodal = true;
          }
        }
      }
    );
  }

  closeAdministratorModal() {
    this.imageUrl = '';
    this.AdminForm.reset();
    this.imageErrMsg = '';
    $('.add_detail').modal('hide');
    this.submitted = false;
  }
  GetChildData(data) {
    if (data) {
      this.Deletemodal = false;
    }
  }
}
