import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	admin_id: any;

	constructor(
		private toastNotification: ToastrService,
		private spinner: NgxSpinnerService
	) { }

	presentSuccessToast(title: string, message: string) {

		this.toastNotification.success(message, title);
	}

	presentErrorToast(title: string, message: string) {
		this.toastNotification.error(message, title);
	}

	presentSpinner() {
		this.spinner.show();
		// setTimeout(() => {
		// 	this.dismissSpinner();
		// },5000);
	}

	dismissSpinner() {
		this.spinner.hide();
	}
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}

	// handleError(error: any) {
	//   console.log('error ====>>>', error.error);
	// }
	handleError(error: any) {
		if (error.error.code == 422) {
			this.toastNotification.error(error.error.message)
		}
	};

	calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): Entries {
		if (list.length === totalResult) {
			let pages = {
				1: []
			};
			let arr = [];
			let page = 1;
			for (let i = 0; i < list.length; i++) {
				arr.push(list[i]);
				if (arr.length == limit) {
					pages[page] = arr;
					page += 1;
					arr = [];
				} else {
					if (i == list.length - 1) {
						pages[page] = arr;
						page += 1;
						arr = [];
					}
				}
			}
			list = pages[current_page];
		}
		return {
			from: limit * (current_page - 1) + 0 + 1,
			to: limit * (current_page - 1) + (list.length - 1) + 1,
			total: totalResult,
		};
	}
}

export interface Entries {
	from: any;
	to: any;
	total: any;
}

export function passwordMatchValidator(g: FormGroup) {
	return g.get('password').value === g.get('confirmPassword').value
		? null : { 'mismatch': true };
}

