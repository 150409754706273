import { formatDate } from "@angular/common";

export class SalesPO {
    plant:string;
    customerId:string;
    amount:number;
    gst:number;
    tatalAmount:number;
    type:string;
    poDate:string;
    validUpToDate:string;
    constructor(data: {
        plant:string;
        customerId:string;
        amount:number;
        gst:number;
        tatalAmount:number;
        type:string;
        poDate:string;
        validUpToDate:string;
    }){
        if(data && data.plant){
            this.plant = data.plant;
        }
        if(data && data.customerId){
            this.customerId = data.customerId;
        }
        if(data && data.amount){
            this.amount = data.amount;
        }
        if(data && data.gst){
            this.gst = data.gst;
        }
        if(data && data.tatalAmount){
            this.tatalAmount = data.tatalAmount;
        }
        if(data && data.type){
            this.type = data.type;
        }
        if(data && data.poDate){
            this.poDate = data.poDate;
        }
        if(data && data.validUpToDate){
            this.validUpToDate = data.validUpToDate;
        }
    }
}

export class SalesPoProduct {
    salesOPId:string;
    productId:string;
    rate:string;   
    quantityDelieved:string;
    quantityOrdered:string;
    amount:string;
    CGSTRate:string='0';
    CGSTAmount:string='0';
    SGSTRate:string='0'
    SGSTAmount:string='0';
    IGSTRate:string='0';
    IGSTAmount:string='0';
    totalAmount:string;
    validFromDate;
    constructor(data: {
        salesOPId:string;
        productId:string;
        rate:string;   
        quantityDelieved:string;
        quantityOrdered:string;
        amount:string;
        CGSTRate:string;
        CGSTAmount:string;
        SGSTRate:string;
        SGSTAmount:string;
        IGSTRate:string;
        IGSTAmount:string;
        totalAmount:string;
        validFromDate;
    }){
        if(data && data.salesOPId){
            this.salesOPId = data.salesOPId;
        }
        if(data && data.productId){
            this.productId = data.productId;
        }
        if(data && data.rate){
            this.rate = data.rate;
        }
        if(data && data.quantityDelieved){
            this.quantityDelieved = data.quantityDelieved;
        }
        if(data && data.quantityOrdered){
            this.quantityOrdered = data.quantityOrdered;
        }
        if(data && data.amount){
            this.amount = data.amount;
        }
        if(data && data.CGSTRate){
            this.CGSTRate = data.CGSTRate;
        }
        if(data && data.CGSTAmount){
            this.CGSTAmount = data.CGSTAmount;
        }
        if(data && data.SGSTRate){
            this.SGSTRate = data.SGSTRate;
        }
        if(data && data.SGSTAmount){
            this.SGSTAmount = data.SGSTAmount;
        }
        if(data && data.IGSTRate){
            this.IGSTRate = data.IGSTRate;
        }
        if(data && data.IGSTAmount){
            this.IGSTAmount = data.IGSTAmount;
        }
        if(data && data.totalAmount){
            this.totalAmount = data.totalAmount;
        }
        if(data && data.validFromDate){
            this.validFromDate = data.validFromDate;
        }
    }
}


export class GetDeliveryInstruction {
    limit:number;
    page:number;
    po_number:number;   
    di_number:number;   
    originalDiStartDate:string;   
    itemCodeId:string;   
    originalDiEndDate:string;
    revisedDiStartDate:string;
    revisedDiEndDate:string;
    scheduledQuantity:number;
    unloadingLocation:string;
    usageLocation:string;
    supplyStartDate:string;
    supplyDiEndDate:string;
    supplyStartTime:string;
    supplyEndTime:string;

    constructor(data: {
        limit:number;
        page:number;
        po_number:number;   
        di_number:number;   
        originalDiStartDate:string;   
        itemCodeId:string;   
        originalDiEndDate:string;
        revisedDiStartDate:string;
        revisedDiEndDate:string;
        scheduledQuantity:number;
        unloadingLocation:string;
        usageLocation:string;
        supplyStartDate:string;
        supplyDiEndDate:string;
        supplyStartTime:string;
        supplyEndTime:string;
    }){
       if(data){
        this.page = data.page || 1;
        this.limit = data.limit || 1;

        if(data && data.po_number){
            this.po_number = data.po_number;
        }
        if(data && data.di_number){
            this.di_number = data.di_number;
        }
        if(data && data.originalDiStartDate){
            this.originalDiStartDate = formatDate(data.originalDiStartDate, 'yyyy-MM-dd', 'en-US');
        }
        if(data && data.originalDiEndDate){
            this.originalDiEndDate = data.originalDiEndDate;
        }
        if(data && data.revisedDiStartDate){
            this.revisedDiStartDate =  formatDate(data.revisedDiStartDate, 'yyyy-MM-dd', 'en-US');
        }
        if(data && data.revisedDiEndDate){
            this.revisedDiEndDate = data.revisedDiEndDate;
        }
        if(data && data.scheduledQuantity){
            this.scheduledQuantity = data.scheduledQuantity;
        }
        if(data && data.unloadingLocation){
            this.unloadingLocation = data.unloadingLocation;
        }
        if(data && data.usageLocation){
            this.usageLocation = data.usageLocation;
        }
        if(data && data.supplyStartDate){
            this.supplyStartDate = data.supplyStartDate;
        }
        if(data && data.supplyDiEndDate){
            this.supplyDiEndDate = data.supplyDiEndDate;
        }
        if(data && data.supplyStartTime){
            this.supplyStartTime = data.supplyStartTime;
        }
        if(data && data.supplyEndTime){
            this.supplyEndTime = data.supplyEndTime;
        }
        if(data && data.itemCodeId){
            this.itemCodeId = data.itemCodeId;
        }
       }
    }
}

export class AddDeliveryInstruction {
    po_number:number;
    di_number:number;
    originalDiDate:string;   
    revisedDiDate:string;
    itemCode:string;
    scheduledQuantity:string;
    unloadingLocation:string;
    usageLocation:string;
    supplyDate:string;
    supplyTime:string;

    constructor(data: {
        po_number:number;
        di_number:number;
        originalDiDate:string;   
        revisedDiDate:string;
        itemCode:string;
        scheduledQuantity:string;
        unloadingLocation:string;
        usageLocation:string;
        supplyDate:string;
        supplyTime:string;
    }){
       if(data){
        if(data && data.po_number){
            this.po_number = data.po_number;
        }
        if(data && data.di_number){
            this.di_number = data.di_number;
        }
        if(data && data.originalDiDate){
            this.originalDiDate = formatDate(data.originalDiDate, 'yyyy-MM-dd', 'en-US');
        }
        if(data && data.revisedDiDate){
            this.revisedDiDate = formatDate( data.revisedDiDate, 'yyyy-MM-dd', 'en-US');
        }
        if(data && data.itemCode){
            this.itemCode = data.itemCode;
        }
       
        if(data && data.scheduledQuantity){
            this.scheduledQuantity = data.scheduledQuantity;
        }
        if(data && data.unloadingLocation){
            this.unloadingLocation = data.unloadingLocation;
        }
        if(data && data.usageLocation){
            this.usageLocation = data.usageLocation;
        }
        if(data && data.supplyDate){
            this.supplyDate = data.supplyDate;
        }
        if(data && data.supplyTime){
            this.supplyTime = data.supplyTime;
        }
       }
    }
}
