import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { FileSaverService } from 'ngx-filesaver';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { AddDeliveryInstruction, GetDeliveryInstruction } from 'src/app/Services/SalesPO/sales-po.model';
import { SalesPoService } from 'src/app/Services/SalesPO/sales-po.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
declare var $: any;

@Component({
  selector: 'app-delivery-instructions',
  templateUrl: './delivery-instructions.component.html',
  styleUrls: ['./delivery-instructions.component.scss']
})
export class DeliveryInstructionsComponent implements OnInit {
  submitted: boolean;
  erroMesage: string;
  excelSheetForm: FormGroup;
  diForm: FormGroup;
  limit: number = 20;
  page: number = 1;
  po_number: number;
  di_number: number;
  originalDiStartDate: string;
  originalDiEndDate: string;
  revisedDiStartDate: string;
  revisedDiEndDate: string;
  scheduledQuantity: number;
  unloadingLocation: string;
  usageLocation: string;
  supplyStartDate: string;
  supplyDiEndDate: string;
  supplyStartTime: string;
  itemCodeId: string;
  supplyEndTime: string;
  deliveryInstructionList: Array<[]>;
  total: number;
  entries: Entries;
  apiHitDone: boolean;
  diFormSubmited: boolean;
  toBeDeletedId: any;
  toBeEdited: any = null;
  item: Array<[]>;
  salesPOList: Array<[]>;
  raiseInvoiceForSalesPo: Array<[]>;
  raiseInvoice: any;
  public chosenDate: any = {};
  public chosenDate1: any = {};
  public chosenDate2: any = {};
  constructor(
    private formGroupService: FormsGroupService,
    private excelService: FileSaverService,
    private commonService: CommonService,
    private salesPoService: SalesPoService,
    private productServices: ProductsService,
    private daterangepickerOptions: DaterangepickerConfig
  ) {

    this.diForm = new FormGroup({
      "po_number": new FormControl('', Validators.required),
      "di_number": new FormControl('', Validators.required),
      "originalDiDate": new FormControl('', Validators.required),
      "revisedDiDate": new FormControl('', Validators.required),
      "itemCode": new FormControl('', Validators.required),
      "scheduledQuantity": new FormControl('', Validators.required),
      "unloadingLocation": new FormControl('', Validators.required),
      "usageLocation": new FormControl('', Validators.required),
      "supplyDate": new FormControl('', Validators.required),
      "supplyTime": new FormControl('', Validators.required),
    })
    this.excelSheetForm = this.formGroupService.getExcelSheetForm();
    this.getDIData();
    this.getItem();
    this.getSalesPO();

    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      "opens": "right",
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };
  }

  get a() {
    return this.excelSheetForm.controls;
  }

  get diFormControls() {
    return this.diForm.controls;

  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".stat-select").change(function () {
        var VAL = $(this).find(":selected").text();
        $(".stat-label>span").text(VAL);
      });
    });
  }

  public picker1 = {
    opens: 'left',
    startDate: moment().subtract(5, 'day'),
    endDate: moment(),
    isInvalidDate: function (date: any) {
      if (date.isSame('2017-09-26', 'day'))
        return 'mystyle';
      return false;
    }
  }


  public selectedDate(value: any, dateInput: any, action): void {
    let startdate = '';
    let endtdate = ''
    if (action === 'orignalDI') {
      dateInput.start = value.start;
      dateInput.end = value.end;
      startdate = value.start.format('YYYY-MM-DD');
      endtdate = value.end.format('YYYY-MM-DD');
      this.originalDiStartDate = startdate;
      this.originalDiEndDate = endtdate
      this.chosenDate1.start = '';
      this.chosenDate1.end = '';
      this.revisedDiStartDate = '';
      this.revisedDiEndDate = '';
      this.revisedDiStartDate = '';
      this.revisedDiEndDate = '';
    } else if (action === 'revisedDI') {
      this.chosenDate.start = '';
      this.chosenDate.end = '';
      this.chosenDate2.start = '';
      this.chosenDate2.end = '';
      dateInput.start = value.start;
      dateInput.end = value.end;
      startdate = value.start.format('YYYY-MM-DD');
      endtdate = value.end.format('YYYY-MM-DD');
      this.revisedDiStartDate = startdate;
      this.revisedDiEndDate = endtdate;
      this.originalDiStartDate = '';
      this.originalDiEndDate = '';
      this.supplyStartDate = ''
      this.supplyDiEndDate = ''
    } else {
      this.chosenDate1.start = '';
      this.chosenDate1.end = '';
      dateInput.start = value.start;
      dateInput.end = value.end;
      startdate = value.start.format('YYYY-MM-DD');
      endtdate = value.end.format('YYYY-MM-DD');
      this.revisedDiStartDate = '';
      this.revisedDiEndDate = '';
      this.originalDiStartDate = '';
      this.originalDiEndDate = '';
      this.supplyStartDate = startdate
      this.supplyDiEndDate = endtdate
    }
    this.getDIData();


  }

  getDataBYdate(evt) {
    let startdate = '';
    let endtdate = '';
    switch (evt) {
      case 'today':
        startdate = moment().subtract(0, 'days').startOf('day').format("YYYY-MM-DD");
        endtdate = moment().subtract(0, 'days').endOf('day').format("YYYY-MM-DD");

        break;

      case 'yesterday':
        startdate = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DD");
        endtdate = moment().subtract(1, 'days').endOf('day').format("YYYY-MM-DD");
        break;

      case 'all':
        startdate = '';
        endtdate = '';
        break;


    }
    this.originalDiStartDate = startdate;
    this.originalDiEndDate = endtdate
    this.getDIData();
  }

  getDIData(page?: number) {
    this.page = page || 1;
    this.commonService.presentSpinner();
    this.apiHitDone = false;
    let diData = new GetDeliveryInstruction({
      limit: this.limit,
      page: this.page,
      po_number: this.po_number,
      di_number: this.di_number,
      originalDiStartDate: this.originalDiStartDate,
      originalDiEndDate: this.originalDiEndDate,
      revisedDiStartDate: this.revisedDiStartDate,
      revisedDiEndDate: this.revisedDiEndDate,
      scheduledQuantity: this.scheduledQuantity,
      unloadingLocation: this.unloadingLocation,
      usageLocation: this.usageLocation,
      supplyStartDate: this.supplyStartDate,
      supplyDiEndDate: this.supplyDiEndDate,
      supplyStartTime: this.supplyEndTime,
      supplyEndTime: this.supplyEndTime,
      itemCodeId: this.itemCodeId,
    })
    this.salesPoService.getDIList(diData).then(
      (res: any) => {

        if (res['body'].code == 200 && res['body']['deliveryInstructionList']['data'].length) {
          this.deliveryInstructionList = res['body']['deliveryInstructionList']['data'];
          this.deliveryInstructionList.forEach((element:any) => {
            element['isSelected'] = false;
            let time = element.supplyTime.split(":");
            element['supplyTime'] = time[0] < 10 ?  '0'+time[0] +':'+time[1] : element.supplyTime
          });
          this.total = res['body']['deliveryInstructionList'].total;
          this.entries = this.commonService.calculateEntries(this.deliveryInstructionList, this.page, this.limit, this.total);
        } else {
          this.deliveryInstructionList = [];
          this.total = 0
          this.entries = this.commonService.calculateEntries(this.deliveryInstructionList, this.page, this.limit, this.total);
        }

      }
    ).catch((error) => {
      this.commonService.presentErrorToast(error.error.message, '');
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.apiHitDone = true;
    })
  }

  clickForRaiseInvoice($event, diData) {
    if ($event.target.checked) {
      diData.isSelected = true;
    } else {
      diData.isSelected = false;
    }
    this.raiseInvoiceForSalesPo = this.deliveryInstructionList.filter((x) => x['isSelected'] === true);
   

    console.log("this.deliveryInstructionList", this.raiseInvoiceForSalesPo.length)
  }

  openRaiseInvoiceModal(){
    this.raiseInvoice = this.raiseInvoiceForSalesPo
    $("#add_detail").modal('show');
  }

  getItem() {
    this.productServices.getItemList().then(
      (res: any) => {
        if (res.code == 200 && res.list.length) {
          this.item = res.list;
          this.item.forEach(element => {
            element['name'] = element['name'] + ' - ' + element['code'];
          });
        }
      }
    )
  }

  getSalesPO(page?:number) {
		this.productServices.salesPOListWithoutPagination().then(
			(res:any) => {
				if (res['body']['code'] == 200 && res['body']['list'].length) {
					this.commonService.dismissSpinner();
					this.salesPOList = res['body']['list'];
				} else {
					this.commonService.dismissSpinner();
					this.salesPOList = [];
				}
			}
		)
	}

  openDIModal(diDetail?: any) {
    this.diForm.reset();
    this.toBeEdited = null;
    this.diFormSubmited = false;
    if (diDetail) {
      this.toBeEdited = { ...diDetail }
      this.diForm.patchValue(this.toBeEdited);
      this.diForm['controls'].originalDiDate.setValue(formatDate(this.toBeEdited.originalDiDate, 'yyyy-MM-dd', 'en-US'));
      this.diForm['controls'].revisedDiDate.setValue(formatDate(this.toBeEdited.revisedDiDate, 'yyyy-MM-dd', 'en-US'));
      this.diForm['controls'].supplyDate.setValue(formatDate(this.toBeEdited.supplyDate, 'yyyy-MM-dd', 'en-US'));
      this.diForm['controls'].itemCode.setValue(this.toBeEdited.itemCode ? this.toBeEdited.itemCode._id : null);
    }
    $('#add_instruction').modal('show');
  }

  exportAsXLSX(): void {
    this.commonService.presentSpinner();
    this.salesPoService.downloadDIExcelTemplate().then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.status == 200 && res.body) {
            this.commonService.dismissSpinner();
            this.excelService.save(res.body, 'delivery-instructions.xlsx');
          } else {
            this.commonService.dismissSpinner();
          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    )
  }

  execlSheet: File = null;
  onSelectFile(event) {
    var file: File = event.target.files[0];
    let currentFile = file;
    let imagetype = currentFile.type.split('/');
    let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.commonService.presentErrorToast('', "Only .xlsx format is allowed");
      this.excelSheetForm.controls['excelSheet'].setValue('');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      this.execlSheet = event.target.files[0]
    }
  };

  uploadExcelSheet() {
    this.submitted = false;
    if (this.excelSheetForm.invalid) {
      this.submitted = true;
      return;
    };
    this.commonService.presentSpinner();
    let payload = new FormData();
    payload.append("excelSheet", this.execlSheet);
    this.salesPoService.uploadBulkDIData(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.presentSuccessToast('', res.message);
          this.submitted = false;
          this.execlSheet = null;
          $('#bulk_add_instructions').modal('hide');
        }
      }
    ).catch((error) => {
    
        this.erroMesage = error.error.message;
      
      this.commonService.presentErrorToast('', error.error.message);
    }).finally(() => {
      this.commonService.dismissSpinner();

    })
  }

  addBulkDI() {
    this.submitted = false;
    this.erroMesage = '';
    this.excelSheetForm.reset();
    $('#bulk_add_instructions').modal('show');
  }

  saveDI() {
    if (this.diForm.invalid) {
      this.diFormSubmited = true;
      return
    }
    let promise: Promise<any> = null;
    let payload = null
    payload = new AddDeliveryInstruction(this.diForm.value)
    if (this.toBeEdited) {
      promise = this.salesPoService.updateDI(payload, this.toBeEdited._id)
    } else {
      promise = this.salesPoService.addDI(payload)
    }

    this.commonService.presentSpinner();
    promise.then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.presentSuccessToast(res.message, '');
        }
      }
    ).catch((error) => {
      this.commonService.presentErrorToast(error.error.message, '');
    }).finally(() => {
      this.getDIData();
      this.toBeEdited = null;
      this.diFormSubmited = false;
      this.diForm.reset();
      this.commonService.dismissSpinner();
      $('#add_instruction').modal('hide');

    })
  }

  openDeleteModal(id) {
    this.toBeDeletedId = id;
    $('#delete_modal').modal('show');
  }

  deleteDI() {
    this.commonService.presentSpinner();
    this.salesPoService.deleteDI(this.toBeDeletedId).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.presentSuccessToast(res.message, '');
        }
      }
    ).catch((error) => {
      this.commonService.presentErrorToast(error.error.message, '');
    }).finally(() => {
      this.commonService.dismissSpinner();
      $('#delete_modal').modal('hide');
      this.getDIData()
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
