<div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/invoices-sales']">Sales Invoices</a></li>
                <li class="breadcrumb-item active">Details</li>
              </ol>
            </div>

          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card table_page">
            <div class="card-body pt-3">
              <div class="invoice_box table-responsive">
                <!-- Header -->
                <h4 class="card-title mb-2">Invoice</h4>
                <table class="table table-bordered">
                  <thead class="thead-light">
                    <tr>
                      <th><strong>Name:</strong></th>
                      <th><strong>Invoice No:</strong></th>
                      <th><strong>Date:</strong></th>
                      <th><strong>Amount </strong></th>
                      <th><strong>GST</strong></th>
                      <th><strong>Total Amount</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title="Name">Bluth Company</td>
                      <td data-title="Invoice No">16835</td>
                      <td data-title="Date">05/12/2020</td>
                      <td data-title="Amount">₹ 1800.00</td>
                      <td data-title="GST">₹ 200.00</td>
                      <td data-title="Total Amount">₹ 2000.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive table_responsive_scroll">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="filter-all">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="page-title-right2">
                          <div class="select_box select_box_text"><svg xmlns="http://www.w3.org/2000/svg" width="33.123"
                            height="35.858" viewBox="0 0 33.123 35.858">
                            <g id="Group_86" data-name="Group 86" transform="translate(857.079 -756.758)">
                              <path id="Path_2227" data-name="Path 2227"
                                d="M95.806,83.085H76.251a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H68.6a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM72.427,85.835a1.467,1.467,0,1,1,1.467-1.467A1.468,1.468,0,0,1,72.427,85.835Z"
                                transform="translate(-921.196 676.574)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                              <path id="Path_2228" data-name="Path 2228"
                                d="M95.806,211.618H92.753a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H85.106a4.033,4.033,0,0,0,7.647,0h3.052a1.284,1.284,0,0,0,0-2.568Zm-6.876,2.751A1.467,1.467,0,1,1,90.4,212.9,1.468,1.468,0,0,1,88.93,214.368Z"
                                transform="translate(-921.196 561.785)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                              <path id="Path_2229" data-name="Path 2229"
                                d="M95.806,340.151H81.752a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H74.1a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM77.928,342.9a1.467,1.467,0,1,1,1.466-1.467A1.468,1.468,0,0,1,77.928,342.9Z"
                                transform="translate(-921.196 446.996)" fill="#505d69" stroke="#505d69"
                                stroke-width="0.3" />
                            </g>
                          </svg></div>
                          <div class="price_range">
                           <label>Amount</label> <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                         </div>
                          <!-- <div class="select_box ml-2">
                            <div class="input-group-reset">
                              <button type="button" class="btn btn-warning custom-select-sm">Reset</button>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="dataTables_filter">
                        <label>
                          <input type="search" class="form-control form-control-sm" placeholder="Search...">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <table class="table mb-0  ">
                  <thead class="thead-light">
                    <tr>
                      <th style="width:30px;">No.</th>
                      <th style="width:180px;">Item</th>
                      <th>Description </th>
                      <th style="width:90px;">Rate</th>
                      <th style="width:85px;">QTY</th>
                      <th style="width:90px;">GST %</th>
                      <th style="width:90px;">GST ₹</th>
                      <th style="width:85px;">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title="No.">1</td>
                      <td data-title="Item">Door</td>
                      <td data-title="Description">In publishing and graphic design, Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a document or a typeface without relying </td>
                      <td data-title="Rate">₹ 800</td>
                      <td data-title="QTY">5</td>
                      <td data-title="GST %">30%</td>
                      <td data-title="GST ₹">₹ 240</td>
                      <td data-title="Total Amount">₹ 1040</td>
                    </tr>
                    <tr>
                      <td data-title="No.">2</td>
                      <td data-title="Item">Door handle</td>
                      <td data-title="Description">In publishing and graphic design, Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a document or a typeface without relying </td>
                      <td data-title="Rate">₹ 800</td>
                      <td data-title="QTY">5</td>
                      <td data-title="GST %">30%</td>
                      <td data-title="GST ₹">₹ 240</td>
                      <td data-title="Total Amount">₹ 1040</td>
                    </tr>
                    <tr>
                      <td data-title="No.">3</td>
                      <td data-title="Item">Rear-view mirror</td>
                      <td data-title="Description">In publishing and graphic design, Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a document or a typeface without relying </td>
                      <td data-title="Rate">₹ 800</td>
                      <td data-title="QTY">5</td>
                      <td data-title="GST %">30%</td>
                      <td data-title="GST ₹">₹ 240</td>
                      <td data-title="Total Amount">₹ 1040</td>
                    </tr>
                    <tr>
                      <td data-title="No.">4</td>
                      <td data-title="Item">Brake light</td>
                      <td data-title="Description">In publishing and graphic design, Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a document or a typeface without relying </td>
                      <td data-title="Rate">₹ 800</td>
                      <td data-title="QTY">5</td>
                      <td data-title="GST %">30%</td>
                      <td data-title="GST ₹">₹ 240</td>
                      <td data-title="Total Amount">₹ 1040</td>
                    </tr>
                    <tr>
                      <td data-title="No.">5</td>
                      <td data-title="Item">Turn signal/ Blinker</td>
                      <td data-title="Description">In publishing and graphic design, Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a document or a typeface without relying </td>
                      <td data-title="Rate">₹ 800</td>
                      <td data-title="QTY">5</td>
                      <td data-title="GST %">30%</td>
                      <td data-title="GST ₹">₹ 240</td>
                      <td data-title="Total Amount">₹ 1040</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info">Showing 1 to 50 of 51 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers">
                      <ul class="pagination pagination-rounded">
                        <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-left"></i></a>
                        </li>
                        <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                        </li>
                        <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-right"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  <!-- page-content -->
