import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
declare var $: any;
@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  @Input() comapnydata: {}
  AddCompanyForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private companyservice: CompanyService,
    private commonSerivce: CommonService
  ) {

  }

  ngOnInit(): void {
    this.AddCompanyForm = this.formBuilder.group({
      companyimage: ['', ''],
      name: ['', Validators.required],
      registrationnumber: ['', Validators.required],
      pannumber: ['', Validators.required],
      registredaddress: ['', Validators.required],
    })

  }

  get f() { return this.AddCompanyForm.controls; }

  fileToUpload: File = null;
  imageUrl: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.currentFile = this.fileToUpload;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Formats allowed : jpg, jpeg, png";
      this.AddCompanyForm.controls['companyimage'].setValue('');
      return false;
    }
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  Deletemodal: boolean;
  displaymsg: any;
  AddCompany() {
    this.submitted = true;


    // stop here if form is invalid
    if (this.AddCompanyForm.invalid) {
      return;
    }

    this.commonSerivce.presentSpinner();
    var frmData = new FormData();
    frmData.append("logoImage", this.fileToUpload);
    frmData.append("companyName", this.AddCompanyForm.value.name);
    frmData.append("registrationNumber", this.AddCompanyForm.value.registrationnumber);
    frmData.append("panNumber", this.AddCompanyForm.value.pannumber);
    frmData.append("registrationOfficeAddress", this.AddCompanyForm.value.registredaddress);
    this.companyservice.AddCompany(frmData).then(
      (res) => {

        if (res) {
          if (res.code === 200) {

            this.AddCompanyForm.reset();
            $('.add_detail').modal('hide');
            this.displaymsg = res.message;
            this.fileToUpload = null;
            this.imageUrl = ''
            this.submitted = false;
            this.commonSerivce.dismissSpinner();

            this.Deletemodal = true;
            // this.commonSerivce.presentSuccessToast('', res.message);

          } else {
            this.commonSerivce.dismissSpinner();
            this.displaymsg = res.message;
            this.Deletemodal = true;
          }
        }
      }
    );

  }
  OKButton() {
    this.Deletemodal = false;
  }
  GetChildData(data) {
    if (data) {
      this.Deletemodal = false;
    }
  }
  closecompanymodal() {
    this.AddCompanyForm.reset();
    this.imageErrMsg = '';
    $('.add_detail').modal('hide');
    this.submitted = false;
  }
}
