import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FormsGroupService {
	excelSheetForm: FormGroup;
	excelInvoiceSheetForm: FormGroup;
	GSTexcelSheetForm: FormGroup;
	RaiseInvoiceForm: FormGroup;
	PoDetailForm: FormGroup;
	AddItemForm: FormGroup;
	addAdminItemForm: FormGroup;
	editAdminItemForm: FormGroup;
	invoceEditForm: FormGroup;
	ProfileSettingsForm: FormGroup;
	addHSNForm: FormGroup;
	editHSNForm: FormGroup;
	constructor(private formBuilder: FormBuilder) { }

	getExcelSheetForm() {
		return this.excelSheetForm = this.formBuilder.group({
			"excelSheet": ['', Validators.required],
		})
	}
	getInvoiceExcel() {
		return this.excelInvoiceSheetForm = this.formBuilder.group({
			"excelSheet": ['', Validators.required],
		})
	}

	GstExcelSheetForm() {
		return this.GSTexcelSheetForm = this.formBuilder.group({
			"excelSheet": ['', Validators.required],
		})
	}

	getPoDetailForm() {
		return this.PoDetailForm = this.formBuilder.group({
			"PO": ['', Validators.required],
			"Freight": ['', Validators.required],
			"Plant": ['', Validators.required],
			"PartyCode": ['', Validators.required],
			"PartyName": ['', Validators.required],
			"FormDate": ['', Validators.required],
			"CreatedBy": ['', Validators.required],
			"AuthorisedBy": ['', Validators.required],
		})
	}

	RaiseInvoice() {
		return this.RaiseInvoiceForm = this.formBuilder.group({
			"IRN": ['', Validators.required],
			"invoicenumber": ['', Validators.required],
			"ewaybill": ['', Validators.required],
			"vehiclenumber": ['', Validators.required],
			"tcsonsale": ['', ''],
			"invoicedate": ['', Validators.required],
			"amount": ['', ''],
			"totalammount": ['', ''],
			"selectall": ['', ''],
			"itemDetails": new FormArray([])

		})
	}

	AddItem() {
		return this.AddItemForm = this.formBuilder.group({
			"ItemCode": ['', Validators.required],
			"ItemName": ['', Validators.required],
			"Description": ['', Validators.required],
			"Unit": ['', Validators.required],
			"hsnnumber": ['', Validators.required],
			"Rate": ['', Validators.required],
			"itemAmount": ['', Validators.required],
			"QTY": ['', ''],
			"Order": ['', Validators.required],
			"CGSTRate": ['', ''],
			"CGSTammount": ['', ''],
			"SGSTrate": ['', Validators.required],
			"SGSTammount": ['', Validators.required],
			"IGSTrate": ['', ''],
			"IGSTammount": ['', ''],
			"TotalAmount": ['', Validators.required],
		})
	}


	addItemsAdminSection() {
		return this.addAdminItemForm = this.formBuilder.group({
			"itemCode": ['', Validators.required],
			"itemName": ['', Validators.required],
			"description": ['', Validators.required],
			"unit": ['', Validators.required],
			"HSNnumber": ['', Validators.required],
		})
	}

	editItemsAdminSection() {
		return this.editAdminItemForm = this.formBuilder.group({
			"itemCode": [''],
			"itemName": [''],
			"description": [''],
			"unit": [''],
			"HSNnumber": [''],
		})
	}


	getinvoceEditForm() {
		return this.invoceEditForm = this.formBuilder.group({
			"inr": [''],
			"invoice": [''],
			"eWayBill": [''],
			"vehicle": [''],
			"createdDate": [''],
			"gst": [''],
			"tcsOnSales": [''],
		});
	}

	profileSetting() {
		return this.ProfileSettingsForm = this.formBuilder.group({
			"profileimg": ['', ''],
			"name": ['', Validators.required],
			"emailid": ['', Validators.required],

		})
	}

	AddHSN() {
		return this.addHSNForm = this.formBuilder.group({
			"hsnnumber": ['', Validators.required],
			"GST": ['', Validators.required],
		})
	}

	EditHSN() {
		return this.editHSNForm = this.formBuilder.group({
			"id": ['', ''],
			"hsnnumber": ['', Validators.required],
			"GST": ['', Validators.required],
		})
	}

}
