import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

	menus: Array<any> = [
		// { route: '/admin/dashboard', icon: ' ri-file-list-3-line', name: 'Dashboard' },
		{ route: '/admin/company', iconnone: 'img-none', icon: ' ri-building-4-line', name: 'Company' },
		{ route: '/admin/administrators', iconnone: 'img-none', icon: ' ri-user-add-line align-middle', name: 'Administrators' },

    // Purchase
		{ route: '/admin/purchase-order', icon: 'Purchaseorder', name: 'Purchase',
    submenu: [{ route: '/admin/purchase-order', subname: 'Purchase Order' },
    { route: '/admin/invoices', subname: 'Purchase Invoices' },
    { route: '/admin/vendor', subname: 'Vendors' }]
    },

    // Sales
		{ route: '/admin/sales-po', icon: 'Items', name: 'Sales',
    submenu: [{ route: '/admin/sales-po', subname: 'Sales PO' },
    { route: '/admin/delivery-instructions', subname: 'Delivery Instructions' },
    { route: '/admin/invoices-sales', subname: 'Sales Invoices' },
    { route: '/admin/customers', subname: 'Customers' },
	{ route: '/admin/inventory', subname: 'Inventory' }
]
	
    },

    // Data Management
		{ route: '/admin/plants', icon: 'Items', name: 'Data Management',
    submenu: [
      { route: '/admin/plants', subname: 'Plants' },
      { route: '/admin/hsncode', subname: 'HSN Code' },
      { route: '/admin/purchase-items', subname: 'Purchase Items' },
      { route: '/admin/products', subname: 'Products' },
      { route: '/admin/item-groups', subname: 'Groups' },
      { route: '/admin/process-stages', subname: 'Process Stages' }
      
    ]
    },

		{ route: '/admin/gstr-2a', icon: 'tax1', iconsmall: 'iconsmall', name: 'GSTR 2A' },
		{ route: '/admin/users', icon: 'Users', name: 'Users' },


		// { route: '/admin/invoices-sales', icon: 'InvoiceSales', name: 'Invoices (Sales)' },
		// { route: '/admin/gst', icon: 'GST', name: 'GST' },

		{ route: '/admin/gatekeeper', iconnone: 'img-none', icon: 'ri-user-line', name: 'Gatekeeper' },
		{ route: '/admin/storekeeper', iconnone: 'img-none', icon: 'ri-user-line', name: 'Storekeeper' },


	];
	ChangePasswordForm: FormGroup;
	submitted = false;
	selectedMenu: any;
	Admindata: any;
	sidePanelEnabled: boolean = false;
	password: boolean;
	confirmpassword: boolean;
	userlogindata: any = {};
	menutoshow: any = [];
	displaymsg: any;
	commonmodal: boolean;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private storage: StorageService,
		private authenticate: AuthenticationService,
	) {

		this.selectedMenu = this.menus[0];
		const savedData = this.storage.getSavedData();
		if (savedData["user_details"] != null) {

			this.userlogindata = savedData["user_details"];

			this.menus.forEach((menus) => {
				switch (this.userlogindata.role) {
					case 'Admin':
						if (menus.route == '/admin/users' || menus.route == '/admin/invoices' || menus.route == '/admin/invoices-sales' || menus.route == '/admin/gst' || menus.route == '/admin/plants' || menus.route == '/admin/vendor' || menus.route == '/admin/purchase-order' || menus.route == '/admin/purchase-items' || menus.route == '/admin/hsncode' || menus.route == '/admin/gstr-2a' || menus.route == '/admin/customers' || menus.route == '/admin/items' || menus.route == '/admin/sales-po' || menus.route == '/admin/item-groups' || menus.route == '/admin/process-stages' || menus.route == '/admin/delivery-instructions') {
							this.menutoshow.push(menus)
						}
						break;

					case 'Superadmin':
						if (menus.route == '/admin/administrators' || menus.route == '/admin/company') {
							this.menutoshow.push(menus)
						}
						break;

					case 'Vendor':
						if (menus.route == '/admin/invoices' || menus.route == '/admin/purchase-order') {
							this.menutoshow.push(menus)
						}
						break;
					case 'Finance':
						if (menus.route == '/admin/invoices' || menus.route == '/admin/invoices-sales' || menus.route == '/admin/gstr-2a' || menus.route == '/admin/purchase-order') {
							this.menutoshow.push(menus)
						}
						break;
					case 'Gatekeeper':
						if (menus.route == '/admin/gatekeeper') {
							this.menutoshow.push(menus)
						}
						break;
					case 'Storekeeper':
						if (menus.route == '/admin/storekeeper') {
							this.menutoshow.push(menus)
						}
						break;

					// default:
					//   break;
				}
			})
		} else {
			this.router.navigate(['/']);
		}


	}

	ngOnInit(): void {

  $(document).ready(function () {
    $("ul#side-menu>li").removeClass("open active");
    $("ul#side-menu>li").click(function(){
      $(this).addClass("open active").siblings().removeClass("open active");
    });
  });


		this.ChangePasswordForm = this.formBuilder.group({
			password: ['', Validators.required],
			confirmpassword: ['', Validators.required],
		});
	}
	get f() { return this.ChangePasswordForm.controls; }
	showPassword() {
		this.password = !this.password;
	}
	showConfirmPassword() {
		this.confirmpassword = !this.confirmpassword;
	}

	navigateTo(menu: any) {
		this.selectedMenu = menu
		this.router.navigate([menu.route]);
	}
	enableSidePanel() {
		this.sidePanelEnabled = !this.sidePanelEnabled;
	}

	goTo(route: string) {
		if (route) {
			this.router.navigate([route]);
		}
	}

	ChangePassword() {

		this.submitted = true;
		// stop here if form is invalid
		if (this.ChangePasswordForm.invalid) {
			return;
		}

		let postdata = {
			newPassword: this.ChangePasswordForm.value.password,
			confirmPassword: this.ChangePasswordForm.value.confirmpassword
		}
		this.commonService.presentSpinner();
		this.authenticate.ChangePassword(postdata, this.userlogindata.adminId).then(
			(res) => {
				if (res) {

					if (res.code == 200) {
						this.commonService.dismissSpinner();
						$('.change_password').modal('hide');
						this.ChangePasswordForm.reset();
						this.submitted = false;
						this.displaymsg = res.message;
						this.commonmodal = true;
					} else if (res.code == 422) {
						this.commonService.dismissSpinner();
						this.displaymsg = res.message;
						this.commonmodal = true;
					}
				} else {

				}

			}
		)
	}


	logout() {

		this.authenticate.Logout().then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						localStorage.clear();
						this.router.navigate(['/']);

					} else if (res.code == 422) {

					}
				} else {

				}

			}
		)

	}
	GetChildData(data) {
		if (data) {
			this.commonmodal = false;
		}
	}
}
