import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
declare var $: any;
@Component({
  selector: 'app-adminresetpassword',
  templateUrl: './adminresetpassword.component.html',
  styleUrls: ['./adminresetpassword.component.scss']
})
export class AdminresetpasswordComponent implements OnInit {
  @Input() adminid: any
  confirmforgotpassword: boolean;
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  resetpasswordForm: FormGroup;
  Deletemodal: boolean;
  displaymsg: any;
  submitted = false;

  commonmodal: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private authenticate: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.resetpasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    }
      ,
      {
        validator: MustMatch('password', 'confirmpassword')
      }
    )
  }
  get f() { return this.resetpasswordForm.controls; }
  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  ResetPassword(adminid) {

    this.submitted = true;
    // stop here if form is invalid
    if (this.resetpasswordForm.invalid) {
      return;
    }
    let postdata = {

      newPassword: this.resetpasswordForm.value.password,
      confirmPassword: this.resetpasswordForm.value.confirmpassword
    }
    this.commonService.presentSpinner();
    this.authenticate.ChangePassword(postdata, adminid).then(
      (res) => {
        if (res) {

          if (res.code == 200) {
            this.commonService.dismissSpinner();
            $('.forgot_password').modal('hide');
            this.resetpasswordForm.reset();
            this.submitted = false;
            this.displaymsg = res.message;
            this.commonmodal = true;
          } else if (res.code == 422) {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.commonmodal = true;
          }
        } else {

        }

      }
    )
  }
  closeResetpasswordModal() {
    $('.forgot_password').modal('hide');
    this.resetpasswordForm.reset();
    this.submitted = false;
  }
  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }
}
