import { Injectable } from '@angular/core';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class StoreKeeperService {

  constructor(
    private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService
  ) {

  }

  async getVendorList(companyid) {

    return this.httpWrapperService.get(endpoint.gatStoreKeepervendor(companyid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.vendorNameList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
          // this.commonService.handleError(error);
        }
      );
  }

  async getVendorInvoiceList(vendorid) {

    return this.httpWrapperService.get(endpoint.gatStoreKeepervendorinvoice(vendorid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.vendorNameList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
          // this.commonService.handleError(error);
        }
      );
  }


  async getstoreKeeperInvoiceData(invoiceid) {

    return this.httpWrapperService.get(endpoint.getstoreKeeperInvoiceData(invoiceid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceData) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }


  async gatstorekeeperapproveinvoice(invoiceid, postdata) {

    return this.httpWrapperService.put(endpoint.gatstorekeeperapproveinvoice(invoiceid), postdata).map(
      (res) => {
        if (res) {
          // if (res.code === 200) {
          //   if (res.invoiceData) {
          //   }
          // } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  gatStorekeeperuploadImage(invoice_id, formdata) {

    return this.httpWrapperService.post(endpoint.gatStorekeeperuploadImage(invoice_id), formdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }


  async rejectInvoice(invoiceid) {

    return this.httpWrapperService.get(endpoint.rejectInvoice(invoiceid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.invoiceData) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  editQuantity(invoice_id, payload: any) {

    return this.httpWrapperService.put(endpoint.editQuantuty(invoice_id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  invoicesList(){

    return this.httpWrapperService.get(endpoint.storekeeperinvoiceList).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

}
