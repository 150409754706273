import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { searchSalesPOData } from 'src/app/Services/DataManagement/products/product.model';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { SalesPO } from 'src/app/Services/SalesPO/sales-po.model';
import { SalesPoService } from 'src/app/Services/SalesPO/sales-po.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';

declare var $: any;

@Component({
  selector: 'app-sales-po-detail',
  templateUrl: './sales-po-detail.component.html',
  styleUrls: ['./sales-po-detail.component.scss']
})
export class SalesPoDetailComponent implements OnInit {
  salesPOId: any;
  getSalesPODetail: any;
  editSalePOForm: FormGroup;
  unitlist: Array<[]>;
  customer: Array<[]>;
  editFormSubmitted = false;
  page: number = 1;
  limit: number = 10;
  total: number;
  salesOPProductList: Array<[]>;
  entries: Entries;
  apiHitDone: boolean;
  constructor(
    private _Activatedroute: ActivatedRoute,
    private commonService: CommonService,
    private productsService: ProductsService,
    private companyservice: CompanyService,
    private productServices: ProductsService,
    private salesPoService: SalesPoService,

  ) {
    this._Activatedroute.params.subscribe(params => {
      this.salesPOId = params['id'];
    });

    this.editSalePOForm = new FormGroup({
      plant: new FormControl('', Validators.compose([Validators.required])),
      customerId: new FormControl('', Validators.compose([Validators.required])),
      customerCode: new FormControl('', Validators.compose([Validators.required])),
      poDate: new FormControl('', Validators.compose([Validators.required])),
      amount: new FormControl('', Validators.compose([Validators.required])),
      gst: new FormControl('', Validators.compose([Validators.required])),
      tatalAmount: new FormControl('', Validators.compose([Validators.required])),
      type: new FormControl('', Validators.compose([Validators.required])),
      validUpToDate: new FormControl('', Validators.compose([Validators.required])),
    })
  }

  get editSalePO() { return this.editSalePOForm.controls; }

  ngOnInit(): void {
    this.getSalesPO();
    this.GetUnitList();
    this.getCustomerList();
    this.getSalesPOProductList();
  }

  toBeEditedData: any;
  openNewProductModal(itemDate?: any) {
    this.toBeEditedData = {
      getSalesPODetail: this.getSalesPODetail,
      itemDate: itemDate || ''

    }
    $("#addItem_detail").modal('show');
  }



  getSalesPO() {
    this.commonService.presentSpinner();
    let searchSalespoData = new searchSalesPOData({
      page: 1,
      limit: 1000,
      search: '',
      startDate: '',
      endDate: '',
      startRange: 0,
      endRange: 0,
      plantId: '',
      customerId: '',
    })
    this.productsService.salesPOList(searchSalespoData).then(
      (res: any) => {
        if (res['body']['code'] == 200 && res['body']['salesPOList']['data'].length) {
          this.commonService.dismissSpinner();
          let salesPOList = res['body']['salesPOList']['data'];
          let detail = salesPOList.find((x) => x._id === this.salesPOId)
          if (detail) {
            this.getSalesPODetail = detail;
          } else {
            this.getSalesPODetail = {}
          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    )
  }

  GetUnitList() {
    this.commonService.presentSpinner();
    this.companyservice.getUnitList(1).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {

            this.unitlist = res.unitList;

          }
        }
      }
    )
  }

  getCustomerList() {
    this.productServices.getCustomerList().then(
      (res: any) => {
        if (res.code == 200 && res.customerlis.length) {
          this.customer = res.customerlis;

        }
      }
    ).finally(() => { })
  }

  getCustomerCode() {
    let c_code: any = this.customer.find((x: any) => x._id === this.editSalePOForm.value.customerId)
    this.editSalePOForm.controls['customerCode'].setValue(c_code ? c_code.CustomerCode : '');
  }


  getSalesPOProductList(page?:number) {
    this.page = page || 1
    this.apiHitDone = true;
    this.salesPoService.getSalesPOProduct({ page: this.page.toString(), limit: this.limit.toString() },this.salesPOId).then(
      (res: any) => {
        if (res['body'].code == 200 && res['body']['salesOPProductList']['data'].length) {
          this.salesOPProductList = res['body']['salesOPProductList']['data'];
          this.apiHitDone = false;
          this.total = res['body']['salesOPProductList'].total;
          this.entries = this.commonService.calculateEntries(this.salesOPProductList, this.page, this.limit, this.total);
        } else {
          this.salesOPProductList = [];
          this.apiHitDone = true
          this.total = 0;
          this.entries = this.commonService.calculateEntries(this.salesOPProductList, this.page, this.limit, this.total);
        }
      }
    ).finally(() => {
      // this.apiHitDone = false;
    })
  }

  openEditModal() {
    let c_code: any = this.customer.find((x: any) => x._id === this.getSalesPODetail.customerId._id)
    this.editSalePOForm.patchValue({
      plant: this.getSalesPODetail.plant._id,
      customerId: this.getSalesPODetail.customerId._id,
      customerCode: c_code ? c_code.CustomerCode : '',
      poDate: formatDate(this.getSalesPODetail.poDate, 'yyyy-MM-dd', 'en-US'),
      amount: this.getSalesPODetail.amount,
      gst: this.getSalesPODetail.gst,
      tatalAmount: this.getSalesPODetail.tatalAmount,
      type: this.getSalesPODetail.type,
      validUpToDate: formatDate(this.getSalesPODetail.validUpToDate, 'yyyy-MM-dd', 'en-US'),
    })
    $("#sales_order_edit").modal('show');
  }

  updateSalesPO() {
    this.editFormSubmitted = false;
    if (this.editSalePOForm.invalid) {
      this.editFormSubmitted = true;
      return
    }

    let salepoData = new SalesPO(this.editSalePOForm.value);
    this.commonService.presentSpinner();
    this.salesPoService.editSalesPO(salepoData, this.getSalesPODetail._id).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.presentSuccessToast(res.message, '')
        }
      }
    ).finally(() => {
      $("#sales_order_edit").modal('hide');
      this.commonService.dismissSpinner();
      this.getSalesPO();
    })
  }

  toBeDeleted:any;
  deleteModal(soProductId){
    this.toBeDeleted = soProductId
    $('#delete_modal').modal('show');
  }

  deleteSaleProductItem(){
    this.commonService.presentSpinner();
    this.salesPoService.deleteSalesPoProduct(this.toBeDeleted).then(
      (res:any)=>{
        if(res.code == 200){
          this.commonService.presentSuccessToast(res.message, '');
        }
      }
    ).finally(()=>{
      $("#delete_modal").modal('hide');
      this.commonService.dismissSpinner();
      this.getSalesPO();
      this.getSalesPOProductList();
    })
  }

  GetChildData(e) {
    this.getSalesPO();
    this.getSalesPOProductList();
    $("#addItem_detail").modal('hide');
  }

}
