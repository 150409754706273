import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BOM } from 'src/app/Services/DataManagement/products/product.model';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { ItemService } from 'src/app/Services/item/item.service';
declare var $: any;
@Component({
  selector: 'app-sales-item-detail',
  templateUrl: './sales-item-detail.component.html',
  styleUrls: ['./sales-item-detail.component.scss']
})
export class SalesItemDetailComponent implements OnInit {
  customerForm: FormGroup;
  billOfMatrialForm: FormGroup;
  password: boolean;
  confirmpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  HSNCode: any = [];
  itemCode: any;
  item_id: any;
  selectedPersonId: any;
  itemSelect: boolean = false;
  item: any = []
  processStage: Array<[]>;
  selectedCustomer: any;
  customer: any = [];
  productId: any;
  productData: any;
  searchOnBillOfMaterial: string;
  toBeEdited: {
    _id: '',
    name: '',
    code: '',
    description: '',
    group: '',
    processStage: '',
    quantity: '',
    unit: '',
    hsn: '',
  };
  customerProductIdToBeDeleted: any;
  bomToBeDeleted: any;
  isSubmitted: boolean;
  isBOMSubmitted: boolean;
  constructor(
    private itemService: ItemService,
    private activeroute: ActivatedRoute,
    private commonservices: CommonService,
    private productServices: ProductsService,
    private formBilder: FormBuilder
  ) {
    this.activeroute.queryParams.subscribe(param => {
      this.productId = param.productId || '';
    })
    this.getCustomerList();
  }

  ngOnInit(): void {
    this.customerForm = this.formBilder.group({
      Rows: this.formBilder.array([]),
    });
    this.billOfMatrialForm = this.formBilder.group({
      BomRows: this.formBilder.array([]),
    });
    this.getProductDetailById();

    this.getHSNCode();
    this.getBOMProcesStage();
    this.getItem();
  }

  get formArr() {
    return this.customerForm.get("Rows") as FormArray;
  }
  get bomFormArr() {
    return this.billOfMatrialForm.get("BomRows") as FormArray;
  }

  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

  initRows(): FormGroup {
    return this.formBilder.group({
      customerCodeId: [""],
      customerId: ["", Validators.required],
      customerItemCode: ["", Validators.required],
      customerItemName: ["", Validators.required],
      isSelected: false,
    });
  }
  bomInitRows(): FormGroup {
    return this.formBilder.group({
      billOfMaterialId: [""],
      processStage: ["", Validators.required],
      productItem: ["", Validators.required],
      unit: ["", Validators.required],
      quantity: [{ disabled: true, value: "--" }, Validators.required],
      grossWeight: [{ disabled: true, value: "--" },],
      netWeight: [{ disabled: true, value: "--" },],
      scrapWeight: [{ disabled: true, value: "--" },],
      isSelected: false,
    });
  }

  getUnit(event, index) {
    let bomFormArray = this.billOfMatrialForm.get('BomRows') as FormArray;
    switch (event) {
      case 'NOS':
        bomFormArray.at(index).get('quantity').enable();
        bomFormArray.at(index).get('grossWeight').disable();
        bomFormArray.at(index).get('grossWeight').setValue('')
        bomFormArray.at(index).get('netWeight').disable();
        bomFormArray.at(index).get('netWeight').setValue('');
        bomFormArray.at(index).get('scrapWeight').disable();
        bomFormArray.at(index).get('scrapWeight').setValue('');
        break;

      case 'KGS':
        bomFormArray.at(index).get('quantity').enable();
        bomFormArray.at(index).get('grossWeight').enable();
        bomFormArray.at(index).get('netWeight').enable();
        bomFormArray.at(index).get('scrapWeight').enable();
        break;

      default:
        break;
    }
  }

  async addNewRowForCustomerproduct() {
    await this.formArr.push(this.initRows());
  }
  async addNewRowForBOM() {
    await this.bomFormArr.push(this.bomInitRows());
  }

  deleteRow(index: number, _id: any) {
    if (_id) {
      this.customerProductIdToBeDeleted = _id;
      $('#delete_modal').modal('show');
    } else {
      this.formArr.removeAt(index);
    }
  }

  deleteBOMRow(index: number, _id: any) {
    if (_id) {
      // this.customerSelect = true;
      this.bomToBeDeleted = _id;
      $('#delete_BOM_modal').modal('show');
    } else {
      this.bomFormArr.removeAt(index);
    }
  }

  getHSNCode() {
    this.itemService.getHSNCodeList().then(
      (res) => {
        if (res.code == 200) {
          this.HSNCode = res.hsnList;
        }
      }
    )
  }

  getCustomerList() {
    this.productServices.getCustomerList().then(
      (res: any) => {
        if (res.code == 200 && res.customerlis.length) {
          this.customer = res.customerlis;
        }
      }
    ).finally(() => { })
  }

  getBOMProcesStage() {
    this.productServices.getBOMProcesStageList().then(
      (res: any) => {
        if (res.code == 200 && res.processStagelist.length) {
          this.processStage = res.processStagelist;
        }
      }
    )
  }
  getItem() {
    this.productServices.getItemList().then(
      (res: any) => {
        if (res.code == 200 && res.list.length) {
          this.item = res.list;
          this.item.forEach(element => {
            element.name = element.name +' - '+ element.code;
          });
        }
      }
    )
  }

  getProductDetailById() {
    this.commonservices.presentSpinner();
    this.productServices.getProductDetailById(this.productId, this.searchOnBillOfMaterial).then(
      (res: any) => {
        if (res['body']['code'] == 200) {
          this.productData = res['body']['productData'];
          this.productData.customerCodeList.forEach(element => {
            element['isSelected'] = true;
          });
          this.productData.billOfMaterialList.forEach(element => {
            element['isSelected'] = true;
          });
          this.toBeEdited = {
            _id: this.productData._id,
            name: this.productData.name,
            code: this.productData.code,
            description: this.productData.description,
            group: this.productData.group,
            processStage: this.productData.processStage,
            quantity: this.productData.quantity,
            unit: this.productData.unit,
            hsn: this.productData.hsn,
          }
        }
      }
    ).catch((error) => {
      this.commonservices.presentErrorToast(error.error.message, '')
    }).finally(() => {
      this.commonservices.dismissSpinner();

      this.clearFormArray();
      this.clearBOMFormArray();
      this.patchCustomerFormFormValue();
      this.patchBillOfMatrialFormValue();
      this.addNewRowForCustomerproduct();
      this.addNewRowForBOM();
    })
  }

  patchCustomerFormFormValue() {
    let formArray = this.customerForm.get("Rows") as FormArray;
    this.productData.customerCodeList.forEach(e => {
      formArray.push(
        this.formBilder.group({
          customerCodeId: e._id,
          customerId: { disabled: true, value: e.customerId._id },
          customerItemCode: { disabled: true, value: e.customerItemCode },
          customerItemName: { disabled: true, value: e.customerItemName },
          isSelected: true,
        })
      )
    })
  }
  patchBillOfMatrialFormValue() {
    let formArray = this.billOfMatrialForm.get("BomRows") as FormArray;
    if (this.productData.billOfMaterialList.length) {
      this.productData.billOfMaterialList.forEach(e => {
        formArray.push(
          this.formBilder.group({
            billOfMaterialId: e._id,
            processStage: { disabled: true, value: e.processStage._id },
            productItem: { disabled: true, value: e.productItem ? e.productItem._id:'' },
            unit: { disabled: true, value: e.unit },
            quantity: { disabled: true, value: e.quantity },
            grossWeight: { disabled: true, value: e.grossWeight },
            netWeight: { disabled: true, value: e.netWeight },
            scrapWeight: { disabled: true, value: e.scrapWeight },
            // grossWeight: [{ value: e.grossWeight ? e.grossWeight : '-', disabled: e.unit == 'NOS' ? true : false }],
            // netWeight: [{ value: e.netWeight ? e.netWeight : '-', disabled: e.unit == 'NOS' ? true : false }],
            // scrapWeight: [{ value: e.scrapWeight ? e.scrapWeight : '-', disabled: e.unit == 'NOS' ? true : false }],
            isSelected: true,
          })
        )
      })
    } else {
      this.productData.billOfMaterialList = [];
      let formArray = this.billOfMatrialForm.get("BomRows") as FormArray;
      formArray.reset();
      formArray.clear();
    }

  }

  editCustomer(type, itemrow, index) {
    if (type == 'edit') {
      let formArray = this.customerForm.get('Rows') as FormArray;
      formArray.at(index).get('customerId').enable();
      formArray.at(index).get('customerItemCode').enable();
      formArray.at(index).get('customerItemName').enable();
      itemrow.controls.isSelected.value = false;

    } else {
      let formArray = this.customerForm.get('Rows') as FormArray;
      let ProductCustomerCode = [];
      formArray.at(index).get('customerId').disable();
      formArray.at(index).get('customerItemCode').disable();
      formArray.at(index).get('customerItemName').disable();
      itemrow.controls.isSelected.value = true;
      ProductCustomerCode = [{
        customerCodeId: itemrow.controls.customerCodeId.value,
        customerId: itemrow.controls.customerId.value,
        customerItemCode: itemrow.controls.customerItemCode.value,
        customerItemName: itemrow.controls.customerItemName.value
      }]
      this.addProduCtcustomer(ProductCustomerCode);
    }
  }

  editItem(type, itemrow, index) {
    let formArray = this.billOfMatrialForm.get('BomRows') as FormArray;
    if (type == 'edit') {
      if (itemrow.controls.unit.value == 'NOS') {
        formArray.at(index).get('processStage').enable();
        formArray.at(index).get('productItem').enable();
        formArray.at(index).get('unit').enable();
        formArray.at(index).get('quantity').enable();
        formArray.at(index).get('grossWeight').disable();
        formArray.at(index).get('netWeight').disable();
        formArray.at(index).get('scrapWeight').disable();
      } else {
        formArray.at(index).get('processStage').enable();
        formArray.at(index).get('productItem').enable();
        formArray.at(index).get('unit').enable();
        formArray.at(index).get('quantity').enable();
        formArray.at(index).get('grossWeight').enable();
        formArray.at(index).get('netWeight').enable();
        formArray.at(index).get('scrapWeight').enable();
      }
      itemrow.controls.isSelected.value = false;
    } else {
      let editBillOfMaterial = [];
      formArray.at(index).get('processStage').disable();
      formArray.at(index).get('productItem').disable();
      formArray.at(index).get('unit').disable();
      formArray.at(index).get('quantity').disable();
      formArray.at(index).get('grossWeight').disable();
      formArray.at(index).get('netWeight').disable();
      formArray.at(index).get('scrapWeight').disable();
      itemrow.controls.isSelected.value = true;

      editBillOfMaterial = [{
        billOfMaterialId: itemrow.controls.billOfMaterialId.value,
        processStage: itemrow.controls.processStage.value,
        productItem: itemrow.controls.productItem.value,
        unit: itemrow.controls.unit.value,
        quantity: itemrow.controls.quantity.value,
        grossWeight: itemrow.controls.grossWeight.value,
        netWeight: itemrow.controls.netWeight.value,
        scrapWeight: itemrow.controls.scrapWeight.value,
      }]
      let editBomData = new BOM(this.productId, editBillOfMaterial);
      this.saveBOM(editBomData)
    }
  }

  saveBOM(editBomData?: any) {
    let payload = {};
    let ProductBillOfMaterial = [];
    if (editBomData && editBomData.ProductBillOfMaterial.length) {
      payload = editBomData
    } else {
      this.isBOMSubmitted = false
      if (this.billOfMatrialForm.invalid) {
        this.isBOMSubmitted = true;
        return
      }
      this.commonservices.presentSpinner();
      this.billOfMatrialForm.value.BomRows.forEach((e: any, index) => {
        delete e.isSelected;
        if (e.billOfMaterialId === '') { // Delete unused key from obj these key only used for DOM transformation
          delete e.billOfMaterialId;
          ProductBillOfMaterial.push(e)
        }
      })
      payload = new BOM(this.productId, ProductBillOfMaterial);

    }
    this.productServices.addBOM(payload).then(
      (res: any) => {

      }
    ).catch((error) => {
      this.commonservices.presentErrorToast(error.error.message, '')
    }).finally(() => {
      let formArray = this.billOfMatrialForm.get('BomRows') as FormArray;
      formArray.clear();
      this.isBOMSubmitted = false;
      this.customerForm.reset();
      this.commonservices.dismissSpinner()
      this.getProductDetailById()
    })
  }


  addProduCtcustomer(ProductCustomerCode?: Array<[]>) {
    let payload = {};
    if (ProductCustomerCode && ProductCustomerCode.length) {
      payload = {
        productId: this.productId,
        ProductCustomerCode: ProductCustomerCode
      }
    } else {
      this.isSubmitted = false;
      if (this.customerForm.invalid) {
        this.isSubmitted = true;
        return
      }
      let ProductCustomerCode = []
      this.customerForm.value.Rows.map((e) => {
        delete e.isSelected;
        if (e.customerCodeId == '') {
          delete e.customerCodeId;
          ProductCustomerCode.push(e)
        }
      })
      payload = {
        productId: this.productId,
        ProductCustomerCode: ProductCustomerCode
      }

    }
    this.commonservices.presentSpinner();
    this.productServices.addproductCustomer(payload).then(
      (res: any) => {
      }
    ).catch((error) => {
    }).finally(() => {
      this.customerForm.reset();
      this.clearFormArray();
      this.isSubmitted = false;
      this.commonservices.dismissSpinner();
      this.getProductDetailById()
    })
  }

  opemModals(val, data) {
    if (val == "delete") {
      $('#delete-item').modal('show');
      this.item_id = data._id;
      this.itemCode = data.itemCode
    }
  }

  deleteCustomerProduct() {
    this.commonservices.presentSpinner();
    this.productServices.deleteCustomerProduct(this.customerProductIdToBeDeleted).then(
      (res: any) => {
      }
    ).finally(() => {
      this.clearFormArray();
      this.commonservices.dismissSpinner();
      this.getProductDetailById();
      $('#delete_modal').modal('hide');
    })
  }

  deleteBOM() {
    this.commonservices.presentSpinner();
    this.productServices.deleteBOM(this.bomToBeDeleted).then(
      (res: any) => {
      }
    ).finally(() => {
      this.clearBOMFormArray();
      this.commonservices.dismissSpinner();
      this.getProductDetailById();
      $('#delete_BOM_modal').modal('hide');
    })
  }

  clearFormArray() {
    let formArray = this.customerForm.get('Rows') as FormArray;
    formArray.clear();
  }
  clearBOMFormArray() {
    let formArray = this.billOfMatrialForm.get('BomRows') as FormArray;
    formArray.clear();
  }
  getResponseFromChild(evt) {
    $('#add_product').modal('hide');
    this.getProductDetailById();
  }
  editProduct() {
    $('#add_product').modal('show');
  }
}
