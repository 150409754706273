import { Injectable } from '@angular/core';
import { HttpInputData, HttpWrapperService } from '../../core/http/http-wrapper/http-wrapper.service';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { Product, addUpdateInventory, searchSalesPOData } from './product.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpWrapperService
  ) { }

  async downloadProductExcelTemplate() {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.http.get(endpoint.dataManagement.downloadproductsheet, httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  getProductList(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.productlist, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  addItemGroup(payload: Product) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.products, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  editproduct(payload: Product, productId) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.dataManagement.editproduct + `/${productId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getprocessList() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.processList).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }
  getitemGroup() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.itemGroup).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  deleteProduct(productId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.dataManagement.deleteproduct + `/${productId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getProductDetailById(productId: string, searchOnBillOfMaterial?: string) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (productId) {
      httpParams = httpParams.set('productId', productId);
    }
    if (searchOnBillOfMaterial) {
      httpParams = httpParams.set('searchOnBillOfMaterial', searchOnBillOfMaterial);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.productdetails, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  getBOMProcesStageList() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.bomprocessList).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  getItemList() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.getitemlist).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  getCustomerList() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.customerList).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  addproductCustomer(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.addproductcustomer, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteCustomerProduct(productCoustomerId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.dataManagement.deleteproductcustomer + `/${productCoustomerId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addBOM(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.addBOM, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  uploadProductExcel(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.uploadproductsheet, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }


  deleteBOM(bomId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.dataManagement.deleteBOM + `/${bomId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getInventryList(query: { page: string; limit: string; search: string,searchByPartNumber:string,searchByPartName:string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query.searchByPartNumber) {
      httpParams = httpParams.set('searchByPartNumber', query.searchByPartNumber);
    }
    if (query.searchByPartName) {
      httpParams = httpParams.set('searchByPartName', query.searchByPartName);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.dataManagement.inventory.getinventory, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }


  addinventory(payload: addUpdateInventory) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.inventory.inventory, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  updateInventory(payload: addUpdateInventory, inventryId) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.dataManagement.inventory.getinventory + `/${inventryId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteInventry(inventryId: any) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.dataManagement.inventory.getinventory + `/${inventryId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  async downloadInventryExcelTemplate() {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.http.get(endpoint.dataManagement.inventory.getexceltemplate, httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  uploadInventryExcel(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.dataManagement.inventory.uploadexcel, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  //  ------------------ Sales Product API -----------------

  async downloadSalesProductExcelTemplate() {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.http.get(endpoint.salesProduct.downloadSalesPOExcelsheetData, httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  salesPOList(payload: searchSalesPOData) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();

    for (let key in payload) {
      httpParams = httpParams.set(key, payload[key]);
      httpInput.params = httpParams;
    }
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.salesProduct.getsalesPOList, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  salesPOListWithoutPagination() {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();


    return new Promise((resolve, reject) => {
      this.http.get(endpoint.salesProduct.getsalesPOListwithoutpagination, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deletesalesPO(salesPOId: any) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.salesProduct.deleteSalesPO + `/${salesPOId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

}
