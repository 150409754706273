import { CommonModule, DatePipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { MyInvoicesComponent } from './my-invoices/my-invoices.component';
import { MyInvoiceDetailsComponent } from './my-invoices/my-invoice-details/my-invoice-details.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { AdministratorsComponent } from './administrators/administrators.component';
import { UsersComponent } from './users/users.component';
import { UnitsComponent } from './units/units.component';
import { InvoicesSalesComponent } from './invoices-sales/invoices-sales.component';
import { GSTComponent } from './gst/gst.component';
import { VendorComponent } from './vendor/vendor.component';
import { SettingsComponent } from './settings/settings.component';
import { GatekeeperComponent } from './gatekeeper/gatekeeper.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompanyComponent } from './company/company.component';
import { InvoiceDetailsComponent } from './invoices-sales/invoice-details/invoice-details.component';
import { CompanysettingsComponent } from './companysettings/companysettings.component';
import { Daterangepicker } from "ng2-daterangepicker";
import { AuthguardGuard } from "src/app/authguard/authguard.guard";
import { AddCompanyComponent } from './modal/add-company/add-company.component';
import { NgxPaginationModule } from "ngx-pagination";
import { AddAdministratorsComponent } from './modal/add-administrators/add-administrators.component';
import { AdminresetpasswordComponent } from './modal/adminresetpassword/adminresetpassword.component';
import { AddUserComponent } from './modal/add-user/add-user.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { PurchaseOrderDetailComponent } from './purchase-order/purchase-order-detail/purchase-order-detail.component';
import { StorekeeperComponent } from './storekeeper/storekeeper.component';
import { ItemsComponent } from './items/items.component';
import { EditpodetailComponent } from './modal/editpodetail/editpodetail.component';
import { AddItemComponent } from './modal/add-item/add-item.component';
import { SumPipe } from "./sum.pipe";
import { AlertmodalComponent } from './modal/alertmodal/alertmodal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from "@ng-select/ng-select";
import { HSNcodeComponent } from './hsncode/hsncode.component';
import { GoodsAndServicesTaxComponent } from './goods-and-services-tax/goods-and-services-tax.component';
import { GoodsAndServicesTaxDetailComponent } from './goods-and-services-tax/goods-and-services-tax-detail/goods-and-services-tax-detail.component';
import { FileSaverModule } from "ngx-filesaver";
import { CustomersComponent } from './customers/customers.component';
import { SalesItemsComponent } from './sales-items/sales-items.component';
import { SalesPoComponent } from './sales-po/sales-po.component';
import { SalesPoDetailComponent } from './sales-po/sales-po-detail/sales-po-detail.component';
import { SalesItemDetailComponent } from "./sales-items/sales-item-detail/sales-item-detail.component";
import { ItemGroupsComponent } from './item-groups/item-groups.component';
import { ProcessStagesComponent } from './process-stages/process-stages.component';
import { DeliveryInstructionsComponent } from './delivery-instructions/delivery-instructions.component';
import { AddProductsComponent } from './modal/add-products/add-products.component';
import { InventoryComponent } from './inventory/inventory.component';
import { SalesPoProductComponent } from './modal/sales-po-product/sales-po-product.component';
import { SalesPoRaiseInvoiceComponent } from './modal/sales-po-raise-invoice/sales-po-raise-invoice.component';


const routes: Routes = [
    {
        path: '', component: AdminComponent, children: [
            { path: '', redirectTo: '/admin/company', pathMatch: 'full', canActivate: [AuthguardGuard] },
            { path: 'admin/invoices', component: MyInvoicesComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/invoices/invoice-details/:id', component: MyInvoiceDetailsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/invoices-sales', component: InvoicesSalesComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/invoices-sales/invoice-details', component: InvoiceDetailsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/units', component: UnitsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/administrators', component: AdministratorsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/users', component: UsersComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/company', component: CompanyComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/plants', component: UnitsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/gst', component: GSTComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/vendor', component: VendorComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/settings', component: SettingsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/companysettings', component: CompanysettingsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/gatekeeper', component: GatekeeperComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/purchase-order', component: PurchaseOrderComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/purchase-order/detail', component: PurchaseOrderDetailComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/hsncode', component: HSNcodeComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/storekeeper', component: StorekeeperComponent },
            { path: 'admin/purchase-items', component: ItemsComponent },
            { path: 'admin/gstr-2a', component: GoodsAndServicesTaxComponent },
            { path: 'admin/gstr-2a/detail', component: GoodsAndServicesTaxDetailComponent },
            { path: 'admin/customers', component: CustomersComponent },
            { path: 'admin/products', component: SalesItemsComponent },
            { path: 'admin/product-detail', component: SalesItemDetailComponent },
            { path: 'admin/sales-po', component: SalesPoComponent },
            { path: 'admin/sales-po-detail/:id', component: SalesPoDetailComponent },
            { path: 'admin/item-groups', component: ItemGroupsComponent },
            { path: 'admin/process-stages', component: ProcessStagesComponent },
            { path: 'admin/delivery-instructions', component: DeliveryInstructionsComponent },
            { path: 'admin/inventory', component: InventoryComponent },

        ]
    }
]


@NgModule({
    declarations: [
        AdminComponent,
        MyInvoicesComponent,
        MyInvoiceDetailsComponent,
        AdministratorsComponent,
        UsersComponent,
        UnitsComponent,
        InvoicesSalesComponent,
        GSTComponent,
        VendorComponent,
        SettingsComponent,
        GatekeeperComponent,
        CompanyComponent,
        InvoiceDetailsComponent,
        CompanysettingsComponent,
        AddCompanyComponent,
        AddAdministratorsComponent,
        AdminresetpasswordComponent,
        AddUserComponent,
        PurchaseOrderComponent,
        PurchaseOrderDetailComponent,
        StorekeeperComponent,
        ItemsComponent,
        EditpodetailComponent,
        AddItemComponent, SumPipe, AlertmodalComponent, HSNcodeComponent, GoodsAndServicesTaxComponent, GoodsAndServicesTaxDetailComponent, CustomersComponent, SalesItemsComponent, SalesPoComponent, SalesPoDetailComponent, SalesItemDetailComponent, ItemGroupsComponent, ItemGroupsComponent, ProcessStagesComponent, DeliveryInstructionsComponent, AddProductsComponent, InventoryComponent, SalesPoProductComponent, SalesPoRaiseInvoiceComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NgxSliderModule,
        FormsModule, Daterangepicker, ReactiveFormsModule, NgxPaginationModule, NgxSpinnerModule, NgSelectModule, FileSaverModule


    ],
    exports: [],
    providers: [DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AdminModule { }
