import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { InvoiceService } from 'src/app/Services/Invoice/invoice.service';
import { StoreKeeperService } from 'src/app/Services/storeKeeper/store-keeper.service';

@Component({
	selector: 'app-storekeeper',
	templateUrl: './storekeeper.component.html',
	styleUrls: ['./storekeeper.component.scss']
})
export class StorekeeperComponent implements OnInit {

	radio = true;
	value: any;
	InvoiceDetail: boolean;
	GatekeeperApprove: boolean;
	SearchInvoice: boolean = true;
	HideContinuebutton: boolean = true;
	invoicereport: boolean;
	preferencesEditable: boolean;
	userlogindata: any;
	companyid: any;
	vendorlist: any;
	Deletemodal: boolean;
	displaymsg: any;
	invoiceList: any;
	vendorname = '';
	invoicenumber = '';
	invoicedata: any;
	invoice_id: '';
	visible = true;
	urls: any[];
	comment: '';
	okModal = false;
	approvedorNotapprovedQualityCount: any;
	errorMessage = '';
	successMessage: any;
	ordQnty: any;
	item_id: any;
	storekeeperCommentDetails: any;
	image_url: any;
	invoiceId: any;
	Status: any = [{ value: 'OK', qualityCheck: 'Ok' }, { value: 'QS', qualityCheck: 'Quantity short' }, { value: 'IR', qualityCheck: 'Item Rejected due to quality issues' }]
	constructor(
		private route: ActivatedRoute,
		private storeKeeperService: StoreKeeperService,
		private commonService: CommonService,
		private storage: StorageService,
		private invoiceservice: InvoiceService
	) {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyid = this.userlogindata.companyId._id;
	}

	ngOnInit(): void {
		this.getInvoiceList();
		this.VendorList();
	}
	changestatus(val) {
		this.value = val;
		if (val == "reject") {
			this.value = val;
		}

	}

	clickcount: number = 0;
	ShowInvoiceDetail(id) {

		this.invoice_id = id;
		if (this.Qualitystatus.length > 0) {
			this.commonService.presentSpinner();
			this.storeKeeperService.gatstorekeeperapproveinvoice(this.invoice_id, this.Qualitystatus).then(
				(res) => {
					if (res) {

						if (res.code == 200) {
							// this.ShowInvoiceDetail('status');
							this.GetInvoiceData(this.invoice_id);

							this.commonService.dismissSpinner();
						} else {
							this.commonService.dismissSpinner();
						}
					}

				}
			)
		}
		this.clickcount++;
		if (this.clickcount == 1) {
			this.InvoiceDetail = true;
			this.SearchInvoice = false;
			this.GetInvoiceData(this.invoice_id);
		} else if (this.clickcount == 2) {
			this.InvoiceDetail = false;
			this.SearchInvoice = false;
			this.invoicereport = true;
			this.HideContinuebutton = false;
			this.GatekeeperApprove = true;
			this.GetInvoiceData(this.invoice_id);


		}
	}

	VendorList() {
		this.storeKeeperService.getVendorList(this.companyid).then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						this.vendorlist = res.vendorNameList;

					} else {
					}
				}

			}
		)
	}

	GetInvoice(e) {
		let vandorid = e.target.value;
		this.commonService.presentSpinner();
		this.storeKeeperService.getVendorInvoiceList(vandorid).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res.code == 200) {
					this.commonService.dismissSpinner();
					// this.invoiceList = [];
					this.invoiceList = res.invoiceList;
					this.errorMessage = '';

				} else {
					this.errorMessage = res.error.message;

					this.invoiceList = [];
				}


			}
		)
	}


	GetInvoiceId(e) {
		// this.invoice_id = e.target.value;


	}


	getInvoiceList() {
		// this.commonService.presentSpinner();
		this.storeKeeperService.invoicesList().then(
			(res) => {
				this.invoiceList = res.invoiceList

			}
		)
	}



	GetInvoiceData(invoice_id) {
		this.storeKeeperService.getstoreKeeperInvoiceData(this.invoice_id).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						// this.approveitemlist = res
						this.invoicedata = res.invoiceData;
						this.approvedorNotapprovedQualityCount = res;
						this.invoiceId = this.invoicedata._id
						// this.imageUrls = this.invoicedata.storekeeperCommentDetails.images ? this.invoicedata.storekeeperCommentDetails.images : '';
						this.storekeeperCommentDetails = this.invoicedata.storekeeperCommentDetails ? this.invoicedata.storekeeperCommentDetails.comment : '';
						this.comment = this.storekeeperCommentDetails;
					}
					else {
						// this.approveitemlist = res
						this.invoicedata = [];
					}
				}

			}
		)
	};
	//
	Qualitystatus: any = [];
	approvedNotApproved(status, id) {

		let payload = {
			"itemId": id,
			"qualityCheck": status,
		}
		this.Qualitystatus.push(payload)

		// this.storeKeeperService.gatstorekeeperapproveinvoice(this.invoice_id, payload).then(
		// 	(res) => {
		// 		this.commonService.dismissSpinner();
		// 		if (res) {

		// 			if (res.code == 200) {
		// 				// this.ShowInvoiceDetail('status');
		// 				this.GetInvoiceData(this.invoice_id);

		// 				this.commonService.dismissSpinner();
		// 			} else {
		// 				this.commonService.dismissSpinner();
		// 			}
		// 		}

		// 	}
		// )
	};
	postimges: any = []
	imageUrls = [];

	fileToUpload: File = null;
	onSelectFile(event) {
		let file = event.target.files;
		if (file && file[0]) {
			var filesCouount = file.length;
			for (let i = 0; i < filesCouount; i++) {
				this.fileToUpload = file[i];
				var reader = new FileReader();
				reader.readAsDataURL(file[i]);
				reader.onload = (evnt) => {
					this.imageUrls.push(evnt.target.result);
					this.postimges.push(file[i]);
				}

			}
		}


	}
	removeImage(index: number) {
		this.imageUrls.splice(index, 1);
		this.postimges.splice(index, 1);
	}

	img: any = []
	isSubmit: boolean;

	UploadImage() {
		this.commonService.presentSpinner();
		if (this.postimges && this.comment) {
			var frmData = new FormData();
			for (let index = 0; index < this.postimges.length; index++) {
				const element = this.postimges[index];
				frmData.append("itemImages", element);
			}
			frmData.append("comment", this.comment);

		}
		else if (this.postimges) {
			var frmData = new FormData();
			for (let index = 0; index < this.postimges.length; index++) {
				const element = this.postimges[index];
				frmData.append("itemImages", element);
			}

		} else if (this.comment) {
			var frmData = new FormData();
			frmData.append("comment", this.comment);


		} else {

		}



		this.storeKeeperService.gatStorekeeperuploadImage(this.invoiceId, frmData).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code === 200) {
						// this.okModal = true;
						// this.SearchInvoice = true;
						this.getInvoiceList();
						this.OKButton();
						this.Deletemodal = true;
						this.postimges = [];
						this.urls = []
						this.comment = ''
						// this.commonService.presentSuccessToast('', res.message);
						this.displaymsg = "Invoice approved successfully..";

					} else {
						// this.commonService.presentErrorToast('', res.message);
						// this.errorMessage = res.message;
					}
				}
			}
		);
	}

	rejectInvoice() {
		this.OKButton();
		this.getInvoiceList();

	};


	backbutton(item) {
		this.clickcount--;

		if (item == 'invoice') {
			this.SearchInvoice = true;
			this.InvoiceDetail = false;
			this.invoicereport = false;
			this.invoice_id = '';
			this.invoiceList = [];
			// this.GetInvoiceData(this.invoice_id);
			this.getInvoiceList();
		}
		if (item == 'invoiceDetail') {
			this.SearchInvoice = false;
			this.InvoiceDetail = true;
			this.invoicereport = false;
			this.GatekeeperApprove = false;
			this.HideContinuebutton = true;
		}

	}

	OKButton() {
		this.clickcount = 0;
		this.okModal = false;
		this.InvoiceDetail = false;
		this.invoicereport = false;
		this.GatekeeperApprove = false;
		this.HideContinuebutton = true;
		this.invoice_id = '';
		this.invoiceList = [];
		this.SearchInvoice = true
	}

	getRate(value) {
		this.ordQnty = value;
	}
	index_id = '';


	openField(value: boolean, data, index) {
		if (index === index) {
			this.visible = false;
		}

		this.preferencesEditable = value;
		if (this.preferencesEditable === true) {
			this.index_id = index
			this.preferencesEditable = value;
			this.item_id = data._id;
			this.ordQnty = '';
		} else if (this.preferencesEditable === false) {
			this.index_id = '';
			this.ordQnty = '';
		}
	}

	editPreferenceTitle(value: boolean, data, index) {
		this.preferencesEditable = value;
		this.index_id = '';
		let payload = {
			"itemId": this.item_id,
			"quantity": this.ordQnty
		}
		this.storeKeeperService.editQuantity(this.invoice_id, payload).then(
			(res) => {
				if (res.code == 200) {
					// this.commonService.presentSuccessToast('', res.message);
					this.displaymsg = res.message;
					this.Deletemodal = true;
					this.GetInvoiceData(this.invoice_id);

				} else {
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		);

	}

	GetChildData(data) {
		if (data) {
			this.Deletemodal = false;
		}
	}


}
