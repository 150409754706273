import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { PurchaseOrderService } from 'src/app/Services/PurchaseOrder/purchase-order.service';
declare var $: any;
@Component({
	selector: 'app-editpodetail',
	templateUrl: './editpodetail.component.html',
	styleUrls: ['./editpodetail.component.scss']
})
export class EditpodetailComponent implements OnInit {
	// @Input() SingleorderDetail
	PoDetailForm: FormGroup;
	submitted = false;
	Freight: any = [];
	poId: any;
	userlogindata: any = {};
	admin_id: any;
	vendorlist: any = []
	vendorNamelist: any = []
	vendorcodelist: any = []
	companyId: string;
	constructor(
		private storage: StorageService,
		private purchaseOrderService: PurchaseOrderService,
		private formGroupService: FormsGroupService,
		private router: Router,
		private commonService: CommonService,
		private route: ActivatedRoute,
		private companyservice: CompanyService,
		private formsGroupsService:FormsGroupService,

	) {

		
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.admin_id = this.userlogindata.adminId;
		this.companyId = this.userlogindata.companyId._id;
		this.route.queryParams.subscribe(params => {
			this.poId = params['id'];

		});
		this.Freight = [{ value: 'Extra', name: 'Extra' }, { value: 'Include', name: 'Include' }]
		this.PoDetailForm = this.formGroupService.getPoDetailForm();
		
	}
	get f() {
		return this.PoDetailForm.controls;

	}
	ngOnInit(): void {
		this.getVendorList();
		this.getUnitList();
	}

	getVendorList() {
		this.commonService.presentSpinner();
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPartyDropDownList().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.vendorlist = res.partyNameList;
						// let vendorId=[];
						// for(var data of this.vendorlist){
						//   vendorId.push(data._id);
						// }

						// this.PoDetailForm.patchValue({
						//   "PartyName" : vendorId
						// })

						// this.vendorlist.forEach(e => {
						//   this.vendorNamelist.push({ 'id': e._id, 'name': e.name });
						//   this.vendorcodelist.push({ 'id': e._id, 'partyCode': e.partyCode });
						// });
					}
				}

			}
		)
	}

	partyname: any = [];
	selectPartyName(e) {

		this.purchaseOrderService.partynamebyid(e.target.value).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.partyname = res.partyNameList;
						this.PoDetailForm.patchValue({
							"PartyCode": this.partyname.partyCode
						})
					}
				}

			}
		)
	}

	selectPartycode(e) {

	}

	EditPoDetail(poid) {
		this.commonService.presentSpinner();
		this.submitted = true;
		if (this.PoDetailForm.invalid) {
			return;
		};

		let postdata = {
			"purchaseOrderNo": this.PoDetailForm.value.PO,
			"freight": this.PoDetailForm.value.Freight,
			"purchaseOrderDate": this.PoDetailForm.value.FormDate,
			"plant": this.PoDetailForm.value.Plant,
			"partyCode": this.PoDetailForm.value.PartyCode,
			"partyName": this.PoDetailForm.value.PartyName,
			"createdBy": this.PoDetailForm.value.CreatedBy,
			"authorizedBy": this.PoDetailForm.value.AuthorisedBy,
		}

		this.purchaseOrderService.EditPO(postdata, poid).then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code === 200) {
						this.PoDetailForm.reset();
						$('.purchase_order_edit').modal('hide');
						this.submitted = false;
						this.commonService.presentSuccessToast('', res.message);

					}
				}
			}
		);

	}

	plantlist: any = [];
	getUnitList() {
		this.commonService.presentSpinner();
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPlantDropDownList().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.code == 200) {
						this.plantlist = res.palntList;
					}
				}

			}
		)
	}
}
