import { Injectable } from '@angular/core';
import { endpoint } from '../../constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { storageKeys } from '../../constants/storage-keys.constants';
import { eventKeys } from '../../constants/event-keys.constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  companyid: any;
  admin_id: string;
  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService) { }


  async AddCompany(Comapnydata) {
    return this.httpWrapperService.post(endpoint.addcompany, Comapnydata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
        }
      )
  }

  async getCompanyList(page) {

    return this.httpWrapperService.get(endpoint.companylist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.companyList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async DeleteCompany(id) {

    return this.httpWrapperService.delete(endpoint.deletecompany(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }


  async EditCompany(payload: any, id) {
    return this.httpWrapperService.put(endpoint.editcompany(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }
        }
      );
  }

  async CompanySearchFilter(payload) {

    return this.httpWrapperService.get(endpoint.searchcompany + '?search=' + payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.companyList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async Addadministrators(admindata) {

    return this.httpWrapperService.post(endpoint.administrators, admindata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          } else {
            return error.error
          }
        }
      )
  }

  async getCompanys() {

    return this.httpWrapperService.get(endpoint.getcompanylist).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.companyList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getAdminList(page) {

    return this.httpWrapperService.get(endpoint.administratorslist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.adminList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async Editadmin(payload: any, id) {
    return this.httpWrapperService.put(endpoint.editadministrators(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else {
            return error.error
          }
        }
      );
  }

  async AdminSearchFilter(payload) {

    return this.httpWrapperService.get(endpoint.searchadmin + '?search=' + payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.adminList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getUserList(page) {

    return this.httpWrapperService.get(endpoint.userlist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.adminList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async SearchByRole(page, postdata) {

    return this.httpWrapperService.post(endpoint.searchuserbyrole(page), postdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async UserSearchFilter(payload) {

    return this.httpWrapperService.get(endpoint.searchuser + '?search=' + payload).map(
      (res) => {
        if (res) {
          // if (res.code === 200) {
          //   if (res.adminList) {
          //   }
          // } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          // if (error.error.code == 401) {
          //   return error.error
          // }
          return error;
          // this.commonService.handleError(error);
        }
      );
  }

  async AddVendor(vendordata) {

    return this.httpWrapperService.post(endpoint.addvendor, vendordata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
        }
      )
  }

  async getVendorList(page) {

    return this.httpWrapperService.get(endpoint.vendorlist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.adminList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async EditVendor(payload: any, id) {
    return this.httpWrapperService.put(endpoint.editvendor(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async AddUnit(unitdata) {

    return this.httpWrapperService.post(endpoint.addunit, unitdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.comapnyadded, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
        }
      )
  }

  async getUnitList(page) {

    return this.httpWrapperService.get(endpoint.getunitlist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.unitList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async Editunit(payload: any, id) {
    return this.httpWrapperService.put(endpoint.editunit(id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }
  async DeleteUnit(id) {

    return this.httpWrapperService.delete(endpoint.deleteunit(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async UnitSearchFilter(page,searchUnit) {

    return this.httpWrapperService.get(endpoint.searchunit(page,searchUnit)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.unitList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async VendorSearchFilter(page,searchUser) {

    return this.httpWrapperService.get(endpoint.searchvendor(page,searchUser)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.unitList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  getPlantDropDownList() {

    return this.httpWrapperService.get(endpoint.plantDropDownList(this.admin_id)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.unitList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

  getPartyDropDownList() {

    return this.httpWrapperService.get(endpoint.partyDropdownList(this.admin_id)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.partyNameList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

}
