import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemgroupService } from 'src/app/Services/DataManagement/ItemGroup/itemgroup.service';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
declare var $: any;

@Component({
  selector: 'app-process-stages',
  templateUrl: './process-stages.component.html',
  styleUrls: ['./process-stages.component.scss']
})
export class ProcessStagesComponent implements OnInit {

  itemCode: any;
  item_id: any;
  page:number = 1;
  limit:number = 10;
  processStageList:Array<any>;
  search:any;
  totalResult:number;
  apiHitDone: boolean;
  entries: Entries;
  processStageToEdit:any;
  processStageForm: FormGroup;
  processStageFormSubmitted: boolean;
  toBeDeletedId: any;
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private processStageServices : ItemgroupService
  ) { 
    this.processStageForm = new FormGroup({
      stageCode: new FormControl('', Validators.compose([Validators.required])),
      stageName: new FormControl('', Validators.compose([Validators.required])),
    })
    this.getProcessStage()
  }

  ngOnInit(): void {
  }

  openProcessStageFormModal(detail?:any){
    this.processStageForm.reset();
    this.processStageFormSubmitted = false;
    this.processStageToEdit = null;
    if(detail){
        this.processStageToEdit = { ...detail };
        this.processStageForm.patchValue(detail)
    }
    $('#add_detail').modal('show');
  }

  getProcessStage(page?:number){
    this.apiHitDone = false;
    this.page = page || 1
    let payload = {
      page:this.page.toString(),
      limit:this.limit.toString(),
      search:this.search
    }
    this.commonService.presentSpinner();
    this.processStageServices.getProcessStage(payload).then(
      (res:any)=>{
        if(res['body'].code == 200 && res['body']['procesStageList'].length){
          this.processStageList = res['body']['procesStageList'];
          this.totalResult =  res['body'].totalResult;
          this.entries = this.commonService.calculateEntries(this.processStageList, this.page, this.limit, this.totalResult);
        }else{
          this.processStageList = [];
        }
      }
    ).catch((error)=>{
      this.commonService.presentErrorToast(error.error,'')
    }).finally(()=>{
      this.apiHitDone = true;
      this.commonService.dismissSpinner();
    })
  }

  saveData(){
    if (this.processStageForm.invalid) {
      this.processStageFormSubmitted = true;
      return;
    }
    let promise: Promise<any> = null;
    this.processStageFormSubmitted = false;
    this.commonService.presentSpinner();
    if(this.processStageToEdit){
      promise = this.processStageServices.updateProcessStage(this.processStageForm.value,this.processStageToEdit._id);
    }else{
      promise = this.processStageServices.addProcessStage(this.processStageForm.value);
    }
    promise.then(
      (res:any)=>{
        if(res){
          if(this.processStageToEdit){
            this.commonService.presentSuccessToast('', 'Update successfully!');
          }else{
            this.commonService.presentSuccessToast('', 'Added successfully!');
          }
        }
      }
    ).catch((error)=>{

    }).finally(()=>{
      this.getProcessStage()
      this.commonService.dismissSpinner();
      $('#add_detail').modal('hide');
    })
  }

  openDeleteModal(Id) {
    this.toBeDeletedId = Id;
	    if (this.toBeDeletedId) {
        $('#delete-item').modal('show');
      }
	}

  deleteProcessStage(){
    this.commonService.presentSpinner();
    this.processStageServices.deleteprocessState(this.toBeDeletedId).then(
      (res: any) => {
        if(res.code == 200){
          this.commonService.presentSuccessToast(res.message,'');
        }
      }
    ).catch((error) => {
      this.commonService.presentSuccessToast(error.error.message,'');
    }).finally(() => {
      $('#delete-item').modal('hide');
      this.commonService.dismissSpinner();
      this.getProcessStage(1);
    })
  }

}
