<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Profile Settings</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-xl-6">
				<div class="card">
					<div class="card-body">
						<div class="profile-content">
							<div class="profile-details">
								<button type="button" class="text-primary btn_style" (click)="OpenEditprofile()"><i
										class="mdi mdi-pencil font-size-18"></i>
								</button>
								<div class="user_img" *ngIf="this.userlogindata.profileImage == '' "
									style="background-color: rgb(248, 249, 250); background-image: url(assets/images/users/admin.jpg);">
								</div>
								<div class="user_img" *ngIf="this.userlogindata.profileImage != ''"
									style="background-color: rgb(248, 249, 250); background-image: url({{this.userlogindata.profileImage}});">
								</div>
								<h4>{{userlogindata.name}}<br><b>({{userlogindata.role}})</b></h4>
							</div>
						</div>
						<table class="table mb-0 basic_profile">
							<tbody>
								<tr>
									<td>
										<h6>Email ID:</h6>
									</td>
									<td style="text-align: end;">
										<p>{{userlogindata.email}} </p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-xl-6"></div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Edit  User Popup -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
	data-backdrop="static">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Profile Setting</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseProfile()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="ProfileSettingsForm">
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Picture</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input"
										formControlName="profileimg" (change)="handleFileInput($event.target.files)">
									<div class="file_img" *ngIf="!imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
									</div>
									<div class="file_img" *ngIf="imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
									</div>

								</div>
								<div *ngIf="imageErrMsg" class="invalid-feedback" style="display: block;">
									{{imageErrMsg}}</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Name</label>
								<input type="text" class="form-control" formControlName="name">
							</div>
							<div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
								<div *ngIf="f.name.errors.required">Name is required</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Email ID </label>
								<input type="email" class="form-control" formControlName="emailid" readonly>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit" (click)="SaveProfileSettings()">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit  User Popup -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>