<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add User</h5>
                <button type="button" class="close" (click)="closeUserModal()">
                    <span aria-hidden="true">×</span>
                </button>

            </div>
            <div class="modal-body">
                <form [formGroup]="UserForm" (ngSubmit)="AddUser()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Picture</label>
                                <div class="custom_file custom_img_input">
                                    <input type="file" id="customFile" class="custom_file_input"
                                        formControlName="adminimage" (change)="handleFileInput($event.target.files)">
                                    <div class="file_img" *ngIf="!imageUrl"
                                        style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
                                    </div>
                                    <div class="file_img" *ngIf="imageUrl"
                                        style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
                                    </div>
                                </div>
                                <div *ngIf="imageErrMsg" class="invalid-feedback" style="display: block;">
                                    {{imageErrMsg}}</div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Name</label>
                                <input type="text" class="form-control" formControlName="name">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Email</label>
                                <input type="email" class="form-control" formControlName="email">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Role
                                </label>
                                <select class="form-control" name="UserRole" id="UserRole" formControlName="role">
                                    <option value="" selected>Select Role</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Finance">Finance</option>
                                    <option value="Gatekeeper">Gatekeeper</option>
                                    <option value="Storekeeper">Storekeeper</option>
                                </select>
                                <div *ngIf="submitted && f.role.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.role.errors.required">Role is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Password
                                </label>
                                <div class="password_box">
                                    <input [type]="password ? 'text' : 'password' " class="form-control"
                                        formControlName="password">
                                    <i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
                                        [ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Confirm Password
                                </label>
                                <div class="password_box">
                                    <input [type]="confirmpassword ? 'text' : 'password' " class="form-control"
                                        formControlName="cpassword">
                                    <i (click)="showconfirmPassword()" class=" auti-custom-input-icon auti-custom-eye"
                                        [ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
                                    <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.cpassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>