import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './components/admin/admin.module';
import { DeletePopupComponent } from './shared-components/delete-popup/delete-popup.component';
import { AlertPopupComponent } from './shared-components/alert-popup/alert-popup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './Services/core/http/http-interceptor/http-interceptor.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExcelService } from './Services/excel.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
	declarations: [
		AppComponent,
		AlertPopupComponent,
		DeletePopupComponent,
		ForgotPasswordComponent,
		LoginComponent,
		PageNotFoundComponent

	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AdminModule,
		NgxSliderModule,
		ToastrModule.forRoot({
			maxOpened: 1,
			preventDuplicates: true,
		}),

		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		BrowserAnimationsModule,
		NgxPaginationModule, NgSelectModule
	],
	providers: [
		ExcelService,
		ToastrService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
