import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { FileSaverService } from 'ngx-filesaver';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { searchSalesPOData } from 'src/app/Services/DataManagement/products/product.model';
import { ProductsService } from 'src/app/Services/DataManagement/products/products.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { PurchaseOrderService } from 'src/app/Services/PurchaseOrder/purchase-order.service';
declare var $: any;

@Component({
	selector: 'app-sales-po',
	templateUrl: './sales-po.component.html',
	styleUrls: ['./sales-po.component.scss']
})
export class SalesPoComponent implements OnInit {
	minValue: number = 0;
	maxValue: number = 0;
	submitted = false;
	userlogindata: any = {};
	customer:Array<[]>;
	excelSheetForm: FormGroup;
	page:number = 1;
	toBeDeletedId:any;
	entries: Entries = {
		from: 0,
		to: 0,
		total: 0
	};

	saveDisabled = false;
	options: Options = {

		floor: 0,
		ceil: 1,
		step: 5,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return "<b>Min:</b> ₹" + value;
				case LabelType.High:
					return "<b>Max:</b> ₹" + value;
				default:
					return "₹" + value;
			}
		}
	};
	poNumber: any;
	companyId: any;
	erroMesage: any;
	successMessage: any;
	openAndCloseModal = false;


	configerSliderOptions(floorCeil: { floor: number, ceil: number }) {
		let newoptions: Options = Object.assign({}, this.options);
		newoptions.floor = floorCeil.floor;
		newoptions.ceil = floorCeil.ceil;
		this.options = newoptions;
	}
	public picker1 = {
		opens: 'left',
		startDate: moment().subtract(5, 'day'),
		endDate: moment(),
		isInvalidDate: function (date: any) {
			if (date.isSame('2017-09-26', 'day'))
				return 'mystyle';
			return false;
		}
	}
	public chosenDate: any = {
		// start: moment().subtract(12, 'month'),
		// end: moment().subtract(6, 'month'),
	};

	showdStartate :Date = null;
	showdEndate : Date = null;
	keyword: string = '';
	partyDetail = '';
	plantName = '';
	execlSheet: File = null;
	salesPOList:Array<any>;
	count = 0;
	limit:number = 10;
	order_id: any;
	unitlist: any;
	admin_id: any;
	maxAmount: number;
	Deletemodal: boolean;
	displaymsg: any;
	constructor(private daterangepickerOptions: DaterangepickerConfig,
		private storage: StorageService,
		private purchaseOrderService: PurchaseOrderService,
		private productsService: ProductsService,
		private excelService: FileSaverService,
		private formGroupService: FormsGroupService,
		private router: Router,
		private commonService: CommonService,
		private companyservice: CompanyService,
		private productServices: ProductsService,

	) {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyId = this.userlogindata.companyId._id;
		this.daterangepickerOptions.settings = {
			locale: { format: 'YYYY-MM-DD' },
			alwaysShowCalendars: false,
			"opens": "right",
			ranges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		};

		this.excelSheetForm = this.formGroupService.getExcelSheetForm();
	}

	ngOnInit(): void {
		this.getSalesPO(this.page);
		this.getUnitList();
		this.getCustomerList();

	}

	getCustomerList() {
		this.productServices.getCustomerList().then(
		  (res: any) => {
			if (res.code == 200 && res.customerlis.length) {
			  this.customer = res.customerlis;
			}
		  }
		).finally(() => { })
	  }

	exportAsXLSX(): void {
		this.commonService.presentSpinner();
		this.productsService.downloadSalesProductExcelTemplate().then(
			(res) => {
				this.commonService.dismissSpinner();
				if (res) {
					if (res.status == 200 && res.body) {
						this.commonService.dismissSpinner();
						this.excelService.save(res.body, 'Sales_PO.xlsx');
					} else {
						this.commonService.dismissSpinner();
					}
				} else {
					this.commonService.dismissSpinner();
				}
			}
		)
	}


	getSalesPO(page?:number) {
		this.minValue = 0;
		this.maxValue = 0
		this.commonService.presentSpinner();
		this.page = page || 1;
		let searchSalespoData = new searchSalesPOData({
			page: this.page,
			limit:this.limit,
			search: this.keyword,
			startDate : this.showdStartate,
			endDate : this.showdEndate,
			startRange : this.minValue,
			endRange : this.maxValue,
			plantId : this.plantName,
			customerId : this.partyDetail,
		})
		this.productsService.salesPOList(searchSalespoData).then(
			(res:any) => {
				if (res['body']['code'] == 200 && res['body']['salesPOList']['data'].length) {
					this.commonService.dismissSpinner();
					this.salesPOList = res['body']['salesPOList']['data'];
					this.count = res['body']['salesPOList'].total;
					let amount = []
					for (var data of this.salesPOList) {
						amount.push(data.amount);
					}
					this.maxValue = Math.max(...amount);
					this.minValue = Math.min(...amount);
					this.configerSliderOptions({ floor: Number(1000), ceil: this.maxValue ? this.maxValue : Number(0) });

					this.entries = this.commonService.calculateEntries(this.salesPOList, page, this.limit, this.count);
				} else {
					this.commonService.dismissSpinner();
					this.salesPOList = [];
				}
			}
		)
	}

	get a() {
		return this.excelSheetForm.controls;

	}

	openAddMoadal() {
		this.submitted = false;
		this.erroMesage = '';
		this.excelSheetForm.reset();
		$('#add_detail').modal('show');
	}
	
	
	onSelectFile(event) {
		this.erroMesage = '';
		var file: File = event.target.files[0];
		let currentFile = file;
		let imagetype = currentFile.type.split('/');
		let ValidImageExt = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
		if ($.inArray(imagetype[1], ValidImageExt) < 0) {
			this.commonService.presentErrorToast('', "Only .xlsx format is allowed");
			this.excelSheetForm.controls['excelSheet'].setValue('');
			return false;
		}
		if (event.target.files && event.target.files[0]) {
			this.execlSheet = event.target.files[0]
		}
	};


	openDeleteModal(id) {
		this.toBeDeletedId = id;
	    if (this.toBeDeletedId) {
        $('#delete-salesPO').modal('show');
      }
	}

	deletesalesPO() {
		this.commonService.presentSpinner();
		this.productsService.deletesalesPO(this.toBeDeletedId).then(
			(res) => {

			}
		).finally(()=>{
			$('#delete-salesPO').modal('hide');
			this.commonService.dismissSpinner();
			this.getSalesPO();
		})
	}

	dismissModal() {
		this.openAndCloseModal = false;
		this.erroMesage = '';
	}

	uploadExcelSheet() {
		this.commonService.presentSpinner();
		this.submitted = false;
		if (this.excelSheetForm.invalid) {
			this.submitted = true;
			return;
		};
		let payload = new FormData();
		payload.append("excelSheet", this.execlSheet);
		this.purchaseOrderService.addPurchaseOrder(payload).then(
			(res) => {
				if (res.code == 200) {
					this.execlSheet = null
					this.commonService.dismissSpinner();
					this.openAndCloseModal = true;
					this.getSalesPO(this.page);
					this.submitted = true;
					$('#add_detail').modal('hide');
					this.commonService.presentSuccessToast('', res.message);
				} else {
					this.commonService.dismissSpinner();
					this.excelSheetForm.reset();
					this.submitted = false;
					this.erroMesage = res.error.message
					this.execlSheet = null;

				}

			}
		)
	}

	public selectedDate(value: any, dateInput: any): void {
		dateInput.start = value.start;
		dateInput.end = value.end;
		this.showdStartate = value.start.format('YYYY-MM-DD');
		this.showdEndate = value.end.format('YYYY-MM-DD');
		this.getSalesPO(this.page);
	}

	resetAll() {
		this.minValue = 0;
		this.maxValue = 0;
		this.partyDetail = '';
		this.plantName = '';
		this.showdStartate = null;
		this.showdEndate = null;
		this.chosenDate = {};
		this.getSalesPO(this.page);



	}

	onUserChange(): void {
		// this.logText += `onUserChange(${this.getChangeContextString(changeContext)})\n`;
		// this.commonService.presentSpinner();
		this.filterAmount(this.page);
	}
	filterAmount(page) {
		this.commonService.presentSpinner();
		this.page = page;
		if (this.userlogindata.role == "Admin") {
			var companyId = this.userlogindata.companyId._id;
		} else {
			companyId = ''
		}
		this.purchaseOrderService.filterPrice(this.page = page, this.minValue, this.maxValue, companyId).then(
			(res) => {
				this.commonService.dismissSpinner();
				this.salesPOList = res.purchaseOrderList;
			}
		)
	}

	// sliderEvent(newCeil: number): void {

	// 	// Due to change detection rules in Angular, we need to re-create the options object to apply the change
	// 	const newOptions: Options = Object.assign({}, this.options);
	// 	newOptions.ceil = this.minValue;;
	// 	this.options = newOptions;
	//   }
	getUnitList() {
		this.companyservice.admin_id = this.companyId;
		this.companyservice.getPlantDropDownList().then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						this.unitlist = res.palntList;
					}
				}

			}
		)
	}


	GetChildData(data) {
		if (data) {
			this.Deletemodal = false;
		}
	}
}
