<div class="page-content gatekeeper">
  <div class="container-fluid">
    <!-- start page title -->

    <div class="center-box">
      <!-- clickcount == {{clickcount}} -->
      <div class="search-invoice search-invoice-list" *ngIf="HideInvoicelist">
        <div class="back-bar">
          <h4 class="card-title mb-2">Invoices List</h4>
        </div>

        <div class="list-inv">
          <div class="table_scroll">
            <div class="card" *ngFor="let invoice of GatekeeperInvoicelist let i = index">
              <div class="card-body">
                <div class="invoice_list">
                  <ul>
                    <li><strong>Truck number:</strong> {{invoice?.vehicle}}
                    </li>
                    <li><strong>Vendor name:</strong> {{invoice.purchaseOrderId?.partyName}}</li>
                    <li><strong>Invoice number:</strong> {{invoice?.invoiceNumber}}</li>
                    <li><strong>P.O. Date:</strong> {{invoice.purchaseOrderId?.purchaseOrderDate | date}}</li>
                  </ul>
                </div>
              </div>
              <div class="card-body view-invoice-btn " (click)="ShowInvoiceDetail('',invoice._id)">
                <span class="view-invoice">View invoice <i class="ri-arrow-right-s-line"></i></span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card search-detail-card" *ngIf="InvoiceDetail">
        <div class="search-detail">
          <div class="back-bar"><i class="ri-arrow-left-s-line" (click)="backbutton('invoice')"></i>
            <h4 class="card-title mb-2">Invoice Detail</h4>
          </div>

          <div class="table_scroll">
            <div class="table-responsive2 table-responsive-invoice">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td data-title="Name">{{userlogindata.companyId.companyName}}</td>
                        <td data-title="Invoice No"> {{invoicedata.invoiceNumber}}</td>
                        <td data-title="Invoice Date">{{invoicedata.invoiceDate}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h4 class="card-title mb-2">Items</h4>
            <div class="table-responsive2">
              <div class="table_card" *ngFor="let items of invoicedata.itemDetails let i = index">
                <table class="table mb-0 ">
                  <tbody>
                    <tr>
                      <td data-title="No.">{{i+1}}</td>
                      <td data-title="Item">{{items.itemName}}</td>
                      <td data-title="Description">{{items.itemDesc}}
                      </td>
                      <td data-title="Rate">₹ {{items.rate}}</td>
                      <td data-title="QTY">{{items.balQnty}} {{items.unit}}</td>

                    </tr>
                  </tbody>
                </table>
                <div class="button-box">
                  <button class="btn badge-d " [ngClass]="{ 'badge-danger':items.gateKeeperStatus === 'Not Approved'}"
                    type="button" (click)="ApproveInvoice(items,'notapprove')"><i class="ri-close-fill"></i>
                  </button>
                  <button class="btn badge-b" type="button"
                    [ngClass]="{ 'badge-success':items.gateKeeperStatus === 'Approved'}"
                    (click)="ApproveInvoice(items,'approve')"><i class="ri-check-line"></i>
                  </button>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="invoice-report" *ngIf="invoicereport">
        <div class="card">
          <div class="card-body">
            <div class="back-bar"><i class="ri-arrow-left-s-line" (click)="backbutton('invoiceDetail')"></i>
              <h4 class="card-title mb-2">Invoice Detail</h4>
            </div>
            <!-- {{invoicedata |json}} -->
            <div class="table_scroll">
              <div class="table-responsive2 table-responsive-invoice form-group">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td data-title="Name">{{userlogindata.companyId.companyName}}</td>
                      <td data-title="Invoice No">{{invoicedata.invoiceNumber}}</td>
                      <td data-title="No of item Approve">{{approveitemlist.noOfApprovedItem}}</td>
                      <td data-title="No of item reject">{{approveitemlist.noOfRejectItem}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group picture-box">
                <!-- <div class="select-image"> <i class="ri-image-add-line"></i> Upload Image
                  <input type="file" class="file-upload">
                </div> -->
                <h4 class="card-title mb-2">Upload Images</h4>
                <div class="picture-row">
                  <div *ngFor='let url of urls let i =index'>
                    <div class="img_col">
                      <i class="ri-close-circle-line" (click)="removeImage(i)"></i>
                      <a data-fancybox="" href="{{url}}" class="data-fancybox-img">
                        <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url({{url}});">

                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="img_col add-img" style="background-color: rgb(248, 249, 250);">
                    <input type="file" class="add-img-input" (change)="onSelectFile($event)" multiple /><i
                      class="ri-add-fill"></i>
                  </div>
                  <!-- <button class="file-upload-button">
                    <input type="file" (change)="onSelectFile($event)" multiple />
                  </button> -->
                </div>
              </div>
              <div class="form-group table-responsive2">
                <label>Note:-</label>
                <textarea rows="3" class="form-control" [(ngModel)]="comment"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fix-button" *ngIf="HideContinuebutton">

        <button class="btn btn-warning" type="button" (click)="ContinueInvoiceReport()">Continue
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0"
            viewBox="0 0 31.49 31.49" style="enable-background:new 0 0 512 512" xml:space="preserve">
            <g>
              <path xmlns="http://www.w3.org/2000/svg" style=""
                d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111  C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587  c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"
                fill="#ffffff" data-original="#1e201d" />
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
              <g xmlns="http://www.w3.org/2000/svg">
              </g>
            </g>
          </svg>
        </button>
        <!-- <button class="btn btn-warning" (click)="ShowInvoiceDetail()">Continue</button> -->
      </div>

      <div class="fix-button fix-button2" *ngIf="GatekeeperApprove">
        <button class="btn badge-b badge-success" type="submit" (click)="UploadImage()"
          [ngClass]="{ 'badge-success':invoicedata.gatekeeperApproval==='Approved'}"><i class="ri-check-line"></i>
        </button> &nbsp;&nbsp;
        <button class="btn badge-d " type="submit" (click)="RejectInvoice()"
          [ngClass]="{ 'badge-danger':invoicedata.gatekeeperApproval==='Not Approved'}"><i class="ri-close-fill"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
    <div class="alert-head sc-ion-alert-ios">
      <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios"></h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">{{displaymsg}}</div>
    <div class="alert-button-group">
      <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close" style=" width: 100%;
    border-right: 0px;
" (click)="OKButton()"><span class="alert-button-inner sc-ion-alert-ios">Ok</span>
      </button>

    </div>

  </div>
</div>
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonemodal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>