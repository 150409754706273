import { Injectable } from '@angular/core';
import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { Customer, updateCustomer } from './customer.model';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpWrapperService) { }

  getCustomer(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.getcustomer, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  addCustomer(payload: Customer) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.addcustomer, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  updateCustomer(payload: updateCustomer) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.updatecustomer, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  uploadBulkCustomerData(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.customersexcel, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  deleteCustomer(customerId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.deletecustomer + `/${customerId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  async downloadCustomerExcelTemplate() {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.http.get(endpoint.dataManagement.downloadcustomerexcel, httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }
}
