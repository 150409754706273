<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Administrators</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							data-toggle="modal" data-target=".add_detail">Add</button>
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." (input)="applyFilter($event.target.value)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:60px;">Picture</th>
										<th style="width:190px;">Name</th>
										<th style="width:190px;">Email ID</th>
										<th>Company Name</th>
										<th>Role</th>
										<th style="width:100px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="AdminList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of AdminList | paginate: { id:'adminlist',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Picture">
											<div class="bg_img" *ngIf="list?.profileImage"
												style="background-color: rgb(248, 249, 250); background-image:url({{list.profileImage}})">
											</div>
											<div class="bg_img" *ngIf="!list?.profileImage"
												style="background-color: rgb(248, 249, 250); background-image:url(assets/images/users/admin.jpg)">
											</div>

										</td>
										<td data-title="Name">{{list.name}}</td>
										<td data-title="Email ID">{{list.email}}</td>
										<td data-title="Company Name">
											<span *ngIf="list.companyId != null">
												{{list.companyId.companyName?list.companyId.companyName:'NA'}}</span>
										</td>
										<td data-title="Role">{{list.role}}</td>

										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="resetpassword(list)"><i
													class="mdi mdi-lock-reset align-middle mr-1 font-size-18"></i>
											</button>
											<button type="button" class="mr-2 text-primary btn_style"
												(click)="OpenEditModal(list)"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button> <a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"
													(click)="DeleteAdmin(list, '')"></i></a>
										</td>
									</tr>

								</tbody>
							</table>
							<div class="row" [hidden]="!AdminList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<pagination-controls id="adminlist" responsive="true" previousLabel="Prev"
										nextLabel="Next" (pageChange)="onTableDataChange($event)">
									</pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  Administrator Popup -->
<app-add-administrators></app-add-administrators>
<!-- End Add  Administrator Popup -->
<!-- Edit  Administrator Popup -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Administrator</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="EditAdminForm">
					<div class="row">
						<input type="hidden" formControlName="id">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Picture</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input"
										formControlName="adminimage" (change)="handleFileInput($event.target.files)">
									<div class="file_img" *ngIf="!imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url({{adminimg}});">
									</div>
									<div class="file_img" *ngIf="imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
									</div>

								</div>
								<div *ngIf="submitted && f.adminimage.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.adminimage.errors.required">Picture is required</div>
								</div>
								<div *ngIf="imageErrMsg" class="invalid-feedback" style="display: block;">
									{{imageErrMsg}}</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Name</label>
								<input type="text" class="form-control" formControlName="name">
								<div *ngIf="submitted && f.name.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.name.errors.required">Picture is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Email</label>
								<input type="email" class="form-control" formControlName="email">
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.email">Email must be a valid email address</div>
								</div>
							</div>
						</div>
						<!-- <div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Role</label>
								<input type="email" class="form-control" formControlName="role" readonly>
							
							</div>
						</div> -->
					</div>
					<button class="btn btn-warning" type="submit" (click)="EditAdmin()">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit  Administrator Popup -->
<!-- Edit User Reset Password Popup -->
<app-adminresetpassword [adminid]="adminid"></app-adminresetpassword>
<!-- End Edit User Reset Password Popup -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deleteadminmodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Administrator</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
				(click)="DeleteAdmin(id,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
				(click)="DeleteAdmin(id,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>