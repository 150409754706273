import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { HttpInputData, HttpWrapperService } from '../../core/http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ItemgroupService {

  constructor(private http: HttpWrapperService) { }


  getCustomer(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.getcustomer, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }
  addItemGroup(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.itemGroup.itemgroup, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  updateItemGroup(payload,itemId:string) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.itemGroup.itemgroup + `/${itemId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteItemGroup(itemId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.itemGroup.itemgroup + `/${itemId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getProcessStage(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.procesStage.processStage, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  addProcessStage(payload,id?:string) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.procesStage.processStage, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  updateProcessStage(payload,id:string) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.procesStage.processStage + `/${id}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteprocessState(processStageId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.procesStage.processStage + `/${processStageId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getitemGroup(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.itemGroup.itemgroup, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }
}
