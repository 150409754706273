<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Company</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							data-toggle="modal" (click)="OpenCompanyModal('','')">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<!-- <label class="select_box"><select class="custom-select custom-select-sm">
                        <option>Type of User</option>
                        <option value="1">Vendor</option>
                        <option value="2">Finance</option>
                        <option value="4">Gatekeeper</option>
                        <option value="5">Owner</option>
                      </select></label> -->
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." (input)="applyFilter($event.target.value)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:75px;">Logo</th>
										<th style="width:100px;">Name</th>
										<th>Registration Number</th>
										<th>PAN Number</th>
										<th>Registered Office Address</th>
										<!-- <th style="width: 120px;">Role</th> -->
										<th style="width:60px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="CompanyList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of CompanyList | paginate: { id:'companylist',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Logo">
											<div class="bg_img"
												style="background-color: rgb(248, 249, 250); background-image:url({{list.logoImage}})">
											</div>
										</td>
										<td data-title="Name">{{list.companyName}}</td>
										<td data-title="Registration Number">{{list.registrationNumber}}</td>
										<td data-title="PAN Number">{{list.panNumber}}</td>
										<td data-title="Registered Office Address">{{list.registrationOfficeAddress}}
										</td>
										<!-- <td data-title="Role">
                      Vendor
                    </td> -->
										<td data-title="Action">
											<!-- <button type="button" class="mr-2 text-primary btn_style"
												data-toggle="modal" data-target=".edit_detail" (click)="OpenCompanyModal('','')"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button>  -->
											<button type="button" class="mr-2 text-primary btn_style"
												data-toggle="modal" data-target=".edit_detail"
												(click)="OpenCompanyModal(list,'')"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button>
											<a href="javascript:void(0);" class="text-danger"
												(click)="DeleteCompany(list, '')"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>

								</tbody>
							</table>
							<div class="row" [hidden]="!CompanyList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">

									<pagination-controls id="companylist" responsive="true" previousLabel="Prev"
										nextLabel="Next" (pageChange)="onTableDataChange($event)">
									</pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  User Popup -->
<app-add-company></app-add-company>
<!-- End Add  User Popup -->
<!-- Edit  User Popup -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Company</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="EditCompanyForm" (ngSubmit)="editCompany()">
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Logo</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input"
										formControlName="companyimage" (change)="handleFileInput($event.target.files)">
									<div class="file_img" *ngIf="!imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url({{companylogo}});">
									</div>
									<div class="file_img" *ngIf="imageUrl"
										style="background-color: rgb(245, 245, 245);background-image: url({{imageUrl}});">
									</div>
								</div>

							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Name</label>
								<input type="text" class="form-control" formControlName="name">
								<div *ngIf="submitted && f.name.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.name.errors.required">Name is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Registration Number</label>
								<input type="tel" class="form-control" formControlName="registrationnumber">
								<div *ngIf="submitted && f.registrationnumber.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.registrationnumber.errors.required">Registration Number is required
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">PAN Number</label>
								<input type="tel" class="form-control" formControlName="pannumber">
								<div *ngIf="submitted && f.pannumber.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.pannumber.errors.required">PAN Number is required</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Registered Office Address</label>
								<textarea rows="3" class="form-control" autocomplete="off"
									formControlName="registredaddress"></textarea>
								<div *ngIf="submitted && f.registredaddress.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="f.registredaddress.errors.required">Registered Office Address is
										required</div>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit  User Popup -->

<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletecompanymodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">deletes the company</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
				(click)="DeleteCompany(id,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
				(click)="DeleteCompany(id,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>