import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {
  UnitForm: FormGroup;
  submitted = false;
  Deletemodal: boolean;
  page = 1;
  count = 0;
  tableSize = 7;
  unitlist: any = [];
  searchText: any;
  limit: any;
  userlogindata: any;
  companyId: any;
  Deletemodals: boolean;
  displaymsg: any;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private companyservice: CompanyService,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private storage:StorageService
  ) { 
    const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.companyId = this.userlogindata.companyId._id;
  }

  ngOnInit(): void {
    this.GetUnitList();
    this.UnitForm = this.formBuilder.group({
      id: ['', ''],
      unitname: ['', Validators.required],
      gst: ['', Validators.required],
      address: ['', Validators.required],

    })
  }
  get f() { return this.UnitForm.controls; }

  Saveunit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.UnitForm.invalid) {
      return;
    }

    let postdata = {
      "unitName": this.UnitForm.value.unitname,
      "gstUin": this.UnitForm.value.gst,
      "unitAddress": this.UnitForm.value.address,
      "companyId": this.companyId

    }
    this.commonService.presentSpinner();

    this.companyservice.AddUnit(postdata).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.GetUnitList();
            this.UnitForm.reset();
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.displaymsg = res.message;
            this.Deletemodals = true;
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            // this.commonService.presentErrorToast('', res.message);
            this.displaymsg = res.message;
            this.Deletemodals = true;
          }
        }
      }
    );
  }

  onTableDataChange(event) {

    this.page = event;
    this.GetUnitList();
  }
  pageData: any;
  GetUnitList() {
    this.commonService.presentSpinner();
    this.companyservice.getUnitList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.unitlist = [];
            this.unitlist = res.unitList;
            this.count = res.totalResult
            this.limit = res.limit;
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }




          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }


  id: any;

  OpenUnitModal(item, e) {
    this.submitted=false;
    this.UnitForm.reset();
    if (e == 'add') {
      this.id = ''
      $('.add_detail').modal('show');
    } else {
      this.id = item._id
      this.UnitForm.reset();
      this.submitted = false;
      this.UnitForm.patchValue({
        id: item._id,
        unitname: item.unitName,
        gst: item.gstUin,
        address: item.unitAddress,
      });
      $('.add_detail').modal('show');
    }

  }
  updatevendor() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.UnitForm.invalid) {
      return;
    }

    let postdata = {
      "unitName": this.UnitForm.value.unitname,
      "gstUin": this.UnitForm.value.gst,
      "unitAddress": this.UnitForm.value.address,
      "companyId": this.companyId

    }
    this.commonService.presentSpinner();

    this.companyservice.Editunit(postdata, this.UnitForm.value.id).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.GetUnitList();
            this.UnitForm.reset();
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.displaymsg = res.message;
            this.Deletemodals = true;
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            // this.commonService.presentErrorToast('', res.message);
          }
        }
      }
    );
  }
  errmsg: string = ''
  applyFilter(filterValue) {

    if (filterValue.length >= 3) {

      this.companyservice.UnitSearchFilter(this.page,filterValue).then(
        (res) => {
          if (res) {
            if (res.code == 200 && res.unitList.length > 0) {
              this.unitlist = [];
              this.unitlist = res.unitList;
              this.count = res.totalResult
              this.limit = res.limit;
              if (this.page == 1) {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
                //   (this.page - 1) * 10 + 1 - this.page * 10
              }
            } else {
              this.unitlist = [];
              this.errmsg = 'No record found...'

            }
          } else {

          }

        }
      )
    } else {
      this.GetUnitList();
    }

  }
  Id: any;
  deleteunit(item, e) {

    if (e == '') {
      this.Id = item._id;
      this.Deletemodal = true
    }

    if (e == 'yes') {
      this.commonService.presentSpinner();

      this.companyservice.DeleteUnit(item).then(
        (res) => {
          this.commonService.dismissSpinner();
          if (res) {
            this.commonService.dismissSpinner();
            if (res.code === 200) {
              this.Deletemodal = false;
              this.GetUnitList();
              this.displaymsg = res.message;
              this.Deletemodals = true;
              // this.commonService.presentSuccessToast('', res.message);

            }
          } else {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message;
            this.Deletemodals = true;
          }
        }
      );
    } else if (e == 'no') {
      this.Deletemodal = false;
    }
  }

  GetChildData(data) {
    if (data) {
      this.Deletemodals = false;
    }
  }
}
