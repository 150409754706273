import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Moment from 'moment';
import { DaterangepickerConfig } from "ng2-daterangepicker";
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { ExcelService } from 'src/app/Services/excel.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { CompanyService } from 'src/app/Services/CompanyServices/company.service';
import { InvoiceService } from 'src/app/Services/Invoice/invoice.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-invoices-sales',
  templateUrl: './invoices-sales.component.html',
  styleUrls: ['./invoices-sales.component.scss']
})
export class InvoicesSalesComponent implements OnInit {
  page = 1;
  count = 0;
  tableSize = 7;
  invoiceList: any = [];
  searchText: any;
  limit: any;
  data: any = [{
    Invoice: '', Date: '', Amount: '', GST: '', FinanceStatus: '', GatekeeperStatus: ''
  }]
  minValue: number = 100;
  maxValue: number = 400;
  userlogindata: any = {};
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min:</b> ₹" + value;
        case LabelType.High:
          return "<b>Max:</b> ₹" + value;
        default:
          return "₹" + value;
      }
    }
  };

  constructor(
    private daterangepickerOptions: DaterangepickerConfig,
    private storage: StorageService,
    private excelService: ExcelService,
    private commonService: CommonService,
    private invoiceservice: InvoiceService,
    private router: Router,
  ) {
    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"];
    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      "opens": "right",
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };
  }
  public picker1 = {
    opens: 'left',
    startDate: moment().subtract(5, 'day'),
    endDate: moment(),
    isInvalidDate: function (date: any) {
      if (date.isSame('2017-09-26', 'day'))
        return 'mystyle';
      return false;
    }
  }
  public chosenDate: any = {
    // start: moment().subtract(12, 'month'),
    // end: moment().subtract(6, 'month'),
  };

  ShowdStartate: any = '';
  ShowdEndate: any = '';
  public selectedDate(value: any, dateInput: any): void {

    dateInput.start = value.start;
    dateInput.end = value.end;
    this.ShowdStartate = value.start.format('YYYY-MM-DD');
    this.ShowdEndate = value.end.format('YYYY-MM-DD');
    // this.GetInvoiceList(this.ShowdStartate, this.ShowdEndate, this.minValue, this.maxValue)
  }
  ngOnInit(): void {
    this.GetInvoiceList();
  }
  onTableDataChange(event) {

    this.page = event;
    this.GetInvoiceList();
  }
  fileToUpload: File = null;
  imageUrl: any;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

  }
  pageData: any;
  GetInvoiceList() {
    this.commonService.presentSpinner();
    this.invoiceservice.GetInvoiceSaleList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.invoiceList = [];
            this.invoiceList = res.invoiceList;
            this.count = res.totalResult
            this.limit = res.limit;
            let monthNames = ["January", "February", "March", "April", "May", "June",
              "July", "August", "September", "October", "November", "December"
            ];
            this.invoiceList.map((e) => {
              let couponExpiryDate = e.invoiceDate
              let d = new Date(couponExpiryDate);
              let dateformate = monthNames[d.getMonth()] + ' ' + d.getDate() + ' ' + d.getFullYear();
              let time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
              // e.createdDate = dateformate + ' | ' + time
              e.invoiceDate = dateformate;
            })
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }




          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }
  SaveFile() {
    this.commonService.presentSpinner();
    var frmData = new FormData();

    frmData.append("excelSheet", this.fileToUpload);

    this.invoiceservice.AddInvoiceSale(frmData).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.responseCode === 200) {
            // this.GetInvoiceList('', '', '', '');
            this.fileToUpload = null
            $('.add_detail').modal('hide');

            // this.commonService.presentSuccessToast('', res.message);

          } else {
            this.commonService.presentErrorToast('', res.message);
          }
        }
      }
    );
  }
}
