<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item"><a [routerLink]="['/admin/purchase-order']">Purchase Order </a>
							</li>
							<li class="breadcrumb-item active">Details</li>
						</ol>
					</div>
					<div class="page-title-right2">
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-lg-12">
				<!-- Invoice -->
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="invoice_box table-responsive">
							<!-- Header -->
							<div class="filter-all purchase-order-edit">
								<div class="d-flex align-items-center justify-content-between">
									<h4 class="card-title mb-2">Purchase Order</h4>
								</div>
								<div class="dataTables_filter">
									<button type="button"
										class="btn btn-rounded btn-warning btn-w waves-effect waves-light mb-2"
										(click)="openEditModa()" *ngIf="userlogindata?.role!='Vendor'"> Edit </button>
								</div>
							</div>
							<table class="table table-bordered ">
								<thead class="thead-light">
									<tr>
										<th><strong>PO #</strong></th>
										<th><strong>Freight</strong></th>
										<th><strong>Plant</strong></th>
										<th><strong>Party Code</strong></th>
										<th><strong>Party Name</strong></th>
										<th><strong>Date</strong></th>
										<th><strong>Amount (₹)</strong></th>
										<th><strong>GST (₹)</strong></th>
										<th><strong>Total Amount (₹)</strong></th>
										<th><strong>Created By</strong></th>
										<th><strong>Authorised By</strong></th>
										<th><strong>Last Updated</strong></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td data-title="PO #">{{SingleorderDetail.purchaseOrderNo}}</td>
										<td data-title="Freight">{{SingleorderDetail.freight}}</td>
										<td data-title="Plant">{{SingleorderDetail.plant}}</td>
										<td data-title="Party Code">{{SingleorderDetail.partyCode}}</td>
										<td data-title="Party Name">{{SingleorderDetail.partyName}}</td>
										<td data-title="Date">{{SingleorderDetail.purchaseOrderDate | date}}</td>
										<td data-title="Amount">{{SingleorderDetail.amount}}</td>
										<td data-title="GST">{{SingleorderDetail.GST}}</td>
										<td data-title="Total Amount">{{SingleorderDetail.totalAmount}}
										</td>
										<td data-title="Created By">{{SingleorderDetail.createdBy}}</td>
										<td data-title="Authorised By">{{SingleorderDetail.authorizedBy}}</td>
										<td data-title="Last Updated">{{SingleorderDetail.upatedDate | date}}</td>
									</tr>
								</tbody>
							</table>

						</div>
						<div class="row">
							<div class="col-sm-12">
								<div class="filter-all">
									<div class="d-flex align-items-center justify-content-between">
										<h4 class="card-title mb-2">Item Details</h4>
									</div>
									<div class="dataTables_filter">
										<label>
											<!-- <button type="button" data-toggle="modal" data-target=".add_detail"
                        class="btn btn-rounded btn-warning btn-w waves-effect waves-light m-0"> Raise Invoice
                      </button> -->
											<button type="button" (click)="RaiseInvoiceModal()"
												class="btn btn-rounded btn-warning btn-w waves-effect waves-light m-0"
												[disabled]="!DisabledRaiseInvoice">
												Raise Invoice
											</button>

											<input type="search" class="form-control form-control-sm"
												placeholder="Search..." [(ngModel)]="keyword"
												(input)="searchitem($event.target.value)">
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive table_responsive_scroll">

							<table class="table mb-0 responsive_scroll">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th style="width:110px;">Item Code</th>
										<th style="width:110px;">Item Name</th>
										<th>Item Description
										<th> Unit </th>
										<th style="width:90px;">Rate</th>
										<th style="width:120px;" class="span-box qty_box">QTY<br>
											<div class="span-box2"><span class="rate">Delivered</span>
												<span class="amount ">Order</span>
											</div>
										</th>
										<th>Amount (₹)</th>
										<th style="width:90px;" class="span-box">CGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:90px;" class="span-box">SGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:90px;" class="span-box">IGST<br>
											<div class="span-box2"><span class="rate">Rate % </span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:85px;">Total Amount (₹)</th>
										<th style="width:85px;" *ngIf="userlogindata.role != 'Vendor'">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let list of ListItems | paginate: {id: 'itemid', itemsPerPage: limit, currentPage: page,totalItems: Itemcount }; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Item Code">{{list.itemCode}}</td>
										<td data-title="Item">{{list.itemName}}</td>
										<td data-title="Description">{{list.itemDesc}} </td>
										<td data-title="Unit">{{list.unit?list.unit:'NA'}}</td>
										<td data-title="Rate">
											<span class="action-checkbox">
												<label class="percent mr-2">₹ {{list.poRate?list.poRate:'0'}}</label>
											</span>
										</td>
										<td data-title="QTY (Delivered / Order)" class="span-box-td qty_box">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list.receivedQnty?list.receivedQnty:'NA'}}</span>
												<span class="amount">{{list.ordQnty?list.ordQnty:'NA'}}</span>
											</div>
										</td>
										<td>{{list.itemAmount?list.itemAmount:'NA'}}</td>
										<td data-title="CGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list.CGSTRate?list.CGSTRate:'NA'}}</span>
												<span class="amount ">{{list.CGSTAmt?list.CGSTAmt:'0'}}</span>
											</div>
										</td>
										<td data-title="SGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list.SGSTRate?list.SGSTRate:'NA'}}</span>
												<span class="amount ">{{list.SGSTAmt?list.SGSTAmt:'0'}}</span>
											</div>
										</td>
										<td data-title="IGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list.IGSTRate?list.IGSTRate:'NA'}}</span>
												<span class="amount ">{{list.IGSTAmt?list.IGSTAmt:'0'}}</span>
											</div>
										</td>
										<td data-title="Total Amount">{{list.totalAmount?list.totalAmount:'0'}}</td>
										<td data-title="Action">
											<!-- <button type="button" data-toggle="modal" data-target=".addItem_detail"
                        class="mr-2 text-primary btn_style">
                        <i class="mdi mdi-plus-circle-outline font-size-18"></i></button> -->
											<!-- <button type="button" data-toggle="modal" data-target=".editItem_detail" class="mr-2 text-primary btn_style">
                                                <i class="mdi mdi-pencil font-size-18"></i>
                                              </button> -->
											<button type="button" data-toggle="modal" (click)="EditItemModal(list)"
												class="mr-2 text-primary btn_style"
												*ngIf="userlogindata.role != 'Vendor'">
												<i class="mdi mdi-pencil font-size-18"></i>
											</button>
											<a href="javascript:void(0);" class="text-danger"
												(click)="deleteitem(list,'')" *ngIf="userlogindata.role != 'Vendor'">
												<i class="mdi mdi-trash-can font-size-18"></i>
											</a>
										</td>
									</tr>

								</tbody>
							</table>
							<button type="button" (click)="AddItemModal()"
								class="btn btn-rounded btn-warning btn-w waves-effect waves-light btn-new-item ml-0"
								data-toggle="modal" *ngIf="userlogindata.role != 'Vendor'"> Add New Item </button>

							<div class="row" *ngIf="ListItems?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entriesItems?.from}} to
										{{entriesItems?.to}} of
										{{entriesItems?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="itemid" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next" (pageChange)="getItemList($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Listing of Invoices -->
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive  ">
							<h4 class="card-title mb-2">Invoices</h4>
							<table class="table mb-0 ">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th style="width:150px;">Invoice #</th>
										<th style="width:150px;">Date</th>
										<th style="width:150px;">Amount (₹)</th>
										<th style="width:150px;">GST (₹)</th>
										<th style="width:150px;"> Business Unit </th>
										<th style="width:150px;"> Gatekeeper Status </th>
										<th> Storekeeper Status </th>
										<th style="width:150px;">Finance Approve</th>
										<th style="width:50px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="PoInvoice?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of PoInvoice | paginate: {id: 'hh1212', itemsPerPage: limit, currentPage: page,totalItems: counts }; let i = index">

										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Invoice #">{{list.invoiceNumber}}</td>
										<td data-title="Date">{{list.invoiceDate}}</td>
										<td data-title="Amount">{{list.totalAmount}}</td>
										<td data-title="GST">{{list.GST ? list.GST : '-'}}</td>
										<td data-title="Business Unit">{{list.plantName}} </td>
										<td data-title="Gatekeeper Status">
											<span class="badge badge-warning"
												*ngIf="list?.gatekeeperApproval == 'Pending'">Pending</span>
											<span class="badge badge-success"
												*ngIf="list?.gatekeeperApproval == 'Approved'">Approved</span>
											<span class="badge badge-danger"
												*ngIf="list?.gatekeeperApproval == 'Not Approved'">Rejected</span>

										</td>
										<td data-title="Storekeeper Status">
											<span class="badge badge-warning"
												*ngIf="list?.storekeeperApproval == 'Pending'">Pending</span>
											<span class="badge badge-success"
												*ngIf="list?.storekeeperApproval == 'Approved'">Approved</span>
											<span class="badge badge-danger"
												*ngIf="list?.storekeeperApproval == 'Not Approved'">Not Approved</span>

										</td>
										<td data-title="Finance Approve" *ngIf="userlogindata?.role !='Vendor'">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"
													(click)="changeStatusPOinvoice(list?._id)"
													[checked]="list.status == 'Approved' ? true  : false"
													*ngIf="list.gatekeeperApproval == 'Approved' && list.storekeeperApproval == 'Approved'">
												<span class="slider round"></span>

											</label>
										</td>
										<td data-title="Finance Approve" *ngIf="userlogindata?.role=='Vendor'">
											<span class="badge badge-success"
												*ngIf="list?.status == 'Approved'">Approved</span>
											<span class="badge badge-danger" *ngIf="list?.status == 'Not Approved'">Not
												Approved</span>

										</td>
										<!-- <label class="switch">
												<input type="checkbox" id="verify" value="1"
													(click)="changeStatusPOinvoice(list?._id)"
													[checked]="list.status == 'Approved' ? true  : false"> <span
													class="slider round"></span>
											</label> -->

										<td data-title="Action">
											<a [routerLink]="['/admin/invoices/invoice-details',list?._id]"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
											<!-- <button type="button" class="mr-2 text-primary btn_style"><i
                            class="ri-file-list-3-line font-size-16"></i> </button>
                        <button type="button" class="mr-2 text-primary btn_style">
                          <i class="ri-download-2-line font-size-16"></i></button>
                        <button type="button" data-toggle="modal" data-target=".edit_detail"
                          class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button> <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a> -->
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" *ngIf="PoInvoice?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="hh1212" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next" (pageChange)="POInvoice($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end table_page-->
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->

<!-- Start Modal Add Detail -->
<div class="modal fade add_detail popup-style" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Raise Invoice</h5>
				<button type="button" class="close" (click)="cancelRaiseInvoice()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form [formGroup]="RaiseInvoiceForm" (ngSubmit)="SaveRaiseInvoice()">
					<div class="invoice_box table-responsive">
						<table class="table table-bordered">
							<thead class="thead-light">
								<tr>
									<th style="width:100px;"><strong>IRN</strong></th>
									<th style="width:100px;"><strong>Invoice #:</strong></th>
									<th style="width:100px;"><strong>e-way Bill #:</strong></th>
									<th style="width:100px;"><strong>Vehicle #:</strong></th>
									<th style="width:100px;"><strong>TCS On Sales </strong></th>
									<th style="width:100px;"><strong>Date:</strong></th>
									<th><strong>Item Quantity</strong></th>
									<th><strong>Total Amount (₹)</strong></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td data-title="IRN">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="IRN"
												[ngClass]="{ 'is-invalid': submitted && f.IRN.errors }"></div>
										<div class="error-msg" *ngIf="submitted && f.IRN.errors"> <span
												style="color: red; font-size: 7px;" *ngIf="f.IRN.errors.required">
												IRN is required.</span>

										</div>
									</td>
									<td data-title="Invoice #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="invoicenumber"
												[ngClass]="{ 'is-invalid': submitted && f.invoicenumber.errors }"></div>
										<div class="error-msg" *ngIf="submitted && f.IRN.errors"> <span
												style="color: red; font-size: 7px;" *ngIf="f.IRN.errors.required">
												InvoiceNo is required.</span>
										</div>
									</td>
									<td data-title="e-Way Bill #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="ewaybill"
												[ngClass]="{ 'is-invalid': submitted && f.ewaybill.errors }"></div>
										<div class="error-msg" *ngIf="submitted && f.ewaybill.errors"> <span
												style="color: red; font-size: 7px;" *ngIf="f.ewaybill.errors.required">
												Ewaybill is required.</span>
										</div>
									</td>
									<td data-title="Vehicle #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="vehiclenumber"
												[ngClass]="{ 'is-invalid': submitted && f.vehiclenumber.errors }"></div>
										<div class="error-msg" *ngIf="submitted && f.vehiclenumber.errors"> <span
												style="color: red; font-size: 7px;"
												*ngIf="f.vehiclenumber.errors.required">
												Vehicleno is required.</span>
										</div>
									</td>
									<td data-title="TCS On Sales">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="tcsonsale"
												[ngClass]="{ 'is-invalid': submitted && f.tcsonsale.errors }"></div>
										<!-- <div class="error-msg" *ngIf="submitted && f.tcsonsale.errors"> <span
                        style="color: red; font-size: 7px;" *ngIf="f.tcsonsale.errors.required">
                        TCSonsale is required.</span>
                    </div> -->
									</td>
									<td data-title="Date">
										<div class="input-box">
											<input type="date" class="form-control form-control-sm"
												formControlName="invoicedate"
												[ngClass]="{ 'is-invalid': submitted && f.invoicedate.errors }">
										</div>
										<div class="error-msg" *ngIf="submitted && f.invoicedate.errors"> <span
												style="color: red; font-size: 7px;"
												*ngIf="f.invoicedate.errors.required">
												Date is required.</span>
										</div>
									</td>
									<td data-title="Amount"><input type="text" class="form-control form-control-sm  "
											formControlName="amount" [(ngModel)]="ItemQuantity" readonly
											style="border: none !important;">
									</td>
									<td data-title="Total Amount"><input type="text"
											class="form-control form-control-sm  " formControlName="totalammount"
											[(ngModel)]="orderQtyCount" readonly style="border: none !important;"></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="table-responsive table-overflow table_scroll table_popup">
						<table class="table">
							<thead class="thead-light">
								<tr>
									<th style="width:50px;">
										<div class="custom-checkbox">
											<input type="checkbox" class="custom-control-input"
												(change)="onSelectAll($event)" formControlName="selectall">
											<label class="custom-control-label"></label>
										</div>
									</th>
									<th>Item </th>
									<th style="width:80px;">PO Rate </th>
									<th style="width:80px;">PO QTY </th>
									<th style="width:90px;">Delivered QTY </th>
									<th style="width:80px;">Rate (₹)</th>
									<th style="width:80px;">QTY</th>
									<!-- <th style="width:60px;">GST %</th>
                  <th style="width:60px;">GST ₹ </th> -->
									<th style="width:90px;" class="span-box">CGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:90px;" class="span-box">SGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:90px;" class="span-box">IGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:100px;">Total Amount (₹)</th>
								</tr>
							</thead>
							<!-- itemlist === {{itemlist |json}} -->
							<tbody formArrayName="itemDetails">

								<tr [formGroupName]="i"
									*ngFor="let item  of RaiseInvoiceForm.controls?.itemDetails?.controls; let i = index">
									<!-- itemlist === {{itemlist[i]?.checked}} -->
									<td data-title="Select">
										<div class="custom-checkbox">
											<input type="checkbox" class="custom-control-input"
												formControlName="itmecheck" (change)="toggleVisibility($event)">
											<label class="custom-control-label"></label>
										</div>
									</td>
									<td data-title="Item">{{itemlist[i]?.itemName}}</td>
									<td data-title="PO Rate">₹ {{itemlist[i]?.poRate ? itemlist[i]?.poRate : '0'}}</td>
									<td data-title="PO QTY">{{itemlist[i]?.ordQnty ? itemlist[i]?.ordQnty : '0'}}</td>
									<td data-title="Delivered QTY">{{itemlist[i]?.receivedQnty ?
										itemlist[i]?.receivedQnty : '0'}}</td>
									<td data-title="Rate">
										<div class="input-box"><input type="text" formControlName="Rate"
												(input)="RaiseInvoicecalc($event.target.value,itemlist[i]?.itemCode,'Itemrate',i)"
												class="form-control form-control-sm"
												[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
												[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': '#ffff'}"
												[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': '#ffff'}">
										</div>
									</td>
									<td data-title="QTY">
										<!-- [ngStyle]="{'border': errorhover ? '1px solid red' : 'blue'}" -->
										<div class="input-box">
											<input type="number" class="form-control form-control-sm abc"
												formControlName="itemQTY"
												(input)="RaiseInvoicecalc($event.target.value,itemlist[i]?.itemCode,'Itemqnty',i)"
												[attr.disabled]="RaiseInvoiceForm.get('itemDetails').value[i]?.itmecheck ? null: ''"
												[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': (RaiseInvoiceForm.get('itemDetails').value[i]?.itemQTY > itemlist[i].balQnty?'#ff3d60':'#ffff') } ">
											<!-- {{RaiseInvoiceForm.get('itemDetails').value[i]?.itemQTY}} >
											{{itemlist[i].balQnty}} -->
										</div>
									</td>
									<td data-title="CGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="text" formControlName="CGSTRate"
														(input)="RaiseInvoicecalc($event.target.value,itemlist[i]?.itemCode,'ItemCGSTrate',i)"
														class="form-control form-control-sm"
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': '#ffff'}">
												</div>
											</span>
											<span class="amount  b-none">
												<div class="input-box"><input type="text" formControlName="CGSTAmt"
														class="form-control form-control-sm  "
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														readonly></div>
												<!-- {{itemlist[i]?.CGSTAmt}} -->
											</span>
										</div>
									</td>
									<td data-title="SGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="text" formControlName="SGSTRate"
														(input)="RaiseInvoicecalc($event.target.value,itemlist[i]?.itemCode,'ItemSGSTrate',i)"
														class="form-control form-control-sm"
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': '#ffff'}">
												</div>
											</span>
											<span class="amount b-none">
												<div class="input-box"><input type="text" formControlName="SGSTAmt"
														class="form-control form-control-sm  "
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														readonly></div>
											</span>
										</div>
									</td>
									<td data-title="IGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="text" formControlName="IGSTRate"
														(input)="RaiseInvoicecalc($event.target.value,itemlist[i]?.itemCode,'Itemigstrate',i)"
														class="form-control form-control-sm"
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														[ngStyle]="{'background': (RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == null || RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck == false)? '#ebedf2': '#ffff'}">
												</div>
											</span>
											<span class="amount b-none">
												<div class="input-box"><input type="text" formControlName="IGSTAmt"
														class="form-control form-control-sm  "
														[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''"
														readonly></div>
											</span>
										</div>
									</td>
									<!-- <td data-title="Total Amount">₹ {{itemlist[i]?.totalAmount ? itemlist[i]?.totalAmount:'0'}}</td> -->
									<td data-title="Total Amount">
										<div class="input-box"><input type="text" formControlName="totalAmount"
												class="form-control form-control-sm  "
												[attr.disabled]="RaiseInvoiceForm.get('itemDetails')?.value[i].itmecheck ? null: ''">
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<!-- <pre>{{this.result | json}}</pre> -->
						<!-- <pre>total_amount == {{total_amount}}</pre> -->
					</div>
					<div class="button-two">
						<button class="btn btn-cancel badge-danger" type="button"
							(click)="cancelRaiseInvoice()">Cancel</button>&nbsp;&nbsp;
						<button class="btn btn-submit badge-success mr-2" type="submit">Submit</button>
					</div>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->


<!-- Start Modal Add Item -->
<app-add-item [itemdata]="itemdata"></app-add-item>
<!-- /.modal -->

<!-- Start Modal Add Item -->
<div class="modal fade editItem_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Item</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Item Code</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Item Name</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Item Description </label>
								<textarea rows="2" class="form-control"></textarea>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<label>Unit</label>
								<input type="tel" class="form-control">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<label>Rate</label>
								<input type="tel" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<label>QTY</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Delivered">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Order">
							</div>
						</div>
						<div class="col-lg-12">
							<label>CGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Rate %">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Amount">
							</div>
						</div>
						<div class="col-lg-12">
							<label>SGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Rate %">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Amount">
							</div>
						</div>
						<div class="col-lg-12">
							<label>IGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Rate %">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="tel" class="form-control" placeholder="Amount">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<label>Total Amount </label>
								<input type="tel" class="form-control">
							</div>
						</div>
					</div>

					<button type="submit" class="btn btn-warning">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<!-- Start Modal purchase order edit -->
<!-- <app-editpodetail [SingleorderDetail]="SingleorderDetail"></app-editpodetail> -->
<!-- /.modal -->

<div class="modal fade purchase_order_edit" id="purchase_order_edit" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Purchase Order Detail </h5>

				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form [formGroup]="PoDetailForm" (ngSubmit)="EditPoDetail()">
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>PO # </label>
								<input type="text" class="form-control" formControlName="PO" readonly>
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.PO.errors"> <span style="color: red;"
                                    *ngIf="f.PO.errors.required">
                                    PO is required.</span>

                            </div> -->
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Freight</label>
								<select class="form-control" formControlName="Freight">
									<option value="{{frei?.name}}" *ngFor="let frei of Freight">{{frei?.name}}</option>
									<!-- <option value="Extra">Extra</option>
                                    <option value="Include">Include</option> -->

								</select>
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.Freight.errors"> <span style="color: red;"
                                    *ngIf="f.Freight.errors.required">
                                    Freight is required.</span>
                            </div> -->
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Plant </label>
								<select class="form-control" formControlName="Plant">
									<!-- <option>Select</option> -->
									<!-- <option value="{{SingleorderDetail.plant}}" >{{SingleorderDetail.plant}}</option> -->
									<option *ngFor="let plant of plantlist" [value]="plant.unitName">
										{{plant.unitName}}</option>

								</select>
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.Plant.errors"> <span style="color: red;"
                                    *ngIf="f.Plant.errors.required">
                                    Plant is required.</span>
                            </div> -->
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Party Code </label>
								<!-- <input type="text" class="form-control" [(ngModel)]="SingleorderDetail.partyCode"
                                    formControlName="PartyCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.PartyCode.errors }"> -->
								<select class="form-control" formControlName="PartyCode"
									(change)="selectValueId($event.target.value,'partCode')">
									<option value="{{SingleorderDetail.partyCode}}" selected hidden>
										{{SingleorderDetail?.partyCode}}
									</option>
									<option *ngFor="let vendor of vendorlist" value={{vendor._id}}>
										{{vendor?.partyCode}}
									</option>
								</select>
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.PartyCode.errors"> <span style="color: red;"
                                    *ngIf="f.PartyCode.errors.required">
                                    PartyCode is required.</span>
                            </div> -->
						</div>
						<div class="col-lg-12">
							<!-- <div class="form-group">
                                <label>Party Name </label>
                                <input type="text" class="form-control" [(ngModel)]="SingleorderDetail.partyName"
                                    formControlName="PartyName"
                                    [ngClass]="{ 'is-invalid': submitted && f.PartyName.errors }">
                            </div> -->
							<div class="form-group">
								<label>Party Name</label>
								<select class="form-control" formControlName="PartyName"
									(change)="selectValueId($event.target.value,'partyName')">
									<option value="{{SingleorderDetail.partyName}}" selected hidden>
										{{SingleorderDetail?.partyName}}
									</option>
									<option *ngFor="let vendor of vendorlist" value={{vendor._id}}>{{vendor.name}}
									</option>
								</select>
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.PartyName.errors"> <span style="color: red;"
                                    *ngIf="f.PartyName.errors.required">
                                    PartyName is required.</span>
                            </div> -->
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Date</label>
								<input type="date" class="form-control" formControlName="FormDate">
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.FormDate.errors"> <span style="color: red;"
                                    *ngIf="f.FormDate.errors.required">
                                    Date is required.</span>
                            </div> -->
						</div>
						<!-- <div class="col-sm-6">
                            <div class="form-group">
                                <label>Amount </label>
                                <input type="text" class="form-control" [(ngModel)]="SingleorderDetail.amount"
                                    formControlName="Amount" [ngClass]="{ 'is-invalid': submitted && f.Amount.errors }">
                            </div>
                            <div class="error-msg" *ngIf="submitted && f.Amount.errors"> <span style="color: red;"
                                    *ngIf="f.Amount.errors.required">
                                    Amount is required.</span>
                            </div>
                        </div> -->
						<!-- <div class="col-sm-6">
                            <div class="form-group">
                                <label>GST</label>
                                <input type="text" class="form-control" [(ngModel)]="SingleorderDetail.GST"
                                    formControlName="GST" [ngClass]="{ 'is-invalid': submitted && f.GST.errors }">
                            </div>
                            <div class="error-msg" *ngIf="submitted && f.GST.errors"> <span style="color: red;"
                                    *ngIf="f.GST.errors.required">
                                    GST is required.</span>
                            </div>
                        </div> -->
						<!-- <div class="col-sm-6">
                            <div class="form-group">
                                <label>Total Amount</label>
                                <input type="text" class="form-control" [(ngModel)]="SingleorderDetail.totalAmount"
                                    formControlName="TotalAmount"
                                    [ngClass]="{ 'is-invalid': submitted && f.TotalAmount.errors }">
                            </div>
                            <div class="error-msg" *ngIf="submitted && f.TotalAmount.errors"> <span style="color: red;"
                                    *ngIf="f.TotalAmount.errors.required">
                                    Total Amount is required.</span>
                            </div>
                        </div> -->
						<div class="col-sm-6">
							<div class="form-group">
								<label>Created By </label>
								<input type="text" class="form-control" formControlName="CreatedBy">
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.CreatedBy.errors"> <span style="color: red;"
                                    *ngIf="f.CreatedBy.errors.required">
                                    Created By is required.</span>
                            </div> -->
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<label>Authorised By</label>
								<input type="text" class="form-control" formControlName="AuthorisedBy">
							</div>
							<!-- <div class="error-msg" *ngIf="submitted && f.AuthorisedBy.errors"> <span style="color: red;"
                                    *ngIf="f.AuthorisedBy.errors.required">
                                    Authorised By is required.</span>
                            </div> -->
						</div>
					</div>
					<button type="submit" class="btn btn-warning">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>


<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Item</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
				(click)="deleteitem(id,'yes')"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"
				(click)="deleteitem(id,'no')"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>



<!-- <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="openAndCloseModal">
  <div class="sc-ion-alert-ios-backdrop"></div>
  <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
    <div class="alert-head sc-ion-alert-ios">
      <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios"></h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios" *ngIf="successMessage">{{successMessage}}</div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios" *ngIf="erroMesage" style="color: red;">{{erroMesage}}
    </div>
    <div class="alert-button-group">
      <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close" style=" width: 100%;
    border-right: 0px;
" (click)="dismissModal()"><span class="alert-button-inner sc-ion-alert-ios">Ok</span>
      </button>

    </div>

  </div>
</div> -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>
