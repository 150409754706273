import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesPoProduct } from 'src/app/Services/SalesPO/sales-po.model';
import { SalesPoService } from 'src/app/Services/SalesPO/sales-po.service';
import { CommonService } from 'src/app/Services/common/common.service';
declare var $: any

@Component({
  selector: 'app-sales-po-product',
  templateUrl: './sales-po-product.component.html',
  styleUrls: ['./sales-po-product.component.scss']
})
export class SalesPoProductComponent implements OnInit {
  AddProductItemForm: FormGroup;
  isSubmited: boolean;
  toBeEdited: any;
  productCodeList: Array<[]>;
  @Output() sendAction: EventEmitter<any> = new EventEmitter();
  @Input() set toBeEditedDate(data: any) {
    this.toBeEdited = data;
    if (this.toBeEdited && this.toBeEdited.itemDate) {
      this.AddProductItemForm.reset();
      this.isSubmited = false;
      this.AddProductItemForm.patchValue({
        "productId": this.toBeEdited.itemDate.productId ? this.toBeEdited.itemDate.productId._id :'',
        "productName": this.toBeEdited.itemDate.productId ? this.toBeEdited.itemDate.productId._id:'',
        "productDescription": this.toBeEdited.itemDate.productId ? this.toBeEdited.itemDate.productId.description : '',
        "unit": this.toBeEdited.itemDate.productId ? this.toBeEdited.itemDate.productId.unit : '',
        "rate": this.toBeEdited.itemDate.rate,
        "quantityDelieved": this.toBeEdited.itemDate.quantityDelieved,
        "quantityOrdered": this.toBeEdited.itemDate.quantityOrdered,
        "amount": this.toBeEdited.itemDate.amount,
        "CGSTRate": this.toBeEdited.itemDate.CGSTRate,
        "CGSTAmount": this.toBeEdited.itemDate.CGSTAmount,
        "SGSTRate": this.toBeEdited.itemDate.SGSTRate,
        "SGSTAmount": this.toBeEdited.itemDate.SGSTAmount,
        "IGSTRate": this.toBeEdited.itemDate.IGSTRate,
        "IGSTAmount": this.toBeEdited.itemDate.IGSTAmount,
        "totalAmount": this.toBeEdited.itemDate.totalAmount,
        "validFromDate": formatDate(this.toBeEdited.itemDate.validFromDate, 'yyyy-MM-dd', 'en-US')

      })
    }else{
      this.AddProductItemForm.reset();
      this.isSubmited = false;
    }

  }
  constructor(
    private commonservice: CommonService,
    private salesPoService: SalesPoService,

  ) {
    this.AddProductItemForm = new FormGroup({
      productId: new FormControl(null, Validators.compose([Validators.required])),
      productName: new FormControl(null),
      productDescription: new FormControl(''),
      unit: new FormControl(''),
      rate: new FormControl('', Validators.compose([Validators.required])),
      quantityDelieved: new FormControl('', Validators.compose([Validators.required])),
      quantityOrdered: new FormControl('', Validators.compose([Validators.required])),
      amount: new FormControl('', Validators.compose([Validators.required])),
      CGSTRate: new FormControl('', Validators.compose([Validators.required])),
      CGSTAmount: new FormControl('', Validators.compose([Validators.required])),
      SGSTRate: new FormControl('', Validators.compose([Validators.required])),
      SGSTAmount: new FormControl('', Validators.compose([Validators.required])),
      IGSTRate: new FormControl('', Validators.compose([Validators.required])),
      IGSTAmount: new FormControl('', Validators.compose([Validators.required])),
      totalAmount: new FormControl('', Validators.compose([Validators.required])),
      validFromDate: new FormControl('', Validators.compose([Validators.required])),
    })
  }

  get itemFormControl() {
    return this.AddProductItemForm.controls;

  }

  ngOnInit(): void {
    this.getProductList()
  }

  getProductList() {
    this.commonservice.presentSpinner();
    this.salesPoService.getSalesProduct().then(
      (res: any) => {
        if (res.code == 200 && res.list.length) {
          this.productCodeList = res.list;
        }
      }
    ).finally(() => {

    })
  }

  getPdoductDetail(productId: any, action) {
    let productDetail: any = this.productCodeList.find((x: any) => x._id == productId._id);
    if (productDetail) {
      let igst = 0;
      let cgst = 0;
      let sgst = 0;
      let customerGST = (this.toBeEdited.getSalesPODetail.customerId.GST).split("");
      let plantGST = (this.toBeEdited.getSalesPODetail.plant.gstUin).split("");
      if (customerGST[0] + customerGST[1] === plantGST[0] + plantGST[1]) {
        cgst = productDetail.hsn.gstRate / 2;
        sgst = productDetail.hsn.gstRate / 2;
      } else {
        igst = productDetail.hsn.gstRate
      }
      this.AddProductItemForm.patchValue({
        "productId": productDetail._id,
        "productName": productDetail._id,
        "productDescription": productDetail.description,
        "unit": productDetail.unit,
        "CGSTRate": cgst,
        "SGSTRate": sgst,
        "IGSTRate": igst
      })
    }

  }

  calculate() {
    let ammount = this.AddProductItemForm.value.rate * this.AddProductItemForm.value.quantityOrdered;
    let CGSTrate = ammount * (parseFloat(this.AddProductItemForm.value.CGSTRate) ? parseFloat(this.AddProductItemForm.value.CGSTRate) : 0) / 100;
    let SGSTrate = ammount * (parseFloat(this.AddProductItemForm.value.SGSTRate) ? parseFloat(this.AddProductItemForm.value.SGSTRate) : 0) / 100;
    let IGSTrate = ammount * (parseFloat(this.AddProductItemForm.value.IGSTRate) ? parseFloat(this.AddProductItemForm.value.IGSTRate) : 0) / 100;
    let totalammount = ammount + CGSTrate + SGSTrate + IGSTrate;
    this.AddProductItemForm.patchValue({
      "CGSTAmount": CGSTrate.toString(),
      "SGSTAmount": SGSTrate.toString(),
      "IGSTAmount": IGSTrate.toString(),
      "amount": ammount.toString(),
      "totalAmount": totalammount.toFixed(2)
    })
  }

  errormsg: string;
  saveitem() {
    this.isSubmited = false;
    if (this.AddProductItemForm.invalid) {
      this.isSubmited = true;
      return
    }

    if (parseInt(this.AddProductItemForm.value.quantityDelieved) > parseInt(this.AddProductItemForm.value.quantityOrdered)) {
      this.errormsg = "Ordered Quantity to be greater than Delivered quantity."
      this.commonservice.dismissSpinner();
      return;
    }

    let productOrderDetail = new SalesPoProduct(this.AddProductItemForm.value)
    productOrderDetail.validFromDate = this.AddProductItemForm.value.validFromDate, 'yyyy-MM-dd', 'en-US'
    productOrderDetail.salesOPId = this.toBeEdited.getSalesPODetail._id;
console.log("this.toBeEdited",this.toBeEdited)
    let promise: Promise<any> = null;
    if (this.toBeEdited && this.toBeEdited.itemDate) {
      promise = this.salesPoService.updateSalesPoProduct(productOrderDetail, this.toBeEdited.itemDate.salesOPId._id);
    } else {
      promise = this.salesPoService.addSalesPoProduct(productOrderDetail)
    }
    this.commonservice.presentSpinner();
    promise.then(
      (res: any) => {
        if (res.code == 200) {
          this.commonservice.presentSuccessToast(res.message, '');
        }
      }
    ).catch((error) => {
      this.commonservice.presentErrorToast(error.error.message, '');
    }).then(() => {
      this.commonservice.dismissSpinner();
      this.AddProductItemForm.reset();
      this.isSubmited = false;
      this.sendAction.emit('product Update');
      $("#sales_order_edit").modal('hide');
    })

  }
}
