<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Products</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn temp-download custom-title" (click)="exportAsXLSX()"><i class="ri-file-download-line"></i><span class="tool">Download Template</span></button>

						<button type="button" class="btn upload-icon custom-title" (click)="openExcelSheetModal()">
							<i class="ri-upload-2-line"></i><span class="tool">Upload</span>
						</button>
					

						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="openProductFormModal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.."  [(ngModel)]="search"
												(input)="getProductList(1)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th>Name</th>
										<th>Code Internal</th>
										<th>Group Code</th>
										<th>Description</th>
										<th>Last Process Stage</th>
										<th>HSN/SAC</th>
										<th style="width:85px;">Action</th>
									</tr>
								</thead>

								<tbody>
									<tr
										*ngFor="let item of productList | paginate: {id: 'productList',itemsPerPage: limit,currentPage: page,totalItems: totalResult}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Name">{{item?.name}}</td>
										<td data-title="Item Code">{{item?.code}}</td>
										<td data-title="Customer Item Code">{{item?.group?.itemGroupCode}}</td>
										<td data-title="Description">{{item?.description}}</td>
										<td data-title="Process Stage">{{item?.processStage?.stageName}} - {{item?.processStage?.stageCode}} </td>
										<td data-title="HSN/SAC">{{item?.hsn?.hsnNumber}}</td>
										<td data-title="Action">
											<a [routerLink]="['/admin/product-detail']" [queryParams]="{productId:item?._id}" class="mr-2 text-solitud"><i
													class="fas fa-eye font-size-18"></i></a>

											<a class="mr-2 text-solitud" (click)="openProductFormModal(item)"><i
													class="mdi mdi-pencil font-size-18"></i></a>

											<a href="javascript:void(0);" class="text-danger" (click)="openDeleteModal(item?._id)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !productList?.length">
										<td colspan="10">
											<h5 class="text-center">No Product found!</h5>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" *ngIf="apiHitDone && productList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
											id="productList" (pageChange)="getProductList($event)"></pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->


<!-- Start Modal Add Purchase Order -->
<div class="modal fade " id="product-excel" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Bulk Add Products</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadExcelSheet()">
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" accept=".xlsx" formControlName="excelSheet"  (change)="onSelectFile($event)"
									[ngClass]="{ 'is-invalid': submitted && excelSheet.excelSheet.errors }">
								</div>
								<div class="error-msg" *ngIf="submitted && excelSheet.excelSheet.errors"> <span
									style="color: red;" *ngIf="excelSheet.excelSheet.errors.required">
									Excel sheet is required.</span>
							</div>
							<div class="error-msg error-ul" *ngIf="erroMesage">
								<!-- <span style="color: red;">
									{{erroMesage}}</span> -->
								<ul>
									<li *ngFor="let err of erroMesage">{{err}}</li>
									<!-- <li>Plant can not be empty</li>
									<li>Plant can not be empty</li> -->
								</ul>
							</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>



<!-- Add Product Popup -->
<app-add-products [productDetail]="toBeEdit" (sendAction)="getResponseFromChild($event)"></app-add-products>
<!-- End Add Product Popup -->

<div class="modal fade alert_popup" id="delete-item" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Purchase Item</h2>
							<h6>{{itemCode}}</h6>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"  (click)="deleteProduct()"><span
									class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>