import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class SumPipe implements PipeTransform {

  transform(value: any, args?: any, field = 'selected'): any {
        
    const filter = value.filter(item => {
      return item[field] === true;
    });

    args = args.split(".");
    if (args.length === 1) {
      return filter.reduce((a, b) => a + b[args[0]], 0);
    }
    if (args.length === 2) {
      return filter.reduce((a, b) => a + b[args[0]][args[1]], 0);
    }

    return 0;
  }

}
