<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios" *ngIf="Deletemodal">
    <div class="alert-head sc-ion-alert-ios">
        <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios"></h2>
    </div>
    <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">{{displaymsg}}</div>
    <div class="alert-button-group">
        <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close" style=" width: 100%;
    border-right: 0px;
" (click)="OKButton()"><span class="alert-button-inner sc-ion-alert-ios">OK</span>
        </button>

    </div>

</div>