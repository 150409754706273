import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { storageKeys } from 'src/app/constants/storage-keys.constants';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from '../../storage/storage.service';
@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

	constructor(
		private storage: StorageService,
		private commonService:CommonService,
		private router:Router
	) { }

	// intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

	// 	const savedData = this.storage.getSavedData();
	// 	if (savedData && savedData[storageKeys.auth_token]) {
	// 		const header = {
	// 			"Authorization": savedData[storageKeys.auth_token]
	// 		}

	// 		const updatedRequest = request.clone({
	// 			headers: new HttpHeaders(header)
	// 		});
	// 		return next.handle(updatedRequest).pipe(
	// 			tap(
	// 				(event: HttpEvent<any>) => {
	// 				}, (error) => {
	// 					if (error instanceof HttpErrorResponse) {

	// 					}
	// 				}
	// 			)
	// 		);
	// 	} else {
	// 		return next.handle(request);
	// 	}
	// }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const savedData = this.storage.getSavedData();
		let header = {};
		if (savedData && savedData[storageKeys.auth_token]) {
		  header = {
			"Authorization": savedData[storageKeys.auth_token]
		  };
		}
	
		const updatedRequest = request.clone({
		  headers: new HttpHeaders(header)
		});
		return next.handle(updatedRequest).pipe(
		  tap(
			(event: HttpEvent<any>) => {
			}, (error) => {
			  if (error instanceof HttpErrorResponse) {
				if (error.status == 401) {
				  this.storage.clearStorageForLogout();
				  this.router.navigate(['/'],{ queryParams: { 'Session': 'expired' } });
				//   this.commonService.presentErrorToast('','Session expired!');
				

				}else{
					console.log('dismiss>>>>>',error)
				  this.commonService.dismissSpinner();
				}
			  }
			}
		  )
		);
	  }
}
