import { LabelType, Options } from '@angular-slider/ngx-slider';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { InvoiceService } from 'src/app/Services/Invoice/invoice.service';
import { ItemService } from 'src/app/Services/item/item.service';
import { PurchaseOrderService } from 'src/app/Services/PurchaseOrder/purchase-order.service';
declare var $: any;

@Component({
	selector: 'app-my-invoice-details',
	templateUrl: './my-invoice-details.component.html',
	styleUrls: ['./my-invoice-details.component.scss']
})
export class MyInvoiceDetailsComponent implements OnInit {
	invoiceid: any;
	minValue: number = 100;
	maxValue: number = 400;
	invoceEditForm: FormGroup;
	ssearchItem = '';
	invoidata: any = [];
	disableds = false;
	erroMesage: any;
	successMessage: any;
	openAndCloseModal = false;
	entriesItems: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	entries: Entries = {
		from: 0,
		to: 0,
		total: 0
	};
	page = 1;
	limit = 10;
	count = 1;
	visible = true;
	options: Options = {
		floor: 0,
		ceil: 500,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return "<b>Min:</b> ₹" + value;
				case LabelType.High:
					return "<b>Max:</b> ₹" + value;
				default:
					return "$" + value;
			}
		}
	};
	thivoiceDate: any;
	palntList: any;
	itemDetails: any;
	preferencesEditable: boolean;
	qtyEditable: boolean;
	balQnty = '';
	item_id: any;
	gatekeeperCommentDetails: any;
	storekeeperCommentDetails: any;
	Deletemodal: boolean;
	displaymsg: any;
	index_id = '';
	index_id2 = '';
	userlogindata: any = {};
	poNumber: any;
	itemCode: any;
	storeKeeperCommentAndImage = '';
	values = false;
	StoreKeeperImage: any;
	storekeeperComment: any;
	storekeeperData: any = [''];
	disabledAlret = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private invoiceservice: InvoiceService,
		private router: Router,
		private purchaseOrderService: PurchaseOrderService,
		private formsGroupsService: FormsGroupService,
		private datePipe: DatePipe,
		private storage: StorageService,
		private itemService: ItemService

	) {
		const savedData = this.storage.getSavedData();
		this.userlogindata = savedData["user_details"];
		this.activatedRoute.params.subscribe(params => {
			this.invoiceid = params.id;
			// this.invoiceservice.invoiceid = this.invoiceid;
		});
		this.invoceEditForm = this.formsGroupsService.getinvoceEditForm();
	}

	ngOnInit(): void {
		this.SingleInvoice(this.page)
	}

	SingleInvoice(page) {
		this.page = page;

		this.commonService.presentSpinner();
		this.invoiceservice.invoiceid = this.invoiceid;
		this.invoiceservice.GetSingleInvoice(page, this.ssearchItem.length >= 3 ? this.ssearchItem : '').then(
			(res) => {
				if (res) {
					if (res.code == 200) {
						this.commonService.dismissSpinner();
						this.invoidata = [];
						this.invoidata = res.invoiveList;
						if (!this.invoidata.matchStatus) {
							this.invoidata.matchStatus = '';
						}
						if (!this.invoidata.matchError) {
							this.invoidata.matchError = '';
						}
						this.gatekeeperCommentDetails = res.invoiveList.gatekeeperCommentDetails
						this.storekeeperData = res.invoiveList.storekeeperCommentDetails;
						if (this.storekeeperData == [{}]) {
							this.disabledAlret = false;
						} else if (this.storekeeperData[0].comment && this.storekeeperData[0].images) {
							this.disabledAlret = true;
						} else if (this.storekeeperData[0].comment || this.storekeeperData[0].images) {
							this.disabledAlret = true;
						}
						this.itemDetails = res.itemDetails.data;
						this.itemDetails.forEach(e => {
							e.SGSTAmt = parseFloat(e.SGSTAmt).toFixed(2);
							e.CGSTAmt = parseFloat(e.CGSTAmt).toFixed(2);
							e.IGSTAmt = parseFloat(e.IGSTAmt).toFixed(2);

						});
						this.count = res.itemDetails.total;
						this.entries = this.commonService.calculateEntries(this.itemDetails, page, this.limit, this.count);

					} else {
						this.commonService.dismissSpinner();

					}
				} else {

				}

			}
		)

	}
	GstchangeStatus(id, e) {
		let postdata = {}
		if (e.target.checked) {
			postdata = {
				'GSTCreditEligible': 'Yes',
			}
		} else {
			postdata = {
				'GSTCreditEligible': 'No',
			}
		}
		this.invoiceservice.invoice_id = id;
		this.invoiceservice.Changecrediteligibilty(postdata).then(
			(res) => {
				if (res.code == 200) {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.SingleInvoice(this.page);
					this.commonService.presentSuccessToast('', res.message);
				} else {
					this.commonService.presentSpinner();
				}
			}
		)
	}
	openModal() {
		$('#reported-issue').modal('show');
	}
	okButton() {
		this.disableds = false;
	}

	searchOrder(value) {
		this.ssearchItem = value;
		this.invoiceservice.searchInvoice(this.invoiceid, this.ssearchItem).then(
			(res) => {
				this.itemDetails = res.data;
				this.limit = res.limit;
				this.page = res.page;
				this.count = res.totalResult;
				this.entries = this.commonService.calculateEntries(this.itemDetails, this.page, this.limit, this.count)
			}
		)

	}
	getRate(value) {
		this.balQnty = value;
	}

	openField(value: boolean, data, index) {

		if (index === index) {
			this.visible = false;
		}

		this.preferencesEditable = value;
		if (this.preferencesEditable === true) {
			this.index_id = index
			this.preferencesEditable = value;
			this.item_id = data._id;
			this.balQnty = '';
		} else if (this.preferencesEditable === false) {
			this.index_id = '';
			this.balQnty = '';
		}
	}

	editPreferenceTitle(value: boolean, data, index) {
		this.preferencesEditable = value;
		this.index_id = '';
		let payload = {
			"itemId": this.item_id,
			"balQnty": this.balQnty
		}
		this.purchaseOrderService.purchaseItemInvoice_id = this.invoiceid
		this.purchaseOrderService.itemPurchaseQuantity(payload).then(
			(res) => {
				if (res.code == 200) {
					this.displaymsg = res.message;
					// this.Deletemodal = true;
					this.SingleInvoice(this.page);

				} else {
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		);

	}

	openeditqty(value: boolean, data, index) {
		if (index === index) {
			this.visible = false;
		}

		this.qtyEditable = value;
		if (this.qtyEditable === true) {
			this.balQnty = '';
			this.index_id2 = index
			this.qtyEditable = value;
			this.item_id = data._id;
		} else if (this.qtyEditable === false) {
			this.index_id2 = '';
			this.balQnty = '';

		}
	}


	editqty(value: boolean, data) {

		this.qtyEditable = value;
		this.index_id2 = '';
		let payload = {
			"itemId": this.item_id,
			"poRate": this.balQnty
		}

		this.purchaseOrderService.purchaseItemInvoice_id = this.invoiceid
		this.purchaseOrderService.editItemPurchasePORateQuantity(payload).then(
			(res) => {
				if (res.code == 200) {
					this.displaymsg = res.message;
					// this.Deletemodal = true;
					// this.commonService.presentSuccessToast('', res.message);
					this.SingleInvoice(this.page);

				} else {
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		);
	}



	changeStatusPOinvoice() {
		this.purchaseOrderService.pOinvoice_id = this.invoiceid;
		this.purchaseOrderService.chnageStatus().then(
			(res) => {
				if (res.code == 200) {
					// this.commonService.presentSuccessToast('', res.message);
					this.displaymsg = res.message;
					this.Deletemodal = true;
				} else {
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		)
	};

	// selectDate(e) {
	// 	this.thivoiceDate = e.target.value;
	// }

	openEdiTInvoiceModal() {
		$('#purchase_order_edit').modal('show');
		let dt = this.datePipe.transform(this.invoidata.createdDate, 'yyyy-MM-dd');
		this.invoceEditForm.patchValue({
			"inr": this.invoidata.IRN,
			"invoice": this.invoidata.invoiceNumber,
			"eWayBill": this.invoidata.eWayBill,
			"vehicle": this.invoidata.vehicle,
			"createdDate": this.datePipe.transform(this.invoidata.invoiceDate, 'yyyy-MM-dd'),
			"gst": this.invoidata.GST,
			"freight": this.invoidata.purchaseOrderId.freight,
			"tcsOnSales": this.invoidata.TCSOnSales,
			// this.datePipe.transform(this.activities.checkInDateTime, 'MMMM d, y','UTC') ||  '--',
		});
		// this.purchaseOrderService.getAdminPlantList(this.invoidata.adminId).then(
		// 	(res) => {
		// 		if (res.code == 200) {
		// 			this.palntList = res.palntList;

		// 		}
		// 	}
		// )
	}

	edirPoInvoce() {
		let payload = {
			"purchaseOrderId": this.invoidata.purchaseOrderId._id,
			"IRN": this.invoceEditForm.value.inr,
			"invoice": this.invoceEditForm.value.invoiceNumber,
			"eWayBill": this.invoceEditForm.value.eWayBill,
			"vehicle": this.invoceEditForm.value.vehicle,
			"invoiceNumber": this.invoceEditForm.value.invoice,
			"TCSOnSales": this.invoceEditForm.value.tcsOnSales,
			"invoiceDate": this.invoceEditForm.value.createdDate,


		}
		this.purchaseOrderService.editPurchaseInvoce(payload, this.invoidata._id).then(
			(res) => {
				if (res.code == 200) {
					this.displaymsg = res.message;
					this.Deletemodal = true;
					$('#purchase_order_edit').modal('hide');
					this.SingleInvoice(this.page)

				} else {
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		)
	}

	dismissModal() {
		this.openAndCloseModal = false;
		this.erroMesage = '';


	}
	GetChildData(data) {
		if (data) {
			this.Deletemodal = false;
		}
	}

	opemDeleteModal(data) {
		this.item_id = data._id;
		this.itemCode = data.itemCode;
		$('#delete-item').modal('show');
	}


	itemsDelete() {
		this.commonService.presentSpinner();
		this.invoiceservice.deleteInvoiceItem(this.invoiceid, this.item_id).then(
			(res) => {

				if (res.code == 200) {
					this.commonService.dismissSpinner();

					$('#delete-item').modal('hide');
					this.displaymsg = res.message;
					this.Deletemodal = true;
					this.SingleInvoice(this.page)



				} else {
					this.commonService.dismissSpinner();
					this.displaymsg = res.message;
					this.Deletemodal = true;
				}
			}
		)
	}

}
