<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Customers</li>
						</ol>
					</div>
					<div class="page-title-right2">
						<button type="button" class="btn temp-download custom-title" (click)="exportAsXLSX()"><i class="ri-file-download-line"></i><span class="tool">Download Template</span></button>

						<button type="button" class="btn upload-icon custom-title" (click)="openAddMoadal()"><i
							class="ri-file-upload-line"></i><span class="tool">Upload</span></button>
						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							data-toggle="modal" (click)="openCustomerFormModal()">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive" style="overflow:hidden  !important;">
							<div class="row">
								<div class="col-sm-12 col-md-6"></div>
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_filter">
										<label>
											<input type="search" class="form-control form-control-sm"
												placeholder="Search.." [(ngModel)]="searchKeyword"
												(input)="getCustomer(1)">
										</label>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:40px;">No.</th>
										<th style="width:100px;">Customer Name & Code</th>
										<th style="width:100px;">Contact Name & Number</th>
										<th style="width:100px;">Primary Email</th>
										<th style="width:100px;">GST</th>
										<th style="width:100px;">PAN</th>
										<th>Shipping Address</th>
										<th>Billing Address</th>
										<th style="width:70px;">Place Of Supply</th>
										<th style="width:100px;">Action</th>
									</tr>
								</thead>

								<tbody>
									<tr
										*ngFor="let customer of customerList | paginate: {id: 'customerList',itemsPerPage: limit,currentPage: page,totalItems: totalResult}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Name">{{customer?.CustomerName}}<br>{{customer?.CustomerCode}}
										</td>
										<td data-title="Contact Detail">
											{{customer?.ContactName}}<br>{{customer?.ContactNumber}}</td>
										<td data-title="Email">{{customer?.PrimaryEmail}}</td>
										<td data-title="GST Number">{{customer?.GST}}</td>
										<td data-title="PAN Number">{{customer?.PAN}}</td>
										<td data-title="Shipping Address">{{customer?.ShippingAddress}}</td>
										<td data-title="Billing Address">{{customer?.BillingAddress}}</td>
										<td data-title="Supply Place">{{customer?.PlaceOfSupply}}</td>
										<td data-title="Action">
											<button type="button" class="mr-2 text-primary btn_style"
												data-toggle="modal" (click)="openCustomerFormModal(customer)"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button> <a href="javascript:void(0);" class="text-danger"
												(click)="openDeleteModal(customer?._id)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !customerList?.length">
										<td colspan="10">
											<h5 class="text-center">No Customer found!</h5>
										</td>
									</tr>

								</tbody>
							</table>
							<!-- <div class="api-inline-loader" *ngIf="!apiHitDone">
								<div class="spinner-border text-primary" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div> -->

							<div class="row" *ngIf="apiHitDone && customerList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''"
											id="customerList" (pageChange)="getCustomer($event)"></pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->


<!-- Start Modal Add Purchase Order -->
<div class="modal fade bulk_add_customer" id="add_customer" tabindex="-1" id="add_detail" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Bulk Add Customers</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadExcelSheet()">
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" accept=".xlsx"
										formControlName="excelSheet" (change)="onSelectFile($event)"
										[ngClass]="{ 'is-invalid': submitted && a.excelSheet.errors }">
								</div>
								<div class="error-msg" *ngIf="submitted && a.excelSheet.errors"> <span
										style="color: red;" *ngIf="a.excelSheet.errors.required">
										Excel sheet is required.</span>
								</div>
								<div class="error-msg error-ul" *ngIf="erroMesage">
									<ul>
										<li *ngFor="let err of erroMesage">{{err}}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>



<!-- Add  User Popup -->
<div class="modal fade add_detail" id="customer-form" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">{{customerToEdit ? 'Edit' : 'Add'}} Customer</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="customerForm">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Customer Name <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="CustomerName">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['CustomerName']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['CustomerName']?.errors?.required">Customer Name
										is required</div>
									<div *ngIf="customerForm?.controls['CustomerName']?.errors?.pattern">Invalid Customer Name</div>
								</div>
							</div>

						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label>Customer Code <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="CustomerCode">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['CustomerCode']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['CustomerCode']?.errors?.required">Customer Code
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Name <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="ContactName">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['ContactName']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['ContactName']?.errors?.required">Contact Name
										is required</div>
									<div *ngIf="customerForm?.controls['ContactName']?.errors?.pattern">Invalid Contact Name</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Contact Number <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="ContactNumber" maxlength="10" (keypress)="numberOnly($event)">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['ContactNumber']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['ContactNumber']?.errors?.required">Contact
										Number
										is required</div>
										<div *ngIf="customerForm?.controls['ContactNumber']?.errors?.minlength">Contact Number length must be 10 digits.</div>										
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Primary Email <span style="color:red;">*</span></label>
								<input type="email" class="form-control" formControlName="PrimaryEmail">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['PrimaryEmail']?.errors"
									class="invalid-feedback" style="display: block;">
									<span *ngIf="customerForm?.controls['PrimaryEmail']?.errors?.required">Primary Email
										is required!</span>
									<span *ngIf="customerForm?.controls['PrimaryEmail']?.errors?.email">Email must be a
										valid email address!</span>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">GST Number <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="GST" maxlength="15">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['GST']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['GST']?.errors?.required">GST Number
										is required</div>
									<div *ngIf="customerForm?.controls['GST']?.errors?.pattern">Invalid GST Number</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">PAN Number <span style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="PAN" maxlength="10">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['PAN']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['PAN']?.errors?.required">PAN Number
										is required</div>
										<div *ngIf="customerForm?.controls['PAN']?.errors?.pattern">Invalid PAN Number</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Shipping Address <span
										style="color:red;">*</span></label>
								<textarea rows="3" class="form-control" autocomplete="off" id="ship-address"
									formControlName="ShippingAddress"></textarea>
								<div *ngIf="customerFormSubmitted && customerForm?.controls['ShippingAddress']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['ShippingAddress']?.errors?.required">Shipping
										Address
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Billing Address <span
										style="color:red;">*</span></label>
								<input type="checkbox" id="same" value="Same as Shipping Address"
									formControlName="bothAddressesSame" [(ngModel)]="isChecked"
									(change)="checkValue($event)">
								<textarea rows="3" class="form-control" autocomplete="off" id="bill-address"
									formControlName="BillingAddress"></textarea>
								<div *ngIf="customerFormSubmitted && customerForm?.controls['BillingAddress']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['BillingAddress']?.errors?.required">Billing
										Address
										is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Place Of Supply <span
										style="color:red;">*</span></label>
								<input type="text" class="form-control" formControlName="PlaceOfSupply">
								<div *ngIf="customerFormSubmitted && customerForm?.controls['PlaceOfSupply']?.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="customerForm?.controls['PlaceOfSupply']?.errors?.required">Place Of
										Supply
										is required</div>
								</div>
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit" (click)="addOrUpdateCustomer()">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add  User Popup -->


<!-- Edit User Reset Password Popup -->
<div class="modal fade forgot_password show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Reset Password</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<form class="form-horizontal" action="index.html">
					<div class="form-group auth-form-group-custom mb-4">
						<i class="ri-lock-2-line auti-custom-input-icon"></i>
						<label for="userpassword">Password</label>
						<input [type]="forgotpassword ? 'text' : 'password' " class="form-control" id="userpassword"
							placeholder="Enter new password">
						<i (click)="showforgotPassword()" class=" auti-custom-input-icon auti-custom-eye"
							[ngClass]="forgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
					</div>
					<div class="form-group auth-form-group-custom mb-4">
						<i class="ri-lock-2-line auti-custom-input-icon"></i>
						<label for="userpassword">Password</label>
						<input [type]="confirmforgotpassword ? 'text' : 'password' " class="form-control"
							id="userpassword" placeholder="Enter Confirm password">
						<i (click)="showConfirmforgotPassword()" class=" auti-custom-input-icon auti-custom-eye"
							[ngClass]="confirmforgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
					</div>

					<div class="mt-4">
						<button class="btn btn-primary w-md waves-effect waves-light" type="submit">Save</button>
					</div>
				</form>
			</div>
		</div><!-- /.modal-content -->
	</div><!-- /.modal-dialog -->
</div><!-- End Edit User Reset Password Popup -->

<div class="modal fade alert_popup" id="delete-customer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Customer</h2>

						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure
							you want to delete?
						</div>

						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"><span
									class="alert-button-inner sc-ion-alert-ios" (click)="deletecustomer()">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>