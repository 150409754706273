<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Sales PO</li>
						</ol>
					</div>

					<div class="page-title-right2">

						<button type="button" class="btn temp-download custom-title"><i class="ri-file-download-line"
								(click)="exportAsXLSX()"></i><span class="tool">Download Template</span></button>

						<button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
							(click)="openAddMoadal()">
							Add
						</button>

					</div>
				</div>
			</div>
		</div>

		<!-- Finance Eunctionality Available -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12">
									<div class="filter-all">
										<div class="d-flex align-items-center justify-content-between">
											<div class="page-title-right2">
												<div class="select_box select_box_text">
													<svg xmlns="http://www.w3.org/2000/svg" width="33.123"
														height="35.858" viewBox="0 0 33.123 35.858">
														<g id="Group_86" data-name="Group 86"
															transform="translate(857.079 -756.758)">
															<path id="Path_2227" data-name="Path 2227"
																d="M95.806,83.085H76.251a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H68.6a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM72.427,85.835a1.467,1.467,0,1,1,1.467-1.467A1.468,1.468,0,0,1,72.427,85.835Z"
																transform="translate(-921.196 676.574)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2228" data-name="Path 2228"
																d="M95.806,211.618H92.753a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H85.106a4.033,4.033,0,0,0,7.647,0h3.052a1.284,1.284,0,0,0,0-2.568Zm-6.876,2.751A1.467,1.467,0,1,1,90.4,212.9,1.468,1.468,0,0,1,88.93,214.368Z"
																transform="translate(-921.196 561.785)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2229" data-name="Path 2229"
																d="M95.806,340.151H81.752a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H74.1a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM77.928,342.9a1.467,1.467,0,1,1,1.466-1.467A1.468,1.468,0,0,1,77.928,342.9Z"
																transform="translate(-921.196 446.996)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
														</g>
													</svg>
												</div>
												<div class="select_box ml-3">
													<div class="input-group" daterangepicker [options]="picker1"
														(selected)="selectedDate($event, chosenDate)">
														<span class="form-control uneditable-input" name="daterange">

															<span *ngIf="chosenDate.start else elseblock">
																{{ chosenDate.start | date:'mediumDate' }} - {{
																chosenDate.end| date:'mediumDate' }}
															</span>
															<ng-template #elseblock>
																<span>
																	<div class="calendar-icon"><span>Select
																			date</span><i
																			class="ri-calendar-2-line"></i></div>
																</span>
															</ng-template>
														</span>
														<span class="input-group-btn">
															<a type="button" class="btn btn-default"><i
																	class="glyphicon glyphicon-calendar"></i></a>
														</span>
													</div>
												</div>
												<div class="price_range">
													<label>Amount</label>
													<ngx-slider [(value)]="minValue" [(highValue)]="maxValue"
														[options]="options" (userChange)="getSalesPO()">
													</ngx-slider>
												</div>
												<div class="select_box ml-2">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories"
														[(ngModel)]="plantName"
														(change)="getSalesPO()">
														<option value="" selected>Select Plant </option>
														<option [value]="plant?._id"
															*ngFor="let plant of unitlist">
															{{plant?.unitName}}</option>
													</select>
												</div>
												<div class="select_box ml-2" *ngIf="userlogindata.role != 'Vendor'">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories"
														[(ngModel)]="partyDetail"
														(change)="getSalesPO()">
														<option value="" selected>Select Party Detail </option>
														<option [value]="customer?._id" *ngFor="let customer of customer">
															{{customer?.CustomerName}}</option>
														<!-- <option value="Vendor 1">Vendor1</option>
														<option value="Vendor 2">Vendor2</option>
														<option value="Vendor 3">Vendor3</option> -->
													</select>
												</div>
												<div class="select_box ml-2">
													<div class="input-group-reset">
														<button type="button" class="btn custom-select-sm"
															(click)="resetAll()"><i
																class="mdi text-primary font-size-18 mdi-refresh"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<div class="dataTables_filter">
											<label>
												<input type="search" class="form-control form-control-sm"
													placeholder="Search on PO number" [(ngModel)]="keyword"
													(input)="getSalesPO(1)">
											</label>
										</div>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">SPO No #</th>
										<th style="width:80px;">Plant</th>
										<th>Customer Name</th>
										<th>Customer Code</th>
										<th>PO Date</th>
										<th>Amount (₹)</th>
										<th>GST (₹)</th>
										<th>Total Amount (₹)</th>
										<th>Created Date</th>
										<th>Last Updated</th>
										<th>Type</th>
										<th>Valid Up-To</th>
										<th style="width:65px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="salesPOList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let item of salesPOList | paginate: {id: 'salesPOList',itemsPerPage: limit,currentPage: page,totalItems: count}; let i = index;">
										<td data-title="Plant">{{item?.po_number}}</td>
										<td data-title="Customer Name">{{item?.plant?.unitName}}</td>
										<td data-title="Customer Code">{{item?.customerId?.CustomerName}}</td>
										<td data-title="Customer Code">{{item?.customerId?.CustomerCode}}</td>
										<td data-title="PO Date">{{item?.poDate | date : 'YYYY-MM-dd'}}</td>
										<td data-title="Amount">{{item?.amount}}</td>
										<td data-title="GST">{{item?.gst}}</td>
										<td data-title="Total">{{item?.tatalAmount}}</td>
										<td data-title="Created Date">{{item?.createdDate | date :'MMM d, y'}}</td>
										<td data-title="Last Updated">{{item?.updatedDate | date : 'MMM d, y'}}</td>
										<td data-title="Type">{{item?.type}}</td>
										<td data-title="Valid Up-To">{{item?.validUpToDate | date : 'MMM d, y'}}</td>
										<td data-title="Action">
											<a [routerLink]="['/admin/sales-po-detail',item._id]" class="mr-2 text-solitud"><i
													class="fas fa-eye font-size-18"></i></a>
											<a href="javascript:void(0);" class="text-danger" (click)="openDeleteModal(item?._id)"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>

									

								</tbody>
							</table>

							<div class="row" *ngIf="salesPOList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing from {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}}
										entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="salesPOList" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next"
											(pageChange)="getSalesPO($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->
<!-- Start Modal Add Sales PO -->
<div class="modal fade add_detail" tabindex="-1" id="add_detail" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Add Sales PO</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="excelSheetForm" (ngSubmit)="uploadExcelSheet()">
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" formControlName="excelSheet"
										accept=".xlsx" (change)="onSelectFile($event)"
										[ngClass]="{ 'is-invalid': submitted && a.excelSheet.errors }">

								</div>
								<div class="error-msg" *ngIf="submitted && a.excelSheet.errors"> <span
										style="color: red;" *ngIf="a.excelSheet.errors.required">
										Excel sheet is required.</span>
								</div>
								<div class="error-msg error-ul" *ngIf="erroMesage">
									<!-- <span style="color: red;">
										{{erroMesage}}</span> -->
									<ul>
										<li *ngFor="let err of erroMesage">{{err}}</li>
										<!-- <li>Plant can not be empty</li>
										<li>Plant can not be empty</li> -->
									</ul>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="Deletemodal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
</div>

<div class="modal fade alert_popup" id="delete-salesPO" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true" style="display: none;">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
					<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
						<div class="alert-head sc-ion-alert-ios">
							<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Sales PO</h2>
						</div>
						<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
						</div>
						<div class="alert-button-group sc-ion-alert-ios">
							<button type="button" class="alert-button" aria-label="Close"  (click)="deletesalesPO()"><span
									class="alert-button-inner sc-ion-alert-ios">Yes</span>
							</button>
							<button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
								aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>