<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Company Settings</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-xl-6">
				<div class="card">
					<div class="card-body">
						<div class="profile-content">
							<div class="profile-details">
								<button type="button" class="text-primary btn_style" data-toggle="modal" data-target=".edit_detail"><i class="mdi mdi-pencil font-size-18"></i>
								</button>
								<div class="user_img" style="background-color: rgb(248, 249, 250); background-image: url(assets/images/company-icon/1189px-Nissan-logo.svg.png);"></div>
 							</div>
						</div>
						<table class="table mb-0 basic_profile">
							<tbody>
								<tr>
									<td>
										<h6>Company Name:</h6>
									</td>
									<td style="text-align: end;">
										<p>Maruti Suzuki</p>
									</td>
								</tr>
								<tr>
									<td>
										<h6>Company Registration Number:</h6>
									</td>
									<td style="text-align: end;">
										<p>Bluth Company</p>
									</td>
								</tr>
								<tr>
									<td>
										<h6>PAN Number:</h6>
									</td>
									<td style="text-align: end;">
										<p>ALWPG5809L</p>
									</td>
								</tr>
								<tr>
									<td>
										<h6>Registered Office Address:</h6>
									</td>
									<td style="text-align: end;">
										<p>Gp-58, Maruti Industrial Complex, Sector 18, Sector Gurugram, Haryana 122008</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-xl-6"></div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Edit  User Popup -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Company Setting</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Picture</label>
								<div class="custom_file custom_img_input">
									<input type="file" id="customFile" class="custom_file_input">
<div class="file_img"
style="background-color: rgb(245, 245, 245);background-image:url(assets/images/company-icon/1189px-Nissan-logo.svg.png);"></div>

								</div>
							</div>
						</div>
 						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Company Name</label>
								<input type="text" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Company Registration Number</label>
								<input type="tel" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">PAN Number</label>
								<input type="tel" class="form-control">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Registered Office Address</label>
								<textarea rows="3" class="form-control" autocomplete="off"></textarea>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit  User Popup -->