import { Injectable } from '@angular/core';
import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { AddDeliveryInstruction, GetDeliveryInstruction, SalesPO, SalesPoProduct } from './sales-po.model';
import { endpoint } from 'src/app/constants/endpoints.constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesPoService {

  constructor(
    private http: HttpWrapperService
  ) { }

  editSalesPO(payload: SalesPO, salesId:string) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.salesProduct.editsalespodetail + `/${salesId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getSalesProduct() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.salesProduct.getProductList).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getSalesPOProduct(query: { page: string; limit: string }, salesPOId) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.salesProduct.getsalesPOProductList + `/${salesPOId}`, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  addSalesPoProduct(payload: SalesPoProduct) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.salesProduct.addsalesProduct , payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  updateSalesPoProduct(payload: SalesPoProduct, saleProductId) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.salesProduct.updatesalesProduct + `/${saleProductId}` , payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteSalesPoProduct(saleProductId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.salesProduct.deleteSalesPOProduct + `/${saleProductId}` ).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  async downloadDIExcelTemplate() {
    let httpInput = new HttpInputData();
    httpInput.responseType = "blob";
    return this.http.get(endpoint.salesProduct.downloadDIExcel, httpInput).map(
      (res) => {
        if (res) {

          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          } else if (error.error.code == 400) {
            return error.error
          } else {
            return error;
          }
          // this.commonService.handleError(error);
        }
      );
  }

  uploadBulkDIData(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.salesProduct.uploadDIExcel, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getDIList(payload: GetDeliveryInstruction) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();

    for (let key in payload) {
      httpParams = httpParams.set(key, payload[key]);
      httpInput.params = httpParams;
    }
    return new Promise((resolve, reject) => {
      this.http.get(endpoint.salesProduct.getDi, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addDI(payload: AddDeliveryInstruction) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint.salesProduct.addDI , payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  updateDI(payload: AddDeliveryInstruction, diId:string) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoint.salesProduct.updateDI  + `/${diId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteDI(diId) {
    return new Promise((resolve, reject) => {
      this.http.delete(endpoint.salesProduct.deleteDi + `/${diId}` ).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

}
