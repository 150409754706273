import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { PurchaseOrderService } from 'src/app/Services/PurchaseOrder/purchase-order.service';
declare var $: any;
@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {
  @Input() itemdata
  poId: any;
  AddItemForm: FormGroup;
  submitted = false;
  userlogindata: any = {};
  companyid: any;
  Deletemodal: boolean
  commonmodal: boolean
  displaymsg: any;
  itemlist: any = [];
  SingleorderDetail: any = [];
  constructor(
    private route: ActivatedRoute,
    private formGroupService: FormsGroupService,
    private purchaseOrderService: PurchaseOrderService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService,
    private router: Router,
  ) {
    const savedData = this.storage.getSavedData();
    this.userlogindata = savedData["user_details"];
    this.companyid = this.userlogindata.companyId._id
    this.route.queryParams.subscribe(params => {
      this.poId = params['id'];
      this.AddItemForm = this.formGroupService.AddItem();
    });

    this.events.subscribe(eventKeys.comapnyadded, () => {
      this.ItemList();
    })
  }
  get f() {
    return this.AddItemForm.controls;

  }
  ngOnInit(): void {
    this.ItemList();
    this.GetSinglepurchaneOrder();
  }
  GetSinglepurchaneOrder() {
    this.commonService.presentSpinner();
    this.purchaseOrderService.getSinglepurchaneOrder(this.poId).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.SingleorderDetail = res.purchaseOrderList
          }

          else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        }

      }
    )
  }
  errormsg: string = ''
  SaveItem(id) {

    this.commonService.presentSpinner();
    this.submitted = true;
    if (this.AddItemForm.invalid) {
      this.commonService.dismissSpinner();
      return;
    };

    if (parseInt(this.AddItemForm.value.QTY) > parseInt(this.AddItemForm.value.Order)) {
      // this.AddItemForm.invalid
      this.errormsg = "Ordered Quantity to be greater than Delivered quantity."
      this.commonService.dismissSpinner();
      return;
    }
    let postdata = {
      "purchaseOrderId": id,
      "itemCode": this.AddItemForm.value.ItemCode,
      "itemName": this.AddItemForm.value.ItemName,
      "itemDesc": this.AddItemForm.value.Description,
      "rate": this.AddItemForm.value.Rate,
      "ordQnty": this.AddItemForm.value.Order,
      "receivedQnty": this.AddItemForm.value.QTY ? this.AddItemForm.value.QTY : '0',
      "unit": this.AddItemForm.value.Unit,
      "itemAmount": this.AddItemForm.value.itemAmount,
      "SGSTRate": this.AddItemForm.value.SGSTrate,
      "SGSTAmt": this.AddItemForm.value.SGSTammount,
      "CGSTRate": this.AddItemForm.value.CGSTRate ? this.AddItemForm.value.CGSTRate : '0',
      "CGSTAmt": this.AddItemForm.value.CGSTammount ? this.AddItemForm.value.CGSTammount : '0',
      "IGSTRate": this.AddItemForm.value.IGSTrate ? this.AddItemForm.value.IGSTrate : '0',
      "IGSTAmt": this.AddItemForm.value.IGSTammount ? this.AddItemForm.value.IGSTammount : '0',
      "totalAmount": this.AddItemForm.value.TotalAmount
    }
    this.purchaseOrderService.AddItem(postdata).then(
      (res) => {

        if (res) {
          if (res.code === 200) {
            this.AddItemForm.reset();

            $('.addItem_detail').modal('hide');
            this.commonService.dismissSpinner();
            this.submitted = false;
            this.displaymsg = res.message
            this.commonmodal = true
          } else {
            this.commonService.dismissSpinner();
            this.displaymsg = res.message
            this.commonmodal = true
          }
        }
      }
    );
  }
  OKButton() {
    this.Deletemodal = false
  }

  UpdateItem(id) {

    this.submitted = true;
    if (this.AddItemForm.invalid) {
      this.commonService.dismissSpinner();
      return;
    };
    if (parseInt(this.AddItemForm.value.QTY) > parseInt(this.AddItemForm.value.Order)) {
      this.commonService.dismissSpinner();
      this.AddItemForm.invalid
      this.errormsg = "Ordered Quantity to be greater than Delivered quantity."

      return;
    }
    this.commonService.presentSpinner();
    let postdata = {
      "itemCode": this.AddItemForm.value.ItemCode,
      "itemName": this.AddItemForm.value.ItemName,
      "itemDesc": this.AddItemForm.value.Description,
      "rate": this.AddItemForm.value.Rate,
      "ordQnty": this.AddItemForm.value.Order,
      "unit": this.AddItemForm.value.Unit,
      "receivedQnty": this.AddItemForm.value.QTY ? this.AddItemForm.value.QTY : '0',
      "SGSTRate": this.AddItemForm.value.SGSTrate,
      "itemAmount": this.AddItemForm.value.itemAmount,
      "SGSTAmt": this.AddItemForm.value.SGSTammount,
      "CGSTRate": this.AddItemForm.value.CGSTRate ? this.AddItemForm.value.CGSTRate : '0',
      "CGSTAmt": this.AddItemForm.value.CGSTammount ? this.AddItemForm.value.CGSTammount : '0',
      "IGSTRate": this.AddItemForm.value.IGSTrate ? this.AddItemForm.value.IGSTrate : '0',
      "IGSTAmt": this.AddItemForm.value.IGSTammount ? this.AddItemForm.value.IGSTammount : '0',
      "totalAmount": this.AddItemForm.value.TotalAmount
    }
    this.purchaseOrderService.EditItem(postdata, id).then(
      (res) => {

        if (res) {
          if (res.code === 200) {
            this.itemdata = ''
            this.AddItemForm.reset();
            this.commonService.dismissSpinner();
            $('.addItem_detail').modal('hide');
            this.submitted = false;
            this.displaymsg = res.message
            this.commonmodal = true
            // this.commonService.presentSuccessToast('', res.message);

          } else {
            this.displaymsg = res.message
            this.commonmodal = true
          }
        }
      }
    );
  }
  calculate() {

    var ammount = this.AddItemForm.value.Rate * this.AddItemForm.value.Order;
    var CGSTrate = ammount * (parseFloat(this.AddItemForm.value.CGSTRate) ? parseFloat(this.AddItemForm.value.CGSTRate) : 0) / 100;
    var SGSTrate = ammount * (parseFloat(this.AddItemForm.value.SGSTrate) ? parseFloat(this.AddItemForm.value.SGSTrate) : 0) / 100;
    var IGSTrate = ammount * (parseFloat(this.AddItemForm.value.IGSTrate) ? parseFloat(this.AddItemForm.value.IGSTrate) : 0) / 100;
    var totalammount = ammount + CGSTrate + SGSTrate + IGSTrate
    this.AddItemForm.patchValue({
      "CGSTammount": CGSTrate,
      "SGSTammount": SGSTrate,
      "IGSTammount": IGSTrate,
      "itemAmount": ammount,
      "TotalAmount": totalammount.toFixed(2)
    })
  }


  ItemList() {

    this.purchaseOrderService.GetItemList(this.companyid).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.itemlist = res.itemList;
          }
        }

      }
    )
  }
  data: any = []
  selectitemCode(e, item) {

    let itemid = e.target.value;
    this.purchaseOrderService.SingleItemDetail(itemid).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            var itemdetail = res.itemList;
            this.data = this.itemlist.find(x => x._id == itemdetail._id);
            if (item == 'itemcode') {
              let igst = 0;
              let cgst = 0;
              let sgst = 0;
              let vendorgst = (this.SingleorderDetail.partyId.gstNumber).split("");
              let plantgst = (this.SingleorderDetail.unitInfo.gstUin).split("");
              if (vendorgst[0] + vendorgst[1] === plantgst[0] + plantgst[1]) {
                cgst = this.data.HSNSANum.gstRate / 2;
                sgst = this.data.HSNSANum.gstRate / 2;
              } else {
                igst = this.data.HSNSANum.gstRate
              }
              this.AddItemForm.patchValue({
                "ItemName": this.data._id,
                "Description": this.data.itemDesc,
                "Unit": this.data.unit,
                "hsnnumber": this.data.HSNSANum.hsnNumber,
                "CGSTRate": cgst,
                "SGSTrate": sgst,
                "IGSTrate": igst
              })
            } else {
              let igst = 0;
              let cgst = 0;
              let sgst = 0;
              let vendorgst = (this.SingleorderDetail.partyId.gstNumber).split("");
              let plantgst = (this.SingleorderDetail.unitInfo.gstUin).split("");
              if (vendorgst[0] + vendorgst[1] === plantgst[0] + plantgst[1]) {
                cgst = this.data.HSNSANum.gstRate / 2;
                sgst = this.data.HSNSANum.gstRate / 2;
              } else {
                igst = this.data.HSNSANum.gstRate
              }
              this.AddItemForm.patchValue({
                "ItemCode": this.data._id,
                "Description": this.data.itemDesc,
                "Unit": this.data.unit,
                "hsnnumber": this.data.HSNSANum.hsnNumber,
                "CGSTRate": cgst,
                "SGSTrate": sgst,
                "IGSTrate": igst
              })
            }

          }
        }

      }
    )
  }
  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }

  CloseIemModal(itemdata) {
    if (!itemdata) {
      $('.addItem_detail').modal('hide')
      this.AddItemForm.reset();
      this.submitted = false;
    } else {
      $('.addItem_detail').modal('hide')

    }


  }
}
