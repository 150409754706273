import { formatDate } from "@angular/common";

export class Product {
    name: string;
    code: string;
    description: string;
    group: string;
    processStage: string;
    quantity: number;
    unit: string
    hsn: string

    constructor(data: {
        name: string;
        code: string;
        description: string;
        group: string;
        processStage: string;
        quantity: number;
        unit: string
        hsn: string
    }) {
        if (data) {
            this.name = data.name;
            this.code = data.code;
            this.description = data.description;
            this.group = data.group;
            this.processStage = data.processStage;
            this.quantity = data.quantity;
            this.unit = data.unit;
            this.hsn = data.hsn;
        }
    }
}

export interface BOMData {
    billOfMaterialId: string,
    processStage: string,
    productItem: string,
    unit: string,
    quantity: string,
    grossWeight?: string,
    netWeight?: string,
    scrapWeight?: string,
}
export class BOM {
    productId: string;
    ProductBillOfMaterial: Array<BOMData> = [];
   
    constructor(
        productId: string,
        ProductBillOfMaterial: Array<{
            processStage: string,
            billOfMaterialId: string,
            productItem: string,
            unit: string,
            quantity: string,
            grossWeight?: string,
            netWeight?: string,
            scrapWeight?: string,
        }>,
       
    ) {
        this.productId = productId;
        if (ProductBillOfMaterial && ProductBillOfMaterial.length) {
            this.ProductBillOfMaterial = ProductBillOfMaterial;
        }
       
    }

}

export class addUpdateInventory {
    productId: string;
    plant: string;
    workOfProgress: number;
    finishedGood: number;
    total: number;

    constructor(data: {
        productId: string;
        plant: string;
        workOfProgress: number;
        finishedGood: number;
        total: number;
       
    }) {
        if (data) {
            this.productId = data.productId;
            this.plant = data.plant;
            this.workOfProgress = data.workOfProgress;
            this.finishedGood = data.finishedGood;
            this.total = data.total;
        }
    }
}

export class searchSalesPOData {
    page:number;
    limit:number;
    search:string;
    startDate;
    endDate;
    startRange : number;
    endRange : number;
    plantId : string;
    customerId : string;

    constructor(data: {
        page:number;
        limit:number;
        search:string;
        startDate;
        endDate;
        startRange : number;
        endRange : number;
        plantId : string;
        customerId : string;   
    }) {
        this.page = data.page || 1;
        this.limit = data.limit || 10;
        if (data && data.search) {
            this.search = data.search;
        }
        if (data && data.startDate) {
            this.startDate = data.startDate
        }
        if (data && data.endDate) {
            this.endDate = data.endDate
        }
        if (data && data.startRange) {
            this.startRange = data.startRange;
        }
        if (data && data.endRange) {
            this.endRange = data.endRange;
        }
        if (data && data.plantId) {
            this.plantId = data.plantId;
        }
        if (data && data.customerId) {
            this.customerId = data.customerId;
        }
    }
}

