<!-- Start Modal Add Detail -->
<div class="modal fade popup-style" id="add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Raise Invoice</h5>
				<button type="button" class="close" (click)="cancelRaiseInvoice()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form [formGroup]="RaiseInvoiceForm" (ngSubmit)="SaveRaiseInvoice()">
					<div class="invoice_box table-responsive">
						<table class="table table-bordered">
							<thead class="thead-light">
								<tr>
									<th style="width:100px;"><strong>IRN</strong></th>
									<th style="width:100px;"><strong>Invoice #:</strong></th>
									<th style="width:100px;"><strong>e-way Bill #:</strong></th>
									<th style="width:100px;"><strong>Vehicle #:</strong></th>
									<th style="width:100px;"><strong>TCS On Sales </strong></th>
									<th style="width:100px;"><strong>Date:</strong></th>
									<th><strong>Item Quantity</strong></th>
									<th><strong>Total Amount (₹)</strong></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td data-title="IRN">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="IRN"
												[ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.IRN.errors }">
											<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.IRN.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.IRN.errors.required">
													IRN is required.</span>
											</div>
										</div>

									</td>
									<td data-title="Invoice #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="invoicenumber" [ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.invoicenumber.errors }">
												<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.invoicenumber.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.invoicenumber.errors.required">
													Invoice number is required.</span>
											</div>
										</div>

									</td>
									<td data-title="e-Way Bill #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="ewaybill" [ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.ewaybill.errors }">
												<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.ewaybill.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.ewaybill.errors.required">
													Ewaybill is required.</span>
											</div>
										</div>

									</td>
									<td data-title="Vehicle #">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="vehiclenumber" [ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.vehiclenumber.errors }">
												<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.vehiclenumber.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.vehiclenumber.errors.required">
													Vehiclenumber is required.</span>
											</div>
										</div>
									</td>
									<td data-title="TCS On Sales">
										<div class="input-box"><input type="text" class="form-control form-control-sm"
												formControlName="tcsonsale" [ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.tcsonsale.errors }">
												<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.tcsonsale.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.tcsonsale.errors.required">
													TCSonsale is required.</span>
											</div>
										</div>

									</td>
									<td data-title="Date">
										<div class="input-box">
											<input type="date" class="form-control form-control-sm"
												formControlName="invoicedate" [ngClass]="{ 'is-invalid': submitted && raiseInvoiceFormControl.invoicedate.errors }">
												<div class="error-msg"
												*ngIf="submitted && raiseInvoiceFormControl.invoicedate.errors"> <span
													style="color: red; font-size: 7px;"
													*ngIf="raiseInvoiceFormControl.invoicedate.errors.required">
													Invoice date is required.</span>
											</div>
										</div>

									</td>
									<td data-title="Amount"><input type="text" class="form-control form-control-sm"
											readonly style="border: none !important;" [ngModelOptions]="{standalone: true}" [(ngModel)]="itemQuantity">
									</td>
									<td data-title="Total Amount"><input type="text"
											class="form-control form-control-sm" [ngModelOptions]="{standalone: true}" [(ngModel)]="totalAmount"
											readonly
											style="border: none !important;"></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="table-responsive table-overflow table_scroll table_popup">
						<table class="table">
							<thead class="thead-light">
								<tr>

									<th>SPO Item </th>
									<th style="width:80px;">SPO Rate </th>
									<th style="width:80px;">SPO QTY </th>
									<th style="width:90px;">Delivered QTY </th>
									<th style="width:80px;">Rate (₹)</th>
									<th style="width:80px;">QTY</th>
									<th style="width:80px;">Blance QTY</th>
									<!-- <th style="width:60px;">GST %</th>
                  <th style="width:60px;">GST ₹ </th> -->
									<th style="width:90px;" class="span-box">CGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:90px;" class="span-box">SGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:90px;" class="span-box">IGST<br>
										<div class="span-box2"><span class="rate">Rate % </span>
											<span class="amount ">Amount (₹)</span>
										</div>
									</th>
									<th style="width:100px;">Total Amount (₹)</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of raiseInvoiceItems">
									<td data-title="Item">{{item?.itemCode?.name +'\t'+ item?.itemCode?.code}}</td>
									<td data-title="SPO Rate">{{item?.orderDetails ? item?.orderDetails?.rate : ''}}
									</td>
									<td data-title="SPO QTY"><input type="number" class="form-control form-control-sm"
											[ngModelOptions]="{standalone: true}"
											[(ngModel)]="item.scheduledQuantity"><br>
										<span *ngFor="let inventory of item?.inventoryList; let j = index"
											style="font-size: 9px;">
											{{inventory?.plant?.unitName}} : {{inventory?.finishedGood}}<input
												type="number" class="form-control form-control-sm">
										</span>

									</td>
									<td data-title="Delivered QTY">{{item?.orderDetails ?
										item?.orderDetails?.quantityDelieved : ''}}</td>
									<td data-title="Rate">
										<div class="input-box"><input type="number" class="form-control form-control-sm"
												[ngModelOptions]="{standalone: true}"
												[(ngModel)]="item.orderDetails && item.orderDetails.rate"
												(ngModelChange)="item.orderDetails ? item.orderDetails.rate : 0" (input)="RaiseInvoicecalc($event.target.value,item,item?.orderDetails,'ItemRate',i)">
										</div>
									</td>
									<td data-title="QTY">
										<div class="input-box"><input type="number" class="form-control form-control-sm"
												[ngModelOptions]="{standalone: true}"
												[(ngModel)]="item.orderDetails && item.orderDetails.quantityOrdered"
												(ngModelChange)="item.orderDetails ? item.orderDetails.quantityOrdered : 0" (input)="RaiseInvoicecalc($event.target.value,item,item?.orderDetails,'Itemqnty',i)">
										</div>
									</td>
									<td data-title="Blance QTY">
										<div class="input-box"><input type="number" class="form-control form-control-sm"
												[ngModelOptions]="{standalone: true}"
												[(ngModel)]="item.orderDetails && item.orderDetails.blanceQty"
												(ngModelChange)="item.orderDetails ? item.orderDetails.blanceQty : 0">
										</div>
									</td>
									<td data-title="CGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.CGSTRate"
														(ngModelChange)="item.orderDetails ? item.orderDetails.CGSTRate : 0" (input)="RaiseInvoicecalc($event.target.value,item,item?.orderDetails,'Itemqnty',i)">
												</div>
											</span>
											<span class="amount  b-none">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.CGSTAmount"
														(ngModelChange)="item.orderDetails ? item.orderDetails.CGSTAmount : 0">
												</div>

											</span>
										</div>
									</td>
									<td data-title="SGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.SGSTRate"
														(ngModelChange)="item.orderDetails ? item.orderDetails.SGSTRate : 0" (input)="RaiseInvoicecalc($event.target.value,item,item?.orderDetails,'Itemqnty',i)">
												</div>
											</span>
											<span class="amount b-none">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm "
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.SGSTAmount"
														(ngModelChange)="item.orderDetails ? item.orderDetails.SGSTAmount : 0">
												</div>
											</span>
										</div>
									</td>
									<td data-title="IGST (Rate % / Amount)" class="span-box-td">
										<div class="span-box2 span-box-d"><span class="rate">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.IGSTRate"
														(ngModelChange)="item.orderDetails ? item.orderDetails.IGSTRate : 0" (input)="RaiseInvoicecalc($event.target.value,item,item?.orderDetails,'Itemqnty',i)">
												</div>
											</span>
											<span class="amount b-none">
												<div class="input-box"><input type="number"
														class="form-control form-control-sm"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="item.orderDetails && item.orderDetails.IGSTAmount"
														(ngModelChange)="item.orderDetails ? item.orderDetails.IGSTAmount : 0">
												</div>
											</span>
										</div>
									</td>
									<td data-title="Total Amount">
										<div class="input-box"><input type="number" class="form-control form-control-sm"
												[ngModelOptions]="{standalone: true}"
												[(ngModel)]="item.orderDetails && item.orderDetails.totalAmount"
												(ngModelChange)="item.orderDetails ? item.orderDetails.totalAmount : 0">
										</div>
									</td>


								</tr>
							</tbody>
						</table>
					</div>
					<div class="button-two">
						<button class="btn btn-cancel badge-danger" type="button">Cancel</button>&nbsp;&nbsp;
						<button class="btn btn-submit badge-success mr-2" type="submit">Submit</button>
					</div>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->