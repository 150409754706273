<!-- Begin page -->
<div id="layout-wrapper" [ngClass]="{'sidebar-enable vertical-collpsed': sidePanelEnabled}">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a class="logo logo-dark">
            <span class="logo-sm">
              <!-- <img src="assets/images/logo-sm-dark.png" alt="" height="22"> -->
            </span>
            <span class="logo-lg">
              <!-- <img src="assets/images/logo-dark.png" alt="" height="20"> -->
            </span>
          </a>

          <a class="logo Welcome_info">
            <h4>Welcome</h4>
            <h5>{{userlogindata.name}}</h5>
          </a>
        </div>

        <button type="button" (click)="enableSidePanel()" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>
        <a class="logo logo-light">
          <span class="logo-sm" style="display: none;">
            <!-- <img src="assets/images/logo-sm-light.png" alt="" height="25" *ngIf="userlogindata.role == 'Superadmin'"> -->
            <!-- <div class="logo-lg-bg" style="background-image: url(assets/images/logo-light.png);"
              *ngIf="userlogindata.role == 'Superadmin'"></div> -->

            <!-- <img src="{{this.userlogindata.companyId.logoImage}}" alt="" height="25"
              *ngIf="userlogindata.role != 'Superadmin'"> -->
            <!-- <div class="logo-sm-bg" style="background-image: url({{this.userlogindata.companyId.logoImage}});"
              *ngIf="userlogindata.role != 'Superadmin'"></div> -->

            <!-- <span style="color: #fff;"> {{userlogindata.userName}} Image</span> -->
          </span>
          <span class="logo logo_bg">
            <!-- <img src="assets/images/logo-light.png" alt="" height="15" > -->
            <div class="logo-lg-bg" style=" background-image: url(assets/images/logo-light.png);"
              *ngIf="userlogindata.role == 'Superadmin'"></div>
            <!-- <img src="{{this.userlogindata.companyId.logoImage}}" alt="" height="25"
              *ngIf="userlogindata.role != 'Superadmin'"> -->
            <!-- <span style="color: #fff;"> {{userlogindata.userName}} Image</span> -->
            <div class="logo-lg-bg" style=" background-image: url({{this.userlogindata.companyId.logoImage}});"
              *ngIf="userlogindata.role != 'Superadmin'"></div>
          </span>
        </a>
        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
             <div class="position-relative">
               <input type="text" class="form-control" placeholder="Search...">
               <span class="ri-search-line"></span>
             </div>
           </form> -->


      </div>

      <div class="d-flex">

        <!-- <div class="dropdown d-inline-block d-lg-none ml-2">
             <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <i class="ri-search-line"></i>
             </button>
             <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
               aria-labelledby="page-header-search-dropdown">

               <form class="p-3">
                 <div class="form-group m-0">
                   <div class="input-group">
                     <input type="text" class="form-control" placeholder="Search ...">
                     <div class="input-group-append">
                       <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                     </div>
                   </div>
                 </div>
               </form>
             </div>
           </div> -->


        <div class="dropdown d-none d-lg-inline-block ml-1" style="display: none !important;">
          <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
        <div class="dropdown d-inline-block user-dropdown">
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/admin.jpg" alt="Header Avatar"> -->
            <img class="rounded-circle header-profile-user"
              src="{{userlogindata?.profileImage ? userlogindata?.profileImage : 'assets/images/users/admin.jpg'}}"
              alt="Header Avatar">
            <span class="d-xl-inline-block ml-1">
              <b>{{userlogindata.name}}</b><br>
              ({{userlogindata.role}})</span>
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" (click)="navigateTo({route: '/admin/settings'})"
              *ngIf="userlogindata.role == 'Superadmin'"><i class="ri-settings-2-line align-middle mr-1"></i> Profile
              Settings</a> <!-- item-->
            <a class="dropdown-item" (click)="navigateTo({route: '/admin/companysettings'})"
              *ngIf="userlogindata.role == 'admin'"><i class="ri-settings-2-line align-middle mr-1"></i> Company
              Settings</a> <!-- item-->

            <!-- <a class="dropdown-item" (click)="navigateTo({route: '/admin/administrators'})">
                <i class="ri-user-add-line align-middle mr-1"></i> Administrators
              </a> -->
            <!-- item-->
            <a class="dropdown-item" data-toggle="modal" data-target=".change_password"><i
                class="mdi mdi-lock-reset align-middle mr-1"></i> Change
              Password</a>
            <!-- <a class="dropdown-item" (click)="navigateTo({route: '/forgot-password'})"><i
                     class="mdi mdi-lock-reset align-middle mr-1"></i> Forgot Password</a> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" (click)="logout()"><i
                class="ri-shut-down-line align-middle mr-1 text-danger"></i> Logout</a>
          </div>
        </div>

        <div class="dropdown d-inline-block" style="display: none !important;">
          <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
            <i class="ri-settings-2-line"></i>
          </button>
        </div>

      </div>
    </div>
  </header>
  <div class="modal fade change_password show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">Change Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-horizontal" [formGroup]="ChangePasswordForm" (ngSubmit)="ChangePassword()">
            <div class="mb-4">
              <div class="form-group auth-form-group-custom mb-0">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Password</label>
                <input [type]="password ? 'text' : 'password' " class="form-control" formControlName="password"
                  placeholder="Enter new password" (keydown.space)="$event.preventDefault()">
                <i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
                  [ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>

              </div>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.password.errors.required">Password is required
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="form-group auth-form-group-custom mb-0">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Password</label>
                <input [type]="confirmpassword ? 'text' : 'password' " class="form-control"
                  placeholder="Enter Confirm password" formControlName="confirmpassword">
                <i (click)="showConfirmPassword()" class=" auti-custom-input-icon auti-custom-eye"
                  [ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>

              </div>
              <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.confirmpassword.errors.required">Confirm password is required
                </div>
              </div>
            </div>

            <div class="mt-4">
              <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">

    <div data-simplebar class="h-100">

      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <li class=" menu-title">Menu</li>
        <ul class="metismenu list-unstyled" id="side-menu">
          <li *ngFor="let menu of menutoshow;" [routerLinkActive]="['active']"
            [class]="menu?.submenu?.length ? 'has-child': ''">
            <a [routerLink]="menu?.route ? [menu?.route] : null"
              [class]="menu?.submenu?.length ? 'waves-effect has-arrow': 'waves-effect'">
              <img *ngIf="menu?.icon" src="assets/images/menu-icon/{{menu?.icon}}.svg"
                class="{{menu?.iconnone}} {{menu?.iconsmall}}">
              <span>{{menu?.name}}</span>
              <i *ngIf="menu?.submenu?.length" class="ri-arrow-right-s-line"></i>
            </a>
            <ul *ngIf="menu?.submenu?.length" class="sub-menu">
              <li *ngFor="let submenu of menu?.submenu;" [routerLinkActive]="['active']">
                <a [routerLink]="[submenu?.route]">{{submenu?.subname}}</a>
              </li>
            </ul>
          </li>

          <!-- <li>
                   <a (click)="navigateTo('/admin/dashboard')" class=" waves-effect">
                     <i class="ri-settings-2-line"></i>
                     <span>Dashboard</span>
                   </a>
                 </li>
                 <li>
                   <a (click)="navigateTo('/admin/users')" class=" waves-effect">
                     <i class="mdi mdi-account-group-outline"></i>
                     <span>Users</span>
                   </a>
                 </li>
                 <li>
                   <a (click)="navigateTo('/admin/transactions')" class=" waves-effect">
                     <i class="ri-file-list-line"></i>
                     <span>Transactionss</span>
                   </a>
                 </li>
                 <li> -->

        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->
  <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s" *ngIf="commonmodal">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <app-alertmodal [displaymsg]="displaymsg" (myOutput)="GetChildData($event)"></app-alertmodal>
  </div>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->

  <div class="main-content">

    <router-outlet></router-outlet>

    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <script>
              document.write(new Date().getFullYear())
            </script> © 2023 Precision Tech Enterprises.
          </div>
          <div class="col-sm-6">
            <div class="text-sm-right d-none d-sm-block copyright">
              Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://greychaindesign.com/">Grey
                Chain</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
  <!-- end main content-->

</div>
<!-- END layout-wrapper -->
