import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	password: boolean;
	LoginForm: FormGroup;
	submitted = false;
	errormsg: string = '';
	role: any;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private authenticate: AuthenticationService,
		private activatedRoute: ActivatedRoute

	) {
		this.activatedRoute.params.subscribe(event => {
		})

	}

	ngOnInit(): void {
		this.LoginForm = this.formBuilder.group({
			username: ['', Validators.required],
			Password: ['', Validators.required],//
		})
	}
	get f() { return this.LoginForm.controls; }

	showPassword() {
		this.password = !this.password;
	}

	admindata: any = {}

	login() {
		this.submitted = true;
		this.commonService.presentSpinner();
		if (this.LoginForm.invalid) {
			this.commonService.dismissSpinner();
			return;
		}

		let loginBody = {
			email: this.LoginForm.value.username.toLowerCase(),
			password: this.LoginForm.value.Password
		}
		this.authenticate.login(loginBody).then(
			(res) => {

				if (res) {
					if (res.status == 200) {
						this.commonService.dismissSpinner();
						switch (res.body.AdminData.role) {
							case 'Superadmin':
								if (res.body.AdminData.role == 'Superadmin') {
									this.router.navigate(['/admin/company']);
								}
								break;
							case 'Admin':
								if (res.body.AdminData.role == 'Admin') {
									this.router.navigate(['/admin/users']);
								}
								break;
							case 'Gatekeeper':
								if (res.body.AdminData.role == 'Gatekeeper') {
									this.router.navigate(['/admin/gatekeeper']);
								}
								break;
							case 'Vendor':
								if (res.body.AdminData.role == 'Vendor') {
									this.router.navigate(['/admin/purchase-order']);
								}
								break;
							case 'Finance':
								if (res.body.AdminData.role == 'Finance') {
									this.router.navigate(['/admin/purchase-order']);
								}
								break;
							case 'Storekeeper':
								if (res.body.AdminData.role == 'Storekeeper') {
									this.router.navigate(['/admin/storekeeper']);
								}
								break;
						}

						this.LoginForm.reset();
					} else if (res.error.code == 400) {
						this.errormsg = res.error.message;
						this.commonService.dismissSpinner();
					}
				} else {
					this.commonService.dismissSpinner();
				}

			}
		)
	}
	forgotpasswormsg: boolean;
	forgotpassword() {

	}

}
