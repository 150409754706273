import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { FormsGroupService } from 'src/app/Services/formsGroup/forms-group.service';
import { ItemService } from 'src/app/Services/item/item.service';
declare var $: any;
@Component({
  selector: 'app-hsncode',
  templateUrl: './hsncode.component.html',
  styleUrls: ['./hsncode.component.scss']
})
export class HSNcodeComponent implements OnInit {
  HSNList: any = [];
  page = 1;
  count = 0;
  limit = 10;
  entries: Entries = {
    from: 0,
    to: 0,
    total: 0
  };
  addHSNForm: FormGroup;
  editHSNForm: FormGroup;
  commonmodal: boolean;
  displaymsg: any;
  submitted = false;
  keyword: string = '';
  constructor(
    private formsGroupService: FormsGroupService,
    private itemService: ItemService,
    private commonService: CommonService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.addHSNForm = this.formsGroupService.AddHSN();
    this.editHSNForm = this.formsGroupService.EditHSN();
    this.GetHSNList(this.page);
  }
  get a() {
    return this.addHSNForm.controls;
  }
  get f() {
    return this.editHSNForm.controls;
  }
  searchHSN(value) {
    this.keyword = value;
    this.GetHSNList(this.page);
  }
  GetHSNList(page) {
    this.page = page;
    this.commonService.presentSpinner();
    this.itemService.getHSNList(this.page, this.keyword.length >= 3 ? this.keyword : this.keyword).then(
      (res) => {
        if (res.code == 200) {
          this.HSNList = res.hsnList;
          this.count = res.totalResult;
          this.commonService.dismissSpinner();
          this.entries = this.commonService.calculateEntries(this.HSNList, page, this.limit, this.count);
        }
      }
    )
  }

  addHSN() {
    this.commonService.presentSpinner();
    this.submitted = true;
    if (this.addHSNForm.invalid) {
      this.commonService.dismissSpinner();
      return;
    }
    let postdata = {
      "hsnNumber": this.addHSNForm.value.hsnnumber,
      "gstRate": this.addHSNForm.value.GST
    }

    this.itemService.addHSN(postdata).then(
      (res) => {
        if (res.code == 200) {
          this.GetHSNList(this.page);
          $('#add_detail').modal('hide');
          this.addHSNForm.reset();
          this.submitted = false;
          this.commonService.dismissSpinner();
          this.displaymsg = res.message;
          this.commonmodal = true;
        } else {
          this.commonService.dismissSpinner();
          this.displaymsg = res.message;
          this.commonmodal = true;
        }
      }
    )
  }

  editHSN() {

    this.commonService.presentSpinner();
    this.submitted = true;
    if (this.editHSNForm.invalid) {
      this.commonService.dismissSpinner();
      return;
    }

    let postdata = {
      "hsnNumber": this.editHSNForm.value.hsnnumber,
      "gstRate": this.editHSNForm.value.GST
    }
    var hsnid = this.editHSNForm.value.id
    this.itemService.editHSN(hsnid, postdata).then(
      (res) => {
        if (res.code == 200) {
          this.GetHSNList(this.page);
          this.submitted = false;
          $('#edit_detail').modal('hide');
          this.commonService.dismissSpinner();
          this.displaymsg = res.message;
          this.commonmodal = true;
        } else {
          this.commonService.dismissSpinner();
          this.displaymsg = res.message;
          this.commonmodal = true;
        }
      }
    )
  }

  hsnid: any;
  opemModals(val, data) {

    if (val == "addModal") {
      this.addHSNForm.reset();
      this.submitted = false;
      $('#add_detail').modal('show');
    } else if (val == "delete") {
      $('#delete-item').modal('show');
      this.hsnid = data._id;
    } else if (val == "edit") {
      this.submitted = false;

      $('#edit_detail').modal('show');
      this.editHSNForm.patchValue({
        "id": data._id,
        "hsnnumber": data.hsnNumber,
        "GST": data.gstRate
      })

    }
  }
  HSNDelete(hsnid) {
    this.commonService.presentSpinner();
    this.itemService.Deletehsn(hsnid).then(
      (res) => {

        if (res.code == 200) {
          this.commonService.dismissSpinner();
          $('#delete-item').modal('hide');
          this.GetHSNList(this.page);
          this.commonService.dismissSpinner();
          // this.displaymsg = res.message;
          // this.commonmodal = true;
        } else {
          this.commonService.dismissSpinner();
          this.displaymsg = res.message;
          this.commonmodal = true;
        }
      }
    )
  }
  GetChildData(data) {
    if (data) {
      this.commonmodal = false;
    }
  }
}
