<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item active">Sales</li>
						</ol>
					</div>
					<div class="page-title-right2"
						*ngIf="userlogindata.role == 'Finance' || userlogindata.role == 'Vendor'">
						<button type="button" data-toggle="modal" data-target=".add_detail"
							class="btn btn-rounded btn-warning btn-w waves-effect waves-light">
							Add
						</button>
						<button type="button" class="btn btn-rounded btn-secondary btn-w " (click)="exportAsXLSX()">
							Download Template
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Finance Eunctionality Available -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="table-responsive">
							<div class="row">
								<div class="col-sm-12">
									<div class="filter-all">
										<div class="d-flex align-items-center justify-content-between">
											<div class="page-title-right2">
												<div class="select_box select_box_text">
													<svg xmlns="http://www.w3.org/2000/svg" width="33.123"
														height="35.858" viewBox="0 0 33.123 35.858">
														<g id="Group_86" data-name="Group 86"
															transform="translate(857.079 -756.758)">
															<path id="Path_2227" data-name="Path 2227"
																d="M95.806,83.085H76.251a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H68.6a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM72.427,85.835a1.467,1.467,0,1,1,1.467-1.467A1.468,1.468,0,0,1,72.427,85.835Z"
																transform="translate(-921.196 676.574)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2228" data-name="Path 2228"
																d="M95.806,211.618H92.753a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H85.106a4.033,4.033,0,0,0,7.647,0h3.052a1.284,1.284,0,0,0,0-2.568Zm-6.876,2.751A1.467,1.467,0,1,1,90.4,212.9,1.468,1.468,0,0,1,88.93,214.368Z"
																transform="translate(-921.196 561.785)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
															<path id="Path_2229" data-name="Path 2229"
																d="M95.806,340.151H81.752a4.033,4.033,0,0,0-7.647,0H65.551a1.284,1.284,0,0,0,0,2.568H74.1a4.033,4.033,0,0,0,7.647,0H95.806a1.284,1.284,0,0,0,0-2.568ZM77.928,342.9a1.467,1.467,0,1,1,1.466-1.467A1.468,1.468,0,0,1,77.928,342.9Z"
																transform="translate(-921.196 446.996)" fill="#505d69"
																stroke="#505d69" stroke-width="0.3" />
														</g>
													</svg>
												</div>
												<div class="select_box ml-3">
													<!-- <div class="input-group date_group">

														<div id="reportrange" class="reportrange"
															style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
															<i class="fa fa-calendar"></i>&nbsp; <span></span> <i
																class="fa fa-caret-down"></i>
														</div>
													</div> -->
													<div class="input-group" daterangepicker [options]="picker1"
														(selected)="selectedDate($event, chosenDate)">
														<span class="form-control uneditable-input" name="daterange">

															<span *ngIf="chosenDate.start else elseblock">
																{{ chosenDate.start | date:'mediumDate' }} - {{
																chosenDate.end| date:'mediumDate' }}
															</span>
															<ng-template #elseblock>
																<span><div class="calendar-icon"><span>Select date</span><i class="ri-calendar-2-line"></i></div></span>
															</ng-template>
														</span>
														<span class="input-group-btn">
															<a type="button" class="btn btn-default"><i
																	class="glyphicon glyphicon-calendar"></i></a>
														</span>
													</div>
												</div>
												<div class="price_range">
													<label>Amount</label>
													<ngx-slider [(value)]="minValue" [(highValue)]="maxValue"
														[options]="options"></ngx-slider>
												</div>
												<div class="select_box ml-2">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories">
														<option value="" selected>Business Unit</option>
														<option value="1">New Delhi</option>
														<option value="2">Gurgaon Plant</option>
														<option value="3">Greater Noida Plant</option>
													</select>
												</div>
												<div class="select_box ml-2">
													<select
														class=" custom-select custom-select-sm form-control form-control-sm"
														name="selectcategories" id="selectcategories">
														<option value="" selected>Customer</option>
														<option value="1">Customer1</option>
														<option value="2">Customer2</option>
														<option value="3">Customer3</option>
													</select>
												</div>
												<div class="select_box ml-2">
													<div class="input-group-reset">
														<button type="button" class="btn custom-select-sm"><i
																class="mdi text-primary font-size-18 mdi-refresh"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<div class="dataTables_filter">
											<label>
												<input type="search" class="form-control form-control-sm"
													placeholder="Search...">
											</label>
										</div>
									</div>
								</div>
							</div>
							<table class="table mb-0">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th style="width:90px;">Invoice #<label class="tb-search"><input type="search" class="form-control form-control-sm"></label></th>
										<th style="width:90px;">Date<label class="tb-search"><input type="search" class="form-control form-control-sm"></label></th>
										<th style="width:75px;">Amount<label class="tb-search"><input type="search" class="form-control form-control-sm"></label></th>
										<th style="width:75px;">GST<label class="tb-search"><input type="search" class="form-control form-control-sm"></label></th>
										<th style="width:120px;">Business Unit<label class="tb-search"><input type="search" class="form-control form-control-sm"></label></th>
										<th>Customer Details<br><label class="tb-search w-100"><input type="search" class="form-control form-control-sm w-100"></label></th>
										<th style="width:110px;">Finance Approve</th>
										<th style="width: 85px;">Action</th>
									</tr>
								</thead>
								<!-- <tbody>
									<tr [hidden]="invoiceList?.length">
										<td colspan="7">
											<h6 class=" mb-0" style="color: #545a60;">No records found</h6>
										</td>
									</tr>
									<tr
										*ngFor="let list of invoiceList | paginate: { id:'invoiceList',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Invoice #">{{list.invoiceNumber}}</td>
										<td data-title="Date">{{list.invoiceDate}}</td>
										<td data-title="Amount">₹ {{list.invoiceDate}}</td>
										<td data-title="GST">₹ {{list.gstAmount}}</td>
										<td data-title="Business Unit">{{list.businessUnit}}</td>
										<td data-title="Customer Details"> {{list.customerDetail}}
										</td>
										<td data-title="Finance Approve">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"> <span
													class="slider round"></span>
											</label>
										</td>
										<td data-title="Action"> <a
												[routerLink]="['/admin/invoices-sales/invoice-details']"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
											<button type="button" class="mr-2 text-primary btn_style"><i
													class="ri-file-list-3-line font-size-16"></i>
											</button>
											<button type="button" data-toggle="modal" data-target=".edit_detail"
												class="mr-2 text-primary btn_style"><i
													class="mdi mdi-pencil font-size-18"></i>
											</button> <a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>

								</tbody> -->
								<tbody>
									<tr>
										<td data-title="No.">1</td>
										<td data-title="Invoice #">#4561234</td>
										<td data-title="Date">Mar 25, 2021</td>
										<td data-title="Amount">₹ 800</td>
										<td data-title="GST">₹ 159</td>
										<td data-title="Business Unit">New Delhi</td>
										<td data-title="Customer Details"> <strong>OKB9</strong>
											<br>Change Automatic Account Assignment
										</td>
										<td data-title="Finance Approve">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"> <span
													class="slider round"></span>
											</label>
										</td>
										<td data-title="Action"> <a
												[routerLink]="['/admin/invoices-sales/invoice-details']"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>

                        <a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr>
										<td data-title="No.">2</td>
										<td data-title="Invoice #">#4561234</td>
										<td data-title="Date">Mar 28, 2021</td>
										<td data-title="Amount">₹ 300</td>
										<td data-title="GST">₹ 149</td>
										<td data-title="Business Unit">Gurgaon Plant</td>
										<td data-title="Customer Details"> <strong>OKB9</strong>
											<br>Change Automatic Account Assignment
										</td>
										<td data-title="Finance Approve">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"> <span
													class="slider round"></span>
											</label>
										</td>
										<td data-title="Action"> <a
												[routerLink]="['/admin/invoices-sales/invoice-details']"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>

                        <a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
									<tr>
										<td data-title="No.">3</td>
										<td data-title="Invoice #">#4561234</td>
										<td data-title="Date">Mar 30, 2021</td>
										<td data-title="Amount">₹ 500</td>
										<td data-title="GST">₹ 119</td>
										<td data-title="Business Unit">Greater Noida Plant</td>
										<td data-title="Customer Details"> <strong>OKB9</strong>
											<br>Change Automatic Account Assignment
										</td>
										<td data-title="Finance Approve">
											<label class="switch">
												<input type="checkbox" id="verify" value="1"> <span
													class="slider round"></span>
											</label>
										</td>
										<td data-title="Action"> <a
												[routerLink]="['/admin/invoices-sales/invoice-details']"
												class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>

                        <a href="javascript:void(0);" class="text-danger"><i
													class="mdi mdi-trash-can font-size-18"></i></a>
										</td>
									</tr>
								</tbody>
							</table>
							<!-- <div class="row" [hidden]="!invoiceList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<pagination-controls id="invoiceList" responsive="true" previousLabel="Prev"
										nextLabel="Next" (pageChange)="onTableDataChange($event)">
									</pagination-controls>
								</div>
							</div> -->
							<div class="row">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing 1 to 50 of 51 entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<ul class="pagination pagination-rounded">
											<li class="paginate_button page-item previous disabled"><a href="#"
													class="page-link"><i class="mdi mdi-chevron-left"></i></a>
											</li>
											<li class="paginate_button page-item active"><a href="#"
													class="page-link">1</a>
											</li>
											<li class="paginate_button page-item "><a href="#" class="page-link">2</a>
											</li>
											<li class="paginate_button page-item next"><a href="#" class="page-link"><i
														class="mdi mdi-chevron-right"></i></a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->
<!-- Start Modal Add Detail -->
<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Add Invoice</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Upload Excel </label>
								<div class="custom_file">
									<input type="file" id="customFile" class="custom_file_input"
										(change)="handleFileInput($event.target.files)">


								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit" [disabled]="!fileToUpload"
						(click)="SaveFile()">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<!-- Start Modal Edit Detail -->
<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Invoice</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Upload Excel</label>
								<div class="custom_file">
									<input type="file" id="customFile" class="custom_file_input">


								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->
