import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService, Entries } from 'src/app/Services/common/common.service';
import { ItemgroupService } from 'src/app/Services/DataManagement/ItemGroup/itemgroup.service';
declare var $: any;

@Component({
  selector: 'app-item-groups',
  templateUrl: './item-groups.component.html',
  styleUrls: ['./item-groups.component.scss']
})
export class ItemGroupsComponent implements OnInit {
  addItemGroupForm: FormGroup;
  itemFormSubmitted: boolean;
  itemCode: any;
  item_id: any;
  itemGroupToBeEdited: any;
  page:number = 1;
  limit : number = 10
  apiHitDone:boolean;
  search:string;
  itemGroupList:Array<any>;
  totalResult:number;
  entries: Entries;
  constructor(
    private itemGroupService: ItemgroupService,
    private commonService: CommonService,

  ) {
    this.addItemGroupForm = new FormGroup({
      "itemGroupCode": new FormControl('', Validators.compose([Validators.required])),
      "itemGroupDesc": new FormControl('', Validators.compose([Validators.required])),
    });
    this.getitemGroup();
  }

  ngOnInit(): void {
  }

  opemModals(val, data) {

    if (val == "delete") {
      $('#delete-item').modal('show');
      this.item_id = data._id;
      this.itemCode = data.itemCode
    }
  }

  getitemGroup(page?:number){
    this.apiHitDone = false;
    this.page = page || 1
    let payload = {
      page:this.page.toString(),
      limit:this.limit.toString(),
      search:this.search
    }
    this.commonService.presentSpinner();
    this.itemGroupService.getitemGroup(payload).then(
      (res:any)=>{
        if(res['body'].code == 200 && res['body']['itemGroupList'].length){
          this.itemGroupList = res['body']['itemGroupList'];
          this.totalResult =  res['body'].totalResult;
          this.entries = this.commonService.calculateEntries(this.itemGroupList, this.page, this.limit, this.totalResult);
        }else{
          this.itemGroupList = [];
        }
      }
    ).catch((error)=>{
      this.commonService.presentErrorToast(error.error,'')
    }).finally(()=>{
      this.apiHitDone = true;
      this.commonService.dismissSpinner();
    })
  }

  openItemGroupModal(itemDetail?: any) {
    this.addItemGroupForm.reset();
    this.itemFormSubmitted = false;
    this.itemGroupToBeEdited = null;
    if(itemDetail){
        this.itemGroupToBeEdited = { ...itemDetail };
        this.addItemGroupForm.patchValue(itemDetail)
    }
    $('#item_group').modal('show');
  }

  addUpdateItem() {
    let promise: Promise<any> = null;
    let payload = null;
    this.itemFormSubmitted = false;
    if (this.addItemGroupForm.invalid) {
      this.itemFormSubmitted = true;
      return;
    }
    if (this.itemGroupToBeEdited) {
      payload = this.addItemGroupForm.value;
      promise = this.itemGroupService.updateItemGroup(payload, this.itemGroupToBeEdited.id);
    } else {
      payload = this.addItemGroupForm.value;
      promise = this.itemGroupService.addItemGroup(payload);
    }
    this.commonService.presentSpinner();
    promise.then(
      (res: any) => {
        if (res) {
          if (this.itemGroupToBeEdited) {
            this.commonService.presentSuccessToast('', 'Update successfully!');
          } else {
            this.commonService.presentSuccessToast('', 'Added successfully!');
          }
        }
      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner();
      this.addItemGroupForm.reset();
      this.getitemGroup();
      $('#item_group').modal('hide');
    })
  }

  toBeDeletedId:any;
  openDeleteModal(Id) {
    this.toBeDeletedId = Id;
	    if (this.toBeDeletedId) {
        $('#delete-item').modal('show');
      }
	}

  deleteItemGroup(){
    this.commonService.presentSpinner();
    this.itemGroupService.deleteItemGroup(this.toBeDeletedId).then(
      (res: any) => {
        if(res.code == 200){
          this.commonService.presentSuccessToast(res.message,'');
        }
      }
    ).catch((error) => {
      this.commonService.presentSuccessToast(error.error.message,'');
    }).finally(() => {
      $('#delete-item').modal('hide');
      this.commonService.dismissSpinner();
      this.getitemGroup(1);
    })
  }
}
