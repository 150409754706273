<div class="page-content">
	<div class="container-fluid">
		<!-- start page title -->
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item"><a [routerLink]="['/admin/sales-po']">Sales PO</a>
							</li>
							<li class="breadcrumb-item active">Details</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
		<!-- end page title -->
		<div class="row">
			<div class="col-lg-12">
				<!-- Invoice -->
				<div class="card table_page">
					<div class="card-body pt-3">
						<div class="invoice_box table-responsive">
							<!-- Header -->
							<div class="filter-all purchase-order-edit">
								<div class="d-flex align-items-center justify-content-between">
									<h4 class="card-title mb-2">Sales PO</h4>
									<div class="dataTables_filter">
										<button type="button"
											class="btn btn-rounded btn-warning btn-w waves-effect waves-light mb-2"
											(click)="openEditModal()">Edit</button>
									</div>
								</div>
							</div>
							<table class="table table-bordered ">
								<thead class="thead-light">
									<tr>
										<th style="width: 80px;">Sales PO No #</th>
										<th style="width: 80px;">Plant</th>
										<th>Customer Name</th>
										<th>Customer Code</th>
										<th>PO Date</th>
										<th>Amount (₹)</th>
										<th>GST (₹)</th>
										<th>Total Amount (₹)</th>
										<th>Created Date</th>
										<th>Last Updated</th>
										<th>Type</th>
										<th>Valid Up-To</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td data-title="No.">#{{getSalesPODetail?.po_number}}</td>
										<td data-title="No.">{{getSalesPODetail?.plant?.unitName}}</td>
										<td data-title="Customer Name">{{getSalesPODetail?.customerId?.CustomerName}}
										</td>
										<td data-title="Customer Code">{{getSalesPODetail?.customerId?.CustomerCode}}
										</td>
										<td data-title="PO Date">{{getSalesPODetail?.poDate | date : 'MMM d, y'}}</td>
										<td data-title="Amount">{{getSalesPODetail?.amount}}</td>
										<td data-title="GST">{{getSalesPODetail?.gst}}</td>
										<td data-title="Total Amount">{{getSalesPODetail?.tatalAmount}}</td>
										<td data-title="Created Date">{{getSalesPODetail?.createdDate | date : 'MMM d, y'}}</td>
										<td data-title="Last Updated">{{getSalesPODetail?.updatedDate | date : 'MMM d, y'}}</td>
										<td data-title="Type">{{getSalesPODetail?.type}}</td>
										<td data-title="Valid Up-To">{{getSalesPODetail?.validUpToDate | date : 'MMM d, y'}}</td>
									</tr>
								</tbody>
							</table>

						</div>
						<div class="row">
							<div class="col-sm-12">
								<div class="filter-all">
									<div class="d-flex align-items-center justify-content-between">
										<h4 class="card-title mb-2">Order Details</h4>
										<div class="dataTables_filter">
											<label>
												<button type="button"
													class="btn btn-rounded btn-warning btn-w waves-effect waves-light btn-new-item ml-0"
													(click)="openNewProductModal()">Add New
													Product</button>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive table_responsive_scroll">

							<table class="table mb-0 responsive_scroll">
								<thead class="thead-light">
									<tr>
										<th style="width:30px;">No.</th>
										<th>Product Code</th>
										<th>Product Name</th>
										<th>Product Description</th>
										<th>Valid From</th>
										<th>Rate</th>
										<th>Unit</th>
										<th style="width:120px;" class="span-box qty_box">QTY<br>
											<div class="span-box2"><span class="rate">Delivered</span>
												<span class="amount ">Ordered</span>
											</div>
										</th>
										<th>Amount (₹)</th>
										<th style="width:90px;" class="span-box">CGST<br>
											<div class="span-box2"><span class="rate">Rate %</span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:90px;" class="span-box">SGST<br>
											<div class="span-box2"><span class="rate">Rate %</span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:90px;" class="span-box">IGST<br>
											<div class="span-box2"><span class="rate">Rate %</span>
												<span class="amount ">Amount (₹)</span>
											</div>
										</th>
										<th style="width:85px;">Total Amount (₹)</th>
										<th style="width:85px;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let list of salesOPProductList | paginate: {id: 'salesOPProductList',itemsPerPage: limit,currentPage: page,totalItems: total}; let i = index;">
										<td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
										<td data-title="Product Code">{{list?.productId?.code}}</td>
										<td data-title="Item">{{list?.productId?.name}}</td>
										<td data-title="Description">{{list?.productId?.description}}</td>
										<td data-title="Valid From">{{list?.validFromDate | date : 'MMM d, y'}}</td>
										<td data-title="Rate"><span class="action-checkbox"><label
													class="percent mr-2">₹ {{list?.rate}}</label></span></td>
										<td data-title="Unit">{{list?.productId?.unit}}</td>
										<td data-title="QTY (Delivered / Order)" class="span-box-td qty_box">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list?.quantityDelieved}}</span><span
													class="amount">{{list?.quantityOrdered}}</span></div>
										</td>
										<td>{{list?.amount}}</td>
										<td data-title="CGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list?.CGSTRate}}</span><span
													class="amount">{{list?.CGSTAmount}}</span></div>
										</td>
										<td data-title="SGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list?.SGSTRate}}</span><span
													class="amount">{{list?.SGSTAmount}}</span></div>
										</td>
										<td data-title="IGST (Rate % / Amount)" class="span-box-td">
											<div class="span-box2 span-box-d"><span
													class="rate">{{list?.IGSTRate}}</span><span
													class="amount">{{list?.IGSTAmount}}</span></div>
										</td>
										<td data-title="Total Amount">{{list?.totalAmount}}</td>
										<td data-title="Action">
											<button type="button" (click)="openNewProductModal(list)"
												class="mr-2 text-primary btn_style">
												<i class="mdi mdi-pencil font-size-18"></i>
											</button>
											<a href="javascript:void(0);" class="text-danger"
												(click)="deleteModal(list?._id)">
												<i class="mdi mdi-trash-can font-size-18"></i>
											</a>
										</td>
									</tr>
									<tr *ngIf="apiHitDone && !salesOPProductList?.length">
										<td colspan="10">
											<h5 class="text-center">No Record found!</h5>
										</td>
									</tr>
								</tbody>
							</table>

							<div class="row" *ngIf="salesOPProductList?.length">
								<div class="col-sm-12 col-md-5">
									<div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
										{{entries?.total}} entries</div>
								</div>
								<div class="col-sm-12 col-md-7">
									<div class="dataTables_paginate paging_simple_numbers">
										<pagination-controls id="salesOPProductList" [autoHide]="true" responsive="true"
											previousLabel="Prev" nextLabel="Next"
											(pageChange)="getSalesPOProductList($event)">
										</pagination-controls>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- container-fluid -->
</div>
<!-- page-content -->






<!-- Start Modal Add Delivery Instructions -->
<div class="modal fade bulk_add_instructions" tabindex="-1" id="bulk_add_instructions" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Upload Delivery Instructions</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">
						<div class="col-lg-12">

							<div class="form-group"><label class="control-label">Upload Excel</label>
								<div class="custom_file custom_img_input">
									<input type="file" class="custom_file_input" accept=".xlsx">
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>


<!-- Add Instruction Popup -->
<div class="modal fade add_instruction" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Add Delivery Instruction</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">PO Number</label>
								<input type="text" class="form-control" value="1234#12">
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">DI Number</label>
								<input type="text" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Original DI Date</label>
								<input type="date" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Revised DI Date</label>
								<input type="date" class="form-control">
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Product Code</label>
								<select class="form-control">
									<option value="" disabled selected>Select Product Code</option>
									<option value="MSP001089">MSP001089</option>
									<option value="MSP001090">MSP001090</option>
									<option value="MSP001091">MSP001091</option>
									<option value="MSP001092">MSP001092</option>
									<option value="MSP001093">MSP001093</option>
								</select>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Scheduled Quantity</label>
								<input type="number" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Unloading Location</label>
								<input type="text" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Usage Location</label>
								<input type="text" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Date</label>
								<input type="date" class="form-control">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Time</label>
								<input type="time" class="form-control">
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Add Instruction Popup -->



<!-- Edit Instruction Popup -->
<div class="modal fade edit_instruction" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Delivery Instruction</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">PO Number</label>
								<input type="text" class="form-control" value="1234#12">
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">DI Number</label>
								<input type="text" class="form-control" value="EK79434304K11">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Original DI Date</label>
								<input type="date" class="form-control" value="2022-05-27">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Revised DI Date</label>
								<input type="date" class="form-control">
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Product Code</label>
								<select class="form-control">
									<option value="" disabled>Select Product Code</option>
									<option value="MSP001089" selected>MSP001089</option>
									<option value="MSP001090">MSP001090</option>
									<option value="MSP001091">MSP001091</option>
									<option value="MSP001092">MSP001092</option>
									<option value="MSP001093">MSP001093</option>
								</select>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label for="validationCustom01">Scheduled Quantity</label>
								<input type="number" class="form-control" value="672">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Unloading Location</label>
								<input type="text" class="form-control" value="A1-32">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Usage Location</label>
								<input type="text" class="form-control" value="A16">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Date</label>
								<input type="date" class="form-control" value="2022-05-27">
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-group">
								<label for="validationCustom01">Supply Time</label>
								<input type="time" class="form-control" value="11:44">
							</div>
						</div>

					</div>
					<button class="btn btn-warning" type="submit">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- End Edit Instruction Popup -->



<!-- Start Modal Add Item -->
<app-sales-po-product [toBeEditedDate]="toBeEditedData" (sendAction)="GetChildData($event)"></app-sales-po-product>
<!-- /.modal -->


<!-- Start Modal Edit Item -->
<div class="modal fade editItem_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Item</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form>
					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label>Product Code <span style="color: red;">*</span></label>
								<input type="text" class="form-control" value="MSP001089">
							</div>
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label>Product Name <span style="color: red;">*</span></label>
								<input type="text" class="form-control" value="Item 1">
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label>Product Description <span style="color: red;">*</span></label>
								<textarea rows="2" class="form-control">Tool die steel bar 2738HH</textarea>
							</div>
						</div>

						<div class="col-sm-12">
							<div class="form-group">
								<label>Valid From <span style="color: red;">*</span></label>
								<input type="date" class="form-control" value="2022-07-02">
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Unit <span style="color: red;">*</span></label>
								<input type="text" class="form-control" value="NOS">
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Rate (₹) <span style="color: red;">*</span></label>
								<input type="number" class="form-control" value="5.54">
							</div>
						</div>

						<div class="col-lg-12">
							<label>QTY</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Delivered" value="0">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Ordered *" value="80000">
							</div>
						</div>

						<div class="col-sm-12">
							<div class="form-group">
								<label>Amount (₹) <span style="color: red;">*</span></label>
								<input type="number" class="form-control" value="443200">
							</div>
						</div>

						<div class="col-lg-12">
							<label>CGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Rate %" value="9">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Amount" value="39888">
							</div>
						</div>
						<div class="col-lg-12">
							<label>SGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Rate %" value="9">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Amount" value="39888">
							</div>
						</div>
						<div class="col-lg-12">
							<label>IGST</label>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Rate %" value="0">
							</div>
						</div>
						<div class="col-sm-6">
							<div class="form-group">
								<input type="number" class="form-control" placeholder="Amount" value="0">
							</div>
						</div>
						<div class="col-sm-12">
							<div class="form-group">
								<label>Total Amount (₹) <span style="color: red;">*</span></label>
								<input type="number" class="form-control" value="522976">
							</div>
						</div>
					</div>

					<button type="submit" class="btn btn-warning">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-- /.modal -->


<div class="modal fade sales_order_edit" id="sales_order_edit" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content table_page">
			<div class="modal-header">
				<h5 class="modal-title mt-0">Edit Sales Order Detail </h5>

				<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
						aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body card-body">
				<form [formGroup]="editSalePOForm">
					<div class="row">

						<div class="col-lg-12">
							<div class="form-group">
								<label>Plant <span style="color: red;">*</span></label>
								<select class="form-control" formControlName="plant">
									<option value="">--Select Plant--</option>
									<option value="{{plant?._id}}" *ngFor="let plant of unitlist">{{plant?.unitName}}
									</option>
								</select>
								<div *ngIf="editFormSubmitted && editSalePO.plant.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.plant.errors.required">Plant is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label>Customer Name <span style="color: red;">*</span></label>
								<select class="form-control" formControlName="customerId" (change)="getCustomerCode()">
									<option value="">-- Select Customer --</option>
									<option value="{{customer?._id}}" *ngFor="let customer of customer">
										{{customer?.CustomerName}}</option>
								</select>
								<div *ngIf="editFormSubmitted && editSalePO.customerId.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.customerId.errors.required">Customer is required</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div class="form-group">
								<label>Customer Code <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="customerCode" readonly>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>PO Date <span style="color: red;">*</span></label>
								<input type="date" class="form-control" formControlName="poDate">
								<div *ngIf="editFormSubmitted && editSalePO.poDate.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.poDate.errors.required">PoDate is required</div>
								</div>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Amount (₹) <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="amount">
								<div *ngIf="editFormSubmitted && editSalePO.amount.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.amount.errors.required">Amount is required</div>
								</div>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>GST (₹) <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="gst">
								<div *ngIf="editFormSubmitted && editSalePO.gst.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.gst.errors.required">GST is required</div>
								</div>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Total Amount (₹) <span style="color: red;">*</span></label>
								<input type="text" class="form-control" formControlName="tatalAmount">
								<div *ngIf="editFormSubmitted && editSalePO.tatalAmount.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.tatalAmount.errors.required">Tatal Amount is required</div>
								</div>
							</div>
						</div>

						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>Created Date</label>
								<input type="date" class="form-control">
							</div>
						</div> -->

						<!-- <div class="col-sm-6">
							<div class="form-group">
								<label>Last Updated</label>
								<input type="date" class="form-control">
							</div>
						</div> -->

						<div class="col-sm-6">
							<div class="form-group">
								<label>Type <span style="color: red;">*</span></label>
								<select class="form-control" formControlName="type">
									<option value="Open" selected>Open</option>
									<option value="Close">Close</option>
								</select>
								<div *ngIf="editFormSubmitted && editSalePO.type.errors" class="invalid-feedback"
									style="display: block;">
									<div *ngIf="editSalePO.type.errors.required">Type is required</div>
								</div>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label>Valid Up-To <span style="color: red;">*</span></label>
								<input type="date" class="form-control" formControlName="validUpToDate">
								<div *ngIf="editFormSubmitted && editSalePO.validUpToDate.errors"
									class="invalid-feedback" style="display: block;">
									<div *ngIf="editSalePO.validUpToDate.errors.required">Valid Up To Date is required
									</div>
								</div>
							</div>
						</div>

					</div>
					<button type="submit" class="btn btn-warning" (click)="updateSalesPO()">Save</button>
				</form>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>


<div class="modal sc-ion-alert-ios-h sc-ion-alert-ios-s" id="delete_modal">
	<div class="sc-ion-alert-ios-backdrop"></div>
	<div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
		<div class="alert-head sc-ion-alert-ios">
			<h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Item</h2>
		</div>
		<div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
		<div class="alert-button-group sc-ion-alert-ios">
			<button type="button" class="alert-button" (click)="deleteSaleProductItem()"><span
					class="alert-button-inner sc-ion-alert-ios">Yes</span>
			</button>
			<button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
					class="alert-button-inner sc-ion-alert-ios">No</span></button>
		</div>
	</div>
</div>